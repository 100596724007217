import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as financeService from "../services/finance";

const _getFinanceData = data => ({
  type: actionTypes.FINANCE_DATA,
  payload: data
});

export const getFinanceData = reqBody => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return financeService
      .getFinanceData(token, reqBody)
      .then(res => {
        //console.log('response get Data', res);
        return dispatch(_getFinanceData(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};
