import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons'

const lineStyle = {
  border:"1px solid white", 
  width:"inherit", 
  height: "2px", 
  display: "inline-block", 
  borderRadius: "5px",
  marginBottom: "5px"
}

const ProgressionBar = ({ progressionValues, progressionStates, state }) => {
  const renderBar = () => {
    const array = [];
    let counter = 0;
    progressionValues.forEach(value => {
      array.push(
        <div key={value.title + "-main"} className="col-xs-4" style={{padding:"0px 20px"}}>
          <div style={{padding:"0px", zIndex:"20", backgroundColor:"#283048", position:"relative"}}>
            <div className="fa-layers">
              <FontAwesomeIcon icon={faCircle} size="lg" color={state >= counter ? "#0365F4" : "#94A3B8"}/>
              <span className="fa-layers-text" style={{fontSize:"12px", marginLeft:"2px", left:state > counter ? "3px" : "50%"}}>
                { state > counter ? <FontAwesomeIcon icon={faCheck} color="white"/> : counter + 1 }
              </span>
            </div>
            <span className="disable-under-360" style={{fontSize:"10px", verticalAlign: "text-top", fontWeight:state === counter ? "bold" : "normal", padding:"0px 0px 0px 10px"}}>
              {value.title}
            </span>
          </div>
        </div>
      );
      counter++;
    })
    return array;
  };

	return (
		<div className="row fa-layers" style={{margin:"0px", width:"100%"}}>
      { renderBar() }
      <div className="fa-layers-text" style={{width:"80%"}}>
        <span style={lineStyle}></span>
      </div>
    </div>
	);
};

export default ProgressionBar;