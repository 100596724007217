import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import * as helper from "../../general/core/helper";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import ModelCharts from "./modelCharts";

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y +
  height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y +
  height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = props => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

export default class AreaCharts extends PureComponent {
  state = {
    show: false
  };

  renderTable = () => {
    this.setState({
      show: !this.state.show
    });
  };
  hideModel = eve => {
    this.setState({
      show: eve
    });
  };

  render() {
    const { data, meta_data } =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id]
        ? this.props.data.barChartData[this.props.chart_id]
        : [];

    const mdata =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id]
        ? this.props.data.barChartData[this.props.chart_id]
        : [];

    let xLines = meta_data ? Object.keys(meta_data) : [];
    xLines = xLines.filter(item => {
      return item !== "xVal";
    });

    const DataFormater = number => {
      if (number > 1000000000) {
        return (number / 1000000000).toString() + "B";
      } else if (number > 1000000) {
        return (number / 1000000).toString() + "M";
      } else if (number > 1000) {
        return (number / 1000).toString() + "K";
      } else {
        return number.toString();
      }
    };

    if (data) {
      sortByMonth(data);
    }
    function sortByMonth(arr) {
      var months = [
        "Jan-2019",
        "Feb-2019",
        "Mar-2019",
        "Apr-2019",
        "May-2019",
        "Jun-2019",
        "Jul-2019",
        "Aug-2019",
        "Sep-2019",
        "Oct-2019",
        "Nov-2019",
        "Dec-2019"
      ];
      arr.sort(function(a, b) {
        return months.indexOf(a.month_year) - months.indexOf(b.month_year);
      });
    }

    if (data) {
      data.sort(function(a, b) {
        if (a.date) {
          let aComps = a.date.split(".");
          let bComps = b.date.split(".");

          var aDate = new Date(aComps[2], aComps[1], aComps[0]);
          var bDate = new Date(bComps[2], bComps[1], bComps[0]);
          return aDate - bDate;
        } else {
          return null;
        }
      });
    }

    return (
      <>
        <AreaChart
          width={parseInt(this.props.width)}
          height={parseInt(this.props.height)}
          data={data}
          lineColor="#fffff"
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5
          }}
          onClick={this.renderTable}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey={`${meta_data ? meta_data.xVal : ""}`} />
          <YAxis type="number" unit="€" tickFormatter={DataFormater} />
          <Tooltip
            cursor={{ stroke: "orange", strokeWidth: 3 }}
            formatter={helper.renderToolTip}
          />

          {xLines &&
            xLines.map((yAxis, index) => {
              return (
                <Area
                  key={index}
                  type="monotone"
                  dataKey={`${meta_data ? meta_data[yAxis] : ""}`}
                  stroke={"#2E80B2"}
                  activeDot={{ r: 6 }}
                />
              );
            })}

          {/*<Area dataKey="uv" stackId="a" fill="#69D874" />
        <Area dataKey="failed" stackId="a" fill="#E84F40" />*/}
        </AreaChart>
        {this.state.show && (
          <ModelCharts
            onHide={this.hideModel}
            show={this.state.show}
            tabdata={data ? mdata : []}
            tablable={data ? this.props.label : ""}
          />
        )}
      </>
    );
  }
}
