import React, { Component } from "react";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
`;
 
export class LoadingScreen extends Component {
  constructor(props) {
  	super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    return (
		<div style={{ zIndex:"200", position:"absolute", width:"100%" }}>
			<div className='sweet-loading' style={{ paddingTop:"25vh", backgroundColor:"rgba(255, 255, 255, 0)" }}>
				<ClipLoader
					css={override}
					sizeUnit={"px"}
					size={150}
					color={'#0365F4'}
					loading={this.state.loading}
				/>
			</div>
		</div>
    )
  }
}