import React, { PureComponent } from "react";
import * as helper from "../../general/core/helper";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import ModelCharts from "./modelCharts";

export default class VerticalBarCharts extends PureComponent {
  state = {
    show: false
  };

  renderTable = () => {
    this.setState({
      show: !this.state.show
    });
  };
  hideModel = eve => {
    this.setState({
      show: eve
    });
  };
  render() {
    const DataFormater = number => {
      if (number > 1000000000) {
        return (number / 1000000000).toString() + "B";
      } else if (number > 1000000) {
        return (number / 1000000).toString() + "M";
      } else if (number > 1000) {
        return (number / 1000).toString() + "K";
      } else {
        return number.toString();
      }
    };
    const { data, meta_data } =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id]
        ? this.props.data.barChartData[this.props.chart_id]
        : [];
    
    const mdata =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id]
        ? this.props.data.barChartData[this.props.chart_id]
        : [];

    let xLines = meta_data ? Object.keys(meta_data) : [];
    xLines = xLines.filter(item => {
      return item !== "xVal";
    });
    return (
      <>
        <BarChart
          width={875}
          height={190}
          data={data}
          lineColor="#fffff"
          layout="vertical"
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5
          }}
          onClick={this.renderTable}
        >
          <CartesianGrid horizontal={false} />
          <YAxis
            dataKey={`${meta_data ? meta_data.xVal : ""}`}
            type="category"
          />
          <XAxis type="number" unit="€" tickFormatter={DataFormater} />
          <Tooltip
            cursor={{ stroke: "orange", strokeWidth: 3 }}
            formatter={helper.renderToolTip}
          />

          {xLines &&
            xLines.map((yAxis, index) => {
              return (
                <Bar
                  key={index}
                  dataKey={`${meta_data ? meta_data[yAxis] : ""}`}
                  fill={"#4a9ab5"}
                />
              );
            })}
        </BarChart>
        {this.state.show && (
          <ModelCharts
            onHide={this.hideModel}
            show={this.state.show}
            tabdata={data ? mdata : []}
            tablable={data ? this.props.label : ""}
          />
        )}
      </>
    );
  }
}
