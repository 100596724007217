import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  data: null,
  permission: null,
  getChartData: null,
  isSidebarToggled: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.SIDE_BAR_MENU:
      return {
        ...state,
        data: action.payload,
        permission: action.payload.permissions
      };
    case actionTypes.SIDE_BAR_MENU_TOGGLE:
      return {
        ...state,
        isSidebarToggled: action.payload.data,
      };
      
    default:
      return state;
  }
};

export default dashboardReducer;