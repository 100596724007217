import React, { Component } from "react";
import { dashboard } from "../../../actions/dashboardAction";
import { connect } from "react-redux";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import LineCharts from "./lineChart";
import BarCharts from "./barChart";
import PieCharts from "./pieChart";
import AreaCharts from "./areaChart";
import VerticalBarCharts from "./verticalBarChart";
import FinanceTeil from "../../general/charts/FinanceTeil";
import TransactionLog from "../../screen/transactionLog/TransactionLog";

class DashBoard2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment(new Date())
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD")
    };
    this.state.products = [];
  }

  handleEvent = (event, picker) => {
    event.preventDefault();
    this.setState({
      fromDate: moment(picker.startDate).format("YYYY-MM-DD"),
      toDate: moment(picker.endDate).format("YYYY-MM-DD")
    });
  };

  render() {
    let data_tx_volume = [
      {
        name: "April",
        uv: 1090
      },
      {
        name: "May",
        uv: 2500
      },
      {
        name: "June",
        uv: 4450
      },
      {
        name: "July",
        uv: 6508
      },
      {
        name: "August",
        uv: 4200
      }
    ];

    let data_fraud_sales_ratio = [
      {
        name: "April",
        uv: 4.21
      },
      {
        name: "May",
        uv: 1.1
      },
      {
        name: "June",
        uv: 7.8
      },
      {
        name: "July",
        uv: 0.1
      },
      {
        name: "August",
        uv: 2
      }
    ];

    let data_approval_rate = [
      {
        name: "April",
        uv: 78.21,
        failed: 21.79
      },
      {
        name: "May",
        uv: 82.1,
        failed: 17.9
      },
      {
        name: "June",
        uv: 64.8,
        failed: 35.2
      },
      {
        name: "July",
        uv: 67.1,
        failed: 32.9
      },
      {
        name: "August",
        uv: 80.8,
        failed: 19.2
      }
    ];
    return (
      <div
        className="container-fluid"
        style={{ marginLeft: "20px", marginRight: "20px" }}
      >
        {/* Charts */}
        <div className="row " style={{ backgroundColor: "#ECEFF6" }}>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Settlement Balance
              </h4>
              <FinanceTeil
                dataset={data_fraud_sales_ratio}
                data={this.props.data}
                chart_id="84"
                height="225"
                width="375"
                teil="Settlement Balance"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Fraud / Sales Ratio
              </h4>
              <LineCharts
                dataset={data_fraud_sales_ratio}
                data={this.props.data}
                chart_id="62"
                label="Fraud / Sales Ratio"
              />
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Chargeback / Sales Ratio
              </h4>
              <LineCharts
                dataset={data_fraud_sales_ratio}
                data={this.props.data}
                chart_id="63"
                label="Chargeback / Sales Ratio"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Approval Rate
              </h4>
              <BarCharts
                dataset={data_approval_rate}
                data={this.props.data}
                chart_id="64"
                label="Approval Rate"
              />
            </div>
          </div>
        </div>

        {/* Data Table Component*/}
        {/* <div className="row">
                <div className="col-md-3">
                    <div className="box">
                        <DataTable />
                    </div>
                </div>
            </div> */}

        <div className="row" style={{ backgroundColor: "#ECEFF6" }}>
          <div className="col-xs-12 col-sm-7">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Sales Volume
              </h4>
              <AreaCharts
                dataset={data_tx_volume}
                data={this.props.data}
                chart_id="60"
                height="190"
                width="875"
                label="Sales Volume"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-5 b-col">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Sales Per Payment Systems
              </h4>
              <PieCharts data={this.props.data} chart_id="44" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-7">
            <div className="box">
              <h4
                className="box-heading"
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                Top Sales Per BIN Country
              </h4>
              <VerticalBarCharts
                dataset={data_fraud_sales_ratio}
                data={this.props.data}
                chart_id="61"
                label="Top Sales Per BIN Country"
              />
            </div>
          </div>
        </div>
        <div
          className="table-row table-responsive"
          style={{ backgroundColor: "#ECEFF6", paddingRight: "5px" }}
        >
          <div
            className="box"
            style={{
              backgroundColor: "#ECEFF6",
              paddingRight: "10px",
              marginLeft: "-10px"
            }}
          >
            {/* Big Data Table Component*/}
            {/* <BigDataTable /> */}
            <TransactionLog />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashboardData: state.dashboardData
});

const mapDispatchToProps = {
  dashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard2);
