import React from 'react';
import ReactCountryFlag from "react-country-flag";
import { COUNTRY_CODE_3TO2, COUNTRY_CODE_TO_NAME } from "../../../../lib/constants/virtualTermConstants";

const BINCountryCell = ({ value }) => {
	return (
		<div style={{margin:"10px 0px"}}>
			{ value && value !== "N/A" ?
				<ReactCountryFlag countryCode={COUNTRY_CODE_3TO2[value]} svg style={{width:'2em', height:"auto", verticalAlign:"bottom"}} title={COUNTRY_CODE_TO_NAME[value]} /> : null
			}
		</div>
	)
};

export default BINCountryCell;