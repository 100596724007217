import React, { PureComponent } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

export default class ModelCharts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show
    };
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  };

  handleHide = () => {
    this.setState(
      {
        show: !this.state.show
      },
      () => {
        this.props.onHide(this.state.show);
        // this.props.onHideModel(this.state.show);
      }
    );
  };

  createRows = rows => {
    let nestedRows = [];
    for (let k in rows) {
      if (k !== "total") {
        nestedRows.push(rows[k].total);
      }
    }
    return nestedRows;
  };

  createCol = columns => {
    if (columns !== []) {
      columns.unshift("month_year");
      columns = columns.filter(function(item, pos) {
        return columns.indexOf(item) === pos;
      });
      return columns.map(item => {
        return {
          dataField: item,
          text: item,
          headerStyle: {
            backgroundColor: "#5d98cf"
          }
        };
      });
    }
  };

  createDateCol = columns => {
    if (columns !== []) {
      columns.unshift("date");
      columns = columns.filter(function(item, pos) {
        return columns.indexOf(item) === pos;
      });
      return columns.map(item => {
        return {
          dataField: item,
          text: item,
          headerStyle: {
            backgroundColor: "#5d98cf"
          }
        };
      });
    }
  };

  createCol1 = columns => {
    let col = [];
    for (let k in columns) {
      col.push({
        dataField: columns[k],
        text: columns[k],
        headerStyle: {
          backgroundColor: "#5d98cf"
        }
      });
    }
    return col;
  };

  render() {
    const { tablable } = this.props ? this.props : "";

    const { columns, rows } = this.props.tabdata ? this.props.tabdata : [];
    const mdata = this.props.tabdata ? this.props.tabdata : [];

    // const Rows = this.createRows(rows ? rows : []);
    // const Col = this.createCol( columns? columns : []);
    let Rows = [];
    let Col = [];
    if (mdata.meta_data !== undefined) {
      Rows = mdata.data ? mdata.data : [];
      Col = this.createCol1(mdata.meta_data ? mdata.meta_data : []);
    } else {
      Rows = this.createRows(rows ? rows : []);
      Col = this.createCol(columns ? columns : []);
      Rows.forEach(item => {
        if (item.month_year) {
          Col = this.createCol(columns ? columns : []);
        } else {
          let dateCol = columns.map(function(item) {
            return item === "month_year" ? "date" : item;
          });
          Col = this.createDateCol(dateCol ? dateCol : []);
        }
      });
    }

    return (
      <div>
        <Modal
          {...this.props}
          show={this.state.show}
          // show={this.props.show}
          onHide={this.handleHide}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg" className="box-heading">
              {/* {tablable} */}
              <span
                style={{
                  textAlign: "left",
                  color: "white",
                  paddingLeft: "10px"
                }}
              >
                {tablable}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Data Table */}
            <BootstrapTable
              keyField={Col[0].dataField ? Col[0].dataField : "month_year"}
              data={Rows ? Rows : []}
              columns={Col ? Col : []}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
