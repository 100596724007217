import { combineReducers } from "redux";
import loginData from "./loginReducers";
import dashboardReducer from "./dashboardReducers";
import chartsDataReducer from "./chartsDataReducers";
import transLogReducer from "./transLogReducers";
import financeReducer from "./financeReducers";
import sideBarToggleReducer from "./sideBarToggleReducer";
import virtualTermReducer from "./virtualTerminalReducers";
import adminReducer from "./adminReducer";
import paymentLinkReducer from "./paymentLinkReducers";
import merchantBoardReducer from "./merchantBoardReducers";
import partnerMerchantsReducer from "./partnerMerchantsReducers";
import ecommercePluginReducer from "./ecommercePluginReducers";
import chargebackAlertsReducer from "./chargebackAlertReducer";
import posTransactionsReducer from "./posTransactionsReducer";

const rootReducer = combineReducers({
  loginData,
  dashboardReducer,
  chartsDataReducer,
  transLogReducer,
  financeReducer,
  sideBarToggleReducer,
  virtualTermReducer,
  paymentLinkReducer,
  adminReducer,
  merchantBoardReducer,
  partnerMerchantsReducer,
  ecommercePluginReducer,
  chargebackAlertsReducer,
  posTransactionsReducer
});

export default (state, action) =>
  rootReducer(action.type === 'LOGOUT' ? undefined : state, action);
