import { api } from "./api";

import * as apiLink from "../lib/constants/apiLink";
// Developers can override this with an env.local file
const baseUrl = apiLink.BASE_API_URL;
 
/**
 * Function for getting transaction Log data
 * @param {*} token
 * @param param // request params
 */
export const getFinanceData = (token, filterBody, params) => {
  // let filterBody =  {"orderId":"","dateFrom":"2019-07-23","dateTo":"2019-07-24","transactionId":0}
  return api(baseUrl, token).post(apiLink.FINANCE_GET_DATA, filterBody, {
    params: params
  });
};
