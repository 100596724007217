import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as downloadService from "../services/downloadPdf";

export const downloadPDF = reqBody => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return downloadService
      .downloadPDF(token, reqBody)
      .then(response => {

        let content_type = response.headers["content-type"].split("/");

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf"
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window

        let filename = "download.pdf";
        if (content_type && content_type.length === 2) {
          filename = content_type[1];
        }

        var link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
          dispatch(null);
        }
      });
  };
};
