export const CHART_COLORS = [
  "#03D3D8",

  "#3D88B6",
  "#00C49F",
  "#f39b12",
  "#FF8042",
  "#08ab0f",
  "#e20c0c",
  "#ab089c",
  "#3f51b5",

  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#82ca9d",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8"
];

export const CHART_COLORS_2 = ["#24c75d", "#c73424", "#f39b12", "#FF8042"];

export default {
  CHART_COLORS,
  CHART_COLORS_2
};
