import React, { Component } from "react";
import { login, logout } from "../../../actions/userAction";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { getPermissions } from "../../../actions/dashboardAction";
import { ScaleLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUser } from "@fortawesome/free-solid-svg-icons";
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from "../../../lib/constants/utils/token";
import cookieUtils from "../../../lib/constants/utils/cookies";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { username: "", password: "" },
      isLoginSuccessful: false,
      isLoading: false,
      errors: {},
      message: "",
      isRevealPassword: false,
    };

    this.iconRevealPassword = React.createRef();
  }

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Cannot be empty";
      this.setState({
        isLoading: false,
      });
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
      this.setState({
        isLoading: false,
      });
    }

    if (
      typeof fields["password"] !== "undefined" &&
      fields["password"].length < 8
    ) {
      formIsValid = false;
      errors["password"] = "Password minimum 8 character";
      this.setState({
        isLoading: false,
      });
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onLoginSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    if (this.handleValidation()) {
      this.props
        .login({
          username: this.state.fields["username"],
          password: this.state.fields["password"],
        })
        .then(async () => {
          const authData = this.props.loginData;

          if (authData.isLoading && authData.users.token) {
            this.props.getPermissions().then(() => {
              if (this.props?.sideBarData?.data?.errors) {
                if (!this.state.message) {
                  this.setState({
                    message: this.props.sideBarData.data.message,
                  });
                }
                this.props.sideBarData.data = null;
                this.setState({
                  isLoading: false,
                });
              } else {
                this.setState({
                  isLoginSuccessful: true,
                });
              }
            });
          } else {
            cookieUtils.remove(ACCESS_TOKEN_KEY);
            cookieUtils.remove(REFRESH_TOKEN_KEY);

            this.setState({
              isLoading: false,
              message:
                authData.users && authData.users.errorMessage
                  ? authData.users.errorMessage[0]
                  : authData.users && authData.users.non_field_errors
                  ? authData.users.non_field_errors[0]
                  : "Could not connect to server",
            });
          }
        });
    }
  }

  errorMessage = () => {
    return (
      <div className="alert alert-danger" role="alert">
        {this.state.message}
      </div>
    );
  };

  togglePassword = (event) => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  render() {
    if (this.state.isLoginSuccessful === true && this.props.sideBarData.data) {
      const { permission } = this.props.sideBarData;
      const { user_permissions } = this.props.sideBarData.data;
      localStorage.setItem("username", this.state.fields["username"]);

      if (user_permissions.is_admin === 1)
        return (
          <Redirect
            to={{
              pathname: "/generic/adminDashboardTab",
              permissions: this.props.sideBarData,
            }}
          />
        );
      else if (permission.pages["dashboardTab"])
        return (
          <Redirect
            to={{
              pathname: "/generic/dashboardTab",
              permissions: this.props.sideBarData,
            }}
          />
        );
      else if (permission.pages["transactionLogTab"])
        return (
          <Redirect
            to={{
              pathname: "/generic/transactionLogTab",
              permissions: this.props.sideBarData,
            }}
          />
        );
      else
        return (
          <Redirect
            to={{ pathname: "*", permissions: this.props.sideBarData }}
          />
        );
    }

    const { isRevealPassword } = this.state;

    return (
      <div>
        <div className="login-box">
          <div className="login-logo">
            <img
              src={"./img/payabl_white_main.svg"}
              height="100px"
              width={200}
              alt="Payabl."
            />
          </div>

          <p className="login-box-msg">Sign in to start your session</p>

          <span>{this.state.message ? this.errorMessage() : null}</span>

          <form
            onSubmit={this.onLoginSubmit.bind(this)}
            name="loginForm"
            noValidate
          >
            <div className="form-group has-feedback">
              <input
                id="login_username_input"
                type="text"
                className="form-control"
                placeholder="Username"
                onChange={this.handleChange.bind(this, "username")}
                value={this.state.fields["username"]}
              />
              <span
                className="form-control-feedback"
                style={{ pointerEvents: "auto" }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
              <div
                className={
                  this.state.errors["username"]
                    ? "alert alert-danger login-alert-box"
                    : "login-alert-box"
                }
                role="alert"
              >
                {this.state.errors["username"]}
              </div>{" "}
            </div>
            <div className="form-group has-feedback">
              <input
                id="login_password_input"
                type={isRevealPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                onChange={this.handleChange.bind(this, "password")}
                value={this.state.fields["password"]}
              />
              <span
                onClick={this.togglePassword}
                ref={this.iconRevealPassword}
                className="form-control-feedback"
                style={{ pointerEvents: "auto", cursor: "pointer" }}
              >
                <span>
                  {isRevealPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              </span>
              <div
                className={
                  this.state.errors["password"]
                    ? "alert alert-danger login-alert-box"
                    : "login-alert-box"
                }
                role="alert"
              >
                {this.state.errors["password"]}
              </div>
            </div>

            <button
              type="submit"
              id="login_btn"
              className="btn btn-primary btn-lg btn-block login-btn"
              style={{ backgroundColor: "#55BD8B" }}
            >
              {this.state.isLoading === true && !this.props.sideBarData.data ? (
                <ScaleLoader color={"white"} height={25} />
              ) : (
                "Sign in"
              )}
            </button>

            <div className="row">
              <div
                className="col-xs-12 login-forgot-password-link"
                align="center"
              >
                <Link
                  to="/reset-password"
                  className="footer-link"
                  style={{ color: "#7cddae" }}
                >
                  Forgot Password
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: state.loginData,
  sideBarData: state.dashboardReducer,
});

const mapDispatchToProps = {
  login,
  logout,
  getPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
