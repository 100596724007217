import React from "react";

import * as helper from "../core/helper";

const FinanceTeil = ({ height, width, ...props }) => {
  if (props.teil && props.teil === "Settlement Balance") {
    return (
      <div className="container-fluid" style={{ textAlign: "center" }}>
        <div className="row row-bottom-margin">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h2 style={{ color: "#2E80B2" }}>
              <b>{helper.nFormatter(462640211, 2) + "€"}</b>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h4 style={{ textAlign: "center" }}>
              {"Total Balance "}
              <i
                style={{ color: "green" }}
                className="fa fa-circle-thin fa-1x"
              />
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h3 style={{ textAlign: "center", color: "green" }}>
              <b>{helper.nFormatter(412640211, 2) + "€"}</b>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h4 style={{ textAlign: "center" }}>
              {"Due "}
              <i
                style={{ color: "green" }}
                className="fa fa-arrow-right fa-1x"
              />
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (props.teil && props.teil === "Security Deposit Balance") {
    return (
      <div className="container-fluid" style={{ textAlign: "center" }}>
        <div className="row row-margin-top">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h2 style={{ textAlign: "center", color: "#2E80B2" }}>
              <b>{helper.nFormatter(0, 2) + "€"}</b>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h4 style={{ textAlign: "center" }}>
              {"Total Balance "}
              <i
                style={{ color: "green" }}
                className="fa fa-circle-thin fa-1x"
              />
            </h4>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{ textAlign: "center" }}>
        <div className="row row-bottom-margin">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h2 style={{ textAlign: "center", color: "#2E80B2" }}>
              <b>{helper.nFormatter(294911950, 2) + "€"}</b>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h4 style={{ textAlign: "center" }}>
              {"Total Balance "}
              <i
                style={{ color: "green" }}
                className="fa fa-circle-thin fa-1x"
              />
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h3 style={{ textAlign: "center", color: "green" }}>
              <b>{helper.nFormatter(1806594, 2) + "€"}</b>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 col-sm-12">
            <h4 style={{ textAlign: "center" }}>
              {"Due "}
              <i
                style={{ color: "green" }}
                className="fa fa-arrow-right fa-1x"
              />
            </h4>
          </div>
        </div>
      </div>
    );
  }
};

export default FinanceTeil;
