import React from "react";
import moment from "moment";

const DateFilterButton = ({datetimeFrom, datetimeTo, ...props}) => {

	const timeDiff_in_minutes = (moment(datetimeTo) - moment(datetimeFrom))/(1000*60);

	return (
		<button className="filter-date-picker-btn primaryBtn2" disabled={props.disabled} style={{height:"40px", fontWeight:"normal", borderWidth:"1px"}}>
			<i className="fa fa-calendar filter-date-picker-btn-icon" />
			<b>
				{ timeDiff_in_minutes === 15
						&& moment(datetimeTo).format("YYYY-MM-DDTHH:mm") ===  moment().tz("Europe/Berlin").format("YYYY-MM-DDTHH:mm") // 15 minutes time range ( converted to milliseconds )
					? "Last 15 Minutes"
					: timeDiff_in_minutes <= 24*60
						&& moment(datetimeFrom).format("DD-MM-YYYY") === moment(datetimeTo).format("DD-MM-YYYY") // today and yesterday
					? moment(datetimeFrom).format("DD-MM-YYYY")
					: "From " +
					moment(datetimeFrom).format("DD-MM-YYYY") +
					" - " +
					"To " +
					moment(datetimeTo).format("DD-MM-YYYY")}
					
			</b>
		</button>
	);
}

export default DateFilterButton;