import React from 'react';

const DateCell = ({ original }) => {
	return (
		<span>
			{ original.reporting_date && original.reporting_date !== "N/A" ? 
				<section><p style={{margin:"0px"}}>{ original.date + " rep. date " + original.reporting_date }</p>
				<p style={{margin:"0px", fontStyle:"italic", color:"rgb(147 161 167)"}}>{ original.time }</p></section>
				: <section><p style={{margin:"0px"}}>{ original.date }</p>
				<p style={{margin:"0px", fontStyle:"italic", color:"rgb(147 161 167)"}}>{ original.time }</p></section>
			}
		</span>
	)
};

export default DateCell;