import React from 'react';
import { Modal } from 'react-bootstrap'

const ALERT = ({ show, handleClose, text, title=''}) => {
    return (
        <div >
            <Modal dialogClassName = 'custom-alert' show={show} onHide={handleClose}>
                <Modal.Header>
                    <div style={{backgroundColor: '#283048', textAlign:'center'}}>
                        <label style={{color:'white', fontSize:'15px', paddingTop:'5px'}}> {title} </label>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:'center'}}>
                        <label style={{color: '#4b7e80', fontSize:'15px'}}> {text} </label>
                        <br></br>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{textAlign:'center'}}>
                        <button className="btn btn-success" onClick={handleClose}>
                            OK
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ALERT;