import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getChargebackAlerts, getChargebackAlertsFilters } from "../../../actions/chargebackAlertsAction";
import styles from "./ChargebackAlerts.module.css";
import moment from "moment-timezone";
import ReactTable from "react-table-6";
import ReactTooltip from "react-tooltip";
import {
    CHARGEBACK_ALERTS_DATA_TABLE_COLUMNS,
    CHARGEBACK_ALERTS_COLUMNS_KEYS,
} from "../../../lib/constants/chargebackAlerts";
import NumberFormat from "react-number-format";
import TransactionIDCell from "../transactionLog/txlogTableCells/TransactionIDCell";
import Pagination from "react-js-pagination";
import _ from "lodash";
import { LoadingScreen } from "../../general/common/LoadingScreen";
import { CustomFilter } from "../../general/common/CustomFilter";
import * as helper from "../../general/core/helper";
import { CHARGEBACK_ALERTS_FILTER_MERCHANT, CHARGEBACK_ALERTS_FILTERS, CHARGEBACK_ALERTS_FILTERS_COMPANY } from "../../../lib/constants/filter";
import ChargebackDownload from "./ChargeBackDownload";
import ChargebackRefund from "./Action/RefundFlow";
import ChargebackReject from "./Action/RejectFlow";

const initialFilters = {
    dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    dateTo: moment().endOf("month").format("YYYY-MM-DD"),
    datetimeFrom: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
    datetimeTo: moment().endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
    txLogParams: { txcount: [1] },
    records_per_page: 20,
    page_number: 1,
};

const ChargebackAlerts = ({
    userPermissions,
    getChargebackAlerts,
    getChargebackAlertsFilters,
    chargebackAlertsReducer,
    backOfficePermission,
}) => {
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [filters, setFilters] = useState(initialFilters);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [isRefundCall, setIsRefundCall] = useState(false);
    const [rowData, setRowData] = useState({});
    const [totalRecordsCount, setTotalRecordsCount] = useState(0);

    const getChargebackAlertsData = () => {
      return chargebackAlertsReducer.chargebackAlertsData;
    };
    
    const getChargebackAlertsFilterData = () => {
      return chargebackAlertsReducer.chargebackAlertsFilters;
    }
    
    const pageSize = initialFilters.records_per_page;
    const chargebackAlertsData = getChargebackAlertsData();
    const chargebackAlertsFilterData = getChargebackAlertsFilterData();

    const fetchChargebackAlerts = useCallback(
      async (filters) => {
        try {
          setLoading(true);
          const { payload: alertsData } = await getChargebackAlerts(filters);

          if (_.isNumber(alertsData?.total_records)) {
            setTotalRecordsCount(alertsData.total_records);
          }
        } catch (error) {
          console.error("Error on fetching chargeback alerts", error);
        } finally {
          setLoading(false);
        }
      },
      [getChargebackAlerts]
    );

    const fetchChargebackAlertFilters = useCallback(
      async () => {
        try {
          setLoading(true);
          await getChargebackAlertsFilters();
        } catch (error) {
          console.error("Error on fetching chargeback alerts filters", error);
        } finally {
          setLoading(false);
        }
      },
      [getChargebackAlertsFilters]
    );

    useEffect(() => {
      fetchChargebackAlertFilters();
    }, [fetchChargebackAlertFilters]);

    useEffect(() => {
      fetchChargebackAlerts(filters);
      setIsRefundCall(false);
    }, [isRefundCall, fetchChargebackAlerts, filters]);

    const handleSuccess = (success) => {
        setIsRefundCall(success);
    };

    const handlePageChange = async (pageNumber) => {
        if (filters?.txLogParams?.txcount) {
            delete filters.txLogParams.txcount;
        }

        const updatedFilters = {
            ...filters,
            page_number: pageNumber,
            txLogParams: { ...filters.txLogParams },
        };

        await fetchChargebackAlerts(updatedFilters);
        setFilters(updatedFilters);
        setActivePage(pageNumber);
    };

    const handleAction = (row) => {
        setShowRefundModal(true);
        setRowData(row);
    };

    const handleRejectAction = (row) => {
        setShowRejectModal(true);
        setRowData(row);
    };

    const renderCell = (row, key) => {
      switch (key) {
        case CHARGEBACK_ALERTS_COLUMNS_KEYS.AMOUNT:
          return (
            <div style={{ margin: "10px 0px" }}>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </div>
          );

        case CHARGEBACK_ALERTS_COLUMNS_KEYS.TRANSACTION_ID:
          return (
            <TransactionIDCell
              value={row.value}
              original={row.original}
              isCounterEnabled={false}
            />
          );

        case CHARGEBACK_ALERTS_COLUMNS_KEYS.ALERT_DATE:
          return moment(new Date(row.value)).format("YYYY-MM-DD");

        case CHARGEBACK_ALERTS_COLUMNS_KEYS.CURRENCY:
          return (
            <div className="vertical-center">
              <p className="margin-0">{_.upperCase(row.value)}</p>
            </div>
          );

        case CHARGEBACK_ALERTS_COLUMNS_KEYS.STATUS:
          return (
            <div className="vertical-center">
              <p className="margin-0">{_.capitalize(row.value)}</p>
            </div>
          );

        case CHARGEBACK_ALERTS_COLUMNS_KEYS.ACTION:
          return (
            <div className="vertical-center">
              {row.value === "disabled" || row.value === "enabled" ? (
                <>
                  <button
                    className={styles.refundButton}
                    onClick={() => handleAction(row.original)}
                    disabled={row.value === "disabled"}
                    data-tip={row.value === "disabled" ? "No permission" : ""}
                  >
                    Refund
                  </button>
                  <button
                    className={styles.rejectButton}
                    onClick={() => handleRejectAction(row.original)}
                    disabled={row.value === "disabled"}
                    data-tip={row.value === "disabled" ? "No permission" : ""}
                  >
                    Reject
                  </button>
                  <ReactTooltip />
                </>
              ) : (
                <span>No Action Required</span>
              )}
            </div>
          );

        default:
          return row.value ? (
            <div className="vertical-center">
              <p className="margin-0">{row.value}</p>
            </div>
          ) : null;
      }
    };

    const getUserSpecificColumns = () => {
        const columns = Object.entries(CHARGEBACK_ALERTS_DATA_TABLE_COLUMNS);
        if (userPermissions.is_admin) {
            return columns.filter(
                ([key]) => key !== CHARGEBACK_ALERTS_COLUMNS_KEYS.Merchant
            );
        } else {
            return columns.filter(
                ([key]) => key !== CHARGEBACK_ALERTS_COLUMNS_KEYS.Company
            );
        }
    };

    const getUserSpecificFilters = () => {
      if (userPermissions.is_admin) {
        return {
          ...CHARGEBACK_ALERTS_FILTERS,
          ...CHARGEBACK_ALERTS_FILTERS_COMPANY,
        };
      } 
      return {
        ...CHARGEBACK_ALERTS_FILTERS,
        ...CHARGEBACK_ALERTS_FILTER_MERCHANT,
      };
    };

    const populateTableColumns = () => {
        const userSpecificColumns = getUserSpecificColumns();
        return userSpecificColumns.map((entry) => {
            const [key, value] = entry;
            const label = value[0];
            const minWidth = value[1];
            return {
                Header: label,
                accessor: key,
                Cell: (row) => renderCell(row, key),
                minWidth: minWidth,
                headerStyle: {
                    color: "#112F3A",
                    textAlign: "center",
                    fontWeight: "700",
                    backgroundColor: "white",
                },
                style: {
                    whiteSpace: "unset",
                    textAlign: "center",
                    color: "#112F3A",
                    fontWeight: "500",
                    position: "relative",
                },
            };
        });
    };

    const onUpdateFilters = (filters) => {
      const updatedFilters = {
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        datetimeFrom: filters.datetimeFrom,
        datetimeTo: filters.datetimeTo,
        records_per_page: pageSize,
        page_number: activePage,
        txLogParams: { txcount: [1], ...filters.filters },
      };
      
      fetchChargebackAlerts(updatedFilters);
      setFilters(updatedFilters);
      setActivePage(1);
    };

    return (
      <div className={styles.chargebackAlertsWrapper}>
        <div className={styles.chargebackAlertsFilters}>
          <CustomFilter
            filters={getUserSpecificFilters()}
            serverFilterData={chargebackAlertsFilterData}
            updateFilters={onUpdateFilters}
            setPaymentMethodId={false}
            noDefaultValues
          />
        </div>
        <div className={styles.chargebackAlertsData}>
          <div className={styles.tableHeader}>
            <div className={styles.titleDownload}>
              <h4>Chargeback Prevention Cases</h4>
              <ChargebackDownload
                filter={filters}
                type="log"
                tableLength={chargebackAlertsData?.page.length}
              />
            </div>
            {userPermissions.is_admin === 1 &&
              backOfficePermission.can_chargeback_prevention_rules === 1 && (
                <button
                  type="button"
                  className={styles.viewRulesButton}
                  onClick={(e) => {
                    e.preventDefault();
                    helper.renderChargebackPreventionRules();
                  }}
                >
                  View Rules
                </button>
              )}
          </div>
          {loading && !chargebackAlertsData?.page && <LoadingScreen />}
          {chargebackAlertsData?.page ? (
            <>
              <div className={styles.tableBodyWrapper}>
                <ReactTable
                  data={chargebackAlertsData.page}
                  columns={populateTableColumns()}
                  className="-highlight -striped"
                  style={{ border: "none" }}
                  minRows={0}
                  pageSize={pageSize}
                  showPagination={false}
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  loading={loading}
                />
              </div>
              <div className="txlog-pagination" style={{ textAlign: "center" }}>
                <Pagination
                  firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                  lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                  prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                  nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                  pageRangeDisplayed={10}
                  activePage={activePage || 1}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={totalRecordsCount}
                  onChange={(page) => handlePageChange(page)}
                />
              </div>
            </>
          ) : (
            <div className={styles.emptyData}>
              <img src={"/img/icons/block file.svg"} alt="." height="70" />
              <br />
              <span>There are currently no chargeback alerts. </span>
            </div>
          )}
        </div>
        <ChargebackRefund
          open={showRefundModal}
          onClose={() => setShowRefundModal(false)}
          rowData={rowData}
          onRefund={() => {
            setShowRefundModal(false);
          }}
          onRefundSuccess={handleSuccess}
        />
        <ChargebackReject
          open={showRejectModal}
          onClose={() => setShowRejectModal(false)}
          rowData={rowData}
          onReject={() => {
            setShowRejectModal(false);
          }}
          onRejectSuccess={handleSuccess}
        />
      </div>
    );
};

const mapStateToProps = (state) => ({
    chargebackAlertsReducer: state.chargebackAlertsReducer,
});

const mapDispatchToProps = {
    getChargebackAlerts, getChargebackAlertsFilters,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChargebackAlerts)
);