import React from "react";
import * as helper from "../core/helper";
import { BarLoader } from "react-spinners";

const FiguresTop = ({ height, width, ...props }) => {
    const meta_data =
        props.data ? props.data : [];

    if (!props.data) {
        return (
            //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
            <BarLoader color={"#999"} speedMultiplier={1} />
        );
    }
    if (meta_data.length !== 0) {
        return (
            <div id="textDiv" className="container-fluid">
                <table id="textTable">
                    <tbody>
                        {meta_data &&
                            Object.keys(meta_data).map(function (keyName, keyIndex) {
                                if (meta_data[keyIndex]["date_value"] && keyIndex === 0) {
                                    return Object.keys(meta_data[keyIndex]).map(function (key) {
                                        if (key !== "date" && key !== "date_value") {
                                            return (
                                                <tr key={meta_data[keyIndex]["date_value"] + keyIndex} className="wide_row">

                                                    <td className="equal_col"><b><small>({meta_data[keyIndex]["date"]})</small><br></br>
                                                        {helper.figuresTopChecks(key, meta_data)}
                                                    </b>
                                                        <br></br>
                                                        <small>{helper.figuresTopChecksYesterday(key, meta_data)}</small>
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return null;
                                        }

                                    })

                                } else {
                                    return null;
                                }
                            })}

                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div>
                <h4 className="noDataFound-style">
                    <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
                    No data was found! 
                </h4>
            </div>
        );
    }
};

export default FiguresTop;
