import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as posTransactionLogService from "../services/posTransactionLog";
import axios from "axios";

const _getPOSTransactionsData = data => ({
  type: actionTypes.POS_TRANSACTION_LOG_DATA,
  payload: data
});

const _filterBodyData = data => ({
  type: actionTypes.FILTER_DATA,
  payload: data
});

const _downloadPOSTransactionData = data => ({
  type: actionTypes.POS_TRANSACTION_LOG_DOWNLOAD,
  headers: data.headers,
  payload: data.data,
});

export const getPOSTransactions = (filter, req, cancelToken = axios.CancelToken.source()) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };

    return posTransactionLogService
      .getPosTransactionData(token, filter, req, cancelToken)
      .then(res => {
        if (res.data) {
          return dispatch(_getPOSTransactionsData(res.data));
        }
      })
      .catch(error => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };
};

export const downloadPOSTransactions = (req) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };

    return posTransactionLogService
      .downloadPOSTransactionData(token, req)
      .then(({headers, data})=> {
        if (data) {
          return dispatch(_downloadPOSTransactionData({headers,data}));
      }})
      .catch(error => {
        if (error?.response) {
          console.error(error.response);
        }
      });
  };
};

export const filterBodyData = filter => {
  return dispatch => {
    return dispatch(_filterBodyData(filter));
  };
};
