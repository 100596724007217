import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";

export default class TableChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  /**
   * Function for creating dynamic columns accoding to meta_data received
   */
  createCol = data => {
    let columns = [];
    Object.keys(data).map(key => {
      let col = {
        dataField: data[key],
        text: this.Capitalize(data[key]),
        sort: true,
        formatter:
          data[key] === "amount_eur"
            ? this.currencyFormatter
            : data[key] === "amount_usd"
            ? this.currencyFormatter
            : null,

        headerStyle: {
          backgroundColor: "#C8E4E6"
        }
      };
      columns.push(col);
      return null;
    });
    return columns;
  };

  /**
   * Function for formating the cell values in column
   */

  Capitalize(str) {
    var frags = str.split("_");
    var i = 0;
    for (i = 0; i < frags.length; i++) {
      if (frags[i].length === 2 || frags[i].length === 3) {
        frags[i] = frags[i].toUpperCase();
      } else {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
    }
    return frags.join(" ");
  }

  currencyFormatter = (cell, row) => {
    return (
      <span>
        {row.amount_eur ? (
          <NumberFormat
            value={cell}
            thousandSeparator={true}
            prefix={"€"}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        ) : (
          <NumberFormat
            value={cell}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        )}
      </span>
    );
  };

  render() {
    const ExportCSV = props => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <button className="btn btn-link" onClick={handleClick}>
          <i className="fa fa-download" style={{ color: "#2E80B2" }} />
        </button>
      );
    };
    const { data, meta_data } = this.props.data !== null ? this.props.data : [];
    const { lable } = this.props;
    let col = meta_data !== undefined ? this.createCol(meta_data) : null;

    // init of Search bar component
    const { SearchBar } = Search;

    // Custome options for pagination component
    const options = {
      custom: true,
      totalSize: data ? data.length : 0,
      sizePerPage: 5
    };

    return (
      <div className=" filter-chips tab-pagination-container">
        {data && (
          <div className="table-responsive tab-pagination" key={lable}>
            <ToolkitProvider
              // key={lable}
              keyField={meta_data ? meta_data.xVal : "yVal1"}
              // keyField={lable}
              data={data ? data : []}
              columns={
                col !== null
                  ? col
                  : [{ dataField: "id", text: "No Data Found" }]
              }
              search
              exportCSV
              // rowClasses={ rowClasses }
            >
              {props => (
                <div className="fixed-height-box">
                  <div className="row" style={{ marginRight: "20px" }}>
                    <div
                      className="col-md-6 col-xs-6"
                      style={{ marginTop: "20px" }}
                    >
                      <ExportCSV {...props.csvProps} />
                    </div>
                    <div
                      className="col-md-6 col-xs-6"
                      style={{ textAlign: "right", marginTop: "30px" }}
                    >
                      <SearchBar
                        {...props.searchProps}
                        key={lable}
                        style={{
                          borderRadius: "0.25em",
                          textAlign: "left",
                          color: "black",
                          border: "2px solid #2E80B2",
                          padding: "0.5em"
                        }}
                      />
                      {/*<ClearSearchButton
                    {...props.searchProps}
                    className="btn btn-primary"
                  />*/}
                    </div>
                  </div>
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {({ baseProps, paginationProps, paginationTableProps }) => (
                      <div>
                        <hr />
                        <BootstrapTable
                          {...props.baseProps}
                          {...paginationTableProps}
                        />
                        {options.totalSize > 5 && (
                          <PaginationListStandalone {...paginationProps} />
                        )}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </div>
    );
  }
}
