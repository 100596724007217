import React from 'react';
import { Modal } from 'react-bootstrap'

const AlertScrollable = ({ show, handleClose, text1, text2, title=''}) => {
    return (
        <div >
            <Modal show={show} onHide={handleClose} centered scrollable aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header>
                    <div style={{backgroundColor: '#283048', textAlign:'center'}}>
                        <label style={{color:'white', fontSize:'15px', paddingTop:'5px'}}> {title} </label>
                    </div>
                </Modal.Header >
                <Modal.Body>                       
                    <div style={{ whiteSpace: 'pre-wrap', textAlign:'center' }} >
                        <label style={{color: '#4b7e80', fontSize:'15px'}}> {text1} </label>
                        <p style={{color: '#4b7e80', fontSize:'12px'}}> {text2} </p>
                    </div>                  
                </Modal.Body>
                <Modal.Footer>
                    <div style={{textAlign:'center'}}>
                        <button className="btn btn-success" onClick={handleClose}>
                            OK
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AlertScrollable;