import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";

export default class NewNestTableChart extends Component {
  stack = [];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  /**
   * Function for creating columns
   */

  createColumns = columns => {
    columns.unshift("name");
    columns = columns.filter(function(item, pos) {
      return columns.indexOf(item) === pos;
    });
    return columns.map(column => {
      return {
        dataField: column,
        classes:
          column === "chargeback_volume_usd"
            ? this.currencyStyle
            : column === "sales_count" || column === "sales_amount_usd"
            ? ""
            : this.customStyle,
        text: this.Capitalize(column),
        // sort: true,
        formatter:
          column === "sales_amount_usd" || column === "chargeback_volume_usd"
            ? this.currencyFormatter
            : null,
        headerStyle: {
          backgroundColor: "#C8E4E6"
        }
      };
    });
  };

  currencyStyle = (cell, row, rowIndex, colIndex) => {
    if (cell >= 500 && cell <= 1000) {
      return "bg-yellow";
    } else if (cell >= 1001 && cell <= 2000) {
      return "bg-orange";
    } else if (cell > 2000) {
      return "bg-red";
    }
  };

  customStyle = (cell, row, rowIndex, colIndex) => {
    if (cell >= 5 && cell <= 10) {
      return "bg-yellow";
    } else if (cell >= 11 && cell <= 20) {
      return "bg-orange";
    } else if (cell > 20) {
      return "bg-red";
    }
  };

  createRows = rows => {
    let nestedRows = [];
    let visa = [];
    let master = [];
    let level = 0;

    for (let k in rows) {
      if (k !== "total") {
        this.addToStack(k, rows[k]);
        // eslint-disable-next-line
        this.stack.forEach((item, index) => {
          if (item.card_brand === "VISA") {
            item.id = "v" + level;
            level++;
            visa.push(item);
          }
          if (item.card_brand === "MASTERCARD") {
            item.id = "m" + level;
            level++;
            master.push(item);
          }
        });
        this.stack = [];
      }
    }
    nestedRows.push(visa);
    nestedRows.push(master);
    return nestedRows;
  };

  addToStack = (k, rows) => {
    if (rows.VISA) {
      rows.VISA.name = k;

      this.stack.push(rows.VISA);
    }

    if (rows.MASTERCARD) {
      rows.MASTERCARD.name = k;
      this.stack.push(rows.MASTERCARD);
    }

    if (!rows.total) {
      return;
    }
  };

  /**
   * Function for formating the cell values in column
   */

  Capitalize(str) {
    var frags = str.split("_");
    var i = 0;
    for (i = 0; i < frags.length; i++) {
      if (frags[i].length === 2 || frags[i].length === 3) {
        frags[i] = frags[i].toUpperCase();
      } else {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
    }
    return frags.join(" ");
  }

  currencyFormatter = (cell, row) => {
    return (
      <span>
        <NumberFormat
          value={cell}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          displayType={"text"}
          fixedDecimalScale={true}
        />
      </span>
    );
  };

  render() {
    const { lable } = this.props;
    this.stack = [];

    const columns = this.createColumns(this.props.data.columns);
    const trows = this.createRows(this.props.data.rows);

    return (
      <div className=" box filter-chips margin-auto">
        {columns &&
          trows.map((rows, i) => {
            return (
              <div className="table-responsive" key={lable + i}>
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  {rows[0] && rows[0].card_brand}
                </h4>
                <BootstrapTable
                  keyField="id"
                  data={rows ? rows : []}
                  columns={columns}
                />
              </div>
            );
          })}
      </div>
    );
  }
}
