import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  barChartData: null
};

const chartsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DATA:
      return {
        ...state,
        barChartData: action.payload,
      };
      
    default:
      return state;
  }
};

export default chartsDataReducer;