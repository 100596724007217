import React from "react";
import * as style from "../style/style";
import NumberFormat from "react-number-format";
import _ from "lodash";

export const daily_balance_columns = [
  {
    Header: " End date ",
    accessor: "merge_date",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 200,
  },
  {
    Header: " Txs date ",
    accessor: "txs_date",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Mid ",
    accessor: "mid",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 180,
  },
  {
    Header: " Company ",
    accessor: "company",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    minWidth: 150,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Transaction currency ",
    accessor: "transaction_currency",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Settlement currency ",
    accessor: "settlement_currency",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
          .toUpperCase()
          .replace(/\s+/g, "")
          .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Sub total ",
    accessor: "sub_total",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Reserve collected ",
    accessor: "reserve_collected",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Ram amount ",
    accessor: "ram_amount",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Reserve due ",
    accessor: "reserve_returned",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Collection date ",
    filterable: false,
    accessor: "rr_rc_date",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Settlement amount ",
    accessor: "payout_amount",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Tx count ",
    accessor: "tx_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={0}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Tx volume ",
    accessor: "tx_volume",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Refund count ",
    accessor: "refund_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={0}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Refund volume ",
    accessor: "refund_volume",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Cft count ",
    accessor: "cft_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Cft volume ",
    accessor: "cft_volume",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Chargeback count ",
    accessor: "chargeback_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Chargeback volume ",
    accessor: "chargeback_volume",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Chargeback rev count ",
    accessor: "chargeback_rev_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Chargeback rev volume ",
    accessor: "chargeback_rev_volume",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Mdr fee ",
    accessor: "mdr_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Mdr adjustments ",
    accessor: "mdr_adjustments",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Capture fee ",
    accessor: "capture_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Auth count ",
    accessor: "auth_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Auth fee ",
    accessor: "auth_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Refund fee ",
    accessor: "refund_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Cft fee ",
    accessor: "cft_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Retrieval request count ",
    accessor: "retrieval_request_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Retrieval request fee ",
    accessor: "retrieval_request_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Cbs fee ",
    accessor: "cbs_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Monthly fee count ",
    accessor: "monthly_fee_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Monthly fee ",
    accessor: "monthly_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " One time fees ",
    accessor: "one_time_fees",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Manual adjustments ",
    accessor: "manual_adjustments",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Pc21 adjustments ",
    accessor: "pc21_adjustments",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " 3d auth count ",
    accessor: "threed_auth_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " 3d auth fee ",
    accessor: "threed_auth_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Applepay Count ",
    accessor: "applepay_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Applepay Add. Fee ",
    accessor: "applepay_add_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: " Googlepay count ",
    accessor: "googlepay_count",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: " Googlepay Add. Fee ",
    accessor: "googlepay_add_fee",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
];

export default {
  daily_balance_columns,
};
