// column cells that can be clicked to select the whole row
export const SELECTABLE_TD = [
    " ",
    "Company",
    "Shop URL",
    "Email",
    "MCC",
    "MID",
    "BIN Country",
    "Type",
    "Order Id",
    "PAN",
    "Date/Time",
    "Amount",
    "Refundable",
    "Currency",
    "Status",
    "Merchant"
];

// [[label of the column], [start width of the column]]
export const COLUMNS_ADMIN = {
    transaction_id: ["Transaction ID", 120],
    merchant_company_id: ["Company", 100],
    shop_url: ["Shop URL", 100],
    email: ["Email", 100], 
    mcc: ["MCC", 40],
    acquirer_mid: ["MID", 100],
    bin_country: ["BIN Country", 80],
    transaction_type: ["Type", 100],
    order_id: ["Order Id", 100], 
    ccn_masked: ["PAN", 130],
    date: ["Date/Time", 70],
    amount: ["Amount", 70],
    remaining_to_refund: ["Refundable", 70], 
    currency: ["Currency", 60],
    transaction_status: ["Status", 60]
};

// [[label of the column], [start width of the column]]
export const COLUMNS_REFUND_REMAINING = {
    transaction_id: ["Transaction ID", 100],
    merchant_name: ["Merchant", 100],
    email: ["Email", 100],
    bin_country: ["BIN Country", 80], 
    transaction_type: ["Type", 100],
    order_id: ["Order Id", 100],
    ccn_masked: ["PAN", 130],
    date: ["Date/Time", 80],
    amount: ["Amount", 90],
    remaining_to_refund: ["Refundable", 90],
    currency: ["Currency", 70],
    transaction_status: ["Status", 70]
};

// [[label of the column], [start width of the column]]
export const COLUMNS_DEFAULT = {
    transaction_id: ["Transaction ID", 100],
    merchant_name: ["Merchant", 100],
    email: ["Email", 100],
    bin_country: ["BIN Country", 80], 
    transaction_type: ["Type", 100],
    order_id: ["Order Id", 100],
    ccn_masked: ["PAN", 130],
    date: ["Date/Time", 100],
    amount: ["Amount", 100],
    currency: ["Currency", 100],
    transaction_status: ["Status", 100]
};