import React, { Component } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ScaleLoader } from "react-spinners";

class SingleInput extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.containerEl = null;
    this.externalWindow = null;
    this.errorMsg = " ";
  }
  
  render() {
    return (
      <>
        <div style={{marginLeft:"0px",marginRight:"0px", width:this.props.width, marginTop:this.props.marginTop}}>
          <div style={{background:"#F8FAFC", textAlign:"left"}}>
            { this.props.label ?
              <label style={{color:"#475569"}}>{this.props.label}</label> :
              null
            }
            { this.props.isOptional ?
              <label style={{color:"hsl(0,0%,50%)", fontWeight:"normal", float:"right"}}>optional</label> :
              null
            }
          </div>
          <input
            value={this.props.value}
            type="text"
            name={this.props.name}
            onChange={e => this.props.onChange(e)}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            className="singleInput"
            pattern={this.props.pattern}
            title={this.props.title}
            autoComplete={this.props.autoComplete}
            defaultValue={this.props.defaultValue}
            style={{borderColor: this.props.showErrorMsg ? "red" : ""}}/>
            { this.props.diplayButton ? 
                this.props.shouldCopyToClipboard ?
                <CopyToClipboard text={this.props.value}>
                  <button
                  type="button"
                  onClick={this.props.buttonOnClick}
                  disabled={this.props.buttonDisabled}
                  className="singleInputBtn">
                    {this.props.buttonText}
                  </button>
                </CopyToClipboard> :
                <button
                type="button"
                onClick={this.props.buttonOnClick}
                disabled={this.props.buttonDisabled}
                className="singleInputBtn">
                  { this.props.buttonLoading ?
                      <ScaleLoader color={"#a8aebb"} height={30} width={4}/> :
                      this.props.buttonText
                  }
                </button> :
              null
            }
        </div>
        { this.props.showErrorMsg ?
          <div>
            <span style={{color: "red", marginRight: "10px"}}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
            <span style={{color: "rgb(128, 128, 128)"}}>
              {this.props.errorMsg ? this.props.errorMsg : this.errorMsg}
            </span>
          </div> :
          this.props.disableErrorMsg ?
            null :
            <div style={{height:"20px"}}></div>
        }
      </>
    );
  }
}

export default SingleInput;