import React, { Component } from "react";
// import Select from "../../general/core/Select";



 export default class CreditFundTrans extends Component {
  constructor(props){
    super(props);
    this.state ={
        amount: 0,
        bal_amount: 2000,
        amountType: "USD"
    }
  }

  onValueChange=(e)=>{
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }


  render() {
    return (
        <div>
            <div className="row box margin-0">
                <div className="col-md-12 bg-color-red">
                  <label>{'CREDIT FUND TRANSFER'}</label>
                </div>
              <div className="col-md-12">
                  <div className="col-xs-4 col-sm-3 col-md-3 padd-20 border-right">
                    <label>Amount: *</label>
                  </div>
                  <div className="col-xs-4 col-sm-3 col-md-3 padd-20 border-right">
                    <input 
                        onChange={this.onValueChange}
                        name="amount"
                        value={this.state.amount}
                        width="100%"/>
                  </div>
                  <div className="col-xs-4 col-sm-3 col-md-3 padd-20 text-right ">
                    <label>{this.state.amountType}</label>
                  </div>
              </div>
            </div>
            <div className="col-md-12 text-right bg-color"> 
                <span>{"Max remaining CFT amount left: "}{`${this.state.bal_amount}  `}{this.state.amountType}</span>
            </div>
            <div className="col-md-12 box">
                <div className="col-md-12  margin-0 color-red"> 
                    <span>{"*  = Manditory field"}</span>
                </div>
                <div className="col-md-12  margin-0 text-right "> 
                    <button className="custom-btn" onClick={(e)=>{e.preventDefault(); console.log("On Submit",this.state)}}>Submit</button>
                </div>
            </div>      
        </div>
    );
  }
}

  