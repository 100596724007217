export const INPUT_STYLES = (disabled, expand) => {
	return {
		valueContainer: (styles) => ({
			...styles,
			overflowY: expand ? "auto" : "none",
			maxHeight: expand ? "170px" : "35px",
			maxWidth: "330px",
			//transition: "all 0.5s ease 0s"
		}),
		control: (styles) => ({
			...styles, 
			border: disabled ? "1px solid hsl(0,0%,95%)" : "1px solid #FFFFFF",
			cursor: "text",
			borderRadius: "6px",
			boxShadow: expand ? "-1px 0px 5px #8cc8e7, 1px 0px 5px #8cc8e7, 0px 1px 5px #8cc8e7, 0px -1px 5px #8cc8e7" : "none",
			transition: "box-shadow 0.5s ease 0s",
			"&:hover": {
				border: "1px solid #35A7E3"
			}
		}),
		clearIndicator: (styles) => ({
			...styles,
			cursor: 'pointer',
			height: "100%",
			borderRadius: "0px 6px 6px 0px",
			"&:hover": { 
				backgroundColor: "#fdbeaf",
				color: "#db381c",
			}
		}),
		multiValue: (styles) => ({
			...styles,
			backgroundColor: disabled ? "hsl(0,0%,95%)" : "#FFFFFF",
			border: disabled ? "2px solid #cdcdcd" : "2px solid #35A7E3",
			borderRadius: "6px",
			color: disabled ? "#cdcdcd" : "#35A7E3",
			fontWeight: "bold"
		}),
		multiValueLabel: (styles) => ({
			...styles,
			color: disabled ? "#cdcdcd" : "#555",
			fontSize: "90%",
		}),
		multiValueRemove: (styles) => ({
			...styles,
			cursor: 'pointer',
			borderRadius: "0px 5px 5px 0px",
		}),
	};
};

export const SELECT_STYLES = (disabled) => {
	return {
		valueContainer: (styles) => ({
			...styles,
			maxHeight: '35px'
		}),
		control: (styles) => ({
			...styles,
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			border: disabled ? "1px solid hsl(0,0%,95%)" : "1px solid #FFFFFF",
			boxShadow: "none",
			cursor: "pointer",
			borderRadius: "6px",
			"&:hover": {
				border: "1px solid #35A7E3"
			}
		}),
		clearIndicator: (styles) => ({
			...styles,
			"&:hover": { 
				backgroundColor: "#fdbeaf",
				color: "#db381c",
			}
		}),
		dropdownIndicator: (styles) => ({
			...styles,
			color: disabled ? "#cdcdcd" : "#1E6AF6",
			"&:hover": { color: "#0353E9" },
			"&:active": { color: "#002D9C" }
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				cursor: 'pointer',
				/*"&:hover": {
					color: isSelected ? '#db381c' : 'black',
					backgroundColor: isSelected ? '#fdbeaf' : '#DEEBFF'
				},
				"&:active": {
					backgroundColor: isSelected ? '#fdbeaf' : '#ccdef9'
				},*/
			}
		},
		menu: (styles) => ({
			...styles,
			//backgroundColor: 'red',
			//transition: "all 0.5s ease 0s"
		})
	};
};

export const ACTION = {
	REMOVE: 'remove-value',
	POP: 'pop-value',
	CLEAR: 'clear',
	SELECT: 'select-option'
};