import React, { Component } from "react";
	
class TXLogMobileRowTable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.columns = [];
		this.data = [];
		
		this.renderTable = this.renderTable.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
	}
	
	componentDidMount() {
		this.columns = this.props.columns;
		this.data = this.props.data;
  }
 
 	handleRowClick(event) {
 		//console.log("this.props.isExpanded",this.props.isExpanded);
 	}
 
 	renderRow(header, content, key) {
 		
 		if (typeof header !== 'string' || !header instanceof String) return;
 		
 		return (
 			<tr key={key}>
		    <th>{header}:</th>
		    <td>{content}</td>
		  </tr>
 		);
 	}
 
 	renderTable() {
 		let res = [];
 		for (let i = 0; i < this.columns.length; i++) {
			res.push(this.renderRow(this.columns[i].Header, this.data[this.columns[i].accessor], this.data["transaction_id"] + "/" + i));
		}
		return res;
 	}
 
 	renderRowData(date, time) {
 		return (
 			<div 
 			className="col-md-3 col-xs-3" 
 			style={{overflow:"hidden", paddingLeft:"0px", paddingRight:"0px", textAlign:"center"}}>
				{date}
				<br/>
				{time}
			</div>
 		);
 	}
 
 	renderRowData2(value) {
 		return (
 			<div 
 			className="col-md-6 col-xs-6" 
 			style={{overflow:"hidden", paddingLeft:"0px", paddingRight:"0px", textAlign:"center"}}>
				{value}
			</div>
 		);
 	}
 
 	renderRowStatus(value) {
 		return (
 			<div 
 			className="col-md-3 col-xs-3" 
 			style={{overflow:"hidden", paddingLeft:"0px", paddingRight:"0px", textAlign:"center", fontWeight:"bold"}}>
				<span
				style={{
					color:
						value === "Successful"
							? "#57d500"
							: value === "Failed"
							? "#ff2e00"
							: "rgb(65, 105, 225)",
					transition: "all .3s ease" }}>
					{ value === "Successful" ? 
							<i className="fa fa-check-circle fa-lg"></i> :
						value === "Failed" ? 
							this.props.data.error_code :
							"Pending"
					}
				</span>
			</div>
 		);
	}

	render() {
		return (
			<div
			onClick={this.handleRowClick}>
				{ this.props.isExpanded ? (
					<table style={{width:"100%"}}>
						<tbody>
							{this.renderTable()}
						</tbody>
					</table>
				) : (	
					<div className="row" style={{height:"310px"}}>
						{this.renderRowData2(this.props.orderID)}
	 					{this.renderRowData(this.props.date, this.props.time)}
	 					{this.renderRowStatus(this.props.transStatus)}
					</div>
				)}
			</div>
		);
	}
}

export default TXLogMobileRowTable;