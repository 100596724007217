import React from "react";

const Checkbox = ({name, label, checked, onChange}) => {

	return (
		<div className="checkboxContainer row filter-field">
			<span style={{ marginLeft: "10px" }}>{label}</span>
			<input 
				type="checkbox" 
				className="custom-checkbox"
				name={name}
				onChange={onChange}
			/>
		</div>
	);
}

export default Checkbox;