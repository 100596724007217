import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  files: null
};

const ecommercePluginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ECOMMERCE_FILES:
      return{
        ...state,
        files: action.payload
      }

    default:
      return state;
  }
};

export default ecommercePluginReducer;