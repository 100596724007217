import React, { Component } from "react";
//import SingleInput from "../../general/core/SingleInput";

class SubUserCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	username: "",
    	email: "",
    };
   	
   	this.handleInput = this.handleInput.bind(this);
  }
 
 	handleInput(event) {
		event.preventDefault();
		this.setState({
			[event.target.name]: event.target.value,
		}, () => {
			this.props.setCreateBody(this.state);
		});
 	}
 
	render() {
		return (
			<>
				<div className="row">
					<div className="col-md-2 col-sm-2 col-xs-12" style={{ minWidth:"250px" }}>
						{/*<SingleInput
							label="Username"
							name="username"
							autoComplete="off"
							//placeholder="Username"
							pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
							title="The number input must start with a number and use a dot as a decimal character."
							marginTop="4px"
							onChange={this.handleInput}
							errorMsg={this.errorTextAmount}
							showErrorMsg={this.props.amountInputWrong}
							defaultValue={this.props.usePrevFormData ? this.props.prevFormData["amount"] : ""}
		/>*/}

						<input
						className="custom-input tl-filter-input-field"
						type="text"
						name="username"
						onChange={this.handleInput}
						placeholder="Username"
						style={{ height: "38px"}}/>

					</div>
					<div className="col-md-2 col-sm-2 col-xs-12" style={{ minWidth:"250px" }}>
						{/*<SingleInput
							label="Email"
							name="email"
							autoComplete="off"
							//placeholder="Email"
							pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
							title="The number input must start with a number and use a dot as a decimal character."
							marginTop="4px"
							onChange={this.handleInput}
							errorMsg={this.errorTextAmount}
							showErrorMsg={this.props.amountInputWrong}
							defaultValue={this.props.usePrevFormData ? this.props.prevFormData["amount"] : ""}
					/>*/}

						
						<input
						className="custom-input tl-filter-input-field"
						type="text"
						name="email"
						onChange={this.handleInput}
						placeholder="Email"
						style={{ height: "38px"}}/>
						

					</div>
					<div className="col-md-6 col-sm-6"></div>
				</div>
			</>
		);
  }
}

export default SubUserCreation;