import React from 'react';
import { Modal } from 'react-bootstrap';
import { ScaleLoader } from "react-spinners";

const CONFIRM = ({ show, handleSave, handleClose, title, confirmText, style = {}, showBtnLoading = false }) => {
    return (
        <div>
            <Modal dialogClassName = 'custom-notification' show={show} onHide={handleClose} style={style}>
                <Modal.Header style={{padding:"0px"}}>
                    <div style={{backgroundColor: '#283048', textAlign:'center', height:"50px"}}>
                        <label style={{color:'white', fontSize:'15px', paddingTop:'5px'}}> {title} </label>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:'center'}}>
                        <label style={{color: 'rgb(10, 103, 156)', fontSize:'15px'}}> Are you sure to {confirmText} ? </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{textAlign:'center'}}>
                        <button className="primaryBtn" style={{float: "right"}} onClick={handleSave} disabled={showBtnLoading}>
	                        { showBtnLoading ?
							          		<ScaleLoader color={"white"} height={15} /> :
							            	"YES"
							            }
                        </button>
                        <button className="secondaryBtn" onClick={handleClose} disabled={showBtnLoading}>
                            NO
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CONFIRM;