import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  users: null,
  isLoading: false,
  isLoggedIn: false,
  error: null,
  resetPassword: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        users: action.payload,
        isLoggedIn: true,
        isLoading: true,
        error: null
      };
      
    case actionTypes.PASS_RESET:
      return {
        ...state,
        resetPassword: action.payload
      };

    case actionTypes.PASS_RESET_CONFM:
      return {
        ...state,
        error: action.payload.error,
        resetPassword: action.payload
      };

    case actionTypes.TOKEN_VERIFY:
      return {
        ...state,
        // token: action.payload
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        // token: action.payload
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default loginReducer;
