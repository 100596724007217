import React from 'react';

const CCNCell = ({ value }) => {
	let logoSrc = "";
	let logoAlt = "";
	let result = value.replace("xxxxxx", "...");
	if (value.charAt(0) === "4") {
		logoSrc = "/img/credit/visa_mini_logo.png";
		logoAlt = "VISA";
	} else if (value.charAt(0) === "3") {
		if (value.charAt(1) === "4" || value.charAt(1) === "7") {
			logoSrc = "/img/credit/amex_mini_logo.png";
			logoAlt = "AMEX";
		} else {
			logoSrc = "/img/credit/diners_mini_logo.png";
			logoAlt = "DC";
		}
	} else {
		logoSrc = "/img/credit/mc_mini_logo.png";
		logoAlt = "MC";
	}
	
	return (
		<div className="row" style={{margin:"8px 0px"}}>
			<img src={logoSrc} width="25" alt={logoAlt}></img>
			{" "}
			{result}
		</div>
	)
};

export default CCNCell;