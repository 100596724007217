import React from "react";
import * as style from "../style/style";
import NumberFormat from "react-number-format";
import _ from "lodash";

export const livebalances_columns = [
  {
    Header: "Company",
    accessor: "pc21_company",
    filterable: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    filterable: false,
    headerStyle: {
      whiteSpace: "unset",
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    minWidth: 40,
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
    filterable: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
  },
  {
    Header: "Settlement Balance(EUR)",
    accessor: "settlement_balance",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Temporary Balance(EUR)",
    accessor: "temporary_balance",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Payout Amount(EUR)",
    accessor: "payout_amount",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Total Available Balance(EUR)",
    accessor: "available_balance",
    filterable: false,
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  }
];

export default {
  livebalances_columns,
};
