import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
    notification :"",
    boardResponse : null,
    boardStatusResponse: null,
    boardDisableResponse: null
};

const merchantBoardReducer = (state = initialState, action) =>{
    switch(action.type) {
        case actionTypes.BOARD_PAYPAL_MERCHANT:
            return {
                ...state,
                boardResponse: action.payload,                
            };
        case actionTypes.BOARD_PAYPAL_MERCHANT_STATUS:
            return {
                ...state,
                boardStatusResponse: action.payload,                
            };
        case actionTypes.BOARD_PAYPAL_MERCHANT_DISABLE:
            return {
                ...state,
                boardDisableResponse: action.payload,
            };
        default:
            return state;
    }
};

export default merchantBoardReducer;