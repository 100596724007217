import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as service from "../services/partnerMerchants";

const _getPartnerMerchantsData = data => ({
    type: actionTypes.PARTNER_MERCHANTS_DATA,
    payload: data
});

export const getPartnerMerchantsData = () => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };

    return service
      .getPartnerMerchantsData(token)
      .then(res => {
        if (res.data) {
          return dispatch(_getPartnerMerchantsData(res.data));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};
