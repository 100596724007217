import * as style from "../style/style";

export const bankdetails_columns = [
  {
    Header: "Mid",
    accessor: "mid_number",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 130,
  },
  {
    Header: "Company",
    accessor: "pc21_company",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      whiteSpace: "unset",
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 40,
  },
  {
    Header: "Beneficiary Name",
    accessor: "beneficiary_name",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "IBAN",
    accessor: "iban_account",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 240,
  },
  {
    Header: "Swift/BIC",
    accessor: "swift_bic",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Bank Name",
    accessor: "bank_name",

    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Bank Street",
    accessor: "bank_add_street1",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    show: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Bank City",
    accessor: "bank_add_city",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    show: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Bank Country",
    accessor: "bank_add_country",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    headerStyle: {
      whiteSpace: "unset",
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 40,
  },
  {
    Header: "Beneficiary Address",
    accessor: "ben_add_street1",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    show: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Beneficiary City",
    accessor: "ben_add_city",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    show: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Beneficiary Country",
    accessor: "ben_add_country",
    filterMethod: (filter, row) => {
      return row[filter.id] !== null
        ? row[filter.id]
            .toUpperCase()
            .replace(/\s+/g, "")
            .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
        : false;
    },
    show: false,
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
];

export default {
  bankdetails_columns,
};
