export const CHARGEBACK_ALERTS_COLUMNS_KEYS = {
    TRANSACTION_ID: "transaction_id",
    ALERT_DATE: "dispute_provider_alert_date",
    AMOUNT: "dispute_provider_transaction_amount",
    CURRENCY: "dispute_provider_transaction_currency",
    STATUS: "payabl_dispute_status",
    CARD_BRAND: "card_brand",
    PROVIDER: "backoffice_dispute_provider",
    REASON_CODE: "dispute_provider_reason_code",
    Merchant: "merchant_name",
    Company: "merchant_company_id",
    ACTION: "action_button_state",
};

export const CHARGEBACK_ALERTS_DATA_TABLE_COLUMNS = {
    // columnKey: [{COLUMN_LABEL, COLUMN_WIDTH}]
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.TRANSACTION_ID]: ["Transaction ID", 100],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.Merchant]: ["Merchant", 100],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.Company]: ["Company", 130],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.ALERT_DATE]: ["Alert Date", 120],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.AMOUNT]: ["Amount", 80],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.CURRENCY]: ["Currency", 80],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.STATUS]: ["Status", 90],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.CARD_BRAND]: ["Card brand", 90],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.PROVIDER]: ["Provider", 100],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.REASON_CODE]: ["Reason Code", 100],
    [CHARGEBACK_ALERTS_COLUMNS_KEYS.ACTION]: ["Action", 300],
};