import React from "react";
import * as style from "../style/style";
import NumberFormat from "react-number-format";
import _ from "lodash";

export const reserve_history_columns = [
  {
    Header: "Mids",
    accessor: "mid",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Company",
    accessor: "pc21_company",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Category",
    accessor: "on_hold_category",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      backgroundColor: style.tableBackgroundColor,
      textAlign: style.headertextAlign
    },
    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original) {
        return {
          style: {
            cursor: "pointer"
          }
        };
      } else {
        return {};
      }
    },
    Cell: row => {
      /* Add data-tip */
      if (row.original.comments) {
        return (
          <span title={row.value + " : " + row.original.comments}>
            <b>*</b> {row.value}
          </span>
        );
      } else {
        return <span title={row.value}>{row.value}</span>;
      }
    }
  },

  {
    Header: "Date",
    accessor: "settl_date",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      backgroundColor: style.tableBackgroundColor,
      color: "black",
      textAlign: style.headertextAlign
    },
    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original) {
        return {
          style: {
            background: rowInfo.original.due_date_color
          }
        };
      } else {
        return {};
      }
    }
  },

  {
    Header: "Reserves Collected",
    accessor: "settl_balance",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      backgroundColor: style.tableBackgroundColor,
      color: "black",
      textAlign: style.headertextAlign
    },

    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original) {
        return {
          style: {
            background: rowInfo.original.settl_ready_to_release
          }
        };
      } else {
        return {};
      }
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  },
  {
    Header: "Outstanding",
    accessor: "outstandings",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      backgroundColor: style.tableBackgroundColor,
      color: "black",
      textAlign: style.headertextAlign
    },
    getProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.original) {
        return {
          style: {
            background: rowInfo.original.settl_ready_to_release
          }
        };
      } else {
        return {};
      }
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      backgroundColor: style.tableBackgroundColor,
      textAlign: style.headertextAlign
    }
  },

  {
    Header: "RC Date",
    accessor: "reserve_collection_date",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  }
];

export default {
  reserve_history_columns
};
