import React, { Component } from "react";
import TXLogMobileRowTable from "./TXLogMobileRowTable";
import * as helper from "../../general/core/helper";
	
class TransactionLogMobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 0,
			data: [],
		};
		this.columns = [];
		this.headers = [];
		this.isExpanded = [];
		
		this.renderRows = this.renderRows.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
	}
	
	componentDidMount() {
		this.columns = this.props.columns; // for header
		for (let i = 0; i < this.columns.length; i++) {
			this.headers.push(this.columns[i].Header);
		};
	
		this.isExpanded = [];
		this.columns.map(() => this.isExpanded.push(false));
		this.setState({
			activePage: this.props.activePage,
			data: this.props.data, // for content
		});
  }
 
 	componentDidUpdate() {
 		if (this.state.activePage !== this.props.activePage) {
 			this.isExpanded = [];
 			this.columns.map(() => this.isExpanded.push(false));
 			this.setState({
 				activePage: this.props.activePage,
 				data: this.props.data, // for content
 			});
 		}
 	}
 
 	handleRowClick(event) {
 		//let classList = event.currentTarget.classList.value.split(" ");
 		let rowID = parseInt(event.currentTarget.id.substring(13), 10);
 		let element = document.getElementById("tlRowCollapse"+rowID);
 		let transDetailLinkElement = document.getElementById("transDetailLink"+rowID);
 		this.isExpanded[rowID] = !this.isExpanded[rowID];
 		
 		if (element.classList.contains("minimized-flag")){
			element.classList.add("fade-out-text");
			transDetailLinkElement.classList.add("fade-out-text");
			element.style.opacity = 0;
			transDetailLinkElement.style.opacity = 0;
			element.style.pointerEvents = 'none';
			element.classList.remove("minimized-flag");
			element.style.opacity = 1;
			transDetailLinkElement.style.opacity = 1;
			element.classList.remove("fade-out-text");
			transDetailLinkElement.classList.remove("fade-out-text");
			element.classList.add("fade-in-text");
			transDetailLinkElement.classList.add("fade-in-text");
			setTimeout(function () {
				element.classList.remove("fade-in-text");
				element.style.pointerEvents = 'auto';
			}, 500);
			setTimeout(function () {
				transDetailLinkElement.classList.remove("fade-in-text");
			}, 700);
		} else {
			element.classList.add("fade-out-text");
			element.style.pointerEvents = 'none';
			element.classList.add("minimized-flag");
			element.classList.remove("fade-out-text");
			element.classList.add("fade-in-text");
			setTimeout(function () {
				element.classList.remove("fade-in-text");
				element.style.pointerEvents = 'auto';
			}, 500);
		}
 		
 		this.forceUpdate();
 	}
 
 	renderRows() {
 		let rows = [];
 	
 		for (let i = 0; i < this.state.data.length; i++) {
 			let orderID = "";
 			//let merchantName = "";
	 		let date = "";
			let time = "";
			let transStatus = "";
			
			for (let j = 0; j < this.columns.length; j++) {
				let key = this.columns[j]["accessor"];
				
				if (key === "order_id") orderID = this.state.data[i][key];
				//if (key === "merchant_name") merchantName = this.state.data[i][key];
				if (key === "date") date = this.state.data[i][key];
				if (key === "time") time = this.state.data[i][key];
				if (key === "transaction_status") transStatus = this.state.data[i][key];
				if (transStatus === 'Failed') {
	
				}
			}
			
			rows.push(
				<div key={this.state.data[i]["transaction_id"] + "outerdiv"}>
					{ this.isExpanded[i] ? (
						<button
						key={this.state.data[i]["transaction_id"] + "link"}
						id={"transDetailLink"+i} 
						style={{
							position:"absolute", 
							zIndex:"1000", 
							right:"-25px", 
							marginTop:"170px", 
							marginRight:"13px", 
							background:"none",
							width:"80px",
							height:"80px",
							borderRadius:"50px 50px 50px 50px",
							borderColor:"rgb(46, 128, 178) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgb(46, 128, 178)",
							border:"none"
						}} //, opacity:this.isExpanded[i] ? "1" : "0"}}
						onClick={e => {
							e.preventDefault();
							helper.renderTransDetails(this.state.data[i]["transaction_id"]);
						}}>
							<span className="glyphicon glyphicon-folder-open" style={{fontSize:"20px", color:"#2e80b2"}}></span>
						</button>
					) : (
						null
					)}
	 				<div 
	 				key={this.state.data[i]["transaction_id"] + "innerdiv"} 
	 				className="row collapse module mobile-tl-row"
	 				id={"tlRowCollapse"+i}
	 				style={{paddingLeft:"20px", paddingTop:"7px", backgroundColor: transStatus === "Successful" ? "rgb(238, 255, 244)" : transStatus === "Failed" ? "rgb(251, 226, 226)" : "rgb(244, 246, 251)", width:"100%", margin:"0px"}}
	 				type="button"
					data-toggle="collapse"
					data-target={"#tlRowCollapse"+i}
					aria-expanded="false"
					aria-controls={"tlRowCollapse"+i}
					onClick={this.handleRowClick}>
	 					<TXLogMobileRowTable 
	 					columns={this.props.columns}
	 					data={this.state.data[i]}
	 					//merchantName={merchantName}
	 					orderID={orderID}
	 					date={date}
	 					time={time}
	 					transStatus={transStatus}
	 					isExpanded={this.isExpanded[i]}/>
					</div>
				</div>
 			);
 		}
 		
 		return rows;
 	}

	render() {
		return (
			<>
				{this.renderRows()}
			</>
		);
	}
}

export default TransactionLogMobile;