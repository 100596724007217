import React from "react";
import { Text } from "recharts";
class CustomizedAxisTick extends React.Component {
  render() {
    const { x, y, payload, chart } = this.props;
    if (chart === "vertical") {
      return (
        <Text x={x} y={y} width={180} textAnchor="end" verticalAnchor="middle">
          {payload.value}
        </Text>
      );
    } else {
      return (
        <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
          {payload.value}
        </Text>
      );
    }
  }
}

export default CustomizedAxisTick;
