
// Matoma script to send the event to matoma for the tracking.
var _paq = (window._paq = window._paq || []);

function initMatomo() {
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
        var u = "https://matomo.inatec.com/";
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", "9"]);
        var d = document,
            g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
    })();
}

function trackEvent(action, label, ...params) {
    if (window._paq) {
        let username = localStorage.getItem("username");
        let merchantId = localStorage.getItem("merchant_id");
        let companyName = localStorage.getItem("company_name");
        window._paq.push(["trackEvent", `${username},${merchantId},${companyName}`, action, label, ...params]);
    } else {
        console.error("Matomo script is not initialized.");
    }
}

export { initMatomo, trackEvent };
