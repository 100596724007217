import apiFactory, {api} from "./api";

import * as apiLink from '../lib/constants/apiLink';
// Developers can override this with an env.local file
const baseUrl = apiLink.BASE_API_URL;
const apiAuth = apiFactory(baseUrl);

/**
 *@param {} token
*/

 
// Post /backoffice/get_permissions
export const getPermissions = token =>{
    return api(baseUrl, token).post(apiLink.BACKOFFICE_GET_PERMISSION);
}

// GET /backoffice/dashboard API
export const sideBarMenu = token =>{
    return api(baseUrl, token).get(apiLink.BACKOFFICE_DASHBOARD);
}



/**
 * Function for getting Charts data
 * @param {*} token 
 * @param body // requestBody
 */
// export const getData = (token, body) =>{
export const getData = (token, body) =>{
    return api(baseUrl, token).post(apiLink.BACKOFFICE_GET_DATA, body);
}

export const testFunction = payload =>{
    return apiAuth.post(apiLink.BACKOFFICE_GET_DATA, payload);
}