import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from "../lib/constants/utils/token";
import * as userService from "../services/user";

const loginSucceeded = data => ({
  type: actionTypes.LOGIN,
  payload: data
});

const loginFailed = data => ({
  type: actionTypes.LOGIN,
  payload: data
});

const verifyEmail = data => ({
  type: actionTypes.PASS_RESET,
  payload: data
});

const confirmEmail = data => ({
  type: actionTypes.PASS_RESET_CONFM,
  payload: data
});

const _refreshToken = data => ({
  type: actionTypes.TOKEN_REFRESH,
  payload: data
});

const _verifyToken = data => ({
  type: actionTypes.TOKEN_VERIFY,
  payload: data
});

export const login = (token, payload) => {
  return dispatch => {
    return userService
      .userLogin(token, payload)
      .then(res => {
        cookieUtils.set(ACCESS_TOKEN_KEY, res.data.token);
        cookieUtils.set(REFRESH_TOKEN_KEY, res.data.refresh_token);
        return dispatch(loginSucceeded(res.data));
      })
      .catch(error => {
        cookieUtils.remove(ACCESS_TOKEN_KEY);
        cookieUtils.remove(REFRESH_TOKEN_KEY);
        if (error.response !== undefined) {
          return dispatch(loginFailed(error.response.data));
        }
      });
  };
};

export const resetPassword = data => {
  return dispatch => {
    return userService
      .resetPassword(data)
      .then(res => {
        return dispatch(verifyEmail(res.data));
      })
      .catch(error => {
        return dispatch(verifyEmail(error.response.data));
      });
  };
};

export const confirmPassword = data => {
  return dispatch => {
    return userService
      .confirmPassword(data)
      .then(res => {
        return dispatch(confirmEmail({ ...res, error: false }));
      })
      .catch(err => {
        if (err.response !== undefined) {
          let data = err.response.data;
          data = { ...data, error: true };
          return dispatch(confirmEmail(data));
        }
      });
  };
};

export const verfyToken = () => {
  return dispatch => {
    return userService.verifyToken().then(res => {
      return dispatch(_verifyToken(res));
    });
  };
};

export const refreshToken = () => {
  return dispatch => {
    return userService.refreshToken().then(res => {
      return dispatch(_refreshToken(res));
    });
  };
};

export const logout = (token, payload) => {
  return dispatch => {
    return userService.userLogout(token, payload).then(() => {
      return dispatch({
        type: actionTypes.LOGOUT
      });
    });
  };
};
