import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadChargebackBreakDownData } from "../../../actions/chargebackAlertsAction";

const ChargebackDownload = (props) => {
  const [waitForDownload, setWaitForDownload] = useState(false);
  const dispatch = useDispatch();

  const chargebackDownload = async () => {
    setWaitForDownload(true);

    const requestBody = props.filter;
    const response = await dispatch(downloadChargebackBreakDownData(requestBody));
    const responseType = "text/csv";
    let fileName = "chargebackLog.csv";

    if (response) {
      if (response.payload) {
        try {
          const blob = new Blob([response.payload], { type: responseType });
          
          // Check if headers contain content-disposition
          const headers = response.headers || {};
          if (headers['content-disposition']) {
            const contentDisposition = headers['content-disposition'];
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setWaitForDownload(false);
        } catch (error) {
          alert('Error processing download:', error);
          setWaitForDownload(false);
        }
      } else {
        setWaitForDownload(false);
      }
    } else {
      setWaitForDownload(false);
    }
  }

  return (
    <button className="txLogBtn btn"
      style={{marginLeft: '2em'}}
      onClick={chargebackDownload}
      disabled={waitForDownload || props.disabled || !props.tableLength>0}>
      { waitForDownload ?
        "Downloading..." :
        <FontAwesomeIcon icon={faDownload} style={{fontSize: "1.6rem"}} />
      }
    </button>
  );
}

const mapDispatchToProps = {
  downloadChargebackBreakDownData
};

export default withRouter(
  connect(null, mapDispatchToProps)(ChargebackDownload)
);
export { ChargebackDownload };
