import * as style from "../style/style";
import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
export const next_payment_columns = [
  {
    Header: "Mids",
    accessor: "mid",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Company",
    accessor: "pc21_company",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Date",
    accessor: "payout_date",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Settlement",
    accessor: "next_settlement_payment",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black"
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  },
  {
    Header: "Reserve",
    accessor: "next_reserve_payment",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black"
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  },
  {
    Header: "Security Deposit",
    accessor: "next_security_deposit_payment",
    aggregate: vals => _.sum(vals),
    Aggregated: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black"
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  }
];

export default {
  next_payment_columns
};
