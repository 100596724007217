import {api} from "./api";
import * as apiLink from '../lib/constants/apiLink';
import { api_for_file_download } from "./api";


const baseUrl = apiLink.BASE_API_URL;

// POST /backoffice/get_ecommerce_files API
export const getPluginNames = (token, body) => {
	return api(baseUrl, token).get(apiLink.GET_ECOMMERCE_FILES, body);
}
export const downloadPlugin = (token, body) => {
	return api_for_file_download(baseUrl, token).post(apiLink.GET_ECOMMERCE_FILES, body);
}