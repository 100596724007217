import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getChargebackAlerts, getAllMerchants, getChargebackPreventionRules, deleteRule } from "../../../../actions/chargebackAlertsAction";
import styles from "./ChargebackPreventionRules.module.css";
import Select from "react-select";
import { SELECT_STYLES } from "../../../../lib/constants/core";
import { LoadingScreen } from "../../../general/common/LoadingScreen";
import { ClipLoader } from "react-spinners";
import CreateChargebackAlertRule from "../CreateChargeback/CreateChargebackAlertRule";
import Popup from "reactjs-popup";

const ChargebackPreventionRules = ({ getAllMerchants, getChargebackPreventionRules, chargebackAlertsReducer }) => {
    const [isComponentLoading, setComponentLoading] = useState(true);
    const [isRulesLoading, setRulesLoading] = useState(false);
    const [selectedMerchantId, setSelectedMerchantId] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRule, setEditingRule] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); 
    const [ruleToDelete, setRuleToDelete] = useState(null); 

    const loadingRingColor = '#0365F4';

    const getMerchantsData = useCallback(() => {
        return chargebackAlertsReducer?.merchantsData;
    }, [chargebackAlertsReducer]);

    const getChargebackPreventionRulesData = useCallback(() => {
        return chargebackAlertsReducer?.chargebackPreventionRules;
    }, [chargebackAlertsReducer]);

    const dropdownMerchantsData = useMemo(() => {
        const merchantsData = getMerchantsData();
        const merchants = merchantsData?.companies?.map((merchant) => {
            return {
                label: merchant,
                value: merchant
            };
        }) || [];

        return [
            { label: 'Select All', value: 'Select All' },
            ...merchants
        ];
    }, [getMerchantsData]);

    const fetchAllMerchants = async () => {
        try {
            await getAllMerchants();
        }
        catch (error) {
            console.error("Error on fetching merchants", error)
        }
        finally {
            setComponentLoading(false);
        }
    }

    const fetchChargebackPreventionRules = async () => {
        try {
            setRulesLoading(true);
            const params = selectedMerchantId === 'Select All' ? {} : { company: selectedMerchantId };
            await getChargebackPreventionRules(params);
        } catch (error) {
            console.error("Error on fetching chargeback prevention rules", error);
        } finally {
            setRulesLoading(false);
        }
    }  

    const handleDeleteRule = async () => {
        try {
            setRulesLoading(true);
            await deleteRule(ruleToDelete?.id);  
        }
        catch (error) {
            alert(`Error on deleting handle Rule ${error}`)
        }
        finally {
            setIsDeleteDialogOpen(false);
            setRulesLoading(false);
            await fetchChargebackPreventionRules();   
        }
    }

    const onChangeSelectedMerchant = (event) => {
        setSelectedMerchantId(event.value);
    }

    useEffect(() => {
        fetchAllMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
            fetchChargebackPreventionRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMerchantId]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setEditingRule(null);
    };

    const handleCloseChargebackAddModal = () => {
        setIsModalOpen(false);
    };

    const handleEditRule = (rule) => {
        setEditingRule(rule);
        setIsModalOpen(true);
    }

    const openDeleteDialog = (rule) => {
        setRuleToDelete(rule);
        setIsDeleteDialogOpen(true);
    }

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    }

    const handleRuleSaved = () => {
        fetchChargebackPreventionRules();
    }

    return (
        <div className={styles.chargebackPreventionRulesWrapper}>
            {
                isComponentLoading ?
                <LoadingScreen />
                :
                    (
                        <>
                            {getMerchantsData() &&
                                <div className={styles.headerAction}>
                                    <Select
                                        className={styles.merchants}
                                        components={{ IndicatorSeparator: null }}
                                        styles={SELECT_STYLES(isComponentLoading)}
                                        isSearchable={true}
                                        isDisabled={isComponentLoading}
                                        name={"merchants"}
                                        options={dropdownMerchantsData}
                                        placeholder={"Select Company"}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={true}
                                        formatGroupLabel={true}
                                        onChange={e => onChangeSelectedMerchant(e)}
                                    />
                                    
                                    <button
                                        className={styles.actionButton}
                                        type="button"
                                        onClick={handleOpenModal}
                                    >
                                        Add Rule
                                    </button>

                                    <CreateChargebackAlertRule
                                        open={isModalOpen}
                                        onClose={handleCloseChargebackAddModal}
                                        companies={dropdownMerchantsData}
                                        editingRule={editingRule}
                                        onSave={handleRuleSaved}
                                    />
                                </div>
                            }

                            <div className={styles.header}>
                                <span>Chargeback Prevention Rules</span>
                            </div>

                            <table className="table table-bordered stripped">
                                <tbody className={`table table-bordered stripped ${styles.bodyWrapper}`}>
                                    <tr style={{ backgroundColor: "white" }}>
                                        <th>Company</th>
                                        <th>Provider</th>
                                        <th>Rule</th>
                                        <th>Action</th>
                                    </tr>
                                    {getChargebackPreventionRulesData()?.length > 0 && !isRulesLoading &&
                                        (
                                            getChargebackPreventionRulesData().map((item, key) => {
                                                return <tr key={key}>
                                                    <td>{item.company}</td>
                                                    <td>{item.provider}</td>
                                                    <td>{item.rule}</td>
                                                    <td>
                                                        <span
                                                            onClick={() => handleEditRule(item)}
                                                            className={styles.editButton}
                                                        >
                                                            Edit
                                                        </span>
                                                        <span
                                                            onClick={() => openDeleteDialog(item)}
                                                             className={styles.popupDeleteButton}
                                                        >
                                                            Delete
                                                        </span>
                                                    </td>
                                                </tr>
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                            {!getChargebackPreventionRulesData()?.length > 0 && !isRulesLoading && selectedMerchantId &&
                                <span className={styles.emptyRules}>No chargeback prevention rules are found for the selected merchant</span>
                            }
                            {isRulesLoading &&
                                <div className={styles.bodyLoader}>
                                    <ClipLoader
                                        sizeUnit={"px"}
                                        size={50}
                                        color={loadingRingColor}
                                        loading={isRulesLoading}
                                    />
                                </div>
                            }
                        </>
                    )
            }
            <Popup open={isDeleteDialogOpen} onClose={closeDeleteDialog} modal>
                <div className={styles.popup}>
                    <div className={styles.content}>
                        <h4 className={styles.popupContent}>Delete chargeback alert rule?</h4>
                        <hr className={styles.separator} />
                        <p>Are you sure you want to delete this rule?</p>
                        <div className={styles.actionButtons}>
                            <button onClick={closeDeleteDialog} className={styles.cancelButton}>Cancel</button>
                            <button onClick={handleDeleteRule} className={styles.modalDeleteButton}>Delete</button>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

const mapStateToProps = state => ({
    chargebackAlertsReducer: state.chargebackAlertsReducer,
});

const mapDispatchToProps = {
    getChargebackAlerts,
    getAllMerchants,
    getChargebackPreventionRules,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChargebackPreventionRules)
);
