import { api, api_for_file_download } from "./api";
import * as apiLink from "../lib/constants/apiLink";

const baseUrl = apiLink.BASE_API_URL;

export const getPosTransactionData = (token, filterBody, params, cancelToken) => {
	return api(baseUrl, token).post(apiLink.POS_TRANSACTION_GET_DATA, filterBody, { params: params, cancelToken: cancelToken.token });
};

export const downloadPOSTransactionData = (token, body) => {
	return api_for_file_download(baseUrl, token).post(apiLink.POS_TRANSACTION_DOWNLOAD_DATA, body);
};
