import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import moment from "moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as helper from "../../general/core/helper";

import { getPaymentLink, updatePaymentLink, getPaymentMethods, sendPaymentLink } from "../../../actions/paymentLinkAction";

const FormBlock = {
  margin:"10px",
  width:"100%",
  minWidth: "150px",
  maxWidth: "1000px",
  boxSizing: "border-box", 
  fontSize: "14px",
  lineHeight: "1.4",
  opacity: "1",
  fontFamily:"SourceSansPro, Helvetica, Arial, sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
}

const FormBlock2 = {
  margin:"5px 10px 5px 10px",
  fontSize: "14px",
  lineHeight: "1.4",
  fontFamily:"SourceSansPro, Helvetica, Arial, sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale"
}

const LabelStyle = {
  fontSize: "14px",
  lineHeight: "1.4",
  opacity: "1",
  margin: "0",
  color: "#2271a1",
  fontFamily: "BlinkMacSystemFont, -apple-system, BlinkMacSystemFont, SegoeUI, 'Roboto', sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale"
}

const LinkField = {
  paddingLeft:"10px",
  width:"95%",
  minWidth:"150px",
  height:"40px",
  fontSize: "14px",
  opacity: "1",
  margin: "0",
  background: "#fff",
  fontFamily: "Helvetica, -apple-system, BlinkMacSystemFont, SegoeUI, 'Roboto', sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
}

const SelectField = { 
  width: "100%",
  maxWidth: "300px",
  minWidth: "200px",
  paddingLeft:"10px", 
  height:"40px",
  fontSize: "14px",
  opacity: "1",
  margin: "0",
  background: "#fff",
  fontFamily: "Helvetica, -apple-system, BlinkMacSystemFont, SegoeUI, 'Roboto', sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
}

const SelectField2 = { 
  padding:"10px", 
  height:"40px",
  fontSize: "14px",
  opacity: "1",
  margin: "0",
  background: "#fff",
  fontFamily: "Helvetica, -apple-system, BlinkMacSystemFont, SegoeUI, 'Roboto', sans-serif",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
}

const ExpiryDate = {
  width: "200px",
  border:"1px solid #cccccc",
  padding:"10px 0 0 10px",
  height:"40px",
  fontSize: "14px",
  opacity: "1",
  margin: "0",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale"
}

const Button = {  
  textAlign:"center",
  borderRadius: "5px",
  width:"10em",
  height:"38px",
  opacity: "0.9",
  margin:" 0 10px 5px 10px",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
}


class PaymentLinkDetails extends Component {
  constructor(props) {
    super(props);

    const paymentLinkData = this.props.paymentLink
        ? this.props.paymentLink.paymentLinData
        : {};

    this.state = {
      paymentLinkData:paymentLinkData,
      linkid:parseInt(window.location.search.substring(1)),
      data:"",
      currencyCodes: [],
      value: 'some\ntext', 
      copied: false,
      lifespanHours: 0,
      lifespanDate: "",
      visibility: false,
      permission: true,
      email:"",
      responseEmail:"",
      errorText:'',
      sendEmail:'',
      errorMessage: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
 }

  /**
   * Fetches payment link fields depending on link id.
   * @param {string} linkid - Link ID which will be send as request.
   */
  async getLinkID(linkid) {
    
    let requestBody = {
      linkid: linkid, 
    };
    const response = await this.props.getPaymentLink(requestBody);
    
    if (helper.isJsonString(response.payload)){
      this.setState({
        responsePaymentLink: response.payload,
      });

      let data_parsed = JSON.parse(response.payload);

      this.setState({
          merchant: data_parsed["merchant"],
          amount: data_parsed["amount"],
          email: data_parsed["email"],
          created: parseInt(data_parsed["created"])*1000,
          currency: data_parsed["currency"],
          lifespan: parseInt(data_parsed["lifespan"])*1000,
          linkid: data_parsed["linkid"],
          order: data_parsed["order"],
          paymentLink: data_parsed["paymentLink"],
          status: data_parsed["status"],
          transactionid: data_parsed["transactionid"],  
          lifespanDate: data_parsed["created"],
          merchant_company_name: data_parsed["merchant_company_name"],
          merchant_company_address1: data_parsed["merchant_company_address1"],
          merchant_company_address2: data_parsed["merchant_company_address2"]
      });

      this.requestPaymentMethodsByMerchantID( data_parsed["merchant"] );
    } else 
    {
      this.getErrorText("errorText", "Cannot load Currency. Error code: 500 (Internal Server Error).");
    }
  }

   /**
   * Fetches payment methods and their fields depending on merchant id.
   * @param {string} merchantID Merchant ID which will be send as request.
   */
  async requestPaymentMethodsByMerchantID(merchantID) {
    // Send request for payment methods list and their required form fields.
  await this.props.getPaymentMethods({ merchantid: merchantID, });

  const merchantData = this.props.paymentMethods
    ? this.props.paymentMethods.paymentMethodsData
    : {};

  console.log('this.props.paymentMethods.paymentMethodsData', this.props.paymentMethods.paymentMethodsData);
  // Error handling for fetching payment methods.
  if (!merchantData.errors) 
  {
    this.getErrorText("errorText", "Something wrong ... : " + merchantData);
    return;
  }
  if (merchantData.errors && merchantData.errors !== "0") 
  {
    this.getErrorText("errorText", "Cannot load Currency. Error code: " + merchantData.errors);
    return;
  }
  
    if (merchantData.data.currencies && merchantData.data.currencies !== "") 
    {

      let arr = merchantData.data.currencies.split(",");
      this.setState({
        currencyCodes:arr
      });
    } else 
    {
      this.getErrorText("errorText", "Currencies missing.");
      this.setState({
        currencyCodes:[]
      });
    }
  }

	/* #####################################################################
	                      * HANDLER FUNCTIONS
  ####################################################################### */

  
  ////////////////////////////////////// Button Copy /////////////////////
  onChangeLink = ({target: {value}}) => {
    this.setState({value, copied: false});
  };

  onClick = ({target: {innerHTML}}) => {
    console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
  };

  onCopyLink = () => {
    this.setState({copied: true, errorText:'', sendEmail:'',});
  };
  //////////////////////////////////////////////////////////
    
  handleInputChange(event) 
  {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if ( name === "lifespanHours" ){

          
      var lifespanDate =  new Date(this.state.created);
      
      lifespanDate.setHours(lifespanDate.getHours() + parseInt(value));
      
      var msec = Date.parse(lifespanDate);
      
      this.setState(state => ({
        ...state,
        lifespanDate:lifespanDate,
        lifespan:msec,
      }))
    }
  }

  async handleSubmit(event) 
  { 
    event.preventDefault();
    	
 	    this.setState({
	    	loading: true, 
        transactionCompleted: false,
      }); 

      var lifespanSec = parseInt(this.state.lifespan)/1000;
      var createdSec = parseInt(this.state.created)/1000;      
       
      var requestBody = { editing: true,
                          linkid: this.state.linkid,
                          merchant:this.state.merchant, 
                          order:this.state.order, 
                          lifespan:lifespanSec,
                          created:createdSec,
                          edited:Date.parse(new Date()),
                          currency:this.state.currency, 
                          amount:this.state.amount,
                          email:this.state.email };

      // Send request to API endpoint.
      await this.props.updatePaymentLink(requestBody);
      const data = this.props.paymentLink
         ? this.props.paymentLink.paymentLinkData
         : {};

      if( helper.isJsonString(data) )
           {
             let dataParsed = JSON.parse(data); 
             if( 'error_message' in dataParsed )
             {
              this.getErrorText("errorText", "Cannot created Payment Link. Error code: "+dataParsed['status']+" "+dataParsed['error_message']);
             }
         } else {
          this.getErrorText("errorText", "Cannot created Payment Link. Error code: 500 (Internal Server Error). AssertionError at backoffice");
         }

      console.log('data', data);
      window.open('/generic/paymentLinkTab', "_self");
  }
  
  /**
   * Checks if an object has no properties.
   * @param {string} ID name of the target element which should be displayed.
   * @param {string} Text which should be displayed.
   * @returns {boolean}
   */
  getErrorText(elementId, errorText) 
  {
    if (elementId === "sendEmail" )
    {
      this.setState({
      sendEmail:errorText,
      errorText:'',
      errorMessage: true
      });
    } else
    {
      this.setState({
      errorText:errorText,
      sendEmail:'',
      errorMessage: true
      });
    }

    document.getElementById(elementId);
    return true;
  }


  /* #####################################################################
	                      * send e-mail
  ####################################################################### */

   /**
   * Fetches fields for sending the PaymentLink by e-mail.
   */
  async handleSubmitEmail(event) {
    event.preventDefault();


    if(this.handleValidation()){

      var requestBody = { 
        paymentlink: this.state.paymentLink,    
        order:this.state.order,    
        amount: this.state.amount,
        currency:this.state.currency,
        date:moment(new Date(this.state.lifespan)).format("DD.MM.YYYY HH:mm:ss"),
        merchant_company_name:this.state.merchant_company_name,
        email:this.state.email,
        transactionid:this.state.transactionid,
        merchant_company_address1:this.state.merchant_company_address1,
        merchant_company_address2:this.state.merchant_company_address2
      };

      // Send request for PaymentLinkEmail.
      
      const response = await this.props.sendPaymentLink(requestBody);

      console.log('sendPaymentLink response', response);

      if (response && response.payload){
        this.setState({
          responseEmail: response.payload,
          errorText:'', 
          sendEmail:'',
        });
  
      //let data_parsed = JSON.parse(response.payload);
      
      console.log("paymentLinkEmail", response.payload);
      this.getErrorText("sendEmail", response.payload);
      return;
      }

      if (response && !hasOwnProperty(response.error)) 
      {
          this.setState({
            errorEmail: response.payload,
            errorText:'', 
            sendEmail:'',
          });

        this.getErrorText("sendEmail", "Cannot send Email. Error code: " + response.errors);
        return;
      }
    }
  }

 /**
 * Its a function, handling form values
 * @param {string} field - form fields
 */
  handleEmail =(field, e)=>{
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
        fields
    });
  }


  handleValidation=()=>{
    let email = this.state.email;
    let link = this.state.paymentLink;
    let errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    if (!link) {
      formIsValid = false;
      errors["link"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  /* #################################################################### */

  componentDidMount() {
    this.getLinkID(this.state.linkid);  
  }
    componentWillUnmount() {   
  } 
  
  render() {
    console.log('PaymentLinkDetails: render this.state', this.state);
    console.log('PaymentLinkDetails: render this.props', this.props);
    return (
      <div  style={{width:"100%", height: "100%", margin:"0", padding:"0", display:"flex", justifyContent: "center", alignSelf:"center"}}>
        <div style={{height: "100%", margin:"0", padding:"0", paddingBottom:"20px", display:"flex", flexDirection: "row", justifyContent: "center", alignSelf:"center",
                    flexWrap:"wrap", border: "1px solid gray", boxShadow: "12px 12px 2px 1px rgb(0 0 0 / 10%)" }}>        
 
          <div style={{width: "100%", padding:"5px"}}>   
            <h4>
              <Link to="/generic/paymentLinkTab">          
                <i className ="fa fa-chevron-left"  style={{ariaHidden:"true", color: "#0c141c"}}> back</i>
              </Link>
            </h4>
          </div>

          <div style={{display:"flex", flexWrap: "wrap", alignSelf: "center", alignItems: "stretch ", justifyContent: "space-between", width: "100%", maxWidth: "1100px", height: "100%", 
                      padding:"20px 20px 20px 20px"}}>

            <h3 style={{ margin:"0 0 0 0", height:"40px", width: "100%", textAlign: "center", color: "white", padding: "10px 0px 5px 10px", background: "#2e80b2", 
                        fontFamily: "'Source Sans Pro', Helvetica, Arial, sans-serif", fontSize: "18px",}}>Link ID {this.state.linkid}</h3>
            
            <div id="errorText" className="noDataFound-style" style={{color: "red", width: "100%", height:"30px", fontStyle: "italic", fontWeight: "bold"}}>
                {this.state.errorText}
            </div> 
            
            {this.state.responsePaymentLink !== null ? (

            <div  style={{margin:"0 5% 5% 5%", width:"100%"}}>
                <div style={{border:"1px solid #cccccc", padding: "15px"}} >

                    <div style={FormBlock} >

                        <label style={LabelStyle} >Link</label>

                        <section style={{display:"flex", flexWrap: "wrap"}}>
                          <section style={{marginBottom: "10px", flexGrow: 3}}>
                            <div style={{display:"flex", alignSelf: "start", justifyContent: "flex-start"}}>
                              <input
                              onChange={this.onChangeLink}
                              name="paymentLink"
                              style={LinkField}
                              required="required"
                              autoComplete="off"
                              defaultValue={this.state.paymentLink}
                              disabled
                              />
                            </div>
                            
                          </section>
                            <div style={{ flexGrow: 0}}>
                              <CopyToClipboard onCopy={this.onCopyLink} text={this.state.paymentLink}>
                                <button className="myButton" style={Button}>Copy link</button>
                              </CopyToClipboard>
                            </div>
                        </section>  
                      
                        <section>

                          <form onSubmit={this.handleSubmitEmail} name="resetPwdForm">
                            <label style={LabelStyle} >Send to</label>

                            <section style={{display:"flex", flexWrap: "wrap"}}> 
                              <section style={{marginBottom: "10px", flexGrow: 3}}> 
                                {/* //<div style={{width: "30%", maxWidth: "200px", marginRight: "10px"}}>
                                  //<select 
                                      //id="selectSendingLetter"
                                      //onChange={this.handleInputChange}
                                      //name="sendingLetter"
                                      //style={SelectField}
                                      //required="required"
                                      //disabled
                                    //>
                                      //<option key='sendpost' value='sendpost'>e-mail</option>
                                  //</select> 
                                //</div>
                                */}
                                <div style={{display:"flex", alignSelf: "start", justifyContent: "flex-start"}}>
                                  <input
                                  onChange={this.handleInputChange}
                                  style={LinkField}
                                  required="required"
                                  autoComplete="off"
                                  type="email" 
                                  id="email" 
                                  name="email"
                                  defaultValue={this.state.email}
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                  placeholder="example@email.com"
                                  title="Please match the requested format: Please provide email address"
                                  //onChange={handleChangeEmail}                        
                                  //onBlur={handleBlurEmail}
                                  //value={this.state.email}
                                  //disabled
                                  />
                                </div>
                              </section>

                              <div style={{ flexGrow: 0}}>
                                <button type="submit"  value="Send"  className="myButton" style={Button}>
                                  Send
                                </button>
                              </div> 
                            </section>

                            <div id="sendEmail" className="noDataFound-style" style={{color: this.state.sendEmail === 'Email sent successfully' ? "green" : "red" , width: "100%", paddingTop:"10px", height:"30px", fontStyle: "italic", fontWeight: "bold"}}>
                                {this.state.sendEmail}
                            </div>

                          </form>  

                        </section>
                    </div>
                              
                </div> 

                <form  
                  onSubmit={this.handleSubmit} 
                  style={{display:"flex", 
                  flexDirection:"column", 
                  justifyContent:"space-around", 
                  alignItems:"normal"
                  }}
                  >
                  
                  <section style={{display:"flex", flexWrap: "wrap", alignSelf: "end", alignItems: "stretch ", justifyContent: "space-evenly", width: "100%", marginTop:"30px", }}>
                  
                    <h4 style={{textAlign: "center", fontFamily: "BlinkMacSystemFont",  color:"#2271a1", width: "80%"}}>Payment link details</h4>
      
                    <div style={{visibility: this.props.user_permissions.allow_user_edit_payment_link && !this.state.visibility  ? "visible" : "hidden", display: "inline-flex"}}>
                        <input 
                        type="button" 
                        value="Edit" 
                        className="myButton" 
                        style={Button}  
                        onClick={e => {e.preventDefault(); this.setState(state => ({ ...state, visibility: true, errorText:'', sendEmail:'', }));}}           
                        />
                    </div>

                  </section>

                  <section style={{width:"100%", display:"flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start"}}>
                    <section  style={{width:"90%", display:"flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start"}}>
                      <div style={FormBlock2} >
                          <div>
                            <label style={LabelStyle} >Order ID</label>
                          </div>
                          <div   >
                            <input
                            style={SelectField}
                            onChange={this.handleInputChange}
                            name="order"
                            required="required"
                            autoComplete="off"
                            pattern="^[a-zA-Z0-9öÖäÄüÜß -/_/]{0,40}$"  
                            title="Characters must be alphanumeric"
                            defaultValue={this.state.order}
                            disabled
                            />
                          </div>
                      </div>

                      <div style={FormBlock2} >
                        <div>
                          <label style={LabelStyle} >Merchant ID</label>
                        </div>
                        <div  >
                          <select
                          id="merchantIDSelection"
                          style={SelectField}
                          onChange={this.handleSelectMerchant}
                          name="merchant"
                          disabled
                          >
                            <option key={this.state.merchant} value={this.state.merchant}>{this.state.merchant}</option>
                          </select> 
                        </div>
                      </div>
                    </section>   
                    
                    <section style={{width:"90%", display:"flex", flexWrap: "wrap", flexDirection: "row", alignItems: "flex-end ", justifyContent: "start"}}>   
                      <section  style={FormBlock2}>
                        <section  >
                          <label style={LabelStyle} >Created date</label>
                          <div style={ExpiryDate}>{moment(new Date(this.state.created)).format("DD.MM.YYYY HH:mm:ss")}</div>
                        </section>

                      </section>
                      <section  style={FormBlock2}>                          
                        <label style={LabelStyle} >Expiration date</label>
                        <div style={ExpiryDate}>{moment(new Date(this.state.lifespan)).format("DD.MM.YYYY HH:mm:ss")}</div>                    
                      </section>

                      <section  style={FormBlock2}>
                          <div>
                            <section style={{display:"flex", flexWrap: "wrap", alignSelf: "center", alignItems: "stretch ", justifyContent: "flex-start",
                                              visibility: this.state.visibility ? "visible" : "hidden"}}>
                                <select 
                                      id="selectLifespan"
                                      onChange={this.handleInputChange}
                                      name="lifespanHours"
                                      style={SelectField2}
                                      disabled = {!this.state.visibility}
                                      >
                                      <option value="0" style={{padding:"0", margin:"0", textAlign: "center"}}>change expiration date &ensp;|&ensp;&#709;</option>
                                      <option value="6">6 hours</option>
                                      <option value="12">12 hours</option>
                                      <option value="24">24 hours</option>
                                      <option value="36">36 hours</option>
                                      <option value="48">48 hours</option> 
                                </select>
                            </section>
                          </div>       
                        </section> 
                    </section>

                    <section style={{width:"90%", display:"flex", flexWrap: "wrap", alignSelf: "center", alignItems: "stretch ", justifyContent: "start"}}>
                        <div style={FormBlock2}>
                            <div>
                              <label style={LabelStyle} >Amount</label>
                            </div>

                            <div   >
                              <input
                              onChange={this.handleInputChange}
                              name="amount"
                              style={SelectField}
                              required="required"
                              autoComplete="off"
                              pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
                              title="The number input must start with a number and use a dot as a decimal character."
                              defaultValue={this.state.amount}
                              disabled = {!this.state.visibility}
                              />
                            </div>
                        </div>
                        
                    
                      <div style={FormBlock2} >
                          <div>
                            <label style={LabelStyle} >Currency</label>
                          </div>

                          <div>
                            <select 
                              id="selectCurrency"
                              onChange={this.handleInputChange}
                              name="currency"
                              style={SelectField}
                              required="required"
                              disabled = {!this.state.visibility}
                            >
                              <option key={this.state.currency} value={this.state.currency}>{this.state.currency}</option>
                              { this.state.currencyCodes ? (
                                this.state.currencyCodes.map(obj => (
                                  <option key={obj} value={obj} style={{color:"black"}}>
                                    {obj}
                                  </option>
                                ))
                                ) : [] 
                              }
                            </select>                  
                          </div> 
                      </div>
                    </section>

                    <section style={{display:"flex", flexWrap: "wrap", flexDirection:"row", alignSelf: "center", alignItems: "stretch ", justifyContent: "flex-end",
                                        width: "100%", margin: 0, padding: "10px" }}>

                            <div style={{visibility: this.state.visibility ? "visible" : "hidden"}}>
                              <input
                              type="submit" 
                              value="Save changes"  
                              className="myButton" 
                              style={Button}
                              />
                            </div>
                            
                            <div style={{visibility: this.state.visibility ? "visible" : "hidden"}}>
                              <Link to="/generic/paymentLinkTab"><input type="submit" value="Cancel" 
                              className="myButton" 
                              style={Button}/></Link>
                            </div>

                    </section>
                  </section>

              </form>     
            </div>

            ) : (
            <div
                className="noDataFound-style"
                style={{ paddingTop: "5%", paddingBottom: "5%", fontSize: "medium", color: "#8a3330" }}>
                {" "}
                payment link not selected
                {" "}
            </div>
              )}


          </div>
        </div>
      </div>
    );
  }
}

//ReactDOM.render(<PaymentLinkDetails/>, document.getElementById('root'));
 
const mapStateToProps = (state) => ({
  paymentLinkDetails: state.paymentLinkReducer,
  paymentLink: state.paymentLinkReducer,
  paymentMethods: state.paymentLinkReducer,
  paymentLinkEmail:  state.paymentLinkReducer,
});

const mapDispatchToProps = {
  getPaymentLink, 
  updatePaymentLink,
  getPaymentMethods,
  sendPaymentLink   
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentLinkDetails)
);
