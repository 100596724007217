import React from "react";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const SubDetails = ({ row, ...props }) => {

	let cvc_flag_string = "";
	if ( row.original.cvc_flag )
	{
		cvc_flag_string = row.original.cvc_flag.toString();
	}

	const renderDetailRow = (name, data) => {
    	if (data && data !== "N/A") {
			if (Number.isInteger(data)) {
				data = data.toString();
			}
			if (data.replace(/\s/g, "").length) {
				// checks if data contains anything other than space, tab or newline
				return (
					<div className="row body">
						<div className="col-md-6 col-xs-6" style={{ fontWeight: "600" }}>
						<p> {name} </p>
						</div>
						<div className="col-md-6 col-xs-6">
						<p> {data} </p>
						</div>
					</div>
				);
			}
    	}
  	}
 
 	return (
 		<SlideDown className={'my-dropdown-slidedown'}>
	    <div className="subcomponent_style" style={{ transition: "height 2s" }}>
			<div className="row">
				<div className="col-md-6 col-xs-6" style={{ backgroundColor: "#DAE4EA" }}>
					<div className="row header">
						<p> Order Details </p>
					</div>
					{renderDetailRow("Bank", row.original.bank)}
					{renderDetailRow("BIN", row.original.bin)}
					{renderDetailRow("BIN Country", row.original.bin_country)}
					{renderDetailRow("BIN Region", row.original.bin_region)}
					{renderDetailRow("Card Brand", row.original.card_brand)}
					{renderDetailRow("Category", row.original.category)}
					{renderDetailRow("CCN Masked", row.original.ccn_masked)}
					{renderDetailRow("Token ID", row.original.token_id)}
					{renderDetailRow("CVC Flag", cvc_flag_string === "1" ? "Yes" : "No")}
					{renderDetailRow("Payment System", row.original.ps_name)}
					{renderDetailRow("Custom 1", row.original.custom_1)}
					{renderDetailRow("Custom 2", row.original.custom_2)}
					{renderDetailRow("Custom 3", row.original.custom_3)}
				</div>
				<div
					className="col-md-6 col-xs-6"
					style={{ backgroundColor: "#DAE4EA" }}>
					<div className="row header">
						<p> Customer Details </p>
					</div>
					{renderDetailRow("Customer ID", row.original.customer_id)}
					{renderDetailRow("Salutation", row.original.salutation)}
					{renderDetailRow("Title", row.original.title)}
					{renderDetailRow("Gender", row.original.gender)}
					{renderDetailRow("Cardholder Name", row.original.cardholder_name)}
					{renderDetailRow("Street", row.original.street)}
					{renderDetailRow("House Number", row.original.house)}
					{renderDetailRow("Zip", row.original.zip)}
					{renderDetailRow("City", row.original.city)}
					{renderDetailRow("State", row.original.state)}
					{renderDetailRow("Country", row.original.country)}
					{renderDetailRow("Mobile", row.original.mobile)}
					{renderDetailRow("Phone", row.original.phone)}
					{renderDetailRow("IP Address", row.original.customer_ip)}
					{renderDetailRow("Email", row.original.email)}
					{renderDetailRow("Birthday", row.original.birthday)}
				</div>
			</div>
	    </div>
	  </SlideDown>
	);
};

export default SubDetails;