/* eslint-disable */
import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Legend
} from "recharts";
import { CHART_COLORS } from "../../../lib/constants/chartColors";
import * as helper from "../core/helper";
import { BarLoader } from "react-spinners";

const PieCharts = ({ width, height, ...props }) => {
  const { data, meta_data } = props.data;

  let pieBarsData = [];
  let test = data
    ? data.map((item, i) => {
        delete item[`${meta_data.yVal2}`];
        pieBarsData.push(item);
        return null;
      })
    : [];

  let headers = [];
  // get axis keys
  var axisKeys = [];

  if (props.data && Array.isArray(props.data) && props.data.length !== 0) {
    headers = props.data[0];
    Object.keys(headers).forEach(function(key) {
      axisKeys.push(key);
    });
    props.data.map((item, i) => {
      pieBarsData.push(item);
      return null;
    });
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent //payload
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed()}%`}
        {/* {`${payload.payload ? payload[meta_data.xVal] : ""}: `}{`${(percent * 100).toFixed(0)}%`} */}
      </text>
    );
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color, payload } = entry;
    if (meta_data) {
      return <span style={{ color }}>{payload[meta_data.xVal]}</span>;
    } else {
      return <span style={{ color }}>{payload[axisKeys[0]]}</span>;
    }
  };

  // const renderToolTip = (value, entry, payload) => {
  //   return [`${payload.value}`, `${payload.payload[meta_data.xVal]}`];
  // };

  if (!data && !props.data) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  }

  if (pieBarsData.length !== 0) {
    return (
      <div style={{ width: "99%", height: parseInt(height) }}>
        <ResponsiveContainer width="99%" height="99%">
          {/* manage the position by just giving width is height*chart size if required pass the chart size into props.. */}
          <PieChart>
            <Pie
              data={pieBarsData}
              labelLine={false}
              label={renderCustomizedLabel}
              // outerRadius={150}
              //outerRadius={150}
              fill="#8884d8"
              dataKey={
                meta_data ? meta_data.yVal1 : axisKeys ? axisKeys[1] : ""
              } //  dynamic key
            >
              {pieBarsData &&
                pieBarsData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={CHART_COLORS[index % CHART_COLORS.length]}
                  />
                ))}
            </Pie>
            {/* <Tooltip  content={<CustomTooltip/>}/> */}
            <Tooltip formatter={helper.renderToolTip} />
            <Legend formatter={renderColorfulLegendText} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  } else {
    return (
      <div>
        <h4 className="noDataFound-style">
          <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
          No data was found! 
        </h4>
      </div>
    );
  }
};

export default PieCharts;
