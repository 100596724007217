import Cookies from 'js-cookie';

const secure = process.env.NODE_ENV !== 'development';

const cookieUtils = {
  set(name, value, options = {}) {
    const defaultOptions = {
      secure: secure,
      sameSite: "strict"
    };

    const finalOptions = { ...defaultOptions, ...options };

    Cookies.set(name, value, finalOptions);
  },

  get(name) {
    return Cookies.get(name);
  },

  remove(name, options = {}) {
    const defaultOptions = {
      secure: secure,
      sameSite: 'strict'
    };

    const finalOptions = { ...defaultOptions, ...options };

    Cookies.remove(name, finalOptions);
  },

  removeAllCookies() {
    const cookies = Cookies.get();

    for (let name in cookies) {
        Cookies.remove(name, {path: ""});
    }
  }
};

export default cookieUtils;
