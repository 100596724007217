import React, { Component } from "react";
import { connect } from "react-redux";
import SubUserSettings from "./SubUserSettings";
import SubUserCreation from "./SubUserCreation";
import { Button } from 'react-bootstrap';
import { addSubuser, getSubusers, updateSubuser, deleteSubuser } from "../../../actions/adminAction";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { ScaleLoader } from "react-spinners";
//import SingleSelect from "../../general/core/SingleSelect";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	createUser: false,
    	editUser: false,
    	error: false,
    	notification: false,
    	selectedSubuser: "",
    	isLoading: false,
    	clickedSave: false,
    	clickedDelete: false,
    };
   	
   	this.errorMsg = "";
   	this.createBody = {
   		username: "",
   		email: "",	
   	}
   	this.editBody = {
   		username: "",
   		actions: {},
   		tabs: {},
   	};
   	this.subuserList = [];
   	this.notificationMsg = "";
   	this.fetchedSubuserList = false;
   
   	this.reset = this.reset.bind(this);
   	this.handleCreateUser = this.handleCreateUser.bind(this);
   	this.handleSelectUser = this.handleSelectUser.bind(this);
   	this.getSubuserList = this.getSubuserList.bind(this);
   	this.renderSection = this.renderSection.bind(this);
   	this.setEditBody = this.setEditBody.bind(this);
   	this.handleSave = this.handleSave.bind(this);
   	this.handleCancel = this.handleCancel.bind(this);
   	this.setCreateBody = this.setCreateBody.bind(this);
   	this.handleDelete = this.handleDelete.bind(this);
   	this.resolveCreate = this.resolveCreate.bind(this);
   	this.resolveEdit = this.resolveEdit.bind(this);
   	this.resolveDelete = this.resolveDelete.bind(this);
  }
 
 	async getSubuserList() {
 		await this.props.getSubusers();
 		
 		if (this.props.adminReducer && this.props.adminReducer.subusers && this.props.adminReducer.subusers.subusers) {
 			this.subuserList = this.props.adminReducer.subusers.subusers;
 		} else {
 			this.errorMsg = "Cannot load the list of user.";
 			this.setState({
				error: true,
			});
 		}
 	}
 
 	async handleSave(event) {
		event.preventDefault();

		let { username, email } = this.createBody;
		let usernameCorrect = /^[a-zA-Z0-9_]+$/.test(username);
 		let emailCorrect = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
		
		if(!usernameCorrect) {
 			this.errorMsg = "Wrong username format.. \n";
 		}
 		if(!emailCorrect) {
 			this.errorMsg += "Wrong email format..";
 		}
 		if ((!usernameCorrect || !emailCorrect) && !this.state.editUser) {
 			this.setState({
 				error: true,
 			});
 		} else {
			this.setState({
				notification: false,
				isLoading: true,
				clickedSave: true,
			});

			if (this.state.createUser) {
				await this.props.addSubuser(this.createBody);
				await this.getSubuserList();
				this.notificationMsg = this.props.adminReducer.notification;
				this.resolveCreate();
			}
			
			if (this.state.editUser) {
				await this.props.updateSubuser(this.editBody);
				await this.getSubuserList();
				this.notificationMsg = this.props.adminReducer.notification;
				this.resolveEdit();
			}
 		}
	}
 
 	handleCancel(event) {
		event.preventDefault();
		this.reset();
	}

	async handleDelete(event) {
  	event.preventDefault();
  	
  	this.setState({
  		isLoading: true,
  		clickedDelete: true,
  	});
  	
  	await this.props.deleteSubuser({ username: this.state.selectedSubuser });
  	await this.getSubuserList();
  	this.notificationMsg = this.props.adminReducer.notification;
  	
  	this.resolveDelete();
	}

	setCreateBody(body) {
		this.createBody = body;
	}

	setEditBody(title, sectionData) {
		this.editBody = {
			...this.editBody,
			[title]: sectionData,	
		};
	}
 		
 	reset() {
 		document.getElementById("userSelection").selectedIndex = 0;
 		this.notificationMsg = "";
    	this.setState({
			createUser: false,
			editUser: false,
			error: false,
			notification: false,
		});
 	}
 
 	resolveCreate() {
 		document.getElementById("userSelection").selectedIndex = 0;
    	this.setState({
			createUser: false,
			editUser: false,
			//error: false,
			notification: true,
			isLoading: false,
			clickedSave: false,
			clickedDelete: false,
		});
 	}
 
 	resolveEdit() {
    	this.setState({
			error: false,
			notification: true,
			isLoading: false,
			clickedSave: false,
			clickedDelete: false,
		});
 	}
 
 	resolveDelete() {
 		document.getElementById("userSelection").selectedIndex = 0;
    	this.setState({
			createUser: false,
			editUser: false,
			error: false,
			notification: true,
			isLoading: false,
			clickedSave: false,
			clickedDelete: false,
		});
 	}
 
 	handleCreateUser() {
 		this.setState({
			createUser: true,
			notification: false,
		});
	
		// Fades out user creation form.
		let element = document.getElementById("userCreationFadeElement");
		element.classList.add("fade-out-text");
		element.style.opacity = 0;
		element.style.pointerEvents = 'none';
		
		// Fades in user creation form.
		element.style.opacity = 1;
		element.classList.remove("fade-out-text");
		element.classList.add("fade-in-text");
		setTimeout(function () {
		element.classList.remove("fade-in-text");
		element.style.pointerEvents = 'auto';
		}, 500);
 	}
 
 	handleSelectUser(event) {
 		this.setState({
			editUser: true,
			notification: false,
			selectedSubuser: event.target.value,
		});
	
		this.editBody = {
 			username: event.target.value,
 			actions: {},
 			tabs: {},
 		}
	
		// Fades out user edit form.
		let element = document.getElementById("userEditFadeElement");
		element.classList.add("fade-out-text");
		element.style.opacity = 0;
		element.style.pointerEvents = 'none';
		
		// Fades in user edit form.
		element.style.opacity = 1;
		element.classList.remove("fade-out-text");
		element.classList.add("fade-in-text");
		setTimeout(function () {
		element.classList.remove("fade-in-text");
		element.style.pointerEvents = 'auto';
		}, 500);
 	}
 
 	componentDidMount() {
 		this.getSubuserList();
 		this.editBody = {
 			...this.editBody,
 			username: this.state.selectedSubuser,
 		}
 	}
 
 	renderSettings(subuserName) {
 		let userSettings = {};
 		let items = [];
 		
 		this.subuserList.map(subuser => {
 			if (subuser.username === subuserName) {
 				let index = this.subuserList.indexOf(subuser);
 				userSettings = this.subuserList[index];
 			}
 			return null;
 		});
 	
 		// Sort sequence of sections shown on the page.
 		let sortable = [];
 		for (var key in userSettings) {
    	sortable.push([key, userSettings[key]]);
		}
		sortable.sort((a, b) => (a < b) ? -1 : (a > b) ? 1 : 0);
		let objSorted = {}
		sortable.forEach( item => objSorted[item[0]] = item[1]);
 		
 		// Push the sorted object into an array to be rendered.
 		Object.keys(objSorted).forEach((key, index) => {
 			if (key !== "username" && key !== "company" && !_.isEmpty(objSorted[key])) {
				items.push(
	 				this.renderSection(key, objSorted[key])
	 			);
 			}
 		
 			if (key === "company") {
 				items.push(
	 				this.renderCompanyInfoBox(key, objSorted[key])
	 			);
 			}
 		})
 		
 		return (
 			<div className="row" style={{ marginLeft:"-5px", marginRight:"-5px" }}>
 				{items}
 			</div>
 		);
 	}
 
 	renderCompanyInfoBox(title, data) {
 		let companies = [];
 		data = data.filter((item, index) => data.indexOf(item) === index);
 	
 		data.forEach(value => {
			companies.push(
				<div key={value} style={{ paddingTop:"10px", fontWeight:"bold" }}>
					{value}
				</div>
 			);
 		});
 		
 		return (
 			<div key={title} className="col-md-4 col-sm-6 col-xs-12" style={{marginBottom:"10px", paddingLeft:"5px", paddingRight:"5px"}}>
	 			<div style={{ padding:"10px", backdropFilter:"blur(5px)", border:"2px solid #64a6d0", backgroundColor:"white", height:"auto" }}>
					<h4 style={{ color:"#2e80b2", fontWeight:"bold" }}>{ title.toUpperCase() }</h4>
					<div style={{ minWidth:"300px" }}>
						{ companies }
					</div>
				</div>
    		</div>
 		);
 	}
 
 	renderSection(title, data) {
 		return (
 			<div key={title} className="col-md-4 col-sm-6 col-xs-12" style={{marginBottom:"10px", paddingLeft:"5px", paddingRight:"5px"}}>
	 			<SubUserSettings 
	    	userSelected=""
	    	selectedSubuser={this.state.selectedSubuser}
	    	setEditBody={this.setEditBody}
	    	title={title}
	    	data={data}
	    	clickedSave={this.clickedSave} />
    	</div>
 		);
 	}
 
  render() {
  
  	if (this.subuserList && this.subuserList.length === 0 && !this.fetchedSubuserList) {
  		this.getSubuserList();
  		this.fetchedSubuserList = true;
  	}
  
  	this.subuserList.sort((a, b) => (a.username < b.username) ? -1 : (a.username > b.username) ? 1 : 0);
  
	return (
		<div className="admin-page">
		<h4 className="admin-heading">Admin</h4>
	
		{ this.state.error ? ( 
			<div>
				<div className="vt-info-box" style={{backgroundColor:"rgb(232, 210, 210)", color:"rgb(195, 0, 0)"}}>
					{this.errorMsg}
				</div>
			</div>
		) : null }
		
		{ this.state.notification ? ( 
			<div>
				<div className="vt-info-box" style={{backgroundColor:"rgb(216, 232, 210)", color:"rgb(39, 117, 0)"}}>
					{String(this.notificationMsg)}
				</div>
			</div>
		) : null }
        
        <div className="row admin-row" style={{margin:"0px"}}>
        	<div className="col-md-2 col-sm-2 col-xs-1 admin-col" style={{ opacity:this.state.createUser ? "0.5" : "1" }}>
			{/*<SingleSelect
				id="userSelection"
				label="Select user:"
				name="user"
				placeholder=""
				marginRight="0px"
				marginLeft="0px"
				marginTop="4px"
				options={this.subuserList}
				onChange={this.handleSelectUser}
				//menuHeight={(this.props.usePrevFormData && this.props.prevFormData["paymentCurrencies"].length > 3) || this.state.currencyCodes.length > 3 ? "120px" : ""}
				defaultValue="DEFAULT"
		/>*/}

            <label>Select user:</label>
            <div className="select-custom-parent admin-select-outer-box">
              <select
              id="userSelection"
              className="admin-select-field "
              style={{color: this.state["merchant_id"] ? "black" : "grey"}}
              onChange={this.handleSelectUser}
              name="user"
              defaultValue="DEFAULT"
              disabled={this.state.createUser || this.state.isLoading}>
                <option value="DEFAULT" disabled>Select and edit user</option>
                { this.subuserList && this.subuserList.length !== 0 ? (
                	this.subuserList.map(obj => (
	                  <option key={obj.username} value={obj.username} style={{color:"black"}}>
	                    {obj.username}
	                  </option>
                	))
               		) : []
              	}
            	</select>
            </div>

			</div>
			<div className="col-md-1 col-xs-1" style={{ paddingTop:"35px", fontWeight:"bold", textAlign:"center" }}> OR </div>
			<div className="col-md-2 col-xs-1" style={{ paddingTop:"26px" }}>
				<Button 
				onClick={this.handleCreateUser}
				disabled={this.state.createUser || this.state.editUser}
				className="admin-create-user-btn"
				style={{
					backgroundColor: this.state.createUser || this.state.editUser ? "grey" : "#2e80b2",
					borderColor: this.state.createUser || this.state.editUser ? "grey" : "#2e80b2"}}>
					Create new user
				</Button>
			</div>
          	<div className="col-md-7 col-xs-9"></div>
     		</div>
     		
     		{ this.state.createUser ? (
	     		<div
	        id="userCreationFadeElement"
	        className="admin-form-div">
	        	<SubUserCreation
	        	setCreateBody={this.setCreateBody} />
	        </div>
    		) : (
        	<div 
          id="userCreationFadeElement"
          className="admin-form-div" 
          style={{padding:"0px"}}>
          </div>
        )}
     		
     		{ this.state.editUser ? (
	     		<div
	        id="userEditFadeElement"
	        className="admin-form-div">
	        	{this.renderSettings(this.state.selectedSubuser)}
	        </div>
        ) : (
       		<div 
          id="userEditFadeElement"
          className="admin-form-div"
          style={{padding:"0px"}}>
          </div>
       	)}
       
       	{ this.state.createUser || this.state.editUser ? (
       		<div style={{ paddingTop: "20px" }}>
		     		<Button 
			  		onClick={this.handleSave}
			  		className="admin-btn admin-save-btn"
			  		disabled={this.state.isLoading}>
				  		{ this.state.clickedSave ?
						<ScaleLoader color={"white"} height={20} /> :
						"SAVE"
	            		}
		    		</Button>
		    		<Button 
		    		onClick={this.handleCancel}
		    		style={{ marginLeft:"10px" }}
		    		className="admin-btn admin-cancel-btn"
		    		disabled={this.state.isLoading}>
		    			CANCEL
		    		</Button>
		    		{ this.state.editUser ? (
			    		<Button 
			    		onClick={this.handleDelete}
			    		style={{ marginLeft:"10px" }}
			    		className="admin-btn"
			    		disabled={this.state.isLoading}>
			    			{ this.state.clickedDelete ?
		          		<ScaleLoader color={"white"} height={20} /> :
		            	"DELETE"
	            	}
			    		</Button>
		       	) : null }
      		</div>
       	) : null }
       
     	</div>
			
		);
  }
}

const mapStateToProps = state => ({
	adminReducer: state.adminReducer,
});

const mapDispatchToProps = {
	addSubuser,
	getSubusers,
	updateSubuser,
	deleteSubuser,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Admin)
);