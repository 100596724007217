export const SideBarMenuItems = [
    {
        code: "dashboardTab",
        label: "Dashboard"
    },
    {
        code: "adminDashboardTab",
        label: "Admin Dashboard"
    },
    {
        code: "volTab",
        label: "Sales Volume"
    },
    {
        code: "transactionLogTab",
        label: "Transaction Log"
    },
    {
        code: "posTransactionsTab",
        label: "POS Transactions"
    },
    {
        code: "cbkTab",
        label: "Chargeback"
    },
    {
        code: "chargebackAlertsTab",
        label: "Chargeback Alerts"
    },
    {
        code: "fraudTab",
        label: "Fraud"
    },
    {
        code: "financeDataTab",
        label: "Finance"
    },
    {
        code: "bITab",
        label: "Business Intelligence"
    },
    {
        code: "aRTab",
        label: "Approval Rate"
    },
    {
        code: "virtualTerminalTab",
        label: "Virtual Terminal"
    },
    {
        code: "admin_sales_tabTab",
        label: "Admin Sales"
    },
    {
        code: "paymentLinkTab",
        label: "Payment Link"
    },
];
