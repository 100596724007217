import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  partnerMerchantsToken: null,
  partnerMerchantsData: null,
};

const partnerMerchantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PARTNER_MERCHANTS_DATA:
      return{
        ...state,
        partnerMerchantsData: action.payload
      }

    default:
      return state;
  }
};

export default partnerMerchantsReducer;