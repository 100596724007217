import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  subusers: null,
  notification: "",
  bla: "",
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_GET_SUBUSERS:
      return {
        ...state,
        subusers: action.payload,
      };
    case actionTypes.ADMIN_ADD_SUBUSER:
      return {
        ...state,
        notification: action.payload,
      };
    case actionTypes.ADMIN_UPDATE_SUBUSER:
      return {
        ...state,
        notification: action.payload,
      };
    case actionTypes.ADMIN_DELETE_SUBUSER:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;