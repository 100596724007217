import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPluginNames, downloadPlugin }  from "../../../actions/ecommercePluginAction";
import FileSaver from 'file-saver';

class EcommercePlugin extends Component {
    constructor(props) {

        super(props);
        
        this.state = {    	
          files: [],
          isLoading: false
        };
        this.isDataLegit = false;

    }
    
    async getPluginsName() {
      
      this.setState({isLoading: true});
      
      await this.props.getPluginNames({});
      
      this.isDataLegit = this.props.ecommercePluginReducer.files && Array.isArray(this.props.ecommercePluginReducer.files);

      this.setState({
          files: this.isDataLegit ? this.props.ecommercePluginReducer.files : [],
      });

  }
  
    handleDownload = async (fileName) => {
      const response = await this.props.downloadPlugin(fileName);
      if(response)
      {
        FileSaver.saveAs(response.payload.data, fileName);
      }
      
    };

    async componentDidMount() {
      this.getPluginsName();
    }
  
    render() {
      const files = this.state.files;
      return (
        <div>
          {files.map((file) => (
            <React.Fragment key={file.name}>
              <button className="btn btn-sm" id={file.name} onClick={() => this.handleDownload(file.name)}>
                {file.name}
              </button>
              <br />
              <br />
            </React.Fragment>
          ))}
        </div>

       
        // <div>
        //   {files.map((file) => (
        //     <><button className="btn btn-sm" id={file.name} key={file.name} onClick={() => this.handleDownload(file.name)}>
        //       {file.name}
        //     </button><br /> <br /></>
        //   ))}
          
        // </div>
      );
    }
  }
  
  const mapStateToProps = (state) => ({  
    ecommercePluginReducer: state.ecommercePluginReducer,
  });
  
  const mapDispatchToProps = {
    getPluginNames,
    downloadPlugin
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EcommercePlugin)
  );
