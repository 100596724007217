export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const LOGOUT = "/logout";
export const DASHBOARD = "/dashboard";
export const RESET_PASS = "/reset-password";
export const CONF_PASS = "/conf-pass";
export const GENERIC_ID = "/generic/:id";
export const VIRTUAL_TERM = "/generic/virtualTerminalTab";
export const PAYMENT_LI = "/generic/paymentLinkTab";
export const ECOMMERCE_PLUGINS = "/generic/ecommercePluginsTab";
export const PAYMENT_LI_DETAILS = "/generic/payment-link-details";
// export const TRANS_LOG="/generic/transaction-log";
export const TRANS_LOG = "/generic/transactionLogTab";
export const POS_TRANSACTIONS = "/generic/posTransactionsTab";
export const CHARGEBACK_ALERTS = "/generic/chargebackAlertsTab";
export const FINANCE_DATA = "/generic/financeDataTab";
export const DashBoard2 = "/generic/dashboardTab";
export const CHART_DETAILS = "/generic/chartDetailsTab/chartId/:chartDetailId";
export const ADMIN = "/generic/adminTab";
export const MERCHANT_BOARD = "/generic/merchantBoardTab";
export const FRAUD_TAB = "/generic/fraudTab";
export const PARTNER_MERCHANTS = "/generic/partnerMerchantsTab";
export const TRUSTLY_BALANCES = "/generic/trustly_balancesTab";