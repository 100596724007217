import React from "react"
import Select from "react-select";
import { BIN_REGION, COUNTRY_CODES } from "../../../lib/constants/filter";
import { SELECT_STYLES } from "../../../lib/constants/core";

const SelectFilter = ({ name, label, options, disabled = false, checkNames = true, ...props }) => {
	const populateOptions = () => {
		if ((name === 'bin_country' || name === 'ip_country') && checkNames) return COUNTRY_CODES;
		if (name === 'bin_region' && checkNames) return BIN_REGION;

		const arr = options?.[name] ?? [];
		const res = arr.map(id => { return { label: id, value: id }; });
	
		return res;
	}
	
	const formatGroupLabel = data => (
	  <div className="groupStyles">
	    <span>{data.label}</span>
	    <span className="groupBadgeStyles">{data.options.length}</span>
	  </div>
	);

	const multiValueContainer = ({ selectProps, data }) => {
		// TODO: HOT FIX (change required)		
		const label = data.label;
		const allSelected = selectProps.value;
		const index = allSelected.findIndex(selected => selected.label === label);
		const isLastSelected = index === allSelected.length - 1;
		const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
		const val = `${label}${labelSuffix}`;
		return val;
	};

  if (name) {
    return (
      <div id={name}>
        <Select
					components={{ MultiValueContainer: multiValueContainer, IndicatorSeparator: null }}
					styles={SELECT_STYLES(disabled)}
					isSearchable={true}
					isDisabled={disabled}
					name={name}
					options={populateOptions()}
					isMulti
					{...props}
					placeholder={label}
					hideSelectedOptions={false}
					closeMenuOnSelect={false}
					formatGroupLabel={formatGroupLabel}
       	/>
      </div>
    );
  } else {
    return <div id="blank"></div>;
  }
};

export default SelectFilter;