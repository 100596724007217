import apiFactory, {api} from "./api";
import * as apiLink from '../lib/constants/apiLink';
// Developers can override this with an env.local file
const baseUrl = apiLink.BASE_API_URL;
const apiAuth = apiFactory(baseUrl);

/**
 *
  @param {} payload
 */


// login API
export const userLogin = payload =>{
    return apiAuth.post(apiLink.USER_LOGIN_API_URL, payload);
}

// logout API
export const userLogout = (token, body) =>{
    return api(baseUrl, token).post(apiLink.USER_LOGOUT_API_URL, body);
}

// password reset API
export const resetPassword = payload =>{
    return apiAuth.post(apiLink.USER_PASS_RESET_API_URL, payload);
}

// password reset API
export const confirmPassword = payload =>{
    return apiAuth.post(apiLink.USER_PASS_RESET_CONF_URL, payload);
}

// refresh Token API
export const refreshToken = payload =>{
    return apiAuth.post(apiLink.REFRESH_TOKEN_URL, payload);
}

// verify Token API
export const verifyToken = payload =>{
    return apiAuth.post(apiLink.VERIFY_TOKEN_URL, payload);
}

// registration API
export const userSignUp = payload =>
  apiAuth.post(apiLink.USER_SIGNUP_API_URL, payload);