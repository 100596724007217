import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import CreatableSelect from "react-select/creatable";
import { INPUT_STYLES, ACTION } from "../../../lib/constants/core";
import _ from "lodash";

const createOption = (label) => ({ label, value: label });
let currentValues = [];

const InputFilter = forwardRef(({ name, label, onValueChange, valueArr, disabled = false, expanderEnabled = true, shouldSplitBySpace = true }, ref) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [event, setEvent] = useState({ action: "", option: null, name: "" });
  const [absoluteClass] = useState('position-absolute');
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const onMouseEnter = () => {
    if (expanderEnabled) {
      setIsHover(true);
    }
  };

  const onMouseLeave = () => {
    if (expanderEnabled) {
      setIsHover(false);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

 	const handleBlur = () => {
    handleValueSet(inputValue, value);
    setIsFocus(false);
  };

  const handleChange = (value, actionMeta) => {
    if (actionMeta.action === ACTION.REMOVE || actionMeta.action === ACTION.POP) {
    	// Removes value when not in the input field anymore.
    	if (actionMeta.removedValue) {
    		currentValues = currentValues.filter(item => item !== actionMeta.removedValue.value);
    	}
      setValue(value !== null ? [...value] : []);
      setEvent({
        action: ACTION.REMOVE,
        removedValue: { label: actionMeta.removedValue?.label ?? "", value: actionMeta.removedValue?.value ?? "" },
        name: name
      });
    }

    if (actionMeta.action === ACTION.CLEAR) {
    	currentValues = [];
      setValue(value !== null ? [...value] : []);
      setEvent({ action: ACTION.CLEAR, name: name })
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case (" " || "Tab"):
        if (shouldSplitBySpace) {
          handleValueSet(inputValue, value);
        }
        break;
      case ",":
      case ";":
      case "Enter":
        handleValueSet(inputValue, value);
        break;
      default:
    }
  };

  const handleInputChange = (inputValue) => {
    if (![" ", ",", ";"].includes(inputValue)) setInputValue(inputValue);
  };

  const handleValueSet = (inputValue, value) => {
    if (!inputValue.trim()) return;    // There should be no empty input.
    const multiComma = inputValue.trim().indexOf(',') > -1;
    const multiSemicolon = inputValue.trim().indexOf(';') > -1;
    const multiSpace = inputValue.trim().indexOf(' ') > -1;

    if (multiComma || multiSemicolon || (multiSpace && shouldSplitBySpace)) {
      const values = inputValue?.trim()?.split(/[,;\s(\r\n|\r|\n)]/).filter(iv => !currentValues?.map(v => v?.value?.trim())?.includes(iv?.trim()));
      let valuesToDisplay = [];

      for (let i = 0; i < values.length; i++) {
        valuesToDisplay = [...valuesToDisplay, createOption(values[i].trim())]
      }

      setInputValue('');
      setValue(valuesToDisplay);
      setEvent({
        action: ACTION.SELECT,
        option: { label: inputValue, value: inputValue },
        name: name
      });

    } else {
      // Values should not be put into the input field when same value already inside.
      if (currentValues.includes(inputValue.trim())) return;
      else currentValues.push(inputValue.trim());

      setInputValue('');
      setValue([...value, createOption(inputValue)]);
      setEvent({
        action: ACTION.SELECT,
        option: { label: inputValue, value: inputValue },
        name: name
      });
    }
  };

  useEffect(() => {
    // Set value to an empty array. Otherwise it would be set to null.
    //if (value == null) setValue([]);
  });

  useEffect(() => {
    onValueChange(value, event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, event]);

  useImperativeHandle(ref, () => ({
    updateValue() {
      if (valueArr && !_.isEqual(valueArr, value)) setValue(valueArr);
    },
    resetEvent(){
      setEvent({ action: "", option: null, name: "" })
    }
  }));

  return (
    <div onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
      <CreatableSelect
        styles={INPUT_STYLES(disabled, (isHover || isFocus) && value.length > 1 && expanderEnabled)}
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isMulti
        isDisabled={disabled}
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={label}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        className={(isHover || isFocus) && value.length > 1 && expanderEnabled ? absoluteClass : ''}
      />
    </div>
  );
});

export default InputFilter;