import React from 'react';
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SingleInput from "../../general/core/SingleInput";

const BatchRefundForm = ({ data, amounts, onValueChange, onCommentChange, submit, close }) => {

  const cell = (row, key) => {
    if (key === "action_amount") {
      return (
        <SingleInput id="refundAmount" name={row.value.split(" ")[1]} width="100%" autoComplete="off" disabled={row.original.remaining_amount === undefined}
          backgroundColor="#eff0f1" placeholder={amounts[row.value.split(" ")[1]] !== undefined ? amounts[row.value.split(" ")[1]] : row.value.split(" ")[0]}
          disableErrorMsg={true} onChange={onValueChange}
        />
      );
    }
    if (key === "refund_comment") {
      return (
        <SingleInput id="refundComment" name={row.value} width="100%" autoComplete="off" disableErrorMsg={true} 
          backgroundColor="#eff0f1" onChange={onCommentChange} disabled={row.original.remaining_amount === undefined}
        />
      );
    }
    if (key === "remaining_amount") {
      if (row.original.remaining_amount !== undefined) return row.value + " " + row.original.currency;
      else return "-";
    }
    return row.value;
  }

  const column = (label, key) => {
    return {
      Header: label,
      accessor: key,
      Cell: row => cell(row, key),
      getProps: (state, rowInfo, column) => {
        if (key !== "action_amount" && key !== "refund_comment")
          return { style: {padding:"19px 10px", textAlign: "center"} };
        else 
          return { style: {padding:"7px 5px"} };
      },
    };
  }

  const columns = () => {
    return [
      column("Transaction ID", "transaction_id"),
      column("Remaining amount", "remaining_amount"),
      column("Comment", "refund_comment"),
      column("Action amount", "action_amount")
    ];
  }
  
  return (
    <>
      <div id="batchRefundHeader">Batch Refund</div>
      <div id="batchRefundTable">
        <ReactTable loading={false} minRows={1} defaultPageSize={20} showPagination={false} sortable={false} multiSort={true} resizable={false} defaultSortDesc={true}
          className="-highlight" style={{border: "none"}} data={data} columns={columns()} rowsText={'rows per page'}
          renderPageJump={
            ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => {return null;}
          }
        />
      </div>
      <div className="row btnContainer">
        <button id="batchRefundSubmitBtn" className="primaryBtn" disabled={false} onClick={e => submit(e)}>
          <span className="big">Refund all</span>
          <span className="small"><FontAwesomeIcon icon={faCheck} size="lg" /></span>
        </button>
        &nbsp; &nbsp;
        <button id="batchRefundCancelBtn" className="secondaryBtn" onClick={() => close()}>
          <span className="big">Cancel</span>
          <span className="small">X</span>
        </button>
      </div>
    </>
  )
};

export default BatchRefundForm;