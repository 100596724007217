import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { CHART_COLORS } from "../../../lib/constants/chartColors";
import * as helper from "../../general/core/helper";
import ModelCharts from "./modelCharts";

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y +
  height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y +
  height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = props => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

export default class LineCharts extends PureComponent {
  state = {
    show: false
  };

  renderTable = () => {
    this.setState({
      show: !this.state.show
    });
  };
  hideModel = eve => {
    this.setState({
      show: eve
    });
  };
  render() {
    const DataFormater = number => {
      return number.toFixed(2).toString();
    };
    const { data } =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id]
        ? this.props.data.barChartData[this.props.chart_id]
        : [];

    let xLines = data && data.columns ? data.columns : [];
    xLines = xLines.filter(item => {
      return item !== "month_year";
    });
    let nestedRows = [];
    if (data && data.rows) {
      for (let k1 in data.rows) {
        if (k1 !== "total") {
          if (data.rows[k1].total) {
            data.rows[k1].total.name = k1;
            nestedRows.push(data.rows[k1].total);
          }
          for (let k2 in data.rows[k1]) {
            if (k2 !== "total") {
              data.rows[k1][k2].name = "• " + k2;
              nestedRows.push(data.rows[k1][k2]);
            }
          }
        }
      }
    }

    if (nestedRows) {
      sortByMonth(nestedRows);
    }

    function sortByMonth(arr) {
      var months = [
        "Jan-2019",
        "Feb-2019",
        "Mar-2019",
        "Apr-2019",
        "May-2019",
        "Jun-2019",
        "Jul-2019",
        "Aug-2019",
        "Sep-2019",
        "Oct-2019",
        "Nov-2019",
        "Dec-2019"
      ];
      arr.sort(function(a, b) {
        return months.indexOf(a.month_year) - months.indexOf(b.month_year);
      });
    }

    return (
      <>
        <LineChart
          width={375}
          height={225}
          data={nestedRows}
          lineColor="#fffff"
          borderColor="#2E80B2"
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5
          }}
          onClick={this.renderTable}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis type="number" unit="%" tickFormatter={DataFormater} />
          <Tooltip
            cursor={{ stroke: "orange", strokeWidth: 3 }}
            formatter={helper.renderToolTip}
          />

          {xLines &&
            xLines.map((yAxis, index) => {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={xLines[index]}
                  stroke={CHART_COLORS[index]}
                  dot={{ stroke: "#2E80B2", strokeWidth: 2 }}
                  strokeWidth="3"
                  activeDot={{ r: 6 }}
                />
              );
            })}
          {/*<Line
          dot={{ stroke: "#2E80B2", strokeWidth: 2 }}
          strokeWidth="3"
          dataKey="uv"
          stroke="#2E80B2"
        >
          {this.props.dataset.map((entry, index) => (
            <Cell key={`cell-${index}`} />
          ))}
        </Line>*/}
        </LineChart>
        {this.state.show && (
          <ModelCharts
            onHide={this.hideModel}
            show={this.state.show}
            tabdata={data ? data : []}
            tablable={data ? this.props.label : ""}
          />
        )}
      </>
    );
  }
}
