import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import _ from 'lodash';
import "react-table-6/react-table.css";
//import {copyStyles} from "../../general/core/helper";
import ReactDOM from 'react-dom';


class GeneratePaymentLink extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.containerEl = null;
    this.externalWindow = null;
  }

  componentDidMount() {
    // Create a new window, a div, and append it to the window    
      this.externalWindow = window.open('', '', 'width=800px,height=580px,left= '+ (Math.trunc(document.body.clientWidth / 2)- 500) +'px,top=200px,menubar=no,toolbar=no,location=no,status=no');
      this.containerEl = this.externalWindow.document.createElement('div');
      this.externalWindow.document.body.appendChild(this.containerEl);
      this.externalWindow.document.title = 'Generate payment link / powercash21 ';
      //copyStyles(document, this.externalWindow.document);
      // update the state in the parent component if the user closes the 
      // new window
      this.externalWindow.addEventListener('beforeunload', () => {
        this.props.closeWindowPortal();
      });
  }

  componentWillUnmount() {
    // This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by just closing the window
    clearInterval(this.timerID);
    this.externalWindow.close();
    /* this.externalWindow.removeEventListener('beforeunload', () => {
      this.props.closeWindowPortal();
    }); */
  }
  
  render() {
    // The first render occurs before componentDidMount (where we open
    // the new window), so our container may be null, in this case
    // render nothing.
    if (!this.containerEl) {
      return null;
    } 
    
    // Append props.children to the container <div> in the new window
    return ReactDOM.createPortal(this.props.children, this.containerEl);  
  }
}


const mapStateToProps = (state) => ({
  generatePaymentLink: state.paymentLinkReducer
  
});

const mapDispatchToProps = {
  //getPaymentLink
};
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GeneratePaymentLink)
  );
