import React, { Component } from "react";
import moment from "moment-timezone";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BIN_REGION_BY_ID, CHECKBOXFIELDS, DATE1MONTH } from "../../../lib/constants/filter";
import InputFilter from "../../general/core/Input";
import SelectFilter from "../../general/core/Select";
import DateFilter from "../../general/common/DateFilter";
import {TransactionLogFilter} from "./TransactionLogFilter";
import classnames from "classnames";

export class CustomFilter extends Component {
  static MOBILE_WIDTH = 600;
  static CLEAR_SELECTION = "clear";
  static REMOVE_VALUE = "remove-value";
  static CARD_BRAND = "card_brand";

  constructor(props) {
    super(props);

    const dateFilter = props.dateFilter ?? DATE1MONTH;
    this.state = {
      ...dateFilter,
      filterData: {},
      isMobile: false,
      windowWidth: 0,
      isAdvancedSearchVisible: false,
      isAdvancedSearchEnabled: props.isAdvancedSearchEnabled ?? false
    };

    this.filterOutFiltersAndRefreshData = this.filterOutFiltersAndRefreshData.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
    this.updateFilter = this.updateFilter.bind(this);

    this.inputRef = {
        transaction_id: React.createRef(),
    }
    this.txFilterRef1 = React.createRef();
  }

  filterOutFiltersAndRefreshData() {
    const filterData = this.state.filterData;
    const checkedValue = "1";
    let filters = {};

    Object.keys(filterData).map(key => {
      if (filterData[key] && CHECKBOXFIELDS.includes(key)) {
        filters[key] = [checkedValue];
      } else if (filterData[key] && (Array.isArray(filterData[key]) && filterData[key].length > 0)) {
        if (typeof filterData[key] == "object") {
          filters[key] = filterData[key];
        } else {
          let array = filterData[key].split(",");
          filters[key] = array;
        }
      }
      else if (filterData[key] && typeof filterData[key] == "string") {
        filters[key] = [filterData[key]];
      }
      else {
        delete filters[key];
      }
      return null;
    });

    if (filters) {
      this.setState({ filters: filters }, () => {
        this.props.updateFilters(this.state);
      });
    } else {
      this.setState({ filters: {} }, () => {
        this.props.updateFilters(this.state);
      });
    }
  }

  updateFilterDataAndRefresh(key, value) {
    const { filterData } = this.state;
    const { setPaymentMethodId } = this.props;

    const updatedFilterData = {
      ...filterData,
      [key]: value,
    };

    // Only add paymentmethod_id if key is CustomFilter.CARD_BRAND and setPaymentMethodId prop is NOT false
    if (key === CustomFilter.CARD_BRAND && setPaymentMethodId !== false) {
      updatedFilterData.paymentmethod_id = value !== "" ? ["1"] : "";
    }

    this.setState(
      { filterData: updatedFilterData },
      this.filterOutFiltersAndRefreshData
    );
  }

  handleDatePicker(event, picker) {
    event.preventDefault();

    this.setState(
      (state, props) => ({
        dateFrom: moment(picker.startDate).format("YYYY-MM-DD"),
        dateTo: moment(picker.endDate).format("YYYY-MM-DD"),
        datetimeFrom: moment(picker.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        datetimeTo: moment(picker.endDate).format("YYYY-MM-DDTHH:mm:ss")
      }),
      () => {
        return this.filterOutFiltersAndRefreshData();
      }
    );
  }

  toggleAdvancedSearch() {
    this.setState({
      isAdvancedSearchVisible: !this.state.isAdvancedSearchVisible
    });
  }

  onChange(value, event) {
    if (event?.action === CustomFilter.REMOVE_VALUE) {
      let array = this.state.filterData[event.name] ? [...this.state.filterData[event.name]] : []; // make a separate copy of the array
      let index = array.indexOf(event.removedValue.value);

      if (index !== -1) {
        array.splice(index, 1);
        this.updateFilterDataAndRefresh(event.name, array.length === 0 ? "" : array);
      }
    } else if (event?.action === CustomFilter.CLEAR_SELECTION) {
      this.updateFilterDataAndRefresh(event.name, "");
    }

    if (event?.option && value) {
      const values = [];

      Object.keys(value).map(function (key) {
        values.push(value[key].value);
        return null;
      });

      this.updateFilterDataAndRefresh(event.name, values);
    }
  }

  handleWindowResize() {
    if (window.innerWidth < CustomFilter.MOBILE_WIDTH && !this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: true });
    }

    if (window.innerWidth > CustomFilter.MOBILE_WIDTH && this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: false });
    }
  }

  componentDidMount() {
    this.filterOutFiltersAndRefreshData();
    window.addEventListener("resize", this.handleWindowResize);

    this.setState({
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < CustomFilter.MOBILE_WIDTH
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  getFilters() {
    const filters = this.props.filters;

    const filtersResult = Object.keys(filters).map(key => {
      if (filters[key].type === 'input') {
          return this.renderInputField(filters[key].id, filters[key].title, filters[key]?.shouldSplitBySpace ?? true)
      } else {
          return this.renderSelectField(filters[key].id, filters[key].title)
        }
      }
    )

    return (
      <div className="row">
        <div className="col-md-12 col-xs-12">
          { filtersResult }
        </div>
      </div>
    );
  }

  renderInputField(name, label, shouldSplitBySpace = true) {
    let values = [];
    if (this.state.filterData[name] && this.state.filterData[name] !== "") {
      this.state.filterData[name].map(v => values.push({ label: v, value: v }));
    }

    return (
      <div key={name} className={`filter-field col-lg-2 col-md-3 col-sm-4 col-xs-6`}>
        <InputFilter
          key={name}
          label={label}
          valueArr={values}
          onValueChange={this.onChange}
          name={name}
          expanderEnabled={true}
          ref={this.inputRef[name]}
          shouldSplitBySpace={shouldSplitBySpace}
        />
      </div>
    );
  }

  renderSelectField(name, label) {
    let values = [];
    if (this.state.filterData[name] && this.state.filterData[name] !== "") {
      this.state.filterData[name].map(v => {
        values.push({
          label: name === this.BIN_REGION ? BIN_REGION_BY_ID[v] : v,
          value: v
        });
        return null;
      });
    }

    return (
      <div key={name} className="filter-field col-lg-2 col-md-3 col-sm-4 col-xs-6">
        <SelectFilter
          key={name}
          label={label}
          value={values}
          onChange={this.onChange}
          options={this.props.serverFilterData}
          name={name}
          checkNames={!this.props.noDefaultValues}
        />
      </div>
    );
  }

  updateFilter(dateObj, data, updateTable) {
    this.setState({
      ...dateObj,
      filterData: data
    }, () => {
      Object.entries(this.inputRef).forEach(([k, v]) => v.current.updateValue());

      if (updateTable) {
        this.props.showLoadingScreen(true);
        this.filterOutFiltersAndRefreshData();
      } else {
        this.props.showLoadingScreen(false);
      }
    });
  }

  render() {
    return (
      <>
        <div className="filter-box box collapse in frosted-glass row" style={{backgroundColor:"none", boxShadow:"none"}}>
          <div className="tab-content" id="myTabContent1">
            <div className=" tab-pane active box-body form-inline filter-search-tab"
                id="search"
                role="tabpanel"
                aria-labelledby="search-tab">
                <div className="row">
                  <div className="col-xs-6">
                     {<DateFilter datetimeFrom={this.state.datetimeFrom} datetimeTo={this.state.datetimeTo} onApply={this.handleDatePicker} disabled={this.props.isDateFilterDisabled}/>}
                  </div>
                  {
                     this.state.isAdvancedSearchEnabled &&
                     (
                      <div className="col-xs-6 text-right nav-item more-search-options-btn">
                          <button
                            className="primaryBtn2"
                            type="button"
                            style={{height:"40px", width:"150px", float:"right", fontWeight:"normal", borderWidth:"1px"}}
                            onClick={() => this.toggleAdvancedSearch()}
                            disabled={this.state.transactionLogFilterOpened}
                          >
                            {this.state.isMobile ? (
                              <><span className="glyphicon glyphicon-search"></span><span className="glyphicon glyphicon-plus"></span></>
                            ) : "Advanced Search"}
                          </button>
                      </div>
                     )
                  }
                </div>
              {this.getFilters()}
            </div>
          </div>
        </div>
        {
          
          <div className={classnames({
            "advanced-filter-close":!this.state.isAdvancedSearchVisible,
            "advanced-filter-open":this.state.isAdvancedSearchVisible,
          })}>
            <TransactionLogFilter
              serverFilterData={this.props.serverFilterData}
              transactionLogFilterIsOpen={this.toggleAdvancedSearch}
              isPosAdvancedFilter={this.props.isPosAdvancedFilter} 
              filterData={this.props.filters.filterData}
              dateFilter={
                {
                  dateFrom: this.state.dateFrom,
                  dateTo: this.state.dateTo,
                  datetimeFrom: this.state.datetimeFrom,
                  datetimeTo: this.state.datetimeTo
                }
              }
              updateFilter={this.updateFilter}
              isAdmin = {this.props.isAdmin} 
              ref={this.txFilterRef1}
              ></TransactionLogFilter>
          </div>
        }
      </>
    );
  }
}
