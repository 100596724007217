import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as dashBoardService from "../services/dashboard";

const _getData = data => ({
  type: actionTypes.GET_DATA,
  payload: data
});

export const getData = reqBody => {

  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return dashBoardService
      .getData(token, reqBody)
      .then(res => {
        return dispatch(_getData(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};
