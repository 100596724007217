import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import * as helper from "../../general/core/helper";
import { getPaymentLink, updatePaymentLink, sendPaymentLink } from "../../../actions/paymentLinkAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SingleInput from "../../general/core/SingleInput";
import moment from "moment";
import FormNotification from "../../general/core/FormNotification";
import { ScaleLoader } from "react-spinners";
import { CSSTransition } from 'react-transition-group';

class PaymentLinkDetails2 extends Component {
  constructor(props) {
    super(props);
    this.email = "";
    this.errorMsgEmail = "Please enter email in correct format";

    this.state = {
      paymentDataObj: null,
      emailBtnDisabled: false,
      emailSent: false,
      notifyEmailSent: false,
      notifyLinkCopied: false,
      emailInputWrong: false,
      emailBtnClicked: false,
    }

    this.rowNames = {
      transactionid: "Transaction ID",
      amount: "Amount",
      status: "Status",
      order: "Order ID",
      merchant: "Merchant ID",
      lifespan: "Expiry date",
      created: "Tokenized transaction"
    };

    this.statusMap = [
      {},
      {
        value: "Created",
        colorCode: "#2e80b2",
      },
      {
        value: "Error",
        colorCode: "#9c0000",
      },
      {
        value: "Expired",
        colorCode: "black",
      },
      {
        value: "Failed",
        colorCode: "red",
      },
      {
        value: "Pending",
        colorCode: "black",
      },
      {
        value: "Viewed",
        colorCode: "black",
      },
      {
        value: "Paid",
        colorCode: "green",
      },
      {
        value: "Cancelled",
        colorCode: "red",
      },
    ];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
    this.disableNotification = this.disableNotification.bind(this);
    this.validateEmailInput = this.validateEmailInput.bind(this);
  }

  componentDidMount() {
    this.getLinkID(this.props.selectedLinkID);
  }

  async getLinkID(linkid) {
    let requestBody = {
      linkid: linkid, 
    };
    const response = await this.props.getPaymentLink(requestBody);
    
    if (helper.isJsonString(response.payload)){
      let data_parsed = JSON.parse(response.payload);

      this.setState({
        paymentDataObj: data_parsed,
      });
    }
  }

  validateEmailInput() {
    if (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i.test(this.email) && this.email !== "") {
      this.setState({
        emailInputWrong: false,
      });
      return true;
    } else {
      this.setState({
        emailInputWrong: true,
      });
      return false;
    }
  }

  handleInputChange(event) {
    if (event.target) {
      this.email = event.target.value;
    } else {
      this.email = event.value;
    }
  }

  async handleSubmitEmail(event) {
    event.preventDefault();

    if (this.validateEmailInput()) {
      var requestBody = { 
        paymentlink: this.state.paymentDataObj["paymentLink"],   
        order: this.state.paymentDataObj["order"],
        amount: this.state.paymentDataObj["amount"],
        currency: this.state.paymentDataObj["currency"],
        date: moment(new Date(parseInt(this.state.paymentDataObj["lifespan"]) * 1000)).format("DD.MM.YYYY HH:mm:ss"),
        merchant_company_name: this.state.paymentDataObj["merchant_company_name"],
        email: this.email,
        transactionid: this.state.paymentDataObj["transactionid"],
        merchant_company_address1: "address1",
        merchant_company_address2: "address2",
      };

      var requestBodyEmailUpdate = {
        linkid: this.state.paymentDataObj["linkid"],
        merchant: this.state.paymentDataObj["merchant"],
        order: this.state.paymentDataObj["order"],
        lifespan: this.state.paymentDataObj["lifespan"],
        created: this.state.paymentDataObj["created"],
        currency: this.state.paymentDataObj["currency"],
        amount: this.state.paymentDataObj["amount"],
        email: this.email,
      };

      const response = await this.props.sendPaymentLink(requestBody);
      await this.props.updatePaymentLink(requestBodyEmailUpdate);

      if (response && response.payload){
        this.setState({
          responseEmail: response.payload,
          errorText: '', 
          sendEmail: '',
          emailSent: true,
          notifyEmailSent: true,
        });
  
        //let data_parsed = JSON.parse(response.payload);
        //this.getErrorText("sendEmail", response.payload);

        return;
      }

      if (response && !hasOwnProperty(response.error)) 
      {
        this.setState({
          errorEmail: response.payload,
          errorText:'', 
          sendEmail:'',
        });

        //this.getErrorText("sendEmail", "Cannot send Email. Error code: " + response.errors);
        return;
      }
    } else {
      this.setState({
        emailInputWrong: true,
      });
    }
  }

  disableNotification() {
    this.setState({
      notifyLinkCopied: false
    })
  }

  sendEmail(e) {
    this.handleSubmitEmail(e);

    this.setState({
      emailBtnDisabled: true,
      emailBtnClicked: true,
    });
  }

  render() {
    return (
      <div id="paymentLinkDetailsContainer">
        <div className="plDetailsContainer container-fluid">
          <form id="plDetailsForm" onSubmit={this.handleSubmit}>
            <div className="FormBlock">
              <div id="plDetailsHeaderContainer">
                <span>
                  <CSSTransition
                    in={this.state.paymentDataObj !== null}
                    timeout={300}
                    classNames="plDetailsData"
                    unmountOnExit
                  >
                    <div id="plDetailsHeader">
                      <div style={{display: "inline-block", marginRight: "25px"}}>
                        Link {this.state.paymentDataObj && this.state.paymentDataObj["linkid"] ? this.state.paymentDataObj["linkid"] : ""}
                      </div>
                      { this.state.paymentDataObj !== null  && this.state.paymentDataObj.hasOwnProperty("status") && typeof this.state.paymentDataObj["status"] === "string" ? 
                          <>

                          </> :
                        this.state.paymentDataObj !== null && this.state.paymentDataObj.hasOwnProperty("status") ?
                          <>
                            <div style={{backgroundColor:"#2e80b2", width: "10px", height: "10px", display: "inline-block", borderRadius: "50%", marginBottom: "1px"}}></div>
                            <span style={{color:this.statusMap[this.state.paymentDataObj["status"]].colorCode, marginLeft: "5px"}}>{this.statusMap[this.state.paymentDataObj["status"]].value}</span>
                          </> :
                          ""
                      }
                    </div>
                  </CSSTransition>
                </span>
                <span>
                  <button className="plDetailsCancelBtn" type="button" onClick={() => this.props.closePopup()}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </button>
                </span>
              </div>
            </div>

            <CSSTransition in={this.state.paymentDataObj !== null} timeout={300} classNames="plDetailsData" unmountOnExit>
              <div style={{padding:"32px 32px 28px 32px", width:"100%"}}>

                <div style={{padding:"32px"}}>
                  
                  { this.state.paymentDataObj &&
                    Object.keys(this.rowNames).map((key, index) => {
                      let value = "";
                      if (this.state.paymentDataObj[key]) 
                        value = this.state.paymentDataObj[key];
                      if (key === "status" && this.state.paymentDataObj["status"] && this.statusMap[this.state.paymentDataObj["status"]]) 
                        value = this.statusMap[this.state.paymentDataObj["status"]].value;
                      if (key === "lifespan" && typeof this.state.paymentDataObj["status"] === "number") 
                        value = moment(new Date(parseInt(this.state.paymentDataObj["lifespan"]) * 1000)).format("DD.MM.YYYY HH:mm:ss");
                      return (
                        <div key={key} className="row rowStyle">
                          <span className="col-md-6 keyCell">
                            {this.rowNames[key]}
                          </span>
                          <span className="col-md-6 valueCell">
                            {value}
                          </span>
                        </div>
                      )
                    })
                  }

                </div>

                { (this.state.paymentDataObj && this.state.paymentDataObj["email"]) || this.state.emailSent ? 
                    <>
                      <div style={{marginTop: "32px", fontWeight: "bold", color: "rgb(71, 85, 105)", marginBottom: "20px"}}>
                        Customer email sent to:
                      </div>
                      <div style={{fontSize: "16px", paddingLeft: "10px", color: "rgb(148, 163, 184)", marginBottom:"32px"}}>
                        {this.state.paymentDataObj["email"] ? this.state.paymentDataObj["email"] : this.email}
                      </div>
                    </> :
                    <SingleInput
                      id="merchantIDSelection"
                      name="email"
                      width="100%"
                      label="Email to"
                      placeholder="Customer email"
                      autoComplete="off"
                      marginTop="32px"
                      diplayButton={true}
                      buttonText="Send Link"
                      onChange={this.handleInputChange}
                      buttonOnClick={e => this.sendEmail(e)}
                      buttonDisabled={(this.state.emailBtnDisabled && !this.state.emailInputWrong) || (this.state.paymentDataObj && this.state.paymentDataObj["status"] && typeof this.state.paymentDataObj["status"] === "string")}
                      buttonLoading={this.state.emailBtnClicked}
                      errorMsg={this.errorMsgEmail}
                      showErrorMsg={this.state.emailInputWrong}
                    />
                }

                { this.state.notifyEmailSent &&
                    <FormNotification text="Email sent!" top="557px" right="230px"/>
                }

                <SingleInput
                  id="selectCurrency"
                  label="Copy + Share"
                  placeholder=""
                  marginRight="0px"
                  marginLeft="0px"
                  marginTop="12px"
                  disabled={true}
                  backgroundColor="#eff0f1"
                  value={this.state.paymentDataObj && this.state.paymentDataObj["paymentLink"] ? this.state.paymentDataObj["paymentLink"] : ""}
                  diplayButton={true}
                  buttonDisabled={this.state.paymentDataObj && this.state.paymentDataObj["status"] && typeof this.state.paymentDataObj["status"] === "string"}
                  buttonText="Copy Link"
                  shouldCopyToClipboard={true}
                  buttonOnClick={e => {
                    this.setState({
                      notifyLinkCopied: true
                    })
                  }}
                />
                
                {/*<FormNotification show={this.state.notifyLinkCopied} text="Link copied!" top="663px" right="20px" callback={this.disableNotification}/>*/}

              </div>
            </CSSTransition>
              
            <CSSTransition in={this.state.paymentDataObj === null} timeout={300} classNames="plDetailsData" unmountOnExit>
              <div id="loaderContainer">
                <ScaleLoader color={"#d2d6de"} height={60} width={6}/>
              </div>
            </CSSTransition>
          </form>     
        </div>
      </div>
    )
  }
}
 
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  getPaymentLink,
  sendPaymentLink,
  updatePaymentLink,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentLinkDetails2)
);