import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { confirmPassword } from "../../../actions/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import * as Yup from "yup";
import { Formik } from "formik";

const formEye = {
  width: "50px",
  paddingLeft: "12px",
  paddingRight: "12px",
  align: "center",
};

/**
 * Schema define for validation
 */
const schema = Yup.object().shape({
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), null], "Passwords don't match")
    .required("Confirm Password is required"),
  new_password1: Yup.string().required("Password is required"),
});

/**
 *  ConfPwd
 *  Component for reset password form.
 */
class ConfPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { new_password1: "", new_password2: "", token: "", uid: "" },
      errors: false,
      message: null,
      visibility: false,
      lengthPs: false,
      uppercasePs: false,
      lowercasePs: false,
      numericalPs: false,
      specialChPs: false,
      visiblePassword: false,
      visiblePassword2: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.showPassword2 = this.showPassword2.bind(this);
  }

  confirmPwd = async () => {
    const values = {
      ...this.state.fields,
      ...queryString.parse(this.props.location.search),
    };
    this.setState({
      fields: values,
    });
    let fields = this.state.fields;
    await this.props.confirmPassword(fields);
    const { resetPassword } = this.props.resetPassword;
    let errorKey = Object.keys(resetPassword).filter((k) => {
      return k !== "error";
    });
    this.setState({
      message: resetPassword.data
        ? resetPassword.data.detail
        : errorKey[0] + ": " + resetPassword[errorKey[0]][0],
      error: resetPassword.error,
    });
    if (!this.state.error) {
      setTimeout(() => {
        this.props.history.push("/login");
      }, 1000);
    }
  };

  handleFields = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });
  };

  handleInputChange(e) {
    let fields = this.state.fields;
    let field = e.target.name;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });

    if (field === "new_password1") {
      this.setState({
        lengthPs: fields[field].length >= 8 ? true : false,
        lowercasePs: /[a-z]/.test(fields[field]) ? true : false,
        uppercasePs: /[A-Z]/.test(fields[field]) ? true : false,
        numericalPs: /[0-9]/.test(fields[field]) ? true : false,
        specialChPs: /[~$&@#%^`'*+-=<>,.:;?!|()]/g.test(fields[field])
          ? true
          : false,
      });
    }

    if (
      this.state.lengthPs &&
      this.state.lowercasePs &&
      this.state.uppercasePs &&
      this.state.numericalPs &&
      this.state.specialChPs
    ) {
      this.setState({ visibility: true });
    } else {
      this.setState({ visibility: false });
    }
  }

  showPassword() {
    this.setState({
      visiblePassword: this.state.visiblePassword ? false : true,
    });
  }

  showPassword2() {
    this.setState({
      visiblePassword2: this.state.visiblePassword2 ? false : true,
    });
  }

  successMessage = () => {
    if (this.state.error) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.state.message}
        </div>
      );
    } else {
      return (
        <div className="alert alert-success" role="alert">
          {this.state.message}
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <Formik
          initialValues={this.state.fields}
          onSubmit={(values) => {
            // api call
            this.setState({
              fields: values,
            });
            this.confirmPwd();
          }}
          validationSchema={schema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              // isSubmitting,
              //handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <div className="login-box">
                <div className="login-box-body">
                  <form onSubmit={handleSubmit} name="passwordForm" noValidate>
                    <div className="login-logo">
                      <img
                        src={"/img/payabl_white_main.svg"}
                        height="100px"
                        width={200}
                        alt="Payabl."
                      />
                    </div>

                    <span>
                      {this.state.message ? this.successMessage() : null}
                    </span>
                    <div className="form-group has-feedback">
                      <div style={{ display: "flex" }}>
                        <input
                          type={
                            this.state.visiblePassword ? "text" : "password"
                          }
                          className="form-control"
                          placeholder="password"
                          name="new_password1"
                          value={values.new_password1}
                          onChange={(e) => {
                            this.handleInputChange(e);
                          }}
                          onKeyUp={(e) => {
                            this.handleInputChange(e);
                          }}
                          onBlur={handleBlur}
                        />

                        <div
                          className="form-control"
                          style={formEye}
                          onMouseDown={this.showPassword}
                          onMouseUp={this.showPassword}
                          //onClick={(e) => {this.showPassword()}}
                        >
                          {this.state.visiblePassword ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </div>
                      </div>

                      {errors.new_password1 && touched.new_password1 && (
                        <div
                          className="input-feedback"
                          style={{ color: "#E65656" }}
                        >
                          {errors.new_password1}
                        </div>
                      )}
                    </div>
                    <div className="form-group has-feedback">
                      <div style={{ display: "flex" }}>
                        <input
                          type={
                            this.state.visiblePassword2 ? "text" : "password"
                          }
                          className="form-control"
                          placeholder="confirm password"
                          name="new_password2"
                          value={values.new_password2}
                          onChange={(e) => {
                            this.handleInputChange(e);
                          }}
                          onBlur={handleBlur}
                        />

                        <div
                          className="form-control"
                          style={formEye}
                          onMouseDown={this.showPassword2}
                          onMouseUp={this.showPassword2}
                          //onClick={(e) => {this.showPassword2()}}
                        >
                          {this.state.visiblePassword2 ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </div>
                      </div>

                      <div style={{ height: "15px" }}>
                        {errors.new_password2 && touched.new_password2 && (
                          <div
                            className="input-feedback"
                            style={{ color: "#E65656" }}
                          >
                            {errors.new_password2}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      disabled={!this.state.visibility}
                    >
                      Submit
                    </button>
                    <div className="row">
                      <div
                        className="col-xs-6"
                        align="right"
                        style={{ width: "100%" }}
                      >
                        <Link
                          to="/login"
                          className="footer-link"
                          style={{ color: "#177D91" }}
                        >
                          Go to Login
                        </Link>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-xs-12"
                        style={{ marginTop: "5px", color: "#02B2B6" }}
                      >
                        Password must contain <br />
                        <span
                          style={{
                            color: this.state.lengthPs ? "#02B2B6" : "#E65656",
                          }}
                        >
                          at least 8 characters,
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            color: this.state.uppercasePs
                              ? "#02B2B6"
                              : "#E65656",
                          }}
                        >
                          uppercase letters (A-Z),
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            color: this.state.lowercasePs
                              ? "#02B2B6"
                              : "#E65656",
                          }}
                        >
                          lowercase letters (a-z),
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            color: this.state.numericalPs
                              ? "#02B2B6"
                              : "#E65656",
                          }}
                        >
                          numbers (0-9),
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            color: this.state.specialChPs
                              ? "#02B2B6"
                              : "#E65656",
                          }}
                        >
                          special characters (such as !,%,@,#,$,&).{" "}
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resetPassword: state.loginData,
});

const mapDispatchToProps = {
  confirmPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfPwd)
);
