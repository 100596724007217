import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import {
  CHART_COLORS,
  CHART_COLORS_2
} from "../../../lib/constants/chartColors";
import * as helper from "../core/helper";
import { BarLoader } from "react-spinners";

const BarCharts = ({ height, width, ...props }) => {
  const { data, meta_data } = props.data.data ? props.data : [];
  let xBars = meta_data ? Object.keys(meta_data) : [];
  xBars = xBars.filter(item => {
    return item !== "xVal";
  });

  let report_format = 0;
  let xLines = [];
  let nestedRows = [];
  let total_rows = [];
  let chart_id = props.chartId;

  if (data && !Array.isArray(data)) {
    report_format = 1;
    xLines = data && data.columns ? data.columns : [];
    nestedRows = [];
    if (data && data.rows) {
      for (let k1 in data.rows) {
        if (k1 !== "total") {
          total_rows.push(k1);

          if (data.rows[k1].total) {
            data.rows[k1].total.name = k1;
            nestedRows.push(data.rows[k1].total);
          }
          for (let k2 in data.rows[k1]) {
            if (k2 !== "total") {
              data.rows[k1][k2].name = "• " + k2;
              nestedRows.push(data.rows[k1][k2]);
            }
          }
        }
      }
    }

    if (data && typeof data.sort === "function") {
      data.sort(function(a, b) {
        if (a.date) {
          let aComps = a.date.split(".");
          let bComps = b.date.split(".");

          var aDate = new Date(aComps[2], aComps[1], aComps[0]);
          var bDate = new Date(bComps[2], bComps[1], bComps[0]);
          return aDate - bDate;
        } else {
          return null;
        }
      });
    }

    if (nestedRows) {
      sortByMonth(nestedRows);
    }
  }

  function sortByMonth(arr) {
    var months = [
      "Jan-2018",
      "Feb-2018",
      "Mar-2018",
      "Apr-2018",
      "May-2018",
      "Jun-2018",
      "Jul-2018",
      "Aug-2018",
      "Sep-2018",
      "Oct-2018",
      "Nov-2018",
      "Dec-2018",
      "Jan-2019",
      "Feb-2019",
      "Mar-2019",
      "Apr-2019",
      "May-2019",
      "Jun-2019",
      "Jul-2019",
      "Aug-2019",
      "Sep-2019",
      "Oct-2019",
      "Nov-2019",
      "Dec-2019",
      "Jan-2020",
      "Feb-2020",
      "Mar-2020",
      "Apr-2020",
      "May-2020",
      "Jun-2020",
      "Jul-2020",
      "Aug-2020",
      "Sep-2020",
      "Oct-2020",
      "Nov-2020",
      "Dec-2020",
      "Jan-2021",
      "Feb-2021",
      "Mar-2021",
      "Apr-2021",
      "May-2021",
      "Jun-2021",
      "Jul-2021",
      "Aug-2021",
      "Sep-2021",
      "Oct-2021",
      "Nov-2021",
      "Dec-2021",
      "Jan-2022",
      "Feb-2022",
      "Mar-2022",
      "Apr-2022",
      "May-2022",
      "Jun-2022",
      "Jul-2022",
      "Aug-2022",
      "Sep-2022",
      "Oct-2022",
      "Nov-2022",
      "Dec-2022",
      "Jan-2023",
      "Feb-2023",
      "Mar-2023",
      "Apr-2023",
      "May-2023",
      "Jun-2023",
      "Jul-2023",
      "Aug-2023",
      "Sep-2023",
      "Oct-2023",
      "Nov-2023",
      "Dec-2023"
    ];
    arr.sort(function(a, b) {
      return months.indexOf(a.month_year) - months.indexOf(b.month_year);
    });
  }

  // fetching chart type to determine which unit will be used
  let unit = "";
  if (xLines && xLines[0]) {
    unit = helper.renderUnit(xLines[0]);
  } else if (meta_data && meta_data["yVal1"]) {
    unit = helper.renderUnit(meta_data["yVal1"]);
  }
  if (
    props.data &&
    Object.keys(props.data).length === 0 &&
    props &&
    Object.keys(props).length === 0
  ) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  } else {
    if (
      (data && Array.isArray(data) && data.length !== 0) ||
      (data && !Array.isArray(data) && total_rows.length !== 0)
    ) {
      
      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
	      	<ResponsiveContainer width="99%" height="99%">
		        <BarChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={report_format === 1 ? nestedRows : data}
		          margin={{
		            top: 20,
		            right: 30,
		            left: 0,
		            bottom: 5
		          }}
		        >
		          <CartesianGrid vertical={false} />
		          {/* // dynamically add X-axis attribute  */}
		          {report_format === 1 ? (
		            <XAxis dataKey={`${report_format === 1 ? "name" : ""}`} />
		          ) : (
		            <XAxis dataKey={`${meta_data ? meta_data.xVal : ""}`} />
		          )}
		
		          {/* <XAxis dataKey="country" /> */}
		          {chart_id === 105 ? (
		            <YAxis type="number" unit={unit} domain={[0, 100]} tickCount={5} />
		          ) : (
		            <YAxis
		              type="number"
		              unit={unit}
		              tickFormatter={helper.DataFormater}
		            />
		          )}
		
		          {/* <Tooltip /> */}
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		            labelFormatter={helper.renderLabelToolTip}
		          />
		          {/*<Legend />*/}
		          {/* <Bar dataKey="amount_usd" type="monotone"  fill="#8884d8" /> */}
		          {/* <Bar dataKey={`${meta_data ? meta_data.yVal2 : ""}`} fill="#82ca9d" />
		            <Bar dataKey={`${meta_data ? meta_data.yVal1 : ""}`} fill="#8884d8" /> */}
		
		          {xBars &&
		            xBars.map((yAxis, index) => {
		              return (
		                <Bar
		                  key={index}
		                  dataKey={`${meta_data ? meta_data[yAxis] : ""}`}
		                  fill={
		                    report_format && report_format === 1
		                      ? CHART_COLORS_2[index]
		                      : CHART_COLORS[index]
		                  }
		                />
		              );
		            })}
		
		          {report_format &&
		            report_format === 1 &&
		            xLines &&
		            props.currentPath === "/generic/cbkTab" &&
		            xLines.map((yAxis, index) => {
		              return (
		                <Bar
		                  key={index}
		                  dataKey={xLines[index]}
		                  stackId="a"
		                  fill={CHART_COLORS[index]}
		                />
		              );
		            })}
		
		          {report_format &&
		            report_format === 1 &&
		            xLines &&
		            props.currentPath !== "/generic/cbkTab" &&
		            xLines.map((yAxis, index) => {
		              return (
		                <Bar
		                  key={index}
		                  dataKey={xLines[index]}
		                  stackId="a"
		                  fill={CHART_COLORS_2[index]}
		                />
		              );
		            })}
		        </BarChart>
	        </ResponsiveContainer>
        </div>
      );
    } else if (
      props.data &&
      Array.isArray(props.data) &&
      props.data.length !== 0
    ) {
      let received_data = props.data;
      let headers = received_data[0];
      if (received_data && typeof received_data.sort === "function") {
        received_data.sort(function(a, b) {
          if (a.date) {
            let aComps = a.date.split(".");
            let bComps = b.date.split(".");
            var aDate = new Date(aComps[2], aComps[1] - 1, aComps[0]).getTime();
            var bDate = new Date(bComps[2], bComps[1] - 1, bComps[0]).getTime();
            return aDate - bDate;
          } else {
            return null;
          }
        });
      }

      if (received_data && typeof received_data.sort === "function") {
        sortByMonth(received_data);
      }
      // get axis keys
      var axisKeys = [];

      Object.keys(headers).forEach(function(key) {
        axisKeys.push(key);
      });

      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
	      	<ResponsiveContainer width="99%" height="99%">
		        <BarChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={received_data}
		          margin={{ top: 20,right: 30,left: 0,bottom: 5 }}
		        >
		          <CartesianGrid vertical={false} />
		
		          <XAxis dataKey={axisKeys[0]} />
		          {props && props.label && props.label.includes("Approval Rate") ? (
		            <YAxis
		              type="number"
		              unit={unit}
		              tickFormatter={helper.DataFormater}
		              domain={[0, 100]}
		            />
		          ) : (
		            <YAxis
		              type="number"
		              unit={unit}
		              tickFormatter={helper.DataFormater}
		            />
		          )}
		
		          {/* <Tooltip /> */}
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		            labelFormatter={helper.renderLabelToolTip}
		            offset={10}
		          />
		
		          {axisKeys &&
		            axisKeys.map((yAxis, index) => {
		              if (index === 0) return null;
		              else {
		                return (
		                  <Bar
		                    key={index}
		                    stackId={axisKeys[0]}
		                    dataKey={axisKeys[index]}
		                    fill={
		                      axisKeys.length === 2 &&
		                      !props.label.includes("Approval Rate") &&
		                      props.label !== "Top 5 Countries Ratios" &&
		                      props.label !== "3D Ratios"
		                        ? CHART_COLORS[0]
		                        : CHART_COLORS_2[index - 1]
		                    }
		                  />
		                );
		              }
		            })}
		        </BarChart>
	        </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="noDataFound-style">
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            No data was found! 
          </h4>
        </div>
      );
    }
  }
};

export default BarCharts;
