import React,{Component} from 'react';

class NotFound extends Component{
    render(){
        return(
            <div className="notFound">
                Error 404
            </div>
        )
    }
}
export default NotFound;