import React, { Component } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class SingleSelect extends Component {
  constructor(props) {
    super(props);
    this.containerEl = null;
    this.externalWindow = null;
    this.errorMsg = "";
  }
  
  render() {
    let options = [];
    if (this.props.options !== undefined && this.props.options.length !== 0 && typeof this.props.options[0] !== 'object') {
      this.props.options.map(v => {
        options.push({label:v, value:v, name:this.props.name});
        return "";
      });
    } else if (this.props.options !== undefined && this.props.options.length !== 0 && typeof this.props.options[0] === 'object') {
      this.props.options.map(o => {
        options.push({label:o.label, value:o.value, name:this.props.name});
        return "";
      });
    }

    return (
      <>
        <div style={{marginLeft:this.props.marginLeft,marginRight:this.props.marginRight, width:this.props.width, marginTop:this.props.marginTop}}>
          <div style={{background:"#F8FAFC"}}>
            <label style={{color:"#475569"}}>{this.props.label}</label>
          </div>
          <Select
            id={this.props.id}
            placeholder={this.props.placeholder}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name={this.props.name}
            value={this.props.value}
            options={options}
            onChange={e => this.props.onChange(e)}
            defaultValue={this.props.defaultValue}
            styles={{control: (base, state) => ({
              ...base,
              height: "45px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              border: "1px solid #E2E8F0",
              boxShadow: "none",
              cursor: "pointer",
              borderRadius: "2px",
              fontSize: "16px",
              "&:hover": {
                border: "1px solid #35A7E3"
              }
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                color: "#475569",
              }),
              menuList: (base, state) => ({
                ...base,
                height: this.props.menuHeight,
              }),
            }}
          />
        </div>
        { this.props.showErrorMsg ?
          <div>
            <span style={{color: "red", marginRight: "10px"}}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
            <span style={{color: "rgb(128, 128, 128)"}}>
              {this.props.errorMsg ? this.props.errorMsg : this.errorMsg}
            </span>
          </div> :
          <div style={{height:"20px"}}></div>
        }
      </>
    );
  }
}

export default SingleSelect;