import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as adminService from "../services/admin";

const _addSubuser = data => ({
  type: actionTypes.ADMIN_ADD_SUBUSER,
  payload: data
});

const _getSubusers = data => ({
  type: actionTypes.ADMIN_GET_SUBUSERS,
  payload: data
});

const _updateSubuser = data => ({
  type: actionTypes.ADMIN_UPDATE_SUBUSER,
  payload: data
});

const _deleteSubuser = data => ({
  type: actionTypes.ADMIN_DELETE_SUBUSER,
  payload: data
});

export const addSubuser = (body) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return adminService
      .addSubuser(token, body)
      .then(res => {
        return dispatch(_addSubuser(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const getSubusers = () => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return adminService
      .getSubusers(token, {})
      .then(res => {
        return dispatch(_getSubusers(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const updateSubuser = (body) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return adminService
      .updateSubuser(token, body)
      .then(res => {
        return dispatch(_updateSubuser(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const deleteSubuser = (body) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return adminService
      .deleteSubuser(token, body)
      .then(res => {
        return dispatch(_deleteSubuser(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};
