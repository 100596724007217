import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import { CHART_COLORS } from "../../../lib/constants/chartColors";
import * as helper from "../core/helper";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { BarLoader } from "react-spinners";

const VerticalBar = ({ height, width, label, ...props }) => {
  const { data, meta_data } = props.data.data ? props.data : [];
  let xBars = meta_data ? Object.keys(meta_data) : [];
  xBars = xBars.filter(item => {
    return item !== "xVal";
  });

  if (data) {
    data.sort(function(a, b) {
      if (a.date) {
        let aComps = a.date.split(".");
        let bComps = b.date.split(".");

        var aDate = new Date(aComps[2], aComps[1], aComps[0]);
        var bDate = new Date(bComps[2], bComps[1], bComps[0]);
        return aDate - bDate;
      } else {
        return null;
      }
    });
  }

  if (data) {
    sortByMonth(data);
  }
  
  function sortByMonth(arr) {
    var months = [
      "Jan-2018",
      "Feb-2018",
      "Mar-2018",
      "Apr-2018",
      "May-2018",
      "Jun-2018",
      "Jul-2018",
      "Aug-2018",
      "Sep-2018",
      "Oct-2018",
      "Nov-2018",
      "Dec-2018",
      "Jan-2019",
      "Feb-2019",
      "Mar-2019",
      "Apr-2019",
      "May-2019",
      "Jun-2019",
      "Jul-2019",
      "Aug-2019",
      "Sep-2019",
      "Oct-2019",
      "Nov-2019",
      "Dec-2019",
      "Jan-2020",
      "Feb-2020",
      "Mar-2020",
      "Apr-2020",
      "May-2020",
      "Jun-2020",
      "Jul-2020",
      "Aug-2020",
      "Sep-2020",
      "Oct-2020",
      "Nov-2020",
      "Dec-2020",
      "Jan-2021",
      "Feb-2021",
      "Mar-2021",
      "Apr-2021",
      "May-2021",
      "Jun-2021",
      "Jul-2021",
      "Aug-2021",
      "Sep-2021",
      "Oct-2021",
      "Nov-2021",
      "Dec-2021",
      "Jan-2022",
      "Feb-2022",
      "Mar-2022",
      "Apr-2022",
      "May-2022",
      "Jun-2022",
      "Jul-2022",
      "Aug-2022",
      "Sep-2022",
      "Oct-2022",
      "Nov-2022",
      "Dec-2022",
      "Jan-2023",
      "Feb-2023",
      "Mar-2023",
      "Apr-2023",
      "May-2023",
      "Jun-2023",
      "Jul-2023",
      "Aug-2023",
      "Sep-2023",
      "Oct-2023",
      "Nov-2023",
      "Dec-2023"
    ];
    arr.sort(function (a, b) {
      return months.indexOf(a.month_year) - months.indexOf(b.month_year);
    });
  }

  // fetching chart type to determine which unit will be used
  let unit =
    meta_data && meta_data["yVal1"]
      ? helper.renderUnit(meta_data["yVal1"])
      : "";

  if (!data && props.data && Object.keys(props.data).length === 0 && props.data.length !== 0) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  } else {
    if (data && Array.isArray(data) && data.length !== 0) {
      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
	      	<ResponsiveContainer width="99%" height="99%">
		        <BarChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={data}
		          lineColor="#fffff"
		          layout="vertical"
		          margin={{
		            top: 20,
		            right: 30,
		            left: 40,
		            bottom: 5
		          }}
		        >
		          <CartesianGrid vertical={false} />
		          {/* // dynamically add X-axis attribute  */}
		          <YAxis
		            dataKey={`${meta_data ? meta_data.xVal : ""}`}
		            type="category"
		          />
		          <XAxis
		            type="number"
		            unit={unit}
		            tickFormatter={helper.DataFormater}
		          />
		
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		            labelFormatter={helper.renderLabelToolTip}
		          />
		
		          {xBars &&
		            xBars.map((yAxis, index) => {
		              return (
		                <Bar
		                  key={index}
		                  dataKey={`${meta_data ? meta_data[yAxis] : ""}`}
		                  fill={CHART_COLORS[0]}
		                />
		              );
		            })}
		        </BarChart>
	        </ResponsiveContainer>
        </div>
      );
    } else if (
      props.data &&
      Array.isArray(props.data) &&
      props.data.length !== 0
    ) {
      let received_data = props.data;
      let headers = received_data[0];

      if (received_data && typeof received_data.sort === "function") {
        received_data.sort(function(a, b) {
          if (a.date) {
            let aComps = a.date.split(".");
            let bComps = b.date.split(".");

            var aDate = new Date(aComps[2], aComps[1], aComps[0]);
            var bDate = new Date(bComps[2], bComps[1], bComps[0]);
            return aDate - bDate;
          } else {
            return null;
          }
        });
      }

      if (received_data && typeof received_data.sort === "function") {
        sortByMonth(received_data);
      }

      // get axis keys
      var axisKeys = [];

      Object.keys(headers).forEach(function(key) {
        axisKeys.push(key);
      });

      return (
        <div style={{ width: "99%", height: parseInt(height) }}>
          <ResponsiveContainer width="99%" height="99%">
            <BarChart
              width={parseInt(width)}
              height={parseInt(height)}
              data={received_data}
              lineColor="#fffff"
              layout="vertical"
              margin={{
                top: 20,
                right: 30,
                left: label.includes("Top declines reasons") ? 200 : 40,
                bottom: 5
              }}
            >
              <CartesianGrid vertical={false} />

              <YAxis
                dataKey={axisKeys[0]}
                type="category"
                tick={<CustomizedAxisTick chart={"vertical"} />}
              />

              <XAxis
                type="number"
                unit={unit}
                tickFormatter={helper.DataFormater}
              />
              {/* <Tooltip /> */}
              <Tooltip
                cursor={{ stroke: "orange", strokeWidth: 3 }}
                formatter={helper.renderToolTip}
                labelFormatter={helper.renderLabelToolTip}
                offset={10}
              />

              {axisKeys &&
                axisKeys.map((yAxis, index) => {
                  if (index === 0 || index === 2) {
                    return null;
                  } else {
                    return (
                      <Bar
                        key={index}
                        dataKey={axisKeys[index]}
                        fill={CHART_COLORS[index - 1]}
                      />
                    );
                  }
                })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="noDataFound-style">
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            No data was found! 
          </h4>
        </div>
      );
    }
  }
};

export default VerticalBar;
