import React from 'react';
import ReactTooltip from 'react-tooltip';

const StatusCell = ({ value, original }) => {

  const errorList = ["Incomplete", "Aborted", "Unknown", "Verification error", "Not enrolled"];
  const inErrorList = value in errorList;

  return (
    <span>
      { value === "Successful" || value === "Verified" || value === "Attempted"?
          <div className="error-message-btn txLogStatus" style={{color:"#62E38F"}}>
            <i className="fa fa-check-circle fa-lg"></i>
          </div>
        : value === "Failed" && original.error_code !== "2000" ?
          <div className="error-message-btn txLogStatus" style={{color:"rgb(215, 39, 0)",fontWeight:"bold"}}>
            <div data-tip data-for={'errorTooltip' + original.transaction_id}>{ original.error_code }</div>
            {!original.error_code && <div data-tip>Failed</div>}
            <ReactTooltip id={'errorTooltip' + original.transaction_id} type='error' place='left'>
              <span>
                {original.error_code + " | " + original.error_message} <br/>
                {
                  original.sub_errors ? Object.keys(original.sub_errors).map((item, index) => {
                    if (original.sub_errors[item].error_code !== "0") return original.sub_errors[item].error_code + " | " + original.sub_errors[item].error_message;
                    else return null;
                  }) : null
                }
              </span>
            </ReactTooltip>
          </div>
        : <div className="error-message-btn txLogStatus" style={{color: inErrorList ? "rgb(215, 39, 0)" : "rgb(63, 103, 210)"}}>
            <b>{value === "In progress" ? "Pending" : value}</b>
          </div>
      }
    </span>
  )
};

export default StatusCell;