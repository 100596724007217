import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  virtualTerminalData: null,
  paymentMethodsData: null,
  txDiagnoseData: null,
};

const virtualTermReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VIRTUAL_TERMINAL:
      return {
        ...state,
        virtualTerminalData: action.payload
      };

    case actionTypes.PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodsData: action.payload
      };

    case actionTypes.TX_DIAGNOSE:
      return {
        ...state,
        txDiagnoseData: action.payload
      };

    default:
      return state;
  }
};

export default virtualTermReducer;
