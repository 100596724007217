export const POS_TRANSACTIONS_COLUMNS_KEYS = {
    TRANSACTION_ID: "transaction_id",
    COMPANY: "merchant_company_id",
    SHOP_NAME: "shop_name",
    MID: "acquirer_mid",
    TID: "tid",
    SEQUENCE_NUMBER: "spdh_sequence",
    BIN_COUNTRY: "bin_country",
    TRANSACTION_TYPE: "pos_tx_type",
    PAN: "ccn_masked",
    DATE: "date",
    AMOUNT: "amount",
    CURRENCY: "currency",
    TRANSACTION_STATUS: "transaction_status",
    ERROR_CODE: "error_code",
    BIN_REGION: "bin_region",
};

export const POS_TRANSACTIONS_DATA_TABLE_COLUMNS = {
    // columnKey: [{COLUMN_LABEL, COLUMN_WIDTH}]
    [POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_ID]: ["Transaction ID", 120],
    [POS_TRANSACTIONS_COLUMNS_KEYS.COMPANY]: ["Company", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.SHOP_NAME]: ["Shop Name", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.MID]: ["MID", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.TID]: ["TID", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.SEQUENCE_NUMBER]: ["Seq. No.", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.BIN_COUNTRY]: ["BIN Country", 80],
    [POS_TRANSACTIONS_COLUMNS_KEYS.BIN_REGION]: ["BIN Region", 80],
    [POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_TYPE]: ["Type", 100],
    [POS_TRANSACTIONS_COLUMNS_KEYS.PAN]: ["PAN", 130],
    [POS_TRANSACTIONS_COLUMNS_KEYS.DATE]: ["Date/Time", 70],
    [POS_TRANSACTIONS_COLUMNS_KEYS.AMOUNT]: ["Amount", 70],
    [POS_TRANSACTIONS_COLUMNS_KEYS.CURRENCY]: ["Currency", 60],
    [POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_STATUS]: ["Status", 60],
};

export const POS_TRANSACTION_FILTERS = {
    transaction_id: {
        id: "transaction_id",
        title: "Transaction ID",
        type: "input",
    },
    shop_name: {
        id: "shop_name",
        title: "Shop Name",
        type: "input",
        shouldSplitBySpace: false,
    },
    company: {
        id: "merchant_company_id",
        title: "Company",
        type: "select",
    },
    currency: {
        id: "currency",
        title: "Currency",
        type: "select",
    },
    card_brand: {
        id: "card_brand",
        title: "Card Scheme",
        type: "select",
    },
    bin_country: {
        id: "bin_country",
        title: "BIN Country",
        type: "select",
    },
    bin_region: {
        id: "bin_region",
        title: "BIN Region",
        type: "select",
    },
    ccn_masked: {
        id: "ccn_masked",
        title: "424242******4242",
        type: "input",
    },
    transaction_type: {
        id: "transaction_type",
        title: "All Transaction Types",
        type: "select",
    },
    spdh_sequence: {
        id: "spdh_sequence",
        title: "Seq. No.",
        type: "input",
    },
};

export const TX_ADVANCED_FILTER_POS_Admin = {
    transaction_id: {
        id: "transaction_id",
        title: "Transaction-ID",
        type: "input",
    },
    transaction_status: {
        id: "transaction_status",
        title: "All Status",
        type: "select",
    },
    error_code: {
        id: "error_code",
        title: "Error-Code",
        type: "input",
    },
    currency: {
        id: "currency",
        title: "All Currencies",
        type: "select",
    },
    TID: {
        id: "tid",
        title: "TID",
        type: "input",
    },
    merchant_name: {
        id: "merchant_name",
        title: "All Merchant Names",
        type: "select",
    },
    rrn: {
        id: "rrn",
        title: "RRN",
        type: "input",
    },
    arn: {
        id: "arn",
        title: "ARN",
        type: "input",
    },
};

export const TX_ADVANCED_FILTER_POS_Merchant = {
    transaction_id: {
        id: "transaction_id",
        title: "Transaction-ID",
        type: "input",
    },
    transaction_status: {
        id: "transaction_status",
        title: "All Status",
        type: "select",
    },
    error_code: {
        id: "error_code",
        title: "Error-Code",
        type: "input",
    },
    currency: {
        id: "currency",
        title: "All Currencies",
        type: "select",
    },
    TID: {
        id: "tid",
        title: "TID",
        type: "input",
    },
    rrn: {
        id: "rrn",
        title: "RRN",
        type: "input",
    },
    arn: {
        id: "arn",
        title: "ARN",
        type: "input",
    },
};

export const transactionTypeLabels = {
    PURCHASE_ONLINE: "PURCHASE",
    PRE_AUTH: "PRE AUTHORIZATION",
    PRE_AUTH_COMPLETION: "PRE AUTHORIZATION COMPLETE",
    PRE_AUTH_INCREMENT: "PRE AUTHORIZATION INCREMENT",
    REVERZAL: "REVERSAL",
    REVERZAL_TECH: "REVERSAL TECHNICAL",
    REVERZAL_CUSTOMER: "REVERSAL CUSTOMER",
    REFUND: "REFUND",
};

export const POS_TRANSACTION_PAYMENT_ID = 174;
