import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import CONFIRM from "../../general/core/Confirmation";
import AlertScrollable from "../../general/core/AlertScrollable";
import { interfacePaymentCall } from "../../../actions/transLogAction";
import BatchRefundForm from "./BatchRefundForm";

var amounts = {};
var comments = {};
const alertTitle = "Batch refund notification"

const BatchRefund = ({selection, reload, sideBarData, interfacePaymentCall}) => {
  const [showConfirmRefund, setShowConfirmRefund] = useState(false);
  const [confirmShouldLoad, setConfirmShouldLoad] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupFading, setPopupFading] = useState(null);
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText1, setAlertText1] = useState("");
  const [alertText2, setAlertText2] = useState("");
 
 	const submitRefund = e => {
 		e.preventDefault();
    setShowConfirmRefund(true);
  }

  const onValueChange = e => {
    e.preventDefault();
    let tmpAmounts = { ...amounts, [e.target.name]: e.target.value }
    amounts = tmpAmounts;
  }

  const onCommentChange = e => {
    e.preventDefault();
    let tmpComments = { ...comments, [e.target.name]: e.target.value }
    comments = tmpComments;
  }

  const toggle = () => {
    clearTimeout(popupFading);
    setPopupOpen(!popupOpen);
    setPopupFading(setTimeout(() => { setPopupFading(null) }, 80));
  }

  const cancelAlert = () => {
    setShowAlert(false);
  };
 
 	const requestBatchRefund = async () => {
    let batch = [];
    let requestBody = { action_type: "refund_batch" };

    for (const [key, value] of Object.entries(selection)) {
      if (value.amount !== undefined) {
        batch.push({
          amount: amounts[key],
          currency: value.currency,
          refund_comment: comments[key],
          transactionid: key,
          merchantid: value.merchantid,
        });
      }
    }

    requestBody["batch"] = batch;
    setConfirmShouldLoad(true);
    
    const response = await interfacePaymentCall(requestBody);

    if (response && response.payload && response.payload.data) {
      if (response.payload.data.result.status === "0") {
        setAlertText1("Batch refunds are executed successfully.");
        setAlertText2("");
        setShowAlert(true);
      }
      else if (response.payload.data.result && response.payload.data.result.errormessage && response.payload.data.result.failedRefundList && response.payload.data.result.failedRefundList.length > 0) 
      {
        var detailedMessage = "Failed Refunds: ";
        response.payload.data.result.failedRefundList.map(function(failedRefund, index) {
          detailedMessage = detailedMessage + String(failedRefund) + ", ";
          return null;
        });
        setAlertText1(response.payload.data.result.errormessage);
        setAlertText2(detailedMessage);
        setShowAlert(true);
      }
      else
      {
        setAlertText1("Refunds could not be processed.");
        setAlertText2("");
        setShowAlert(true);
      }      
    }
    
    toggle();
    reload();
    setConfirmShouldLoad(false);
    setShowConfirmRefund(!showConfirmRefund);
  }

  const populateData = () => {
    var data = [];
    let tmpAmounts = {};
    for (const [key, value] of Object.entries(selection)) {
      if (value.amount === undefined) continue;
      var obj = {
        transaction_id: key,
        currency: value.currency,
        remaining_amount: value.amount,
        action_amount: value.amount + " " + key,
        refund_comment: key,
      };
      data.push(obj);
      tmpAmounts[key] = value.amount;
    }
    setData(data);
    amounts = tmpAmounts;
    setPopupOpen(true);
  }
  
  return (
    <span className={sideBarData.isSidebarToggled ? "batchRefundContainerSmall" : "batchRefundContainer"}>
      <button type="button" onClick={() => populateData()} disabled={Object.keys(selection).length === 0} className="btn txLogBtn">
        <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" />
      </button>

      <Popup modal open={popupOpen || popupFading !== null} closeOnDocumentClick={false}
        className = { `transition ${(popupOpen) ? 'fadeIn in' : 'fadeOut out'}` }>
        <BatchRefundForm key="batchRefundForm" data={data} amounts={amounts} onValueChange={(e) => onValueChange(e)}
          onCommentChange={(e) => onCommentChange(e)} submit={(e) => submitRefund(e)} close={() => toggle()}
        />
      </Popup>
    
      { showConfirmRefund ?
        <CONFIRM show={showConfirmRefund} handleSave={() => requestBatchRefund()} handleClose={() => setShowConfirmRefund(false)}
          title={"Confirm Refund"} confirmText={"refund all"} style={{paddingTop:"300px"}} showBtnLoading={confirmShouldLoad}
        /> : null
      }

      { showAlert ? <AlertScrollable show={showAlert} handleClose={cancelAlert} text1={alertText1} text2={alertText2} title={alertTitle}/> : null }
    </span>
  );
}

const mapStateToProps = state => ({
  sideBarData: state.dashboardReducer
});

const mapDispatchToProps = {
  interfacePaymentCall
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BatchRefund)
);