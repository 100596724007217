import axios from "axios";
import history from "../history";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, parseJwt } from "../lib/constants/utils/token";
import { REFRESH_TOKEN_URL } from "../lib/constants/apiLink";
import cookieUtils from "../lib/constants/utils/cookies";

let isRefreshing = false;
let requestsQueue = [];

const processQueue = (error, token = null) => {
    requestsQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    requestsQueue = [];
};

const apiFactory = baseUrl => {
  const service = axios.create({
    baseURL: baseUrl,
    transformResponse: [
      data => {
        if (typeof data === "string") {
          try {
            data = JSON.parse(data);
          } catch (e) {
            /* Ignore */
          }
        }
        return data;
      }
    ],
  });

  return service;
};

const isAccessTokenExpired = () => {
  const accessToken = cookieUtils.get(ACCESS_TOKEN_KEY);
  if (accessToken) {
    const decodedToken = parseJwt(accessToken);
    return Date.now() >= (decodedToken?.exp ?? 0) * 1000;
  }
  return true; // Assume expired if no token is found
};

const baseService = axios.create();

baseService.interceptors.request.use(config => {
  if (config.url.endsWith('/login')) {
    delete config.headers.Authorization;
  } else {
    const token = cookieUtils.get(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Backoffice ${token}`;
    }
  }

  return config;
}, error => {
  return Promise.reject(error);
});

baseService.interceptors.response.use(
  (response) => response,
  (error) => {
      const originalRequest = error.config;
      if (
          (error?.response?.status === 401 ||
              error?.response?.status === 403) &&
          !originalRequest._retry
      ) {
          if (isRefreshing) {
              // Add request to the queue if a refresh is already in progress
              return new Promise((resolve, reject) => {
                  requestsQueue.push({ resolve, reject });
              })
                  .then((token) => {
                      originalRequest.headers[
                          "Authorization"
                      ] = `Backoffice ${token}`;
                      return baseService(originalRequest);
                  })
                  .catch((err) => {
                      return Promise.reject(err);
                  });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise((resolve, reject) => {
              const refreshToken = cookieUtils.get(REFRESH_TOKEN_KEY);
              if (refreshToken && isAccessTokenExpired()) {
                  axios
                      .post(REFRESH_TOKEN_URL, { token: refreshToken })
                      .then(({ data }) => {
                          cookieUtils.set(ACCESS_TOKEN_KEY, data.token);
                          cookieUtils.set(
                              REFRESH_TOKEN_KEY,
                              data.refresh_token
                          );
                          baseService.defaults.headers.common[
                              "Authorization"
                          ] = `Backoffice ${data.token}`;
                          originalRequest.headers[
                              "Authorization"
                          ] = `Backoffice ${data.token}`;
                          processQueue(null, data.token); // Retry queued requests with the new token
                          resolve(baseService(originalRequest));
                      })
                      .catch((err) => {
                          processQueue(err, null); // Reject queued requests
                          signOut();
                          reject(err);
                      })
                      .finally(() => {
                          isRefreshing = false;
                      });
              } else {
                  signOut();
                  reject(error);
              }
          });
      }

      return Promise.reject(error);
  }
);

const buildHeader = (obj = {}) => {
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  Object.assign(header, obj);
  return header;
};

const createService = (baseUrl, header = {}, params = {}, responseType = 'json') => {
  return {
    ...baseService,
    defaults: {
      ...baseService.defaults,
    },
    baseURL: baseUrl,
    headers: buildHeader(header),
    params: params,
    responseType: responseType,
    transformResponse: [function (data) {
      if (typeof data === 'string') {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
      return data;
    }],
  };
};

export const api = (baseUrl, header = {}, params = {}) => createService(baseUrl, header, params);

export const pdfApi = (baseUrl, header = {}, params = {}) => {
  const service = axios.create({
    baseURL: baseUrl,
    responseType: "blob",
    headers: buildHeader({ ...header, ...{} }),
    params: params,
    validateStatus: status => {
      if (status === 401) {
        (async () => {
          signOut();
        })();
      }
      return true;
    }
  });
  return service;
};

export const api_for_file_download = (baseUrl, header = {}) => {
  const service = axios.create({
    baseURL: baseUrl,
    headers: buildHeader({ ...header, ...{} }),
    responseType: "blob"
  });
  return service;
};

const signOut = () => {
  cookieUtils.remove(ACCESS_TOKEN_KEY);
  cookieUtils.remove(REFRESH_TOKEN_KEY);
  history.push("/");
  window.location.reload();
};

export default apiFactory;
