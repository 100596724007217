export const LAYOUTS = [
  // row 1 (4 tiles)
  //layout 1
  {
    rows: 2,
    row_1: {
      count: 4,
      columns: {
        col_1: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_2: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_3: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_4: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        }
      }
    },
    row_2: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-12 col-lg-5 col-md-12 b-col",
          width: "595",
          height: "442"
        },
        col_3: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        }
      }
    }
  },
  //layout 2
  {
    rows: 3,
    row_1: {
      count: 4,
      columns: {
        col_1: {
          class: "col-md-3",
          width: "375",
          height: "225"
        },
        col_2: {
          class: "col-md-3",
          width: "375",
          height: "225"
        },
        col_3: {
          class: "col-md-3",
          width: "375",
          height: "225"
        },
        col_4: {
          class: "col-md-3",
          width: "375",
          height: "225"
        }
      }
    },
    row_2: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-5 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        }
      }
    },
    row_3: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-5 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        }
      }
    },
    row_4: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-5 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-7",
          width: "875",
          height: "190"
        }
      }
    }
  },
  //layout 3
  {
    rows: 2,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-md-4",
          width: "530",
          height: "225"
        },
        col_2: {
          class: "col-md-4",
          width: "530",
          height: "225"
        },
        col_3: {
          class: "col-md-4",
          width: "530",
          height: "225"
        }
      }
    },
    row_2: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-3",
          width: "375",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-9 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-3",
          width: "375",
          height: "300"
        }
      }
    },
    row_3: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-4",
          width: "375",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-8 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-4",
          width: "375",
          height: "300"
        }
      }
    }
  },
  //layout 4
  {
    rows: 3,

    row_1: {
      count: 4,
      columns: {
        col_1: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_2: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_3: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_4: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_2: {
      count: 2,
      columns: {
        col_1: {
          class: "col-sm-12 col-xs-12 col-md-12 col-lg-7",
          width: "875",
          height: "442px"
        },
        col_2: {
          class: "col-sm-12 col-xs-12 col-md-12 col-lg-5 b-col",
          width: "99%",
          height: "442px"
        }
      }
    },
    row_3: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
  //layout 5
  {
    rows: 2,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_2: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_3: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        }
      }
    },
    row_2: {
      count: 1,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-12 col-md-12",
          width: "375",
          height: "190"
        }
      }
    }
  },
  //layout 6
  {
    rows: 2,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_2: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_3: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        }
      }
    },
    row_2: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-12 col-lg-5 col-md-12 b-col",
          width: "99%",
          height: "442px"
        },
        col_3: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        }
      }
    }
  },
  //layout 7
  {
    rows: 2,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12 flex-box",
          width: "530",
          height: "225"
        },
        col_2: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12 flex-box",
          width: "530",
          height: "225"
        },
        col_3: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12 flex-box",
          width: "530",
          height: "225"
        }
      }
    },
    row_2: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          width: "1590",
          height: "420px"
        }
      }
    }
  },
  //layout 8
  {
    rows: 5,
    row_1: {
      count: 4,
      columns: {
        col_1: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_2: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_3: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        },
        col_4: {
          class: "col-lg-3 col-sm-6 col-xs-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_2: {
      count: 2,
      columns: {
        col_1: {
          class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
          width: "690",
          height: "350"
        },
        col_2: {
          class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
          width: "690",
          height: "350"
        }
      }
    },
    row_3: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_4: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_5: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
  //layout 9
  {
    rows: 4,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_2: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        },
        col_3: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "530",
          height: "225"
        }
      }
    },
    row_2: {
      count: 2,
      columns: {
        col_1: {
          class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
          width: "690",
          height: "350"
        },
        col_2: {
          class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
          width: "690",
          height: "350"
        }
      }
    },
    row_3: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_4: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
  //layout 10
  {
    rows: 5,
    row_1: {
      count: 3,
      columns: {
        col_1: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "500",
          height: "250"
        },
        col_2: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "500",
          height: "250"
        },
        col_3: {
          class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
          width: "500",
          height: "250"
        }
      }
    },
    row_2: {
      count: 2,
      columns: {
        col_1: {
          class: "col-lg-7 col-sm-12 col-xs-12 col-md-12",
          width: "800",
          height: "250"
        },
        col_2: {
          class: "col-lg-5 col-md-12 col-sm-12 col-xs-12",
          width: "600",
          height: "250"
        }
      }
    },
    row_3: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          width: "1575",
          height: "350"
        }
      }
    },

    row_4: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "1575",
          height: "350"
        }
      }
    },
    row_5: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
  //layout 11
  {
    rows: 1,
    row_1: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
          width: "500",
          height: "250"
        }
      }
    }
  },
  //layout 12
  {
    rows: 4,

    row_1: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_2: {
      count: 2,
      columns: {
        col_1: {
          class: "col-lg-6 col-sm-6 col-xs-12",
          width: "690",
          height: "350"
        },
        col_2: {
          class: "col-lg-6 col-sm-6 col-xs-12",
          width: "690",
          height: "350"
        }
      }
    },
    row_3: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    },
    row_4: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
  //layout 13
  {
    rows: 5,
    row_1: {
      count: 4,
      columns: {
        col_1: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "125"
        },
        col_2: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "125"
        },
        col_3: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "125"
        },
        col_4: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "125"
        }
      }
    },
    row_2: {
      count: 4,
      columns: {
        col_1: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_2: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_3: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        },
        col_4: {
          class: "col-lg-3 col-sm-6 col-xs-12 col-md-6 flex-box",
          width: "375",
          height: "225"
        }
      }
    },
    row_3: {
      count: 3,
      columns: {
        col_1: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        },
        col_2: {
          class: "col-xs-12 col-sm-12 col-lg-5 col-md-12 b-col",
          width: "595",
          height: "442"
        },
        col_3: {
          class: "col-xs-12 col-sm-12 col-lg-7 col-md-12",
          width: "875",
          height: "190"
        }
      }
    },
    row_4: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "1575",
          height: "350"
        }
      }
    },
    row_5: {
      count: 1,
      columns: {
        col_1: {
          class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
          width: "375",
          height: "225"
        }
      }
    }
  },
    //layout 14
    {
      rows: 2,
      row_1:  {
        count: 2,
        columns: {
          col_1: {
            class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
            width: "690",
            height: "250"
          },
          col_2: {
            class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
            width: "690",
            height: "250"
          }
        }
      },
      row_2: {
        count: 1,
        columns: {
          col_1: {
            class: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
            width: "1575",
            height: "650"
          }
        }
      }
    },
    // layout 15
    {
      rows: 6,
      row_1: {
        count: 3,
        columns: {
          col_1: {
            class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
            width: "530",
            height: "225"
          },
          col_2: {
            class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
            width: "530",
            height: "225"
          },
          col_3: {
            class: "col-lg-4 col-md-12 col-sm-12 col-xs-12",
            width: "530",
            height: "225"
          }
        }
      },
      row_2: {
        count: 2,
        columns: {
          col_1: {
            class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
            width: "690",
            height: "350"
          },
          col_2: {
            class: "col-lg-6 col-md-12 col-sm-12 col-xs-12",
            width: "690",
            height: "350"
          }
        }
      },
      row_3: {
        count: 1,
        columns: {
          col_1: {
            class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
            width: "375",
            height: "225"
          }
        }
      },
      row_4: {
        count: 1,
        columns: {
          col_1: {
            class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
            width: "375",
            height: "225"
          }
        }
      },
      row_5: {
        count: 1,
        columns: {
          col_1: {
            class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
            width: "375",
            height: "225"
          }
        }
      },
      row_6: {
        count: 1,
        columns: {
          col_1: {
            class: "col-lg-12 col-sm-12 col-xs-12 col-md-12",
            width: "375",
            height: "225"
          }
        }
      }
    },
];

export default LAYOUTS;
