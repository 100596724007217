export const PAYMENT_METHODS = {
  1: "Credit Card",
  2: "WireTransfer",
  11: "Amex",
  12: "QiWi",
  17: "SOFORT",
  18: "Bitcoin",
  19: "SkrillWallet",
  45: "Netteller"
};

export const CURRENCY_CODES = [
  { label: "HKD", value: "HKD" },
  { label: "RUB", value: "RUB" },
  { label: "RSD", value: "RSD" },
  { label: "SEK", value: "SEK" },
  { label: "AUD", value: "AUD" },
  { label: "SGD", value: "SGD" },
  { label: "CAD", value: "CAD" },
  { label: "TRY", value: "TRY" },
  { label: "USD", value: "USD" },
  { label: "UAH", value: "UAH" },
  { label: "EUR", value: "EUR" },
  { label: "CHF", value: "CHF" },
  { label: "ZAR", value: "ZAR" },
  { label: "PHP", value: "PHP" },
  { label: "THB", value: "THB" },
  { label: "GBP", value: "GBP" }
];

export const FIELD_COL_WIDTH = {
  birthday: "3",
  //url_failed: "3",
  country: "3",
  phone: "2",
  language: "3",
  title: "2",
  street: "3",
  lastname: "3",
  state: "3",
  mobile: "3",
  company: "3",
  customerip: "3",
  custom1: "3",
  custom2: "3",
  custom3: "3",
  fax: "3",
  house: "3",
  customerid: "3",
  postbox: "3",
  orderid: "3",
  //url_return: "3",
  email: "3",
  //url_success: "3",
  salutation: "2",
  zip_code: "1",
  firstname: "3",
  gender: "2",
  city: "3",
  amount: "2",
  currency: "2"
};

export const INPUT_FIELD_TYPE = {
  phone: "phone",
  title: "text",
  street: "text",
  lastname: "text",
  state: "text",
  mobile: "phone",
  company: "text",
  customerip: "ip",
  custom1: "alphanumeric",
  custom2: "alphanumeric",
  custom3: "alphanumeric",
  fax: "phone",
  house: "alphanumeric",
  customerid: "alphanumeric",
  postbox: "alphanumeric",
  orderid: "alphanumeric",
  email: "email",
  zip_code: "alphanumeric",
  firstname: "text",
  city: "text",
  amount: "decimal"
};

export const PESRSONAL_DATA_FIELDS = [
  "salutation",
  "title",
  "firstname",
  "lastname",
  "birthday",
  "gender",
  "language"
];

export const CONTACT_DATA_FIELDS = [
  "street",
  "house",
  "zip_code",
  "city",
  "state",
  "country",
  "phone",
  "mobile",
  "fax",
  "email"
];

export const OPTIONAL_FIELDS = [
  "company",
  "customerip",
  "custom1",
  "custom2",
  "custom3",
  "customerid",
  "postbox",
  "orderid"
];

// Fields that should be rendered as input fields.
export const INPUT_FIELDS = [
  "amount",
  "firstname",
  "lastname",
  "email",
  "url_success",
  "url_failed",
  "birthday",
  "phone",
  "title",
  "street",
  "state",
  "mobile",
  "company",
  "customerip",
  "custom1",
  "custom2",
  "city",
  "custom3",
  "fax",
  "house",
  "customerid",
  "postbox",
  "orderid",
  "url_return",
  "zip_code"
];

// Fields that should be rendered as select fields.
export const SELECT_FIELDS = [
  "currency",
  "payment_method",
  "country",
  "language",
  "salutation",
  "gender"
];

export const STATUS_ERRORS = [
  {
    label: "-6150",
    value: "The selected currency is not supported.",
    color: "red"
  },
  { label: "2000", value: "The payment is pending.", color: "green" }
];

export const URLS = {
  url_success:
    "https://sandbox.powercash21.com/testshop/InatecResponse/success.php",
  url_failed:
    "https://sandbox.powercash21.com/testshop/InatecResponse/failed.php",
  //url_return: "http://devpc21n-08.inatec.local:3000/generic/transaction-return",
  url_return:
    "https://sandbox.powercash21.com/testshop/InatecResponse/redirect_response.php"
  //url_return: "http://www.inatec.com/simulation/billing_response.php",
};

// List of all countries in a simple list / array.
export const COUNTRY_LIST = [
  { label: "Afghanistan", value: "AFG" },
  { label: "Albania", value: "ALB" },
  { label: "Algeria", value: "DZA" },
  { label: "American Samoa", value: "ASM" },
  { label: "Andorra", value: "AND" },
  { label: "Angola", value: "AGO" },
  { label: "Anguilla", value: "AIA" },
  { label: "Antarctica", value: "ATA" },
  { label: "Antigua and Barbuda", value: "ATG" },
  { label: "Argentina", value: "ARG" },
  { label: "Armenia", value: "ARM" },
  { label: "Aruba", value: "ABW" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Azerbaijan", value: "AZE" },
  { label: "Bahamas (the)", value: "BHS" },
  { label: "Bahrain", value: "BHR" },
  { label: "Bangladesh", value: "BGD" },
  { label: "Barbados", value: "BRB" },
  { label: "Belarus", value: "BLR" },
  { label: "Belgium", value: "BEL" },
  { label: "Belize", value: "BLZ" },
  { label: "Benin", value: "BEN" },
  { label: "Bermuda", value: "BMU" },
  { label: "Bhutan", value: "BTN" },
  { label: "Bolivia (Plurinational State of)", value: "BOL" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BES" },
  { label: "Bosnia and Herzegovina", value: "BIH" },
  { label: "Botswana", value: "BWA" },
  { label: "Bouvet Island", value: "BVT" },
  { label: "Brazil", value: "BRA" },
  { label: "British Indian Ocean Territory (the)", value: "IOT" },
  { label: "Brunei Darussalam", value: "BRN" },
  { label: "Bulgaria", value: "BGR" },
  { label: "Burkina Faso", value: "BFA" },
  { label: "Burundi", value: "BDI" },
  { label: "Cabo Verde", value: "CPV" },
  { label: "Cambodia", value: "KHM" },
  { label: "Cameroon", value: "CMR" },
  { label: "Canada", value: "CAN" },
  { label: "Cayman Islands (the)", value: "CYM" },
  { label: "Central African Republic (the)", value: "CAF" },
  { label: "Chad", value: "TCD" },
  { label: "Chile", value: "CHL" },
  { label: "China", value: "CHN" },
  { label: "Christmas Island", value: "CXR" },
  { label: "Cocos (Keeling) Islands (the)", value: "CCK" },
  { label: "Colombia", value: "COL" },
  { label: "Comoros (the)", value: "COM" },
  { label: "Congo (the Democratic Republic of the)", value: "COD" },
  { label: "Congo (the)", value: "COG" },
  { label: "Cook Islands (the)", value: "COK" },
  { label: "Costa Rica", value: "CRI" },
  { label: "Croatia", value: "HRV" },
  { label: "Cuba", value: "CUB" },
  { label: "Cura�ao", value: "CUW" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czechia", value: "CZE" },
  { label: "C�te d'Ivoire", value: "CIV" },
  { label: "Denmark", value: "DNK" },
  { label: "Djibouti", value: "DJI" },
  { label: "Dominica", value: "DMA" },
  { label: "Dominican Republic (the)", value: "DOM" },
  { label: "Ecuador", value: "ECU" },
  { label: "Egypt", value: "EGY" },
  { label: "El Salvador", value: "SLV" },
  { label: "Equatorial Guinea", value: "GNQ" },
  { label: "Eritrea", value: "ERI" },
  { label: "Estonia", value: "EST" },
  { label: "Eswatini", value: "SWZ" },
  { label: "Ethiopia", value: "ETH" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FLK" },
  { label: "Faroe Islands (the)", value: "FRO" },
  { label: "Fiji", value: "FJI" },
  { label: "Finland", value: "FIN" },
  { label: "France", value: "FRA" },
  { label: "French Guiana", value: "GUF" },
  { label: "French Polynesia", value: "PYF" },
  { label: "French Southern Territories (the)", value: "ATF" },
  { label: "Gabon", value: "GAB" },
  { label: "Gambia (the)", value: "GMB" },
  { label: "Georgia", value: "GEO" },
  { label: "Germany", value: "DEU" },
  { label: "Ghana", value: "GHA" },
  { label: "Gibraltar", value: "GIB" },
  { label: "Greece", value: "GRC" },
  { label: "Greenland", value: "GRL" },
  { label: "Grenada", value: "GRD" },
  { label: "Guadeloupe", value: "GLP" },
  { label: "Guam", value: "GUM" },
  { label: "Guatemala", value: "GTM" },
  { label: "Guernsey", value: "GGY" },
  { label: "Guinea", value: "GIN" },
  { label: "Guinea-Bissau", value: "GNB" },
  { label: "Guyana", value: "GUY" },
  { label: "Haiti", value: "HTI" },
  { label: "Heard Island and McDonald Islands", value: "HMD" },
  { label: "Holy See (the)", value: "VAT" },
  { label: "Honduras", value: "HND" },
  { label: "Hong Kong", value: "HKG" },
  { label: "Hungary", value: "HUN" },
  { label: "Iceland", value: "ISL" },
  { label: "India", value: "IND" },
  { label: "Indonesia", value: "IDN" },
  { label: "Iran (Islamic Republic of)", value: "IRN" },
  { label: "Iraq", value: "IRQ" },
  { label: "Ireland", value: "IRL" },
  { label: "Isle of Man", value: "IMN" },
  { label: "Israel", value: "ISR" },
  { label: "Italy", value: "ITA" },
  { label: "Jamaica", value: "JAM" },
  { label: "Japan", value: "JPN" },
  { label: "Jersey", value: "JEY" },
  { label: "Jordan", value: "JOR" },
  { label: "Kazakhstan", value: "KAZ" },
  { label: "Kenya", value: "KEN" },
  { label: "Kiribati", value: "KIR" },
  { label: "Korea (the Democratic People's Republic of)", value: "PRK" },
  { label: "Korea (the Republic of)", value: "KOR" },
  { label: "Kuwait", value: "KWT" },
  { label: "Kyrgyzstan", value: "KGZ" },
  { label: "Lao People's Democratic Republic (the)", value: "LAO" },
  { label: "Latvia", value: "LVA" },
  { label: "Lebanon", value: "LBN" },
  { label: "Lesotho", value: "LSO" },
  { label: "Liberia", value: "LBR" },
  { label: "Libya", value: "LBY" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Macao", value: "MAC" },
  { label: "Madagascar", value: "MDG" },
  { label: "Malawi", value: "MWI" },
  { label: "Malaysia", value: "MYS" },
  { label: "Maldives", value: "MDV" },
  { label: "Mali", value: "MLI" },
  { label: "Malta", value: "MLT" },
  { label: "Marshall Islands (the)", value: "MHL" },
  { label: "Martinique", value: "MTQ" },
  { label: "Mauritania", value: "MRT" },
  { label: "Mauritius", value: "MUS" },
  { label: "Mayotte", value: "MYT" },
  { label: "Mexico", value: "MEX" },
  { label: "Micronesia (Federated States of)", value: "FSM" },
  { label: "Moldova (the Republic of)", value: "MDA" },
  { label: "Monaco", value: "MCO" },
  { label: "Mongolia", value: "MNG" },
  { label: "Montenegro", value: "MNE" },
  { label: "Montserrat", value: "MSR" },
  { label: "Morocco", value: "MAR" },
  { label: "Mozambique", value: "MOZ" },
  { label: "Myanmar", value: "MMR" },
  { label: "Namibia", value: "NAM" },
  { label: "Nauru", value: "NRU" },
  { label: "Nepal", value: "NPL" },
  { label: "Netherlands (the)", value: "NLD" },
  { label: "New Caledonia", value: "NCL" },
  { label: "New Zealand", value: "NZL" },
  { label: "Nicaragua", value: "NIC" },
  { label: "Niger (the)", value: "NER" },
  { label: "Nigeria", value: "NGA" },
  { label: "Niue", value: "NIU" },
  { label: "Norfolk Island", value: "NFK" },
  { label: "Northern Mariana Islands (the)", value: "MNP" },
  { label: "Norway", value: "NOR" },
  { label: "Oman", value: "OMN" },
  { label: "Pakistan", value: "PAK" },
  { label: "Palau", value: "PLW" },
  { label: "Palestine, State of", value: "PSE" },
  { label: "Panama", value: "PAN" },
  { label: "Papua New Guinea", value: "PNG" },
  { label: "Paraguay", value: "PRY" },
  { label: "Peru", value: "PER" },
  { label: "Philippines (the)", value: "PHL" },
  { label: "Pitcairn", value: "PCN" },
  { label: "Poland", value: "POL" },
  { label: "Portugal", value: "PRT" },
  { label: "Puerto Rico", value: "PRI" },
  { label: "Qatar", value: "QAT" },
  { label: "Republic of North Macedonia", value: "MKD" },
  { label: "Romania", value: "ROU" },
  { label: "Russian Federation (the)", value: "RUS" },
  { label: "Rwanda", value: "RWA" },
  { label: "R�union", value: "REU" },
  { label: "Saint Barth�lemy", value: "BLM" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
  { label: "Saint Kitts and Nevis", value: "KNA" },
  { label: "Saint Lucia", value: "LCA" },
  { label: "Saint Martin (French part)", value: "MAF" },
  { label: "Saint Pierre and Miquelon", value: "SPM" },
  { label: "Saint Vincent and the Grenadines", value: "VCT" },
  { label: "Samoa", value: "WSM" },
  { label: "San Marino", value: "SMR" },
  { label: "Sao Tome and Principe", value: "STP" },
  { label: "Saudi Arabia", value: "SAU" },
  { label: "Senegal", value: "SEN" },
  { label: "Serbia", value: "SRB" },
  { label: "Seychelles", value: "SYC" },
  { label: "Sierra Leone", value: "SLE" },
  { label: "Singapore", value: "SGP" },
  { label: "Sint Maarten (Dutch part)", value: "SXM" },
  { label: "Slovakia", value: "SVK" },
  { label: "Slovenia", value: "SVN" },
  { label: "Solomon Islands", value: "SLB" },
  { label: "Somalia", value: "SOM" },
  { label: "South Africa", value: "ZAF" },
  { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { label: "South Sudan", value: "SSD" },
  { label: "Spain", value: "ESP" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Sudan (the)", value: "SDN" },
  { label: "Suriname", value: "SUR" },
  { label: "Svalbard and Jan Mayen", value: "SJM" },
  { label: "Sweden", value: "SWE" },
  { label: "Switzerland", value: "CHE" },
  { label: "Syrian Arab Republic", value: "SYR" },
  { label: "Taiwan (Province of China)", value: "TWN" },
  { label: "Tajikistan", value: "TJK" },
  { label: "Tanzania, United Republic of", value: "TZA" },
  { label: "Thailand", value: "THA" },
  { label: "Timor-Leste", value: "TLS" },
  { label: "Togo", value: "TGO" },
  { label: "Tokelau", value: "TKL" },
  { label: "Tonga", value: "TON" },
  { label: "Trinidad and Tobago", value: "TTO" },
  { label: "Tunisia", value: "TUN" },
  { label: "Turkey", value: "TUR" },
  { label: "Turkmenistan", value: "TKM" },
  { label: "Turks and Caicos Islands (the)", value: "TCA" },
  { label: "Tuvalu", value: "TUV" },
  { label: "Uganda", value: "UGA" },
  { label: "Ukraine", value: "UKR" },
  { label: "United Arab Emirates (the)", value: "ARE" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    value: "GBR"
  },
  { label: "United States Minor Outlying Islands (the)", value: "UMI" },
  { label: "United States of America (the)", value: "USA" },
  { label: "Uruguay", value: "URY" },
  { label: "Uzbekistan", value: "UZB" },
  { label: "Vanuatu", value: "VUT" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VEN" },
  { label: "Viet Nam", value: "VNM" },
  { label: "Virgin Islands (British)", value: "VGB" },
  { label: "Virgin Islands (U.S.)", value: "VIR" },
  { label: "Wallis and Futuna", value: "WLF" },
  { label: "Western Sahara", value: "ESH" },
  { label: "Yemen", value: "YEM" },
  { label: "Zambia", value: "ZMB" },
  { label: "Zimbabwe", value: "ZWE" },
  { label: "�land Islands", value: "ALA" }
];

export const SALUTATION_LIST = [
  { label: "Mr", value: "MR" },
  { label: "Mrs", value: "MRS" }
];

export const GENDER_LIST = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" }
];

export const COUNTRY_CODE_3TO2 = {
	"AFG": "AF",
	"ALB": "AL",
	"DZA": "DZ",
	"ASM": "AS",
	"AND": "AD",
	"AGO": "AO",
	"AIA": "AI",
	"ATA": "AQ",
	"ATG": "AG",
	"ARG": "AR",
	"ARM": "AM",
	"ABW": "AW",
	"AUS": "AU",
	"AUT": "AT",
	"AZE": "AZ",
	"BHS": "BS",
	"BHR": "BH",
	"BGD": "BD",
	"BRB": "BB",
	"BLR": "BY",
	"BEL": "BE",
	"BLZ": "BZ",
	"BEN": "BJ",
	"BMU": "BM",
	"BTN": "BT",
	"BOL": "BO",
	"BES": "BQ",
	"BIH": "BA",
	"BWA": "BW",
	"BVT": "BV",
	"BRA": "BR",
	"IOT": "IO",
	"BRN": "BN",
	"BGR": "BG",
	"BFA": "BF",
	"BDI": "BI",
	"CPV": "CV",
	"KHM": "KH",
	"CMR": "CM",
	"CAN": "CA",
	"CYM": "KY",
	"CAF": "CF",
	"TCD": "TD",
	"CHL": "CL",
	"CHN": "CN",
	"CXR": "CX",
	"CCK": "CC",
	"COL": "CO",
	"COM": "KM",
	"COD": "CD",
	"COG": "CG",
	"COK": "CK",
	"CRI": "CR",
	"HRV": "HR",
	"CUB": "CU",
	"CUW": "CW",
	"CYP": "CY",
	"CZE": "CZ",
	"CIV": "CI",
	"DNK": "DK",
	"DJI": "DJ",
	"DMA": "DM",
	"DOM": "DO",
	"ECU": "EC",
	"EGY": "EG",
	"SLV": "SV",
	"GNQ": "GQ",
	"ERI": "ER",
	"EST": "EE",
	"SWZ": "SZ",
	"ETH": "ET",
	"FLK": "FK",
	"FRO": "FO",
	"FJI": "FJ",
	"FIN": "FI",
	"FRA": "FR",
	"GUF": "GF",
	"PYF": "PF",
	"ATF": "TF",
	"GAB": "GA",
	"GMB": "GM",
	"GEO": "GE",
	"DEU": "DE",
	"GHA": "GH",
	"GIB": "GI",
	"GRC": "GR",
	"GRL": "GL",
	"GRD": "GD",
	"GLP": "GP",
	"GUM": "GU",
	"GTM": "GT",
	"GGY": "GG",
	"GIN": "GN",
	"GNB": "GW",
	"GUY": "GY",
	"HTI": "HT",
	"HMD": "HM",
	"VAT": "VA",
	"HND": "HN",
	"HKG": "HK",
	"HUN": "HU",
	"ISL": "IS",
	"IND": "IN",
	"IDN": "ID",
	"IRN": "IR",
	"IRQ": "IQ",
	"IRL": "IE",
	"IMN": "IM",
	"ISR": "IL",
	"ITA": "IT",
	"JAM": "JM",
	"JPN": "JP",
	"JEY": "JE",
	"JOR": "JO",
	"KAZ": "KZ",
	"KEN": "KE",
	"KIR": "KI",
	"PRK": "KP",
	"KOR": "KR",
	"KWT": "KW",
	"KGZ": "KG",
	"LAO": "LA",
	"LVA": "LV",
	"LBN": "LB",
	"LSO": "LS",
	"LBR": "LR",
	"LBY": "LY",
	"LIE": "LI",
	"LTU": "LT",
	"LUX": "LU",
	"MAC": "MO",
	"MDG": "MG",
	"MWI": "MW",
	"MYS": "MY",
	"MDV": "MV",
	"MLI": "ML",
	"MLT": "MT",
	"MHL": "MH",
	"MTQ": "MQ",
	"MRT": "MR",
	"MUS": "MU",
	"MYT": "YT",
	"MEX": "MX",
	"FSM": "FM",
	"MDA": "MD",
	"MCO": "MC",
	"MNG": "MN",
	"MNE": "ME",
	"MSR": "MS",
	"MAR": "MA",
	"MOZ": "MZ",
	"MMR": "MM",
	"NAM": "NA",
	"NRU": "NR",
	"NPL": "NP",
	"NLD": "NL",
	"NCL": "NC",
	"NZL": "NZ",
	"NIC": "NI",
	"NER": "NE",
	"NGA": "NG",
	"NIU": "NU",
	"NFK": "NF",
	"MNP": "MP",
	"NOR": "NO",
	"OMN": "OM",
	"PAK": "PK",
	"PLW": "PW",
	"PSE": "PS",
	"PAN": "PA",
	"PNG": "PG",
	"PRY": "PY",
	"PER": "PE",
	"PHL": "PH",
	"PCN": "PN",
	"POL": "PL",
	"PRT": "PT",
	"PRI": "PR",
	"QAT": "QA",
	"MKD": "MK",
	"ROU": "RO",
	"RUS": "RU",
	"RWA": "RW",
	"REU": "RE",
	"BLM": "BL",
	"SHN": "SH",
	"KNA": "KN",
	"LCA": "LC",
	"MAF": "MF",
	"SPM": "PM",
	"VCT": "VC",
	"WSM": "WS",
	"SMR": "SM",
	"STP": "ST",
	"SAU": "SA",
	"SEN": "SN",
	"SRB": "RS",
	"SYC": "SC",
	"SLE": "SL",
	"SGP": "SG",
	"SXM": "SX",
	"SVK": "SK",
	"SVN": "SI",
	"SLB": "SB",
	"SOM": "SO",
	"ZAF": "ZA",
	"SGS": "GS",
	"SSD": "SS",
	"ESP": "ES",
	"LKA": "LK",
	"SDN": "SD",
	"SUR": "SR",
	"SJM": "SJ",
	"SWE": "SE",
	"CHE": "CH",
	"SYR": "SY",
	"TWN": "TW",
	"TJK": "TJ",
	"TZA": "TZ",
	"THA": "TH",
	"TLS": "TL",
	"TGO": "TG",
	"TKL": "TK",
	"TON": "TO",
	"TTO": "TT",
	"TUN": "TN",
	"TUR": "TR",
	"TKM": "TM",
	"TCA": "TC",
	"TUV": "TV",
	"UGA": "UG",
	"UKR": "UA",
	"ARE": "AE",
	"GBR": "GB",
	"UMI": "UM",
	"USA": "US",
	"URY": "UY",
	"UZB": "UZ",
	"VUT": "VU",
	"VEN": "VE",
	"VNM": "VN",
	"VGB": "VG",
	"VIR": "VI",
	"WLF": "WF",
	"ESH": "EH",
	"YEM": "YE",
	"ZMB": "ZM",
	"ZWE": "ZW",
	"ALA": "AX",
};

export const COUNTRY_CODE_TO_NAME = {
  "AFG": "Afghanistan",
  "ALB": "Albania",
  "DZA": "Algeria",
  "ASM": "American Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarctica",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ABW": "Aruba",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas (the)",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivia (Plurinational State of)",
  "BES": "Bonaire, Sint Eustatius and Saba",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BVT": "Bouvet Island",
  "BRA": "Brazil",
  "IOT": "British Indian Ocean Territory (the)",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CYM": "Cayman Islands (the)",
  "CAF": "Central African Republic (the)",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Christmas Island",
  "CCK": "Cocos (Keeling) Islands (the)",
  "COL": "Colombia",
  "COM": "Comoros (the)",
  "COD": "Congo (the Democratic Republic of the)",
  "COG": "Congo (the)",
  "COK": "Cook Islands (the)",
  "CRI": "Costa Rica",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CUW": "Cura�ao",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "CIV": "C�te d'Ivoire",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic (the)",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FLK": "Falkland Islands (the) [Malvinas]",
  "FRO": "Faroe Islands (the)",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GUF": "French Guiana",
  "PYF": "French Polynesia",
  "ATF": "French Southern Territories (the)",
  "GAB": "Gabon",
  "GMB": "Gambia (the)",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Greece",
  "GRL": "Greenland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island and McDonald Islands",
  "VAT": "Holy See (the)",
  "HND": "Honduras",
  "HKG": "Hong Kong",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran (Islamic Republic of)",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "PRK": "Korea (the Democratic People's Republic of)",
  "KOR": "Korea (the Republic of)",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Lao People's Democratic Republic (the)",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MAC": "Macao",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands (the)",
  "MTQ": "Martinique",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexico",
  "FSM": "Micronesia (Federated States of)",
  "MDA": "Moldova (the Republic of)",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands (the)",
  "NCL": "New Caledonia",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger (the)",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolk Island",
  "MNP": "Northern Mariana Islands (the)",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palestine, State of",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines (the)",
  "PCN": "Pitcairn",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Qatar",
  "MKD": "Republic of North Macedonia",
  "ROU": "Romania",
  "RUS": "Russian Federation (the)",
  "RWA": "Rwanda",
  "REU": "R�union",
  "BLM": "Saint Barth�lemy",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "MAF": "Saint Martin (French part)",
  "SPM": "Saint Pierre and Miquelon",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SXM": "Sint Maarten (Dutch part)",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "SGS": "South Georgia and the South Sandwich Islands",
  "SSD": "South Sudan",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan (the)",
  "SUR": "Suriname",
  "SJM": "Svalbard and Jan Mayen",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syrian Arab Republic",
  "TWN": "Taiwan",
  "TJK": "Tajikistan",
  "TZA": "Tanzania, United Republic of",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TKM": "Turkmenistan",
  "TCA": "Turks and Caicos Islands (the)",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates (the)",
  "GBR": "United Kingdom of Great Britain and Northern Ireland (the)",
  "UMI": "United States Minor Outlying Islands (the)",
  "USA": "United States of America (the)",
  "URY": "Uruguay",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela (Bolivarian Republic of)",
  "VNM": "Viet Nam",
  "VGB": "Virgin Islands (British)",
  "VIR": "Virgin Islands (U.S.)",
  "WLF": "Wallis and Futuna",
  "ESH": "Western Sahara",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe",
  "ALA": "�land Islands"
};
