import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import { getTransData } from "../../../actions/transLogAction";
import ReactTable from "react-table-6";
import selectTableHOC from "react-table-6/lib/hoc/selectTable";
import "react-table-6/react-table.css";
import TransactionLogMobile from "./TransactionLogMobile";
import SubDetails from "./SubDetails";
import Download from "./Download";
import BatchRefund from "./BatchRefund";
import ALERT from "../../general/core/Alert";
import NumberFormat from "react-number-format";
import { interfacePaymentCall } from "../../../actions/transLogAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
//import { SUCCESSFUL_TRANSACTIONS_STATUS, INPROGRESS_TRANSACTIONS_STATUS } from "../../../lib/constants/transactionStatusConstants";
import _ from "lodash";
import TransactionIDCell from "./txlogTableCells/TransactionIDCell";
import BINCountryCell from "./txlogTableCells/BINCountryCell";
import CCNCell from "./txlogTableCells/CCNCell";
import StatusCell from "./txlogTableCells/StatusCell";
import DateCell from "./txlogTableCells/DateCell";
import ErrorCodeCell from "./txlogTableCells/ErrorCodeCell";

//performance test 
//import { columns } from "./TXLogColumns";

class TransactionLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      transactionDetails: {},
      pagination: [],
      totalRecordsCount: 0,
      transLogDataObject: null,
      windowWidth: 0,
      isMobile: false,
      showNoDataAlert: false,
      txDownloadNotAllowed: false,
      selectedTransactions: {},
      selectedTransactions2: [],
      batchRefundTXAmount: {},

      showConfirmRefund: false,
      refundNotValid: false,
      batchRefundRequestSent: false,
      confirmShouldLoad: false,
      
      selection: [],
      allTransactionIDs: [],
      allSelectedTransactions: {},
      allBatchRefundTXAmount: {},
      selectAll: false,
      
      loading: false,
      
      performance: false,
    };
    this.transLogData = null;
    this.mobileWidth = 600;

    this.columns = [];

    this.manageLoadingState = this.manageLoadingState.bind(this);
    this.reload = this.reload.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  handlePageChange = async pageNumber => {
    let requestBody = {};

    let filterBody =
      this.props.transLogData.filterData !== null
        ? this.props.transLogData.filterData
        : {
          orderId: "",
          datetimeFrom: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          datetimeTo: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          transactionId: 0
        };

    if (filterBody.txLogParams && filterBody.txLogParams.txcount) {
      delete filterBody.txLogParams.txcount;
    }

    if (this.state.pagination) {
      filterBody.txLogParams.pageIntervalStart = [
        this.state.pagination[pageNumber][0]
      ];
      filterBody.txLogParams.pageIntervalEnd = [
        this.state.pagination[pageNumber][1]
      ];
    } else {
      if (
        this.props.transLogData &&
        this.props.transLogData.transLogData.pagination &&
        this.state.pagination
      ) {
        filterBody.txLogParams.pageIntervalStart = [
          this.props.transLogData.transLogData.pagination[pageNumber][0]
        ];
        filterBody.txLogParams.pageIntervalEnd = [
          this.props.transLogData.transLogData.pagination[pageNumber][1]
        ];
      }
    }

    filterBody.txLogParams.pageId = [pageNumber];
    await this.props.getTransData(filterBody, requestBody);
    this.setState({ activePage: pageNumber });
  };

  cancelAlert = () => {
    this.setState({ showNoDataAlert: false, txDownloadNotAllowed: false, refundNotValid: false });
  };

  onValueChange = e => {
    e.preventDefault();
    let batchRefundTXAmount = {
    	...this.state.batchRefundTXAmount,
    	[e.target.name]: e.target.value
    }
   
   	this.setState({
      batchRefundTXAmount: batchRefundTXAmount
    });
  };

  handleWindowResize = () => {
    if (window.innerWidth < this.mobileWidth && !this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: true });
    }

    if (window.innerWidth > this.mobileWidth && this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: false });
    }
  };

  manageLoadingState() {
  	
  	let allTransactionIDs = [];
  	let allSelectedTransactions = {};
  	let allBatchRefundTXAmount = {};
  	if (this.transLogData && this.transLogData !== null && !_.isEmpty(this.transLogData)) {
	  	this.transLogData.page.forEach((obj) => {
	  		allTransactionIDs.push(obj.transaction_id);
	  		allSelectedTransactions = {
    		...allSelectedTransactions,
    		[obj.transaction_id]: {
          amount: obj.remaining_to_refund,
          currency: obj.currency,
          merchantid: obj.merchant_id
        }
	    	};
	    	allBatchRefundTXAmount = {
	    		...allBatchRefundTXAmount,
	    		[obj.transaction_id]: obj.remaining_to_refund,
	    	}
	  	});
  	}
  	
    // Compares two transaction log data objects to decide if new data has been fetched.
    // Flags will be setted in Generic to disable loading screen.
    if (this.transLogData) {
      if (this.transLogData && this.transLogData !== this.state.transLogDataObject) {
        this.setState({
        	selectedTransactions: {},
          selectedTransactions2: [],
        	selection: [],
        	transLogDataObject: this.transLogData,
        	loading: false,
        	allTransactionIDs: allTransactionIDs,
        	allSelectedTransactions: allSelectedTransactions,
        	allBatchRefundTXAmount: allBatchRefundTXAmount
        });
        this.props.setMadeFilterChange(false);
      }
    }
  }

  transactionsInLog() {
    if( this.props.transLogData 
      && this.props.transLogData.transLogData 
      && Object.keys(this.props.transLogData.transLogData).length !== 0 )
    {
      // transLogData contains transactions 
      return true;
    }
    else
    {
      return false;
    }	
 	}


  renderTotalCount() {
    if (
      this.props.transLogData &&
      this.props.transLogData.transLogData &&
      this.props.transLogData.transLogData.totalRecordsCount &&
      !isNaN(this.props.transLogData.transLogData.totalRecordsCount)
    ) {
      // eslint-disable-next-line
      this.state.activePage = 1;
      // eslint-disable-next-line
      this.state.totalRecordsCount = this.props.transLogData.transLogData.totalRecordsCount;
      // eslint-disable-next-line
      this.state.pagination = this.props.transLogData.transLogData.pagination;
      return <b>{this.props.transLogData.transLogData.totalRecordsCount}</b>;
    } else {
      return <b>{this.state.totalRecordsCount}</b>;
    }
  }
 
 	getTotalCount() {
		if (
      this.props.transLogData &&
      this.props.transLogData.transLogData &&
      this.props.transLogData.transLogData.totalRecordsCount &&
      !isNaN(this.props.transLogData.transLogData.totalRecordsCount)
    ) {
      // eslint-disable-next-line
      this.state.activePage = 1;
      // eslint-disable-next-line
      this.state.totalRecordsCount = this.props.transLogData.transLogData.totalRecordsCount;
      // eslint-disable-next-line
      this.state.pagination = this.props.transLogData.transLogData.pagination;
      return this.props.transLogData.transLogData.totalRecordsCount;
    } else {
      return this.state.totalRecordsCount;
    }
 	}

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);

    this.populateTXLogColumns();
    //useMemo(() => this.populateTXLogColumns(), this.props.transLogData.transLogData);

    this.setState({
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < this.mobileWidth
    });
  }

  /**
   * updating react-tooltip component after getting dynamic data for transaction-Log table
   */
  componentDidUpdate() {
    this.populateTXLogColumns();
    //useMemo(() => this.populateTXLogColumns(), this.props.transLogData.transLogData);

    const { transLogData } =
      this.props.transLogData.transLogData !== null
        ? this.props.transLogData
        : [];

    if (transLogData) {
      this.transLogData = transLogData;
    }
    this.manageLoadingState();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  renderCell = (row, key) => {
    if (key === "amount" || key === "counter") {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true} />
        </span>
      );
    } else if (key === "transaction_id") {
        return <TransactionIDCell value={row.value} original={row.original} />
    } else if (key === "error_code") {
        return <ErrorCodeCell original={row.original} />
    } else if (key === "date") {
        return <DateCell original={row.original} />
    } else if (key === "transaction_status") {
        return <StatusCell value={row.value} original={row.original} />
    } else if (key === "bin_country") {
  			return <BINCountryCell value={row.value} />
    } else if (key === "ccn_masked" && row.value && row.value !== "N/A") {
        return <CCNCell value={row.value} />
    } else return row.value;
  }

  renderColumn = (label, key, minWidth) => {
    return {
      Header: label,
      accessor: key,
      Cell: row => this.renderCell(row, key),
      minWidth: minWidth,
      headerStyle: { color: "#556486", textAlign: "center", fontWeight: "600", backgroundColor: "white" },
      style: { whiteSpace: "unset", textAlign: "center", color: "#235669" }
    };
  };

  renderArrowExpander = () => {
    return {
      expander: true,
      Header: () => "",
      width: 65,
      Expander: ({ isExpanded, ...rest }) => <>{ isExpanded ? <FontAwesomeIcon icon={faAngleDown} size="xs" /> : <FontAwesomeIcon icon={faAngleRight} size="xs" /> }</>,
      style: { cursor: "pointer", fontSize: 30, padding: "0", textAlign: "center", userSelect: "none", color: "#1E6AF6" }
    };
  }

  populateTXLogColumns = () => {
    const columnsAdmin = {
      transaction_id: ["Transaction ID", 100], merchant_company_id: ["Company", 100], shop_url: ["Shop URL", 100], email: ["Email", 100], 
      mcc: ["MCC", 40], mid: ["MID", 100], bin_country: ["BIN Country", 80], transaction_type: ["Type", 100], order_id: ["Order Id", 100], 
      ccn_masked: ["PAN", 130], date: ["Date", 70], time: ["Time", 60], amount: ["Amount", 70], remaining_to_refund: ["Refundable", 70], 
      currency: ["Currency", 60], transaction_status: ["Status", 60]
    };

    const columnsRefundRemaining = {
      transaction_id: ["Transaction ID", 100], merchant_name: ["Merchant", 100], email: ["Email", 100], bin_country: ["BIN Country", 80], 
      transaction_type: ["Type", 100], order_id: ["Order Id", 100], ccn_masked: ["PAN", 130], date: ["Date", 80], time: ["Time", 70], 
      amount: ["Amount", 90], remaining_to_refund: ["Refundable", 90], currency: ["Currency", 70], transaction_status: ["Status", 70]
    };

    const columns = {
      transaction_id: ["Transaction ID", 100], merchant_name: ["Merchant", 100], email: ["Email", 100], bin_country: ["BIN Country", 80], 
      transaction_type: ["Type", 100], order_id: ["Order Id", 100], ccn_masked: ["PAN", 130], date: ["Date", 100], time: ["Time", 80], 
      amount: ["Amount", 100], currency: ["Currency", 100], transaction_status: ["Status", 100]
    };

    var tmpObj = this.props.user_permissions["is_admin"] ? columnsAdmin : 
      this.props.user_permissions["allow_user_remaining_to_refund_column"] ? columnsRefundRemaining : columns;

    this.columns = [];
    this.columns.push(this.renderArrowExpander());

    for (const [k, v] of Object.entries(tmpObj)) {
      this.columns.push(this.renderColumn(v[0], k, v[1]));
    }
  }

  async reload() {
    let filterBody = this.props.transLogData.filterData !== null ? this.props.transLogData.filterData
       : {
           orderId: "",
           datetimeFrom: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
           datetimeTo: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
           transactionId: 0
         };
   
   await this.props.getTransData(filterBody, {});
   this.manageLoadingState();
  }
 
 	toggleSelection(key, t, value) {
 		let id = key.substring(7, key.length);
   
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(id);
    
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(id);
    }
    
    let selectedTransactions = {...this.state.selectedTransactions};
    let batchRefundTXAmount = {...this.state.batchRefundTXAmount};
    const isInObject = this.state.selectedTransactions.hasOwnProperty(id);
    
    if (isInObject) {
    	delete selectedTransactions[id];
    	delete batchRefundTXAmount[id];
    } else {
    	selectedTransactions = {
    		...selectedTransactions,
    		[id]: {
          amount: value.remaining_to_refund,
          currency: value.currency,
          merchantid: value.merchant_id
        }
    	};
    	batchRefundTXAmount = {
    		...batchRefundTXAmount,
    		[id]: value.remaining_to_refund,
    	}
    }
   
   	this.setState({
      selectedTransactions: selectedTransactions,
      batchRefundTXAmount: batchRefundTXAmount,
      selection: selection
    });
 	}
 
 	toggleAll(key, t, value) {
 		if (!this.state.selectAll) {
	 		this.setState({
	 			selection: this.state.allTransactionIDs,
	 			selectedTransactions: this.state.allSelectedTransactions,
	 			batchRefundTXAmount: this.state.allBatchRefundTXAmount,
	 			selectAll: true
	 		});
 		} else {
 			this.setState({
	 			selection: [],
	 			selectedTransactions: {},
	 			batchRefundTXAmount: {},
	 			selectAll: false
	 		});
 		}
 	}
 
 	isSelected(key) {
    return this.state.selection.includes(key);
 	}
 
 	// performance testing
 	/*columnstest() { 
 		return useMemo( 
	 		() =>
				[
			 		{accessor:"transaction_id", Header:"Transaction ID"},
			 		{accessor:"merchant_name", Header:"Merchant"},
			 		{accessor:"bin_country", Header:"BIN Country"},
			 		{accessor:"transaction_type", Header:"Type"},
			 		{accessor:"order_id", Header:"Order Id"},
			 		{accessor:"ccn_masked", Header:"PAN"},
			 		{accessor:"date", Header:"Date"},
			 		{accessor:"time", Header:"Time"},
			 		{accessor:"amount", Header:"Amount"},
			 		{accessor:"remaining_to_refund", Header:"Refundable"},
			 		{accessor:"currency", Header:"Currency"},
			 		{accessor:"transaction_status", Header:"Status"},
			 	], 
			[this.state.performance]
		);
	}*/

  render() {
  	const SelectTable = selectTableHOC(ReactTable);
  	const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
  	
  	const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      keyField: "transaction_id",
      getTrProps: (s, r) => {
        //const selected = this.isSelected(r.original._id);
        return {
          style: {
            //maxWidth: "40px",
            //width: "40px",
            //backgroundColor: selected ? "lightgreen" : "inherit",
            // color: selected ? 'white' : 'inherit',
          }
        };
      }
    };
  	
    return (
      <div className={!this.state.isMobile ? "box2" : ""} style={ !this.state.isMobile ? {} : {margin:"-10px"} }>

       	<div className="tl-table-heading">
          <div style={{display:"inline-block"}}>
          	<h4 style={{marginTop:"0px", marginBottom:"0px"}}>
              Transaction Log
              { this.transactionsInLog() === true ? 
                (
                  <span style={{fontSize:"14px", paddingLeft:"10px"}}>
                  ({(this.state.activePage - 1) * 100 + 1} -{" "}{this.getTotalCount() < 100 ? this.renderTotalCount() : this.state.activePage * 100} of {this.renderTotalCount()} results)
                  </span>
                ) : (
                  <span style={{fontSize:"14px", paddingLeft:"10px"}}>
                  (0 results)
                  </span>
                )
              }
          	</h4>
        	</div>
        	<div style={{display:"inline-block", width:"4%"}}></div>
        	<div style={{display:"inline-block"}}>
	         	{ (this.props.user_permissions && this.props.user_permissions.allow_user_download_transaction_log) &&
			         	<Download 
                  filter={this.props.transLogData.filterData}
                  type={"log"}
                  records={this.getTotalCount()}
                  disabled={!this.props.transLogData.transLogData || !this.props.transLogData.transLogData.page} />
	         	}
	         	{
	         		this.props.user_permissions["allow_user_remaining_to_refund_column"] &&
	         			<BatchRefund 
                  selectedTransactions={this.state.selectedTransactions}
                  batchRefundTXAmount={this.state.batchRefundTXAmount}
                  onValueChange={this.onValueChange}
                  disabled={Object.keys(this.state.selectedTransactions).length === 0 && this.state.selectedTransactions.constructor === Object}
                  reload={this.reload} />
	         	}
	         	{ (this.props.user_permissions && (this.props.user_permissions.allow_user_download_transaction_break_down
                                           || this.props.user_permissions.allow_user_download_transaction_break_down_mdr) ) &&
			         	<Download 
                  filter={this.props.transLogData.filterData}
                  type={"breakdown"}
                  shouldShowCompactButton={true}
                  disabled={!this.props.transLogData.transLogData || !this.props.transLogData.transLogData.page} />
	         	}
          </div>
        </div>

        { this.props.transLogData.transLogData && this.props.transLogData.transLogData.page ? (
          <div>
            { !this.state.isMobile ? (
              <div id="txLogTable">
                <SelectTable
                	loading={this.state.loading}
                  minRows={5}
                  className="-highlight"
                  style={{border: "none"}}
                  pageSize={100}
                  data={this.props.transLogData.transLogData ? this.props.transLogData.transLogData.page : []}
                  columns={this.columns}
                  showPagination={false}
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  getTrProps={(state, rowInfo) => {
									  if (rowInfo && rowInfo.row) {
									  	//let isGreen = SUCCESSFUL_TRANSACTIONS_STATUS.includes(rowInfo.row._original.transaction_status);
									  	//let isBlue = INPROGRESS_TRANSACTIONS_STATUS.includes(rowInfo.row._original.transaction_status) || rowInfo.row._original.error_code === "2000";
									  	//let rowColor = isGreen ? "#EEFFF4" : isBlue ? "#F4F6FB" : "#FBE2E2";
									  	//let rowSelectedColor = isGreen ? "rgb(174, 255, 202)" : isBlue ? "rgb(208, 222, 255)" : "rgb(255, 186, 186)";
									    return {
									      onClick: (e) => {},
									      style: {
									        //background: this.isSelected(rowInfo.original.transaction_id) ? rowSelectedColor : rowColor
                          background: this.isSelected(rowInfo.original.transaction_id) ? "#d1e7f4" : "white",
                          height: "65px",
                          //padding:"19px 0px"
									      }
									    }
									  } else return {}
									}}
                  SubComponent={row => <SubDetails row={row}/>}
                  {...checkboxProps}
                />
              </div>
            ) : (
              <TransactionLogMobile columns={this.columns} data={this.props.transLogData.transLogData.page}
                activePage={this.state.activePage} />
            )}
          </div>
        ) : (
          <div className="noDataFound-style" style={{ paddingTop: "5%", paddingBottom: "5%", fontSize: "medium", top:"200px" }}>
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            {" "} There are currently no orders. {" "}
          </div>
        )}

        {/* TABLE PAGINATION */}
        { (this.props.transLogData.transLogData && this.props.transLogData.transLogData.page) &&
          <div style={{ textAlign: "center" }}>
            <Pagination
              firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
              lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
              prevPageText={<i className="glyphicon glyphicon-menu-left" />}
              nextPageText={<i className="glyphicon glyphicon-menu-right" />}
              pageRangeDisplayed={10}
              activePage={this.state.activePage ? this.state.activePage : 1}
              itemsCountPerPage={100}
              totalItemsCount={this.state.totalRecordsCount ? this.state.totalRecordsCount : 5}
              onChange={this.handlePageChange} />
          </div>
        }

        { this.state.showNoDataAlert && <ALERT show={this.state.showNoDataAlert} handleClose={this.cancelAlert} text="No transaction is found to download." /> }
        { this.state.txDownloadNotAllowed && <ALERT show={this.state.txDownloadNotAllowed} handleClose={this.cancelAlert} text="Transaction Log download is not allowed." /> }
        { this.state.refundNotValid && <ALERT show={this.state.refundNotValid} handleClose={this.cancelAlert} text={"Given amount is not valid!"} /> }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transLogData: state.transLogReducer
  // filterData: state.transLogReducer,
});

const mapDispatchToProps = {
  getTransData,
  interfacePaymentCall
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionLog)
);