import React from 'react';

const PaymentLinkStatus = ({ status }) => {
    var color = "";
    var value = "Created";
    switch (status) {
        case 1: color = "#2e80b2"; value = "Created"; break;
        case 2: color = "#9c0000"; value = "Error"; break;
        case 3: color = "black"; value = "Expired"; break;
        case 4: color = "red"; value = "Failed"; break;
        case 5: color = "black"; value = "Pending"; break;
        case 6: color = "black"; value = "Viewed"; break;
        case 7: color = "green"; value = "Paid"; break;
        case 8: color = "red"; value = "Cancelled"; break;
        default: color= "black"; value = status;
    }

    return (
        <div style={{padding:"15px 0px"}}>
            <div style={{backgroundColor:color, width: "7px", height: "7px", display: "inline-block", borderRadius: "50%", marginRight: "5px", marginBottom: "1px"}}></div>
            <span style={{color:color}}>{value}</span>
        </div>
    );
};

export default PaymentLinkStatus;