import * as style from "../style/style";
import React from "react";
import NumberFormat from "react-number-format";

export const chargebacks_columns = [
  {
    Header: "Post Date",
    accessor: "post_date",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Mids",
    accessor: "mid",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    },
    minWidth: 150
  },
  {
    Header: "Company",
    accessor: "pc21_company",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  },
  {
    Header: "Amount",
    accessor: "amount",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black"
    },
    Cell: row => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    }
  },
  {
    Header: "ARN",
    accessor: "arn",
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor
    }
  }
  //,
  // {
  //   Header: "Tran. Type",
  //   accessor: "tran_type",
  //   headerStyle: {
  //     backgroundColor: style.headerBackGroundColor,
  //     color: style.headertextColor,
  //     textAlign: style.headertextAlign,
  //     borderTop: style.headerborderTop,
  //     borderBottom: style.headerborderBottom
  //   },
  //   style: {
  //     whiteSpace: "unset",
  //     textAlign: style.headertextAlign,
  //     backgroundColor: style.tableBackgroundColor
  //   }
  // }
];

export default {
  chargebacks_columns
};
