import React, { PureComponent } from "react";
import { ResponsiveContainer, PieChart, Pie, Sector, Cell } from "recharts";

const DataFormater = number => {
  if (number > 1000000000) {
    return (number / 1000000000).toFixed(2).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(2).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toFixed(2).toString() + "K";
  } else {
    return number.toFixed(2).toString();
  }
};
const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.ps_name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="orange" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${DataFormater(value)}€`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class PieCharts extends PureComponent {
  state = {
    activeIndex: 0
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    let meta_data =
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id] &&
      this.props.data.barChartData[this.props.chart_id].meta_data
        ? this.props.data.barChartData[this.props.chart_id].meta_data
        : [];
    let pieBarsData = [];
    const CHART_COLORS = [
      "#3D88B6",
      "#00C49F",
      "#f39b12",
      "#FF8042",
      "#08ab0f",
      "#e20c0c",
      "#ab089c",
      "#3f51b5"
    ];
    if (
      this.props &&
      this.props.data &&
      this.props.data.barChartData &&
      this.props.data.barChartData[this.props.chart_id] &&
      this.props.data.barChartData[this.props.chart_id].data
    ) {
      this.props.data.barChartData[this.props.chart_id].data.map((item, i) => {
        delete item[`${meta_data.yVal2}`];
        pieBarsData.push(item);
        return null;
      });
    }
    return (
      <div style={{ width: "99%", height: "442px" }}>
        <ResponsiveContainer width="99%" height="99%">
          <PieChart>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={pieBarsData}
              innerRadius={100}
              outerRadius={140}
              fill="#2E80B2"
              dataKey={meta_data ? meta_data.yVal1 : ""}
              onMouseEnter={this.onPieEnter}
            >
              {pieBarsData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
