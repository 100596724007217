import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { CHART_COLORS } from "../../../lib/constants/chartColors";
import * as helper from "../core/helper";
import { BarLoader } from "react-spinners";

const LineCharts = ({ height, width, ...props }) => {
  const { data, meta_data } = props.data.data ? props.data : [];

  let xLines = meta_data ? Object.keys(meta_data) : [];
  xLines = xLines.filter(item => {
    return item !== "xVal";
  });

  let report_format = 0;
  let nestedRows = [];
  let total_rows = [];
  if (data && !Array.isArray(data)) {
    report_format = 1;
    xLines = data && data.columns ? data.columns : [];
    nestedRows = [];
    if (data && data.rows) {
      for (let k1 in data.rows) {
        if (k1 !== "total") {
          total_rows.push(k1);

          if (data.rows[k1].total) {
            data.rows[k1].total.name = k1;
            nestedRows.push(data.rows[k1].total);
          }
          for (let k2 in data.rows[k1]) {
            if (k2 !== "total") {
              data.rows[k1][k2].name = "• " + k2;
              nestedRows.push(data.rows[k1][k2]);
            }
          }
        }
      }
    }

    if (nestedRows) {
      sortByMonth(nestedRows);
    }
  }

  if (data && typeof data.sort === "function") {
    data.sort(function(a, b) {
      if (a.date) {
        let aComps = a.date.split(".");
        let bComps = b.date.split(".");

        var aDate = new Date(aComps[2], aComps[1], aComps[0]);
        var bDate = new Date(bComps[2], bComps[1], bComps[0]);
        return aDate - bDate;
      } else {
        return null;
      }
    });
  }

  if (data && typeof data.sort === "function") {
    sortByMonth(data);
  }
  function sortByMonth(arr) {
    var months = [
      "Jan-2018",
      "Feb-2018",
      "Mar-2018",
      "Apr-2018",
      "May-2018",
      "Jun-2018",
      "Jul-2018",
      "Aug-2018",
      "Sep-2018",
      "Oct-2018",
      "Nov-2018",
      "Dec-2018",
      "Jan-2019",
      "Feb-2019",
      "Mar-2019",
      "Apr-2019",
      "May-2019",
      "Jun-2019",
      "Jul-2019",
      "Aug-2019",
      "Sep-2019",
      "Oct-2019",
      "Nov-2019",
      "Dec-2019",
      "Jan-2020",
      "Feb-2020",
      "Mar-2020",
      "Apr-2020",
      "May-2020",
      "Jun-2020",
      "Jul-2020",
      "Aug-2020",
      "Sep-2020",
      "Oct-2020",
      "Nov-2020",
      "Dec-2020",
      "Jan-2021",
      "Feb-2021",
      "Mar-2021",
      "Apr-2021",
      "May-2021",
      "Jun-2021",
      "Jul-2021",
      "Aug-2021",
      "Sep-2021",
      "Oct-2021",
      "Nov-2021",
      "Dec-2021",
      "Jan-2022",
      "Feb-2022",
      "Mar-2022",
      "Apr-2022",
      "May-2022",
      "Jun-2022",
      "Jul-2022",
      "Aug-2022",
      "Sep-2022",
      "Oct-2022",
      "Nov-2022",
      "Dec-2022",
      "Jan-2023",
      "Feb-2023",
      "Mar-2023",
      "Apr-2023",
      "May-2023",
      "Jun-2023",
      "Jul-2023",
      "Aug-2023",
      "Sep-2023",
      "Oct-2023",
      "Nov-2023",
      "Dec-2023"
    ];
    arr.sort(function(a, b) {
      return months.indexOf(a.month_year) - months.indexOf(b.month_year);
    });
  }

  // fetching chart type to determine which unit will be used
  let unit = xLines && xLines[0] ? helper.renderUnit(xLines[0]) : "";

  if (props.data && Object.keys(props.data).length === 0 && props.data.length !== 0) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  } else {
    if (total_rows.length !== 0) {
      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
	      	<ResponsiveContainer width="99%" height="99%">
		        <LineChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={report_format === 1 ? nestedRows : data}
		          margin={{
		            top: 20,
		            right: 30,
		            left: 0,
		            bottom: 5
		          }}
		        >
		          {report_format === 1 ? (
		            <CartesianGrid vertical={false} />
		          ) : (
		            <CartesianGrid strokeDasharray="3 3" />
		          )}
		          {/* <XAxis dataKey="name" /> */}
		          {report_format === 1 ? (
		            <XAxis dataKey={`${report_format === 1 ? "name" : ""}`} />
		          ) : (
		            <XAxis dataKey={`${meta_data ? meta_data.xVal : ""}`} />
		          )}
		          <YAxis
		            type="number"
		            unit={unit}
		            tickFormatter={helper.DataFormater}
		          />
		          {/* <Tooltip content={({payload}) => (<div>{JSON.stringify(payload[0])}</div>)} isAnimationActive={true} /> */}
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		            labelFormatter={helper.renderLabelToolTip}
		          />
		
		          {/* <Legend />*/}
		          {/* <Line type="monotone" dataKey={`${meta_data ? meta_data.yVal1 : ""}`} stroke="#82ca9d" activeDot={{r: 6}} />
		                <Line type="monotone" dataKey={`${meta_data ? meta_data.yVal2 : ""}`} stroke="#8884d8" activeDot={{r: 6}} /> */}
		          {report_format &&
		            report_format === 0 &&
		            xLines &&
		            xLines.map((yAxis, index) => {
		              return (
		                <Line
		                  key={index}
		                  type="monotone"
		                  dataKey={`${meta_data ? meta_data[yAxis] : ""}`}
		                  stroke={CHART_COLORS[index]}
		                  activeDot={{ r: 6 }}
		                  dot={{ stroke: "#2E80B2", strokeWidth: 1 }}
		                  strokeWidth="2"
		                />
		              );
		            })}
		
		          {report_format &&
		            report_format === 1 &&
		            xLines &&
		            xLines.map((yAxis, index) => {
		              return (
		                <Line
		                  key={index}
		                  type="monotone"
		                  dataKey={xLines[index]}
		                  stroke={CHART_COLORS[index]}
		                  dot={{ stroke: "#2E80B2", strokeWidth: 2 }}
		                  strokeWidth="3"
		                  activeDot={{ r: 6 }}
		                />
		              );
		            })}
		        </LineChart>
	        </ResponsiveContainer>
        </div>
      );
    } else if (
      props.data &&
      Array.isArray(props.data) &&
      props.data.length !== 0
    ) {
      let received_data = props.data;
      let headers = received_data[0];
      // get axis keys
      var axisKeys = [];
      var minMaxKeys = [];

      if (received_data && typeof received_data.sort === "function") {
        received_data.sort(function(a, b) {
          if (a.date) {
            let aComps = a.date.split(".");
            let bComps = b.date.split(".");

            var aDate = new Date(aComps[2], aComps[1], aComps[0]);
            var bDate = new Date(bComps[2], bComps[1], bComps[0]);
            return aDate - bDate;
          } else {
            return null;
          }
        });
      }

      if (received_data && typeof received_data.sort === "function") {
        sortByMonth(received_data);
      }

      Object.keys(headers).forEach(function(key) {
        axisKeys.push(key);
      });

      Object.keys(received_data).forEach(function(key) {
        minMaxKeys.push(received_data[key][axisKeys[1]]);
      });

      var min = Math.min.apply(null, minMaxKeys),
        max = Math.max.apply(null, minMaxKeys);

      if (max === 0) {
        max = max + 6;
      }
      //max = Math.round(max);
      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
	      	<ResponsiveContainer width="99%" height="99%">
		        <LineChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={received_data}
		          margin={{
		            top: 20,
		            right: 30,
		            left: 0,
		            bottom: 5
		          }}
		        >
		          <CartesianGrid vertical={false} />
		
		          <XAxis dataKey={axisKeys[0]} />
		
		          {max <= 100 ? (
		            <YAxis
		              type="number"
		              unit={unit}
		              tickFormatter={helper.DataFormater}
		              domain={[min, max]}
		            />
		          ) : (
		            <YAxis
		              type="number"
		              unit={unit}
		              tickFormatter={helper.DataFormater}
		            />
		          )}
		
		          <Line
		            key={"line_0"}
		            dataKey={axisKeys[1]}
		            stroke="#5d98cf"
		            strokeWidth="3"
		          />
		          {/* <Tooltip content={({payload}) => (<div>{JSON.stringify(payload[0])}</div>)} isAnimationActive={true} /> */}
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		          />
		        </LineChart>
	        </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="noDataFound-style">
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            No data was found! 
          </h4>
        </div>
      );
    }
  }
};

export default LineCharts;
