import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../../actions/userAction";

import * as Yup from "yup";
import { Formik } from "formik";
import { ClipLoader } from "react-spinners";

/**
 * Schema define for validation
 */
const schema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

/**
 *  ResetPwd
 *  Component to handle email for reset password.
 */
class ResetPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { email: "" },
      errors: {},
      users: null,
      message: null,
      email_sent: false,
      error: false,
      loading: false,
    };
  }
  /**
   * It is a function reset password api action call.
   */
  resetPwd = async () => {
    let email = this.state.fields;
    if (this.handleValidation()) {
      await this.props.resetPassword(email);
      if (this.props.loginData.resetPassword) {
        const resetPassword = this.props.loginData.resetPassword;
        if (resetPassword.detail) {
          this.setState({
            message: resetPassword.detail,
            error: false,
            loading: true,
          });

          setTimeout(() => {
            this.setState({
              email_sent: true,
              loading: false,
            });
          }, 2000);

          // setTimeout(()=>{
          //   this.setState({
          //   email_sent: true
          //   });
          //   this.props.history.push('/conf-pass');
          // }, 1000);
        } else {
          this.setState({
            message: resetPassword.email[0],
            email_sent: false,
            error: true,
          });
        }
      }
    }
  };

  /**
   * Its a function, handling form values
   * @param {string} field - form fields
   */
  handleEmail = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  successMessage = () => {
    if (this.state.error) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.state.message}
        </div>
      );
    } else {
      return (
        <div className="alert alert-success" role="alert">
          {this.state.message}
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <Formik
          initialValues={this.state.fields}
          onSubmit={(values) => {
            // api call
            this.setState({
              fields: values,
            });
            this.resetPwd();
          }}
          validationSchema={schema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              // isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <div className="login-box">
                <div className="login-box-body">
                  <div className="login-logo">
                    <img
                      src="/img/payabl_white_main.svg"
                      alt="Powercash21"
                      width={200}
                    />
                  </div>

                  {this.state.message && this.state.error ? (
                    <span> {this.successMessage()} </span>
                  ) : null}

                  {this.state.message &&
                  !this.state.error &&
                  this.state.email_sent ? (
                    <span> {this.successMessage()} </span>
                  ) : null}

                  {this.state.loading ? (
                    <div style={{ textAlign: "center", paddingTop: "20px" }}>
                      {" "}
                      <ClipLoader color={"rgb(46, 128, 178)"} size={80} />{" "}
                    </div>
                  ) : null}

                  {!this.state.email_sent && !this.state.loading ? (
                    <form onSubmit={handleSubmit} name="resetPwdForm">
                      <div className="form-group has-feedback">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email..."
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="glyphicon glyphicon-envelope form-control-feedback" />
                        {errors.email && touched.email && (
                          <div
                            className="input-feedback"
                            style={{ color: "red" }}
                          >
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                          style={{ backgroundColor: "#55BD8B" }}
                        >
                          Send
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-xs-6">
                          <Link
                            to="/login"
                            className="footer-link"
                            style={{ color: "#7cddae" }}
                          >
                            Go to Login
                          </Link>
                        </div>
                      </div>
                    </form>
                  ) : null}
                  <br />
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: state.loginData,
});

const mapDispatchToProps = {
  resetPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPwd)
);
