import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import { ACCESS_TOKEN_KEY } from "../lib/constants/utils/token";
import * as chargebackAlertService from "../services/chargebackAlert";
import axios from "axios";

const _getChargebackAlerts = (data) => ({
    type: actionTypes.CHARGEBACK_ALERTS,
    payload: data,
});

const _getChargebackAlertsFilters = (data) => ({
    type: actionTypes.CHARGEBACK_ALERTS_FILTERS,
    payload: data,
});

const _getChargebackPreventionRules = (data) => ({
    type: actionTypes.CHARGEBACK_PREVENTION_RULES,
    payload: data,
});

const _getAllMerchants = (data) => ({
    type: actionTypes.ALL_MERCHANTS,
    payload: data,
});

const _downloadChargebackDownData = (data) => ({
    type: actionTypes.CHARGEBACK_RULES_EXPORT_DOWNLOAD,
    headers: data.headers,
    payload: data.data,
});

export const getChargebackAlerts = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    return (dispatch) => {
        let token = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };

        return chargebackAlertService
            .getChargebackAlerts(token, params, cancelToken)
            .then((res) => {
                if (res.data) {
                    return dispatch(_getChargebackAlerts(res.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response);
                }
            });
    };
};

export const getChargebackAlertsFilters = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    return (dispatch) => {
        let token = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };

        return chargebackAlertService
            .getChargebackAlertsFilters(token, params, cancelToken)
            .then((res) => {
                if (res.data) {
                    return dispatch(_getChargebackAlertsFilters(res.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response);
                }
            })
    }
}

export const getChargebackPreventionRules = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    return (dispatch) => {
        let token = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };
        return chargebackAlertService
            .getChargebackPreventionRules(token, params, cancelToken)
            .then((res) => {
                if (res.data) {
                    return dispatch(_getChargebackPreventionRules(res.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response);
                }
            });
    };
};

export const getAllMerchants = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    return (dispatch) => {
        let token = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };

        return chargebackAlertService
            .getAllMerchants(token, params, cancelToken)
            .then((res) => {
                if (res.data) {
                    return dispatch(_getAllMerchants(res.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response);
                }
            });
    };
};

export const postChargeBack = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };
    return chargebackAlertService
        .addChargeBack(token, params, cancelToken)
        .then((res) => {
            console.log(res);
        })

        .catch((error) => {
            if (error.response) {
                console.error(error.response);
            }
        });
};

export const updateChargeBack = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };
    return chargebackAlertService
        .updateChargeBack(token, params, cancelToken)
        .catch((error) => {
            if (error.response) {
                console.error(error.response);
            }
        });
};

export const deleteRule = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };
    return chargebackAlertService
        .deleteChargeback(token, params, cancelToken)
        .catch((error) => {
            if (error.response) {
                console.error(error.response);
            }
        });
};

export const downloadChargebackBreakDownData = (req) => {
    return (dispatch) => {
        let token = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };

        return chargebackAlertService
            .exportDownloadChargeback(token, req)
            .then((res) => {
                if (res.data) {
                    let responseData = {};
                    responseData.headers = res.headers;
                    responseData.data = res.data;
                    return dispatch(_downloadChargebackDownData(responseData));
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    alert("Error in chargeback export: ", error.response);
                }
            });
    };
};

export const chargebackDecisionAction = (
    params,
    cancelToken = axios.CancelToken.source()
) => {
    let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };
    return chargebackAlertService
        .chargeBackAlertAction(token, params, cancelToken)
        .catch((error) => {
            if (error.response) {
                console.error(error.response);
            }
        });
};
