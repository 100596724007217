import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

const FormNotification = ({ show, text, top, right, callback = () => {console.log("")}, ...props }) => {
  const MIL_SECONDS_OPEN = 1000;
  const MIL_SECONDS_FADE = 100;

  useEffect(() => {
    let isMounted = true;
    setTimeout(function() {
      if (isMounted) {
        callback();
      }
    }, MIL_SECONDS_OPEN + MIL_SECONDS_FADE);
    return () => { isMounted = false };
  })

  let classProps = "formNotification sb9 formNotificationTransition";
  //let classProps = "formNotificationTransition";
  
  return (
    <div>
      <CSSTransition in={show} className={classProps} timeout={200} unmountOnExit>
        <div style={{top: top, right: right}}>
          <FontAwesomeIcon icon={faCheckCircle}/>{" "}{text}
        </div>
      </CSSTransition>
    </div>
  );
}

export default FormNotification;