import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as dashBoardService from "../services/dashboard";

const dashboardSucceeded = data => ({
  type: actionTypes.DASHBOARD,
  payload: data
});

const _sideBarMenu = data => ({
  type: actionTypes.SIDE_BAR_MENU,
  payload: data
});

const _sideBarMenuToggle = data => ({
  type: actionTypes.SIDE_BAR_MENU_TOGGLE,
  payload: data
});

const _getData = data => ({
  type: actionTypes.GET_DATA,
  payload: data
});

export const dashboard = () => {
  return dispatch => {
    return dispatch(dashboardSucceeded({}));
  };
};

export const sideBarMenuToggle = data => {
  return dispatch => {
    return dispatch(_sideBarMenuToggle({ data }));
  };
};

export const getPermissions = () => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return dashBoardService
      .getPermissions(token)
      .then(res => {
        if (res.data.data) {
          return dispatch(_sideBarMenu(res.data.data));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const getData = () => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return dashBoardService
      .getData(token)
      .then(res => {
        return dispatch(_getData(res.data));
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};
