import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { getPartnerMerchantsData } from "../../../actions/partnerMerchantsAction";

class PartnerMerchants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh_token: "",
            partnerMerchantsData: [],
            shouldGetData: false
        };

        this.isDataLegit = false;
    }

    async getPartnerMerchantsData() {
        await this.props.getPartnerMerchantsData({});

        this.isDataLegit = this.props.partnerMerchantsReducer.partnerMerchantsData && Array.isArray(this.props.partnerMerchantsReducer.partnerMerchantsData.data);

        this.setState({
            partnerMerchantsData: this.isDataLegit ? this.props.partnerMerchantsReducer.partnerMerchantsData.data : [],
        });
    }

    renderPaymentLinkColumn(label, key, minWidth) {
        return {
            Header: label,
            accessor: key,
            minWidth: minWidth,
            headerStyle: {
                textAlign: "center",
                fontWeight: "600",
                padding: "10px 0px"
            },
            style: {
                whiteSpace: "unset",
                textAlign: "center",
                color: "#235669",
                padding: "10px 0px"
            }
        };
    };

    renderPaymentLinkColumns() {
        return [
            this.renderPaymentLinkColumn("Company", "name", 180),
            this.renderPaymentLinkColumn("Country", "incorporationCountry", 180),
            this.renderPaymentLinkColumn("Industry", "industryCode", 180),
            this.renderPaymentLinkColumn("Status", "status", 180),
        ];
    }

    componentDidMount() {
        this.getPartnerMerchantsData();
    }

    render() {

        return (
            <>
                <div className="tl-table-heading" style={{width: "100%", padding: "15px"}}>
                    <div style={{display:"inline-block"}}>
                        <h4 style={{marginTop:"0px", marginBottom:"0px"}}>Partner Merchants</h4>
                    </div>
                </div>

                <ReactTable
                    className="-highlight"
                    style={{border: "none", width: "100%"}}
                    data={this.state.partnerMerchantsData}
                    columns={this.renderPaymentLinkColumns()}
                    showPagination={true}
                    sortable={true}
                    multiSort={true}
                    resizable={true}
                    defaultSortDesc={true}
                />
            </>
        )
    }
}

const mapStateToProps = state => ({
    partnerMerchantsReducer: state.partnerMerchantsReducer
});

const mapDispatchToProps = {
    getPartnerMerchantsData
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PartnerMerchants)
);