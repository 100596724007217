import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  financeData: null
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FINANCE_DATA:
      return {
        ...state,
        financeData: action.payload
      };

    default:
      return state;
  }
};

export default financeReducer;
