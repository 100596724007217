import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  paymentLinkData: null,
  paymentLinkListData: null,
  paymentMethodsData: null,
  
};

const paymentLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_LINK:
      return {
        ...state,
        paymentLinkData: action.payload
      };

    case actionTypes.SET_PAYMENT_LINK:
      return {
        ...state,
        paymentLinkData: action.payload
      };
      
    case actionTypes.UPDATE_PAYMENT_LINK:
      return {
        ...state,
        paymentLinkData: action.payload
      };  

    case actionTypes.PAYMENT_LINK_LIST:
        return {
          ...state,
          paymentLinkListData: action.payload
        };

    case actionTypes.PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodsData: action.payload
      };

    default:
      return state;
  }
};

export default paymentLinkReducer;
