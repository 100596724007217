import React, { Component } from "react";
import NumberFormat from "react-number-format";
import * as helper from "../core/helper";
import ReactExport from "react-data-export";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import _ from "lodash";

class TableCharts extends Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    this.state = {
      //state is by default an object
      data: null,
      metadata: null,
      height: null,
      width: null
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.data];
    arrayCopy.sort(this.compareBy(key));
    this.setState({ data: arrayCopy });
  }

  excelColumnGenerator = () => {
    if (this.props.data) {
      // for header take the first object, it could be any actually
      let header = this.props.data[0];

      // create header list from keys in header
      let header_list = Object.keys(header).map(function(key) {
        // check if min width is required for the column

        let excel_header_key = key;

        if (key.startsWith("v_")) {
          excel_header_key = key.replace("v_", "visa_");
        }
        if (key.startsWith("m_")) {
          excel_header_key = key.replace("m_", "mastercard_");
        }
        if (key.startsWith("a_")) {
          excel_header_key = key.replace("a_", "count_");
        }
        if (key.startsWith("p_")) {
          excel_header_key = key.replace("p_", "rate_");
        }

        return {
          Header: helper.Capitalize(excel_header_key),
          accessor: key
        };
      });

      return header_list;
    }
  };

  checkIfPivotNeeded = () => {
    if (this.props.data) {
      // for header take the first object, it could be any actually
      let header = this.props.data[0];

      let keysForPivot = [];
      let paginationRequired = null;

      // create header list from keys in header
      Object.keys(header).map(function(key) {
        if (key === "category") {
          keysForPivot = ["status", "category"];
        }
        if (key === "payment_system") { // for admin sales tab
          keysForPivot = ["company", "payment_system"];
          paginationRequired = true;
        }
        if (key === "ccn_masked") {
          keysForPivot = ["ccn_masked"];
        }
        return [null, paginationRequired] ;
      });

      return [keysForPivot, paginationRequired];
    }
  };

  // dynamically generate the columns from keys recieved in data
  columnGenerator = () => {
    let thisTest = this;
    if (this.props.data) {
      // for header take the first object, it could be any actually
      let header = this.props.data[0];

      // for reports we might have additional header for showing visa and mastercard as a header
      // keys starting with v_ will be visa columns and starting with m_ belongs to mastercard
      // rest will be as additional count
      let v_count = 0;
      let m_count = 0;
      let a_count = 0;
      let p_count = 0;
      let add_count = 0;

      // create header list from keys in header
      let header_list = Object.keys(header).map(function(key) {
        // count visa master and other additional fields
        if (key.startsWith("v_")) {
          v_count = v_count + 1;
        }
        if (key.startsWith("a_")) {
          a_count = a_count + 1;
        }
        if (key.startsWith("p_")) {
          p_count = p_count + 1;
        }
        if (key.startsWith("m_")) {
          m_count = m_count + 1;
        }
        if (
          !key.startsWith("m_") &&
          !key.startsWith("v_") &&
          !key.startsWith("a_") &&
          !key.startsWith("p_")
        ) {
          add_count = add_count + 1;
        }

        if (key.includes("color")) {
          return {
            Header: helper.Capitalize(key),
            accessor: key,

            show: false,
            headerStyle: {
              backgroundColor: "#5d98cf",
              fontWeight: "bold",
              whiteSpace: "unset",
              borderColor: "white",
              border: "1px solid white",
              borderRightColor: "#5d98cf"
            },
            style: {
              verticalAlign: "center"
            }
          };
        }

        // check if min width is required for the column
        let minWidthRequired = helper.getMinWidthForColumns(key);
        if (minWidthRequired !== 0) {
          return {
            Header: helper.Capitalize(key),
            accessor: key,
            aggregate: vals => _.sum(vals),
            Aggregated: row => {
              if (key.includes("name") || key.includes("account") ) {
                return (
                  <div style={{ backgroundColor: "#e6f2ff" }}>
                    <span>
                      <i
                        style={{ color: "black" }}
                        className="fa fa-ellipsis-h"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                );
              } else if(key.includes("count")) {
                return (
                  <span>
                    <NumberFormat
                      value={row.value}
                      thousandSeparator={true}
                      decimalScale={key.includes("count") ? 0 : 2}
                      displayType={"text"}
                      fixedDecimalScale={true}
                    />
                  </span>
                );
              }
              else
              {
                return <span>-</span>;
              }
            },
            headerStyle: {
              backgroundColor: "#5d98cf",
              fontWeight: "bold",
              whiteSpace: "unset",
              borderColor: "white",
              border: "1px solid white",
              textAlign: "center",
              borderRightColor: "#5d98cf"
            },
            style: {
              textAlign: "center",
              verticalAlign: "center"
            },
            minWidth: minWidthRequired,
            getProps: (state, rowInfo) => {
              if (
                rowInfo &&
                rowInfo.original &&
                rowInfo.original[key + "_color"]
              ) {
                return {
                  style: {
                    background: rowInfo.original[key + "_color"]
                  }
                };
              } else if (
                rowInfo &&
                rowInfo.original &&
                rowInfo.original[key] === "" &&
                key === "company"
              ) {
                return {
                  style: {
                    background: "#99a7c2"
                  }
                };
              } else {
                return {};
              }
            },
            // do some number formating for volume, count and amount columns
            sortMethod: (a, b) => helper.sortDataForTables(a, b),
            Cell: row => {
              if (
                (key.includes("volume") || key.includes("amount")) &&
                !key.includes("country") &&
                !key.includes("ratio") &&
                !key.includes("color")
              ) {
                return (
                  <span>
                    <NumberFormat
                      value={row.value}
                      thousandSeparator={true}
                      decimalScale={
                        key.includes("count") || key.startsWith("a_") ? 0 : 2
                      }
                      displayType={"text"}
                      fixedDecimalScale={true}
                    />
                  </span>
                );
              } else if (key.includes("error")) {
                return <b>{row.value}</b>;
              } else if (
                key.includes("chargeback_count") ||
                key.includes("fraud_count")
              ) {
                if (row.value > 0) {
                  let isExcepFroudCount =
                    row.column.Header === "Excep Fraud Count";
                  return (
                    <span
                      onClick={e => {
                        e.preventDefault();
                        let company = row.original.company;
                        let monthYear = row.original.monthYear;
                        let dateFrom = thisTest.props.dateFrom;
                        let dateTo = thisTest.props.dateTo;
                        let acquirer = row.original.acquirer;

                        if (
                          row.original.v_chargeback_count ||
                          row.original.m_chargeback_count
                        ) {
                          if (row.column.parentColumn.Header === "Visa") {
                            thisTest.props.setModalContentURL(
                              `/generic/transaction-list?${company}&${monthYear}&${dateFrom}&${dateTo}&${acquirer}&chargeback&V`
                            );
                            thisTest.props.openModal();
                          } else {
                            // Master
                            thisTest.props.setModalContentURL(
                              `/generic/transaction-list?${company}&${monthYear}&${dateFrom}&${dateTo}&${acquirer}&chargeback&E`
                            );
                            thisTest.props.openModal();
                          }
                        }

                        if (!isExcepFroudCount) {
                          if (
                            row.original.v_fraud_count ||
                            row.original.m_fraud_count
                          ) {
                            if (row.column.parentColumn.Header === "Visa") {
                              thisTest.props.setModalContentURL(
                                `/generic/transaction-list?${company}&${monthYear}&${dateFrom}&${dateTo}&${acquirer}&fraud&V`
                              );
                              thisTest.props.openModal();
                            } else {
                              // Master
                              thisTest.props.setModalContentURL(
                                `/generic/transaction-list?${company}&${monthYear}&${dateFrom}&${dateTo}&${acquirer}&fraud&E`
                              );
                              thisTest.props.openModal();
                            }
                          }
                        }
                      }}
                      style={{
                        color: !isExcepFroudCount ? "blue" : "black",
                        textDecoration: !isExcepFroudCount
                          ? "underline"
                          : "none",
                        cursor: !isExcepFroudCount ? "pointer" : "text"
                      }}
                    >
                      <span>
                        <NumberFormat
                          value={row.value}
                          thousandSeparator={true}
                          decimalScale={key.includes("count") ? 0 : 2}
                          displayType={"text"}
                          fixedDecimalScale={true}
                        />
                      </span>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <NumberFormat
                        value={row.value}
                        thousandSeparator={true}
                        decimalScale={key.includes("count") ? 0 : 2}
                        displayType={"text"}
                        fixedDecimalScale={true}
                      />
                    </span>
                  );
                }
              } else if (key.includes("transaction_id")) {
                return (
                  <span
                    onClick={e => {
                      e.preventDefault();
                      helper.renderTransDetails(row.original.transaction_id);
                    }}
                    style={{
                      color: "#235669",
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                  >
                    <b>{row.value}</b>
                  </span>
                );
              } else {
                return row.value;
              }
            }
          };
        } else if (key.includes("-20")) {
          return {
            Header: helper.Capitalize(key),
            accessor: key,
            aggregate: vals => _.sum(vals),
            headerStyle: {
              backgroundColor: "#5d98cf",
              fontWeight: "bold",
              whiteSpace: "unset",
              borderColor: "white",
              border: "1px solid white",
              textAlign: "center",
              borderRightColor: "#5d98cf"
            },
            style: {
              textAlign: "center",
              verticalAlign: "center"
            },
            getProps: (state, rowInfo) => {
              if (
                rowInfo &&
                rowInfo.original &&
                rowInfo.original[key + "_color"]
              ) {
                return {
                  style: {
                    background: rowInfo.original[key + "_color"]
                  }
                };
              } else {
                return {};
              }
            },
            Cell: row => {
              if (key.includes("-20")) {
                let unit = "";
                if (key.includes("_percent")) {
                  unit = "%";
                  return (
                    <span>
                      <NumberFormat
                        value={row.value}
                        thousandSeparator={true}
                        decimalScale={key.includes("count") ? 0 : 2}
                        displayType={"text"}
                        fixedDecimalScale={true}
                      />
                      {unit}
                    </span>
                  );
                }
                else
                {
                  return row.value;
                }

              } else {
                return row.value;
              }
            }
          };
        } else {
          return {
            Header: helper.Capitalize(key),
            accessor: key,
            aggregate: vals => _.sum(vals),
            Aggregated: row => {
              if (key.includes("transaction_id") || key.includes("mcc") ) {
                return (
                  <div style={{ backgroundColor: "#e6f2ff" }}>
                    <span>
                      <i
                        style={{ color: "black" }}
                        className="fa fa-ellipsis-h"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                );
              } else {
                return (
                  <span>
                    <NumberFormat
                      value={row.value}
                      thousandSeparator={true}
                      decimalScale={key.includes("count") ? 0 : 2}
                      displayType={"text"}
                      fixedDecimalScale={true}
                    />
                  </span>
                );
              }
            },

            headerStyle: {
              backgroundColor: "#5d98cf",
              fontWeight: "bold",
              whiteSpace: "unset",
              borderColor: "white",
              textAlign: "center",
              border: "1px solid white",
              borderRightColor: "#5d98cf"
            },
            style: {
              textAlign: "center",
              verticalAlign: "center"
            },
            getProps: (state, rowInfo) => {
              if (
                rowInfo &&
                rowInfo.original &&
                rowInfo.original[key + "_color"]
              ) {
                return {
                  style: {
                    background: rowInfo.original[key + "_color"]
                  }
                };
              } else {
                return {};
              }
            },
            sortMethod: (a, b) => helper.sortDataForTables(a, b),
            Cell: row => {
              if (
                (key.includes("volume") ||
                  key.includes("amount") ||
                  key.includes("Total") ||
                  key.includes("DOMESTIC") ||
                  key === "settlement" ||
                  key === "reserves" ||
                  key.includes("INTERNATIONAL") ||
                  key.includes("INTRA_REGIONAL") ||
                  key.includes("EUROPE")) &&
                !key.includes("country") &&
                !key.includes("ratio") &&
                !key.includes("color")
              ) {
                return (
                  <span>
                    <NumberFormat
                      value={row.value}
                      thousandSeparator={true}
                      decimalScale={key.includes("count") ? 0 : 2}
                      displayType={"text"}
                      fixedDecimalScale={true}
                    />
                  </span>
                );
              } else if (
                key.includes("chargeback_count") ||
                key.includes("fraud_count")
              ) {
                if (row.value > 0) {
                  return (
                    <span
                      onClick={e => {
                        e.preventDefault();
                      }}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                    >
                      <b>
                        <span>
                          <NumberFormat
                            value={row.value}
                            thousandSeparator={true}
                            decimalScale={key.includes("count") ? 0 : 2}
                            displayType={"text"}
                            fixedDecimalScale={true}
                          />
                        </span>
                      </b>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <NumberFormat
                        value={row.value}
                        thousandSeparator={true}
                        decimalScale={key.includes("count") ? 0 : 2}
                        displayType={"text"}
                        fixedDecimalScale={true}
                      />
                    </span>
                  );
                }
              } else if (key.includes("transaction_id")) {
                return (
                  <span
                    onClick={e => {
                      e.preventDefault();
                      helper.renderTransDetails(row.original.transaction_id);
                    }}
                    style={{
                      color: "#235669",
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                  >
                    <b>{row.value}</b>
                  </span>
                );
              } else {
                return row.value;
              }
            }
          };
        }
      });

      // now we have counts, for visa mastercard if any else all will go to additonal counts
      let add_header = {
        additional: add_count,
        visa: v_count,
        "masterCard/Maestro": m_count,
        count: a_count,
        rate: p_count
      };

      // result will hold the final array of objects whichh will be passed to the react table module
      var result = [];

      // starting with first column
      let column_number = 0;

      // loop around the add header array to move the categories under their header
      // for eg all column starting with v_ should go under Visa
      Object.keys(add_header).map(function(key, count) {
        let add_headers = [];

        // push objects to array for the columns
        for (var i = column_number; i < add_header[key] + column_number; i++) {
          add_headers.push(header_list[i]);
        }

        column_number = column_number + add_header[key];

        // push to final array
        result[count] = {
          Header: key === "additional" ? "-" : helper.Capitalize(key),
          headerStyle: {
            backgroundColor: "#5d98cf",
            textAlign: "center",
            fontWeight: "bold",
            whiteSpace: "unset",
            borderColor: "white",
            border: "1px solid white",
            borderRightColor: "#5d98cf"
          },
          columns: add_headers
        };
        return null;
      });

      return result;
    }
  };

  render() {
    //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
    const columns = this.columnGenerator();
    const excelColumns = this.excelColumnGenerator();
    const pivotRequired = this.checkIfPivotNeeded();
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
      <div className="box-body">
        <ExcelFile
          element={
            <button className="btn btn-link">
              <i
                style={{ color: "black" }}
                title="Download"
                className="fa fa-download fa-1x"
              />
            </button>
          }
        >
          <ExcelSheet
            data={this.props && this.props.data ? this.props.data : []}
            name="tableCharts"
          >
            {excelColumns.map((item, i) => {
              if (item.Header.includes("Color")) {
                return [];
              } else {
                return (
                  <ExcelColumn
                    key={i}
                    label={item.Header}
                    value={item.accessor}
                  />
                );
              }
            })}
          </ExcelSheet>
        </ExcelFile>
        <div className="table-responsive">
          <font size="2">
            <ReactTable
              minRows={1}
              collapseOnSortingChange={false}
              className="-striped -highlight"
              defaultPageSize={25}
              data={this.props.data ? this.props.data : []}
              columns={columns}
              sortable={true}
              multiSort={true}
              resizable={true}
              showPagination={
                pivotRequired[0] && pivotRequired[0].length >= 1 && pivotRequired[1] !== true
                  ? false
                  : this.props.data && this.props.data.length > 25
                  ? true
                  : false
              }
              pivotBy={pivotRequired[0]}
            />
          </font>
        </div>
      </div>
    );
  }
}
export default TableCharts;
