import { api } from "./api";

import * as apiLink from "../lib/constants/apiLink";
// Developers can override this with an env.local file
const baseUrl = apiLink.BASE_API_URL;

/**
 * Function for getting payment link from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const getPaymentLink = (token, body) => {
  return api(baseUrl, token).post(apiLink.GET_PAYMENT_LINK,body);
};

/**
 * Function for getting payment link from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const setPaymentLink = (token, body) => {
  return api(baseUrl, token).post(apiLink.SET_PAYMENT_LINK,body);
};

/**
 * Function for getting payment link from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const updatePaymentLink = (token, body) => {
  return api(baseUrl, token).post(apiLink.UPDATE_PAYMENT_LINK,body);
};

/**
 * Function for getting payment link from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const getPaymentLinkList = (token, body) => {
  return api(baseUrl, token).post(apiLink.GET_PAYMENT_LINK_LIST,body);
};

/**
 * Function for getting payment methods data from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const getPaymentMethods = (token, body) => {
  return api(baseUrl, token).post(apiLink.GET_PAYMENT_METHODS,body);
}

/**
 * Function for getting payment link from backend api.
 * @param {string} token Login token.
 * @param {object} body Request body.
 */
export const sendPaymentLink = (token, body) => {
  return api(baseUrl, token).post(apiLink.SEND_PAYMENT_LINK,body);
};
