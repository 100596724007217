import { api } from "./api";
import * as apiLink from "../lib/constants/apiLink";

const baseUrl = apiLink.BASE_API_URL;

export const getChargebackAlerts = (token, params) => {
    return api(baseUrl, token).post(apiLink.GET_CHARGEBACK_ALERTS, params);
};

export const getChargebackAlertsFilters = (token, params) => {
    return api(baseUrl, token).get(apiLink.GET_CHARGEBACK_ALERTS_FILTERS, params);
}

export const getChargebackPreventionRules = (token, params) =>
    api(baseUrl, token).get(
        `${apiLink.CHARGEBACK_RULES}${
            params.company ? `?company=${params.company}` : ""
        }`
    );

export const getAllMerchants = (token, params) => {
    return api(baseUrl, token).get(apiLink.GET_ALL_MERCHANTS, params);
};

export const addChargeBack = (token, params) => {
    return api(baseUrl, token).post(apiLink.CHARGEBACK_RULES, params);
};

export const updateChargeBack = (token, params) => {
    return api(baseUrl, token).put(apiLink.CHARGEBACK_RULES, params);
};

export const deleteChargeback = (token, params) => {
    return api(baseUrl, token).delete(`${apiLink.CHARGEBACK_RULES}/${params}`);
};

export const exportDownloadChargeback = (token, params) => {
    return api(baseUrl, token).post(
        apiLink.CHARGEBACK_RULES_EXPORT_DOWNLOAD,
        params
    );
};

export const chargeBackAlertAction = (token, params) => {
    return api(baseUrl, token).post(apiLink.CHARGEBACK_ALERT_DECISION, params);
};
