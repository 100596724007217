import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getVirtualTerminal, getPaymentMethods, getTXDiagnose } from "../../../actions/virtualTerminalAction";
//import SuccessMod from "../../general/core/SuccessModel";
import ClipLoader from "react-spinners/ClipLoader";
import {  PAYMENT_METHODS, 
          CURRENCY_CODES, 
          OPTIONAL_FIELDS, 
          INPUT_FIELDS, 
          SELECT_FIELDS, 
          FIELD_COL_WIDTH,
          PESRSONAL_DATA_FIELDS,
          CONTACT_DATA_FIELDS,
          //STATUS_ERRORS,
          URLS,
          INPUT_FIELD_TYPE,
          COUNTRY_LIST,
          SALUTATION_LIST,
          GENDER_LIST,
} from "../../../lib/constants/virtualTermConstants";
import _ from 'lodash';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import * as helper from "../../general/core/helper";
import DatePicker from 'react-date-picker';
//import SelectFilter from "../../general/core/Select";

const modalStyle = {
  content : {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:"0px",
    height: '80%',
    maxHeight: '610px'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

class VirtualTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToSend: {},
      payment_method: 0,
      paymentMethods: [],
      currencyCodes: [],
      paymentMethodFields: {},
      modalIsOpen: false,
      transactionID: "",
      loading: false,
      transactionCompleted: false,
      infoBoxOpen: false,
      formHasErrors: false,
      showErrorMsg: false,
      datePickerValue: null,
    };

    this.errorCode = "";
    this.popUpWindow = null;
    this.secondToEndRequest = 300;
    this.completionMsg = "";
    this.completionMsg2 = "";
   	this.transID = "";
   	this.redirectURL = "";
   	this.startURL = "";
   	this.transactionID = "";
   	this.interval = "";
   	this.timer = 0;
   	
   	// REGEX
   	this.regexNumber = /^[0-9\b]+$/;
   	this.regexPhone = /^[0-9\b\-() ]{0,20}$/;
   	this.regexAlphanumeric = /^[A-Za-z0-9\b\-/ ]{0,255}$/;
   	this.regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   	this.regexText = /^[A-Za-z ]{0,100}$/;
   	this.regexDecimal = /^\d*(\.\d{2})?$/;
   	this.regexIP = /^\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b$/;
   	
   	this.formErrorMsg = [];
    this.errorMsg = "";
    this.errorMsgColor = "rgb(195, 0, 0)";
    this.fieldsWithError = [];
   	
   	this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSelectValue = this.handleSelectValue.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.checkFormCorrectness = this.checkFormCorrectness.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeMsgBox = this.closeMsgBox.bind(this);
    this.startGatewayResponseTimer = this.startGatewayResponseTimer.bind(this);
    this.renderTransDetails = this.renderTransDetails.bind(this);
    this.handleErrorByCode = this.handleErrorByCode.bind(this);
    this.handleSelectMerchant = this.handleSelectMerchant.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.renderSegmentBtn = this.renderSegmentBtn.bind(this);
    this.handleErrorOnSubmit = this.handleErrorOnSubmit.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    
    // test 
    //this.handleSelectMerchant2 = this.handleSelectMerchant2.bind(this);
    
    this.selectPaymentMethodRef = React.createRef();
    this.selectFieldRef = React.createRef();
    this.datePickerRef = React.createRef();
  }
 
  openModal() {
    this.setState({modalIsOpen: true});
  }
 
  afterOpenModal() {
    clearInterval(this.interval);
    this.timer = 0;
  }
 
  closeModal() {
    this.setState({
    	modalIsOpen: false,
    	loading: false,
  	});
  	this.checkTransactionStatus();
  }

  /**
   * Checks if all mandatory fields have been filled.
   * @param {array} mandatoryFields Array with all fields that has to be filled before sending.
   * @returns {boolean}
   */
  mandatoryFieldsFilled(mandatoryFields) {
    let fields = this.state.fieldsToSend;
    for (var i in mandatoryFields) {
      if (!fields.hasOwnProperty(mandatoryFields[i]) || fields[mandatoryFields[i]] === "") return true; // Disable send btn.
    }
    return false;
  };
  
  /**
   * Checks the correctness of the form inuputs with regular expressions.
   */
  checkFormCorrectness() {
  	let errorCounter = 0;
  	this.formErrorMsg = [];
  	this.fieldsWithError = [];
  	Object.keys(this.state.fieldsToSend).map(key => {
  		let value  = this.state.fieldsToSend[key];
  		
  		if (INPUT_FIELD_TYPE[key] === "text") {
  			if (!this.regexText.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
  				this.formErrorMsg.push("Characters must be alphabetical [a-z, A-Z]");
  				this.formErrorMsg.push(<br key={key+"BR"}/>);
  				this.fieldsWithError.push(key);
  				errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "phone") {
  			if (!this.regexPhone.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
  				this.formErrorMsg.push("Characters must be a number [0-9]");
  				this.formErrorMsg.push(<br key={key+"BR"}/>);
  				this.fieldsWithError.push(key);
  				errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "number") {
  			if (!this.regexNumber.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
  				this.formErrorMsg.push("Characters must be a number [0-9]");
  				this.formErrorMsg.push(<br key={key+"BR"}/>);
  				this.fieldsWithError.push(key);
  				errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "alphanumeric") {
  			if (!this.regexAlphanumeric.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
  				this.formErrorMsg.push("Characters must be a number or alphabetical [a-z, A-Z, 0-9]");
  				this.formErrorMsg.push(<br key={key+"BR"}/>);
  				this.fieldsWithError.push(key);
  				errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "email") {
  			if (!this.regexEmail.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
					this.formErrorMsg.push("Valid email required [abc@abc.com]");
					this.formErrorMsg.push(<br key={key+"BR"}/>);
					this.fieldsWithError.push(key);
					errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "decimal") {
  			if (!this.regexDecimal.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
					this.formErrorMsg.push("Valid decimal with max. 2 decimal required [12.XX]");
					this.formErrorMsg.push(<br key={key+"BR"}/>);
					this.fieldsWithError.push(key);
					errorCounter++;
				}
  		}
  		if (INPUT_FIELD_TYPE[key] === "ip") {
  			if (!this.regexIP.test(value)) {
					this.formErrorMsg.push(<b key={key+"B"}>{key[0].toUpperCase() + key.slice(1) + ": "}</b>);
					this.formErrorMsg.push("Valid IP required.");
					this.formErrorMsg.push(<br key={key+"BR"}/>);
					this.fieldsWithError.push(key);
					errorCounter++;
				}
  		}
  		return null;
  	});
  	if (errorCounter === 0) {
  		this.errorMsg = "";
  		this.setState({formHasErrors: false});
  		return true;
  	}
  	else {
  		this.errorMsg = "Invalid form inputs.";
  		this.setState({
  			formHasErrors: true, 
  			showErrorMsg: true
  		});
  		return false;
  	}
  }
 
 	/**
   * Checks transaction id format for security. 
   * Rejects all transaction id with characters other than numbers. TODO
   */
//  	checkTransactionIDFormat(transactionid) {
//  		return this.regexNumber.test(transactionid);
//  	}
 
 	/**
   * Start timer after request was send. After this.secondToEndRequest the request will end and#
   * an error message will inform the user about that.
   */
 	startGatewayResponseTimer() {
 		this.interval = window.setInterval(() => {
    	this.timer++;
    	if (this.timer % this.secondToEndRequest === 0){
				this.errorMsg = "Gateway is not responding";
				this.setState({
					loading: false, 
					showErrorMsg: true,
					transactionCompleted: true,
				});
				clearInterval(this.interval);
  			this.timer = 0;
	    }
		}, 1000);
 	}
 
 	handleErrorOnSubmit(status) {
 		this.errorCode = status;
		this.setState({
    	loading: false,
    });
   	window.$('#vtInfoBoxCollapse').collapse('show');
   	clearInterval(this.interval);
		this.timer = 0;
		this.setTransMsgByErrorCode();
 	}
 
 	setNativeValue(element, value) {
	  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
	  const prototype = Object.getPrototypeOf(element);
	  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
	  
	  if (valueSetter && valueSetter !== prototypeValueSetter) {
	  	prototypeValueSetter.call(element, value);
	  } else {
	    valueSetter.call(element, value);
	  }
	}
 
 	/**
   * Fetches payment methods and their fields depending on merchant id.
   * Also sets currency codes. TODO consider split this into seperate method?
   * @param {string} merchantID Merchant ID which will be send as request.
   */
 	async requestPaymentMethodsByMerchantID(merchantID) {
 		// Send request for payment methods list and their required form fields.
    await this.props.getPaymentMethods({
    	merchantid: merchantID,
    });
    const data = this.props.paymentMethods
      ? this.props.paymentMethods.paymentMethodsData
      : {};
    
    let paymentMethods = [];
    let currencyCodes = [];
    //Object.keys(data.data.result).map(index => paymentMethods.push(PAYMENT_METHODS[data.data.result[index].payment_method]));
    
    // Error handling for fetching payment methods.
    if (!data.errors) {
    	window.alert("Something wrong ... : " + data);
    	return;
    }
    if (data.errors && data.errors !== "0") {
    	window.alert("Cannot load Payment Methods. Error code: " + data.errors);
    	return;
    }
	//test
	/*let data = {
		errors: "0",
		data: {
			merchantid: "test",
			mandatory_fields: {
				1: [
					"amount",
					"currency",
					"firstname",
					"lastname",
					"email"
				]
			},
			currencies: "EUR,GBP,USD",
			result: [
				{
					payment_method: 1,
					description: "Credit Card",
					name: "Credit Card"
				}
			]
		}
	}*/
   	
    // ################# TEMPORARILY ###############################
    Object.keys(data.data.result).map(index => { 
    	let code = data.data.result[index].payment_method;
    	if (Object.keys(data.data.mandatory_fields).includes(code.toString())) {
    		paymentMethods.push(PAYMENT_METHODS[code])
    	}
    	return null;
    }); // temporarily disable not implemented methods ######################################
   	
   	if (paymentMethods.length === 1) {
   		//this.selectPaymentMethodRef.current.value = paymentMethods[0];
   		
//    		const event = new Event('select', { bubbles: true })
// 		  const select = document.querySelector('#selectPaymentMethodID')
// 		  select.value = paymentMethods[0];
// 		  select.dispatchEvent(event);
		  
		  // Use HTMLInputElement for select
// 			const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set
// 			nativeSelectValueSetter.call(this.selectPaymentMethodRef, paymentMethods[0])
// 			const selectEvent = new Event('select', { bubbles: true })
// 			this.selectPaymentMethodRef.dispatchEvent(selectEvent)
// 			console.log("this.selectPaymentMethodRef", this.selectPaymentMethodRef);
// 			this.setNativeValue(this.selectPaymentMethodRef, paymentMethods[0]);
// 			this.selectPaymentMethodRef.dispatchEvent(new Event('select', { bubbles: true }));
   		
   		//this.selectPaymentMethodRef.current.onChange;
   		//console.log("this.selectPaymentMethodRef", this.selectPaymentMethodRef);
   	}
   	//console.log("data", data);
   	if (data.data.currencies && data.data.currencies !== "") {
   		let arr = data.data.currencies.split(",");
   		arr.map(c => currencyCodes.push({label: c, value: c}));
   	} else {
   		window.alert("Currencies missing.");
   		currencyCodes = CURRENCY_CODES;
   	}
   
    this.setState({
    	paymentMethods: paymentMethods,
    	paymentMethodFields: data.data.mandatory_fields,
    	currencyCodes: currencyCodes,
  	});
 	}

  /**
   * Checks if an object has no properties.
   * @param {object} obj 
   * @returns {boolean}
   */
  isObjectEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  /**
   * Checks the transaction status.
   */
  async checkTransactionStatus() {
		let requestBody = {
      merchantid: localStorage.getItem("merchantid"),
      transactionid: localStorage.getItem("transactionid"),
      type_name: "tx",
    };
   
   	if (requestBody.transactionid === null) return;
    
    await this.props.getTXDiagnose(requestBody);
    const data = this.props.txDiagnose
      ? this.props.txDiagnose.txDiagnoseData
      : {};
      
    // TODO Error handling. data = null?
		
		// edge case. testen patrick
		if (data[0] === "Invalid input.") {
			localStorage.removeItem("transactionid");
			localStorage.removeItem("merchantid");
			window.alert("Invalid input. Something wrong with the request body for txDiagnose.");
			return;
		}

		if (data && data.transaction && data.transaction.transaction_status[Object.keys(data.transaction.transaction_status)[0]]) {
	    let transactionStatus = data.transaction.transaction_status;
	    
	    for (let i = 0; i < Object.keys(transactionStatus).length; i++) {
	    	if (transactionStatus[Object.keys(transactionStatus)[i]].hasOwnProperty("error_code")) {
	    		this.errorCode = transactionStatus[Object.keys(transactionStatus)[i]].error_code;
	    		break;
	    	}
    	}
    	this.setTransMsgByErrorCode();
   	}
  }

	/**
   * Closes the info box and resets the transaction id.
   */
	closeMsgBox() {
		this.setState({
			transactionID: "",
			transactionCompleted: false,
			infoBoxOpen: false,
		});
	}

	handleErrorByCode() {
 		//this.errorCode = data.status;
		this.setState({
    	loading: false,
    });
   	this.setTransMsgByErrorCode();
   	window.$('#vtInfoBoxCollapse').collapse('show');
   	clearInterval(this.interval);
		this.timer = 0;
 	}

  /**
   * Resolves the transaction by error code after the pop up window has been closed.
   * Informs user about the status over UI.
   */
  setTransMsgByErrorCode() {
  	if (!this.state.transactionCompleted && localStorage.getItem("transactionid") !== null) {
    	this.setState({transactionID: localStorage.getItem("transactionid")}, () => {
    		if (this.errorCode === "0") {
    			this.completionMsg = "Your transaction with the ID number ";
					this.completionMsg2 = " is successful.";
    		} else if (this.errorCode === "2000") {
					this.completionMsg = "Your transaction with the ID number ";
					this.completionMsg2 = " is now pending.";
				} else if (this.errorCode === "-13002") {
					this.completionMsg = "Your transaction with the ID number ";
					this.completionMsg2 = " failed due to a communication problem. Please contact our Tech Support.";
				} else {
					this.completionMsg = "Your transaction with the ID number ";
					this.completionMsg2 = " failed.";
				}
				this.setState({transactionCompleted: true, infoBoxOpen: true});
    	});
    	this.transID = localStorage.getItem("transactionid");
    	this.forceUpdate();
    	localStorage.removeItem("merchantid");
    	localStorage.removeItem("transactionid");
  	}
  }
 
 	/** ##################################################################
	 * HANDLER FUNCTIONS
	####################################################################### */
	
	/**
   * Clears all fields in the current form.
   */
  handleClear() {
  	this.formErrorMsg = [];
  	this.errorMsg = "";
  	this.fieldsWithError = [];
  	if (this.state.formHasErrors) this.setState({infoBoxOpen: false});
    this.setState({
      fieldsToSend:{},
      formHasErrors: false,
      datePickerValue: null,
    });
  }
 
 	/**
   * Sets value if the field of the merchant select is filled.
   * Resets form page.
   */
 	handleSelectMerchant(event) {
 		event.preventDefault();
    const { name, value } = event.target;
    
    /**
	   * Clear fields if merchant id selection is changed.
	   * Reset payment method selection and clear all errors.
	   * Simply resets whole page.
	   */
    this.setState({
      [name]: value,
      payment_method: 0,
      fieldsToSend: {},
      formHasErrors: false,
      showErrorMsg: false,
      datePickerValue: null,
    });
   	//window.$('#merchantIDSelection').prop('selected', function() {return this.defaultSelected;});
   	this.fieldsWithError = [];
   	
   	this.selectPaymentMethodRef.current.value = "DEFAULT";
   	
   	this.requestPaymentMethodsByMerchantID(value);
 	}
 
//  	handleSelectMerchant2(value, event) {
//     //const { name, value } = event.target;
//     
//     console.log("value", value);
//     console.log("event", event);
//     
//     /**
// 	   * Clear fields if merchant id selection is changed.
// 	   * Reset payment method selection and clear all errors.
// 	   * Simply resets whole page.
// 	   */
//     this.setState({
//       [event.name]: value[0].value,
//       payment_method: 0,
//       fieldsToSend: {},
//       formHasErrors: false,
//       showErrorMsg: false,
//     });
//    	//window.$('#merchantIDSelection').prop('selected', function() {return this.defaultSelected;});
//    	this.fieldsWithError = [];
//    	
//    	this.selectPaymentMethodRef.current.value = "DEFAULT";
//    	
//    	this.requestPaymentMethodsByMerchantID(value);
//  	}
 
 	/**
   * Sets value if the field of the payment method select is filled.
   * Resets form page.
   */
 	handleSelectPaymentMethod(event) {
 		event.preventDefault();
    const { name, value } = event.target;
 		
 		/**
	   * Clear fields if merchant id selection is changed.
	   * Reset payment method selection and clear all errors.
	   * Simply resets whole page.
	   */
 		this.setState({
      [name]: value,
      fieldsToSend:{},
      formHasErrors: false,
      showErrorMsg: false,
      datePickerValue: null,
    });
   	this.fieldsWithError = [];
   	
   	/**
	   * Fades out form div.
	   */
    let element = document.getElementById("vtFadeElement");
    element.classList.add("fade-out-text");
    element.style.opacity = 0;
    element.style.pointerEvents = 'none';

    /**
	   * Fades in form div.
	   */
    element.style.opacity = 1;
    element.classList.remove("fade-out-text");
    element.classList.add("fade-in-text");
    setTimeout(function () {
      element.classList.remove("fade-in-text");
      element.style.pointerEvents = 'auto';
    }, 500);
 	}
	
	/**
   * Sets value if a field of the main form is filled.
   */
  handleSelectValue(event) {
    event.preventDefault();
    const { name, value } = event.target;
  	this.handleFieldValue(name, value);
  }
 
 	/**
   * 
   */
 	handleFieldValue(name, value) {
 		this.setState({
      fieldsToSend: {
        ...this.state.fieldsToSend,
        [name]: value
      }
    }, () => {
      if (value === "") delete this.state.fieldsToSend[name]; // Delete field attribute if value is an empty string.
      if (this.isObjectEmpty(this.state.fieldsToSend)) this.setState({fieldsToSend: {}}); // Set fieldsToSend to empty if all attributes are deleted.
    });
 	}
 
 	/**
   * 
   */
 	handleDatePickerChange(date) {
 		let month = date.getMonth() + 1;
 		let d = date.getDate() + "" + (month < 10 ? "0" : "") + month + "" + date.getFullYear();
 		
 		this.setState({ 
 			datePickerValue: date,
 			fieldsToSend: {
 				...this.state.fieldsToSend,
 				birthday: d // change property and make it generic for reusabilty.
 			}
 		});
 	}
	
	/**
   * Submit form.
   */
  async onSubmit(event) {
  	event.preventDefault();
  	
    // Execute only if all form fields have the right data type input.
    if (this.checkFormCorrectness()) {
    	this.startGatewayResponseTimer();
    	
	    this.setState({
	    	loading: true, 
	    	transactionCompleted: false
	    });
	    
	    // Set request body. 
	    // Required fields: merchantid, payment_method, url_success, url_failed, url_return.
	    let paymentMethodID = _.invert(PAYMENT_METHODS)[this.state.payment_method];
	    let requestBody = {
	      ...this.state.fieldsToSend, 
	      merchantid: this.state.merchant_id,
	      payment_method: paymentMethodID,
	      url_success: URLS.url_success,
	      url_failed: URLS.url_failed,
	      url_return: URLS.url_return,
	    };
	   
	    // Send request to API endpoint.
	    await this.props.getVirtualTerminal(requestBody);
	    const data = this.props.virtualTerminal
	      ? this.props.virtualTerminal.virtualTerminalData
	      : {};
	      
	    console.log("data", data);
	    
	    // ERROR user has no permission.
	    if (data && data.data && data.data.result && data.data.result.status === "-104") {
	    	this.setState({
		    	loading: false,
		    });
	    	clearInterval(this.interval);
    		this.timer = 0;
	    	window.alert("The user has no permission to use this action");
	    }
	      
	    // Store transactionid in local storage. Especially for SOFORT which calls another web page.
	  	localStorage.setItem("transactionid", data.transactionid);
	  	localStorage.setItem("merchantid", this.state.merchant_id);
	  	
	  	// TODO change all payment_method name to ID number
	  	// If Bitcoin has status 127001. TEMPORARILY TODO
	  	if (data.status && this.state.payment_method === "Bitcoin") {
	  		this.errorCode = data.status;
	  		this.setState({
		    	loading: false,
		    });
		   	window.$('#vtInfoBoxCollapse').collapse('show');
		   	clearInterval(this.interval);
    		this.timer = 0;
	  		this.setTransMsgByErrorCode();
	  	}
	  
	  	// TODO change all payment_method name to ID number
	  	// If SOFORT has status -13002. TEMPORARILY TODO
	  	if (data.status && this.state.payment_method === "SOFORT") {
	  		this.errorCode = data.status;
	  		this.setState({
		    	loading: false,
		    });
		   	window.$('#vtInfoBoxCollapse').collapse('show');
		   	clearInterval(this.interval);
    		this.timer = 0;
	  		this.setTransMsgByErrorCode();
	  	}
	   
	   	this.startURL = data.start_url;
	   	this.redirectURL = data.redirect_url;
	   	this.transactionID = data.transactionid;
	   	
	   	// TODO error handling
	   	if (data.errorcodes && data.errorcode && data.errorcode !== "0") {
	   		window.alert("Errorcode: " + data.errorcodes);
	   	}
	   	
			/**
		   * SOFORT payment handler.
		   */
	    if (data.redirect_url) {
	      window.open(this.redirectURL, '_self', 'toolbar=0,location=0,menubar=0');
	    }
	   
	   	/**
		   * Credit Card payment handler.
		   */
	    if(data.start_url){
	      this.openModal();
	    }
  	}
  }
 
 	/** ##################################################################
	 * LIFECYCLE FUNCTIONS
	####################################################################### */
	
	/**
   * After unmount the interval which was used for the timer will be cleared.
   */
 	componentWillUnmount() {
    clearInterval();
  }

  /**
   * Fetches the transaction id in local storage if stored and
   * displays it in the info box.
   */
  componentDidMount() {
  	let transactionid = localStorage.getItem("transactionid");
  	// Check for transactionid in local storage.
  	// Returning from the SOFORT page this code should be executed.
  	if (transactionid !== null && transactionid !== "undefined") {
  		this.checkTransactionStatus();
  		window.$('#vtInfoBoxCollapse').collapse('show');
  	}
  }
 
 	componentDidUpdate() {
 		// Removes inner border of child element of date picker.
  	if (this.datePickerRef.current) {
  		this.datePickerRef.current.wrapper.children[0].style.border = "none";
  	}
 	}
 
 	/** ##################################################################
	 * RENDER FUNCTIONS
	####################################################################### */
 
 	/**
   * Opens a window with transaction details of the current transaction displayed inside.
   */
 	renderTransDetails() {
   	helper.renderTransDetails(this.state.transactionID);
	}

	/**
   * Renders the segment buttons which open additional, optional form fields.
   * @param {string} btnText Name of the button which should be displayed.
   * @param {string} dataTarget ID name of the target element which should be collapsed.
   * @param {string} areaControls ID name for collapsable element. 
   * @returns {html}
   */
	renderSegmentBtn(btnText, dataTarget, areaControls) {
		return (
			<div className="vt-collapse-icon">
        <Button
        className="collapsed vt-options-btn"
        data-toggle="collapse"
        data-target={dataTarget}
        aria-expanded="false"
        aria-controls={areaControls}
        disabled={this.state.loading}>
          {btnText}
        </Button>
      </div>
		);
	}

	/**
   * Renders form field depending on type of data.
   * @param {string} name Name of field.
   * @param {string} label Label of field.
   * @param {string} colSize Width of field.
   * @param {array} options Options of an select-field.
   * @param {string} placeholder Placeholder of field.
   * @returns {html}
   */
  renderField(name, label, colSize, options, placeholder) {
    if (SELECT_FIELDS.includes(name)) {
      return(
        <div key={name} className={"col-md-"+colSize+" col-sm-"+colSize+" col-xs-"+colSize+" vt-payment-method-box"} style={{minWidth: "100px", opacity:this.state.loading ? "0.5" : "1"}}>
          <div className="row">
	          <div className="col-md-8" style={{minWidth: "100px"}}>
		          <div>
		            <label>{label}</label>
		            <span className="display-label">{}</span>
		          </div>
		          <div 
		          className="select-custom-parent" 
		          style={{margin:"0px", height:"40px", background:"white"}}>
		            <select
		            ref={this.selectFieldRef}
		            value={this.state.fieldsToSend[name] || "DEFAULT"}
		            style={{color: this.state.fieldsToSend[name] ? "black" : "grey", height:"38px"}}
		            className="vt-select-field"
		            onChange={this.handleSelectValue}
		            name={name}
		            disabled={this.state.loading}>
		              <option value="DEFAULT" disabled>{placeholder}</option>
		              {options.map(obj => (
		                <option key={obj.label} value={obj.value} style={{color:"black"}}>
		                  {obj.label}
		                </option>
		              ))}
		            </select>
		          </div>
	          </div>
          </div>
        </div>
      );
    } else if (INPUT_FIELDS.includes(name)) {
      return (
	        <div key={name} className={"col-md-"+colSize+" col-sm-"+colSize+" col-xs-"+colSize+" vt-payment-method-box"} style={{minWidth: "250px", opacity:this.state.loading ? "0.5" : "1"}}>
	          <div>
	            <label>{label}</label>
	            <span className="display-label">{}</span>
	          </div>
	          { name !== "birthday" ? (
	          <div 
	          className="select-custom-parent" 
	          style={{margin:"0px", borderColor:this.fieldsWithError.includes(name) ? "rgb(195, 0, 0)" : "", height:"40px", background:"white"}}>
	            <input
	            value={this.state.fieldsToSend[name] || ""}
	            className="vt-select-field"
	            onChange={this.handleSelectValue}
	            name={name}
	            placeholder={placeholder}
	            style={{
	            	border:"none", 
	            	height:"38px", 
	            	paddingLeft:"15px", 
	            	backgroundColor:this.fieldsWithError.includes(name) ? "rgb(232, 210, 210)" : "",
	            	color:this.fieldsWithError.includes(name) ? "rgb(195, 0, 0)" : ""}}
	            disabled={this.state.loading}/>
	          </div>
          ) : (
	        	<DatePicker
	      		ref={this.datePickerRef}
		        onChange={this.handleDatePickerChange}
		        value={this.state.datePickerValue}
		        className="tl-field-date-picker"
		        calendarClassName="tl-field-calendar"
		        clearIcon={null}
		        dayPlaceholder="DD"
		        monthPlaceholder="MM"
		        yearPlaceholder="YYYY"/>
          )}
        </div>
      );
    } else return null;
  }

  /**
   * Renders form fields depending on number of fields.
   * Careful! Recursive function. Limit your number of data in fields.
   * @param {array} fields 
   * @returns {html}
   */
  renderFormFields(fields) {
    let size = fields.length;
    let options = [];

    if (size <= 4){
      return(
        <div className="row" style={{paddingTop:"10px"}}>
          {fields.map(e => { 
			let label = e.charAt(0).toUpperCase() + e.slice(1);
			if (e === "firstname") {
				label = "First Name";
			}
			if (e === "lastname") {
				label = "Last Name";
			}
			if (e === "customerip") {
				label = "Customer IP";
			}
			if (e === "customerid") {
				label = "Customer ID";
			}
			if (e === "postbox") {
				label = "Post Box";
			}
			if (e === "orderid") {
				label = "Order ID";
			}
              let isSelect = (e === "currency" || e === "salutation" || e === "gender" || e === "language" || e === "country");
              if (e === "currency") options = this.state.currencyCodes;
              if (e === "salutation") options = SALUTATION_LIST;
              if (e === "gender") options = GENDER_LIST;
              if (e === "language") options = [{label:"German", value:"DE"}, {label:"English", value:"EN"}];
              if (e === "country") options = COUNTRY_LIST;
              return this.renderField(e, label, FIELD_COL_WIDTH[e], options, (isSelect ? "Select" : "Enter "+label));
            })
          }
        </div>
      )
    } else {
      let temp = [];
      let rest = [];
      temp.push(fields[0]);
      temp.push(fields[1]);
      temp.push(fields[2]);
      temp.push(fields[3]);
      fields.map(e => rest.push(e));
      rest.splice(0, 4);
      return(
        <div>
          <div className="row" style={{paddingTop:"10px"}}>
            {temp.map(e => {
				let label = e.charAt(0).toUpperCase() + e.slice(1);
				if (e === "firstname") {
					label = "First Name";
				}
				if (e === "lastname") {
					label = "Last Name";
				}
				if (e === "customerip") {
					label = "Customer IP";
				}
				if (e === "customerid") {
					label = "Customer ID";
				}
				if (e === "postbox") {
					label = "Post Box";
				}
				if (e === "orderid") {
					label = "Order ID";
				}
                let isSelect = (e === "currency" || e === "salutation" || e === "gender" || e === "language" || e === "country");
                if (e === "currency") options = this.state.currencyCodes;
	              if (e === "salutation") options = SALUTATION_LIST;
				        if (e === "gender") options = GENDER_LIST;
				        if (e === "language") options = [{label:"German", value:"DE"}, {label:"English", value:"EN"}];
	              if (e === "country") options = COUNTRY_LIST;
                return this.renderField(e, label, FIELD_COL_WIDTH[e], options, (isSelect ? "Select" : "Enter "+label));
              })
            }
          </div>
          {this.renderFormFields(rest)}
        </div>
      )
    }
  }

  render() {
		let serverFilterData = this.props.serverFilterData;
    // Depending on chosen payment method and merchant mandatory fields and
    // optional fields will be setted.
    let mandatoryFields = [];
    let personalDataFields = PESRSONAL_DATA_FIELDS;
    let contactDataFields = CONTACT_DATA_FIELDS;
    let optionalFields = OPTIONAL_FIELDS;
    
    let merchantOptions = [];
    if (!this.isObjectEmpty(serverFilterData) && serverFilterData["merchant_name"]) {
    	serverFilterData["merchant_name"].map(v => merchantOptions.push({label: v, value: v}));
    }
    
    mandatoryFields = this.state.paymentMethodFields[_.invert(PAYMENT_METHODS)[this.state.payment_method]];
    if (mandatoryFields) {
      personalDataFields = personalDataFields.filter(e => !mandatoryFields.includes(e));
      contactDataFields = contactDataFields.filter(e => !mandatoryFields.includes(e));
      optionalFields = optionalFields.filter(e => !mandatoryFields.includes(e));
      mandatoryFields = mandatoryFields.filter(e => !["url_success", "url_failed"].includes(e));
    }
   //TODO verschieben nach oben zu den props?
   	this.mandatoryFields = this.state.paymentMethodFields[_.invert(PAYMENT_METHODS)[this.state.payment_method]];
    if (this.mandatoryFields) {
      personalDataFields = personalDataFields.filter(e => !this.mandatoryFields.includes(e));
      contactDataFields = contactDataFields.filter(e => !this.mandatoryFields.includes(e));
      optionalFields = optionalFields.filter(e => !this.mandatoryFields.includes(e));
      this.mandatoryFields = this.mandatoryFields.filter(e => !["url_success", "url_failed"].includes(e));
    }

    return (
      <div className="virTermForm" style={{width:"100%"}}>
        <h4 className="vt-heading">
          Virtual Terminal
        </h4>
        
       	{/** ##################################################################
					* ERROR BOX
				####################################################################### */}
       	{ this.state.formHasErrors ? ( 
        	<div>
	        	<div className="vt-info-box" style={{backgroundColor:"rgb(232, 210, 210)", color:"rgb(195, 0, 0)"}}>
	        		{this.formErrorMsg}
	        	</div>
        	</div>
        	) : null
       	}
       
       	{/** ##################################################################
					* INFO BOX
				####################################################################### */}
        { this.state.transactionCompleted || true ? ( 
        	<div id="vtInfoBoxCollapse" className="collapse">
	        	<div className="vt-info-box row" style={{margin:"10px 0px 0px 0px"}}>
	        		<div className="col-md-11 col-sm-11 col-xs-11" style={{paddingTop:"6px"}}>
		        		{this.completionMsg}
		        		<Button className="vt-info-box-btn" onClick={this.renderTransDetails} style={{marginBottom:"2px"}}>
		        			{this.state.transactionID}
		        		</Button>
		        		{this.completionMsg2}
	        		</div>
	        		<div className="col-md-1 col-sm-1 col-xs-1" style={{padding:"0px"}}>
		        		<Button 
		        		className="vt-info-box-cancel collapsed" 
		        		onClick={this.closeMsgBox}
			          data-toggle="collapse"
		            data-target="#vtInfoBoxCollapse"
		            aria-expanded="false"
		            aria-controls="vtInfoBoxCollapse">
		        			<i className="fa fa-times fa-lg"></i>
		        		</Button>
	        		</div>
	        	</div>
        	</div>
        	) : null
       	}

				{/** ##################################################################
					* MERCHANT ID AND PAYMENT METHOD SELECT FIELD
				####################################################################### */}
        <div className="row" style={{paddingTop:"20px"}}>
        	<div className={"col-md-2 col-sm-2 col-xs-2 vt-payment-method-box"} style={{minWidth: "250px", opacity:this.state.loading ? "0.5" : "1"}}>
            <div>
              <label>Merchant IDs</label>
              <span className="display-label">{}</span>
            </div>
            <div 
            className="select-custom-parent" 
            style={{margin:"0px", height:"40px", background:"white"}}>
              <select
              id="merchantIDSelection"
              className="vt-select-field"
              style={{color: this.state["merchant_id"] ? "black" : "grey", height:"38px"}}
              onChange={this.handleSelectMerchant}
              name="merchant_id"
              defaultValue="DEFAULT"
              disabled={this.state.loading}>
                <option value="DEFAULT" disabled>Select Merchant ID</option>
                { !this.isObjectEmpty(serverFilterData) ? (
                	serverFilterData["merchant_name"].map(obj => (
	                  <option key={obj} value={obj} style={{color:"black"}}>
	                    {obj}
	                  </option>
                	))
               		) : []
              	}
            	</select>
            </div>
          </div>
          
	        {/*<SelectFilter
	        label="Select Merchant ID"
	        //value={values}
	        onChange={this.handleSelectMerchant2}
	        options={merchantOptions}
	        name="merchant_id"
	        //style={{ width: width + "%" }}
	        />*/}
          
          <div className={"col-md-2 col-sm-2 col-xs-2 vt-payment-method-box"} style={{minWidth: "250px", opacity:this.state.loading || !this.state.merchant_id ? "0.5" : "1"}}>
            <div>
              <label>Payment Methods</label>
              <span className="display-label">{}</span>
            </div>
            <div 
            className="select-custom-parent" 
            style={{margin:"0px", height:"40px", background:"white"}}>
              <select
              ref={this.selectPaymentMethodRef}
              id="selectPaymentMethodID"
              className="vt-select-field"
              style={{color: this.state["payment_method"] ? "black" : "grey", height:"38px"}}
              onChange={this.handleSelectPaymentMethod}
              name="payment_method"
              defaultValue="DEFAULT"
              disabled={this.state.loading || !this.state.merchant_id}>
                <option value="DEFAULT" disabled>Select Payment Method</option>
                {this.state.paymentMethods.map(obj => (
                  <option key={obj} value={obj} style={{color:"black"}}>
                    {obj}
                  </option>
                ))}
            	</select>
            </div>
          </div>
          <div className="col-md-8"></div>
        </div>

				{/** ##################################################################
					* MAIN FORM FIELDS
				####################################################################### */}
        { !this.isObjectEmpty(this.state.paymentMethodFields) && this.state.payment_method !== 0 ? (
          <div 
          id="vtFadeElement"
          className="vt-form-div">

            {this.renderFormFields(mandatoryFields)}
            {this.renderSegmentBtn("Add personal data", "#vtPersonalCollapse", "vtPersonalCollapse")}
            <div
            className="collapse" 
            id="vtPersonalCollapse" >
              {this.renderFormFields(personalDataFields)}
            </div>
            {this.renderSegmentBtn("Add contact data", "#vtContactCollapse", "vtContactCollapse")}
            <div
            className="collapse" 
            id="vtContactCollapse" >
              {this.renderFormFields(contactDataFields)}
            </div>
            {this.renderSegmentBtn("Add optional data", "#vtOptionalCollapse", "vtOptionalCollapse")}
            <div
            className="collapse" 
            id="vtOptionalCollapse" >
              {this.renderFormFields(optionalFields)}
            </div>

						{ this.state.showErrorMsg ? (
	            <div style={{textAlign:"center", color:this.errorMsgColor, height:"10px"}}>
	              <h5 id="errorMsg">
	                {this.errorMsg}
	              </h5>
	            </div>
          		) : ( "" )
          	}

						{/** ##################################################################
							* CLEAR AND SUBMIT BUTTON
						####################################################################### */}
            <div className="row">
              <div className="col-md-12" style={{textAlign:"center", paddingTop:"25px"}}>
                <Button
                className="btn btn-danger vt-btn hellobla"
                type="button"
                onClick={this.handleClear}
                style={{
                backgroundColor:this.isObjectEmpty(this.state.fieldsToSend) || this.state.loading ? "grey" : "#dd4b39",
                borderColor:this.isObjectEmpty(this.state.fieldsToSend) || this.state.loading ? "grey" : "#dd4b39"}}
                disabled={this.isObjectEmpty(this.state.fieldsToSend) || this.state.loading}>
                  Clear
                </Button>
                <Button
                className="btn btn-success vt-btn"
                type="button"
                onClick={this.onSubmit}
                data-toggle="collapse"
		            data-target={this.state.infoBoxOpen && !this.state.formHasErrors ? "#vtInfoBoxCollapse" : ""}
		            aria-expanded="true"
		            aria-controls={this.state.infoBoxOpen && !this.state.formHasErrors ? "vtInfoBoxCollapse" : ""}
                style={{
                backgroundColor:this.mandatoryFieldsFilled(mandatoryFields) || this.state.loading ? "grey" : "#00a65a",
                borderColor:this.mandatoryFieldsFilled(mandatoryFields) || this.state.loading ? "grey" : "#00a65a"}}
                disabled={this.mandatoryFieldsFilled(mandatoryFields) || this.state.loading}>
                	{ this.state.loading ? <ClipLoader color="white" size={20}/> : "Send" }
                </Button>
              </div>
            </div>
          </div>
          ) : (
          <div 
          id="vtFadeElement"
          className="vt-form-div">
          </div>
          )
        }
      	
      	{/** ##################################################################
					* MODAL FOR SOME PAYMENT METHODS
				####################################################################### */}
        <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        closeTimeoutMS={300}
        style={modalStyle}
        contentLabel="Example Modal">
        	<div style={{position:"relative"}}>
	          <iframe title="iframe" src={this.startURL} width="830" height="600" style={{border:"none", width:"50vw"}}></iframe>
	          <Button 
	          onClick={this.closeModal} 
	          className="modal-cancel-btn collapsed"
	          data-toggle="collapse"
            data-target={"#vtInfoBoxCollapse"}
            aria-expanded="true"
            aria-controls={"vtInfoBoxCollapse"}>
	          	<i className="fa fa-times fa-lg"></i>
	          </Button>
       		</div>
        </Modal>
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  virtualTerminal: state.virtualTermReducer,
  paymentMethods: state.virtualTermReducer,
  txDiagnose: state.virtualTermReducer
});

const mapDispatchToProps = {
  getVirtualTerminal, getPaymentMethods, getTXDiagnose
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VirtualTerminal)
);
