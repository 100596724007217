import { api } from "./api";
import * as apiLink from "../lib/constants/apiLink";

const baseUrl = apiLink.BASE_API_URL;

/**
 * Getting the data for partner merchant list.
 * @param {*} token 
 * @param {*} body 
 * @returns 
 */
export const getPartnerMerchantsData = (token, body) => {
	return api(baseUrl, token).post(apiLink.GET_PARTNER_MERCHANTS_DATA, body);
};