import React from 'react';
import * as helper from "../../../general/core/helper";

const TransactionIDCell = ({ value, original, isCounterEnabled = true }) => {
	return (
		<div style={{margin:"10px 0px"}}>
			<span id="transactionIDCell" onClick={e => {e.preventDefault(); helper.renderTransDetails(original.transaction_id);}}>
				<b>{value}</b>
			</span>{" "}
			{isCounterEnabled && ( original.counter === "N/A" ? " - 00" : " - 0" + original.counter )}
		</div>
	)
};

export default TransactionIDCell;