import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Collapsible from "react-collapsible";
import NumberFormat from "react-number-format";
import { daily_balance_columns } from "../../../lib/constants/columns/finance/daily_balance";
//import { balances_columns } from "../../../lib/constants/columns/finance/balance";
import { transfers_columns } from "../../../lib/constants/columns/finance/transfers";
import { next_payment_columns } from "../../../lib/constants/columns/finance/payment_due";
import { chargebacks_columns } from "../../../lib/constants/columns/finance/chargebacks";
import { bankdetails_columns } from "../../../lib/constants/columns/finance/bankdetails";
import { livebalances_columns } from "../../../lib/constants/columns/finance/livebalances";
import { settlement_history_columns } from "../../../lib/constants/columns/finance/settlement_reserve_history";
import { reserve_history_columns } from "../../../lib/constants/columns/finance/reserve_history";
//import { settlement_files_columns } from "../../../lib/constants/columns/finance/statements_mbc";
import * as style from "../../../lib/constants/columns/style/style";
import ReactExport from "react-data-export";
import { getFinanceData } from "../../../actions/financeAction";
import { downloadPDF } from "../../../actions/downloadPDFAction";
import _ from "lodash";
import Download from "../transactionLog/Download";
import { trackEvent } from "../../../matomoScript";

function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

function convertDate(dateString) {
  if (dateString === null || dateString === undefined) {
    return "Invalid date string";
  }
  return dateString.replace(/-/g, '');
}


class Finance extends Component {
  async componentDidMount() { }

  financeEventNames = {
    CLICK_SUMMARY: 'click_finance_summary',
    CLICK_SALES: 'click_finance_sales',
    CLICK_CHARGEBACKS: 'click_finance_chargebacks',
    CLICK_BANK_DETAILS: 'click_finance_bank_details',
    CLICK_BALANCES: 'click_finance_balances',
    CLICK_DOWNLOADS: 'click_finance_downloads',
    CLICK_LIVE_BALANCES: 'click_finance_live_balances'
  };

  downloadPDF = (props, endpoint) => {
    const {category, dateFrom, dateTo, mid} = props?.original||{};
    const documentCategory = category || props?.column?.Header || '';
    const documentDateFrom = dateFrom || '';
    const documentDateTo = dateTo || '';
    const documentMid = mid || '';

    this.pushTrackEvent(
        `${documentCategory
            .replace('download_settlement_stmt', 'download_statement_stmt')
            .replace('download_payout_stmt', 'download_payout_report')
            .replace('download_wireout_stmt', 'download_wireout_report')}`,
        `${documentMid},${convertDate(documentDateFrom)},${convertDate(documentDateTo)}`
    );
  
    let filterBody = {
      mid: props.original.mid,
      pc21_company: props.original.pc21_company,
      endpoint: props.original.category ? props.original.category : endpoint,
      dateFrom: props.original.dateFrom,
      dateTo: props.original.dateTo,
      payment_id: props.original.payment_id ? props.original.payment_id : null,
      payout_date: props.original.date ? props.original.date : null,
      invoice_date: props.original.date ? props.original.date : null,
      filters: [],
    };

    this.props.downloadPDF(filterBody);
  };
  
  pushTrackEvent(eventName,eventData) {
    trackEvent(eventName, eventData);
}
  render() {
    // check if finance data props exist
    let raw_data = [];
    if (
      this.props &&
      this.props.financeData &&
      this.props.financeData.financeData
    ) {
      raw_data = this.props.financeData.financeData;
      //console.log(raw_data);
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const conversion_reports_column = [
      {
        Header: "Mids",
        accessor: "mid",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Company",
        accessor: "pc21_company",

        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
        },
      },
      {
        Header: "Date",
        accessor: "date",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Download",
        id: "request_state",

        Cell: (props) => (
          <button onClick={() => this.downloadPDF(props)}>
            <i style={{ color: "#5d98cf" }} className="fa fa-download fa-1x" />
          </button>
        ),
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          color: "green",
          textAlign: "center",
        },
      },
    ];

    const wireout_report_column = [
      {
        Header: "Mids",
        accessor: "mid",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Company",
        accessor: "pc21_company",

        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
        },
      },
      {
        Header: "Date",
        accessor: "date",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Currency",
        accessor: "currency",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#a3cee6",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Download",
        id: "request_state",

        Cell: (props) => (
          <button onClick={() => this.downloadPDF(props)}>
            <i style={{ color: "#5d98cf" }} className="fa fa-download fa-1x" />
          </button>
        ),
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          color: "green",
          textAlign: "center",
        },
      },
    ];

    const balances_columns = [
      {
        Header: "Mids",
        accessor: "mid",
        filterMethod: (filter, row) => {
          return row[filter.id] !== null
            ? row[filter.id]
              .toUpperCase()
              .replace(/\s+/g, "")
              .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
            : false;
        },
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
        minWidth: 150,
      },
      {
        Header: "Company",
        accessor: "pc21_company",
        filterMethod: (filter, row) => {
          return row[filter.id] !== null
            ? row[filter.id]
              .toUpperCase()
              .replace(/\s+/g, "")
              .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
            : false;
        },
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        minWidth: 150,
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Currency",
        accessor: "settlement_currency",
        filterMethod: (filter, row) => {
          return row[filter.id] !== null
            ? row[filter.id]
              .toUpperCase()
              .replace(/\s+/g, "")
              .includes(filter.value.toUpperCase().replace(/\s+/g, ""))
            : false;
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
        minWidth: 100,
      },
      {
        Header: "Opening Settl. Balance",
        accessor: "opening_settlement_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#a3cee6",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Settl. Amount",
        accessor: "settlement_amount",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#a3cee6",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Wire Fee",
        accessor: "wire_fee",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#a3cee6",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Settlement Paid",
        accessor: "settlement_amount_paid",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#a3cee6",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Settlement",
        accessor: "settlement_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
          color: "#5d98cf",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Opening Reserve Bal.",
        accessor: "opening_reserve_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#cdd1d4",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Reserve Collected",
        accessor: "reserve_colleced",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#cdd1d4",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value * -1}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Reserve Amount Paid",
        accessor: "reserve_amount_paid",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: "#cdd1d4",
          textAlign: style.headertextAlign,
          color: "black",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Reserve",
        accessor: "reserve_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
          color: "#5d98cf",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Security Deposit",
        accessor: "security_deposit_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
          color: "#5d98cf",
        },
        Cell: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
      {
        Header: "Grand Balance",
        accessor: "grand_balance",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          whiteSpace: "unset",
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: "left",
          color: "#5d98cf",
          fontWeight: "bold",
        },
        minWidth: 130,
        Cell: (row) => {
          return (
            <span>
              <button
                onClick={() =>
                  this.downloadPDF(row, "download_monthly_balance_stmt")
                }
              >
                <i
                  style={{ color: "#5d98cf" }}
                  className="fa fa-download fa-1x"
                />
              </button>{" "}
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },

      {
        Header: "Wire Amount",
        accessor: "total_wire_amount",
        filterable: false,
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <span>
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          color: "#5d98cf",
          textAlign: "left",
          fontWeight: "bold",
        },
        minWidth: 130,
        Cell: (row) => {
          return (
            <span>
              <button
                onClick={() => this.downloadPDF(row, "download_details_stmt")}
              >
                <i
                  style={{ color: "#5d98cf" }}
                  className="fa fa-download fa-1x"
                />
              </button>{" "}
              <NumberFormat
                value={row.value}
                thousandSeparator={true}
                decimalScale={2}
                displayType={"text"}
                fixedDecimalScale={true}
              />
            </span>
          );
        },
      },
    ];

    const invoice_reports_column = [
      {
        Header: "Company",
        accessor: "pc21_company",

        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          textAlign: style.headertextAlign,
        },
      },
      {
        Header: "Date",
        accessor: "date",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Download",
        id: "request_state",

        Cell: (props) => (
          <button onClick={() => this.downloadPDF(props)}>
            <i style={{ color: "#5d98cf" }} className="fa fa-download fa-1x" />
          </button>
        ),
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          color: "green",
          textAlign: "center",
        },
      },
    ];

    const settlement_files_column = [
      {
        Header: "Mids",
        accessor: "mid",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Company",
        accessor: "pc21_company",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Currency",
        accessor: "settlement_currency",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Start Date",
        accessor: "dateFrom",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "End Date",
        accessor: "dateTo",
        aggregate: (vals) => _.sum(vals),
        Aggregated: (row) => {
          return (
            <div style={{ backgroundColor: "#e6f2ff" }}>
              <span>
                <i
                  style={{ color: "black" }}
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
          );
        },
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          textAlign: style.headertextAlign,
          backgroundColor: style.tableBackgroundColor,
        },
      },
      {
        Header: "Statement Report",
        id: "request_state",

        Cell: (props) => (
          <button onClick={() => this.downloadPDF(props)}>
            <i style={{ color: "#5d98cf" }} className="fa fa-download fa-1x"/>
          </button>
        ),
        headerStyle: {
          backgroundColor: style.headerBackGroundColor,
          color: style.headertextColor,
          textAlign: style.headertextAlign,
          borderTop: style.headerborderTop,
          borderBottom: style.headerborderBottom,
        },
        style: {
          whiteSpace: "unset",
          backgroundColor: style.tableBackgroundColor,
          color: "green",
          textAlign: style.headertextAlign,
        },
      },
        {
          Header: "Transaction report",
          id: "request_state",
          
          Cell: (props) => (
                <div>
                    { (this.props.user_permissions && 
                      (this.props.user_permissions.allow_user_download_transaction_break_down ||
                        this.props.user_permissions.allow_user_download_transaction_break_down_fee || 
                        this.props.user_permissions.allow_user_download_transaction_break_down_mdr) &&
                        isNumeric(props.original.mid)) &&
                        <Download 
                            filter={{
                              dateFrom: props.original.dateFrom,
                              dateTo: props.original.dateTo,
                              datetimeFrom: props.original.dateFrom + 'T00:00:00',
                              datetimeTo: props.original.dateTo + 'T23:59:59',
                              txLogParams: {mid: [props.original.mid]}
                            } }
                            type="breakdown"
                            disabled={false} 
                            shouldShowCompactButton={false}
                            category= {props.original.category}
                        />
                    }
                </div>
        ), 
          headerStyle: {
              backgroundColor: style.headerBackGroundColor,
              color: style.headertextColor,
              textAlign: style.headertextAlign,
              borderTop: style.headerborderTop,
              borderBottom: style.headerborderBottom,
          },
          style: {
              whiteSpace: "unset",
              backgroundColor: style.tableBackgroundColor,
              color: "#5d98cf",
              textAlign: style.headertextAlign,
          },
      },
      
    ];

    return (
      <div
        className="box"
        style={{
          backgroundColor: "#ECEFF6",
          width: "100%",
          paddingLeft: "1px",
          color: "black",
        }}
      >
        <div
          className="filter-box box collapse in frosted-glass row"
          id="filterCollapse"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            marginBottom: "0px",
            borderTop: "none",
            margin: "0px",
          }}
        >
          <ul className="nav nav-tabs">
            <li className="active" onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_SUMMARY)}>
              <a data-toggle="tab" href="#summary">
                Summary
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_SALES)}>
              <a data-toggle="tab" href="#sales">
                Sales
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_CHARGEBACKS)}>
              <a data-toggle="tab" href="#chargebacks">
                Chargebacks
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_BANK_DETAILS)}>
              <a data-toggle="tab" href="#bankdetails">
                Bank Details
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_BALANCES)}>
              <a data-toggle="tab" href="#balances">
                Balances
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_DOWNLOADS)}>
              <a data-toggle="tab" href="#reports">
                Downloads
              </a>
            </li>
            <li onClick={()=>this.pushTrackEvent(this.financeEventNames.CLICK_LIVE_BALANCES)}>
              <a data-toggle="tab" href="#livebalances">
                Live Balances
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div
              id="summary"
              className="tab-pane fade in active"
              style={{ paddingTop: "30px" }}
            >
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <a data-toggle="collapse" href="#collapse1">
                        Summary
                      </a>
                    </h4>
                  </div>
                  <div id="collapse1" className="panel-collapse collapse in">
                    <div className="panel-body">
                      <Collapsible
                        trigger="Upcoming Payments"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Upcoming Payments
                          <ExcelFile
                            element={
                              <button className="btn btn-link">
                                <i
                                  style={{
                                    color: "black",
                                  }}
                                  className="fa fa-download fa-1x"
                                />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={raw_data["next_payments"]}
                              name="next_payments"
                            >
                              {next_payment_columns.map((item, i) => {
                                return (
                                  <ExcelColumn
                                    key={i}
                                    label={item.Header}
                                    value={item.accessor}
                                  />
                                );
                              })}
                            </ExcelSheet>
                          </ExcelFile>
                        </h4>
                        <ReactTable
                          minRows={1}
                          pageSize={150}
                          minRow={1}
                          data={raw_data["next_payments"]}
                          columns={next_payment_columns}
                          showPagination={false}
                          sortable={false}
                          multiSort={false}
                          resizable={false}
                          filterable={false}
                          pivotBy={["settlement_currency", "pc21_company"]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-footer">
                      <Collapsible
                        trigger="Settlement Breakdown"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          Settlement Breakdown
                          <ExcelFile
                            element={
                              <button className="btn btn-link">
                                <i
                                  style={{
                                    color: "black",
                                  }}
                                  className="fa fa-download fa-1x"
                                />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={raw_data["settlement_history"]}
                              name="settlement_history"
                            >
                              {settlement_history_columns.map((item, i) => {
                                return (
                                  <ExcelColumn
                                    key={i}
                                    label={item.Header}
                                    value={item.accessor}
                                  />
                                );
                              })}
                            </ExcelSheet>
                          </ExcelFile>
                        </h4>
                        <ReactTable
                          minRows={1}
                          minRow={1}
                          data={raw_data["settlement_history"]}
                          columns={settlement_history_columns}
                          showPagination={false}
                          sortable={false}
                          multiSort={false}
                          resizable={false}
                          filterable={false}
                          pivotBy={[
                            "settlement_currency",
                            "pc21_company",
                            "mid",
                          ]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-body">
                      <Collapsible
                        trigger="Reserve Breakdown"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Reserve Breakdown
                          <ExcelFile
                            element={
                              <button className="btn btn-link">
                                <i
                                  style={{
                                    color: "black",
                                    border: "none",
                                  }}
                                  className="fa fa-download fa-1x"
                                />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={raw_data["reserve_history"]}
                              name="reserve_history"
                            >
                              {reserve_history_columns.map((item, i) => {
                                return (
                                  <ExcelColumn
                                    key={i}
                                    label={item.Header}
                                    value={item.accessor}
                                  />
                                );
                              })}
                            </ExcelSheet>
                          </ExcelFile>
                        </h4>

                        <ReactTable
                          minRows={1}
                          minRow={1}
                          pageSize={150}
                          data={raw_data["reserve_history"]}
                          columns={reserve_history_columns}
                          showPagination={false}
                          sortable={false}
                          multiSort={false}
                          resizable={false}
                          filterable={false}
                          pivotBy={[
                            "settlement_currency",
                            "pc21_company",
                            "mid",
                          ]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-footer">
                      <Collapsible
                        trigger="Transfers History"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Transfer History
                          <ExcelFile
                            element={
                              <button className="btn btn-link">
                                <i
                                  style={{
                                    color: "black",
                                  }}
                                  className="fa fa-download fa-1x"
                                />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={raw_data["transfers"]}
                              name="transfers"
                            >
                              {transfers_columns.map((item, i) => {
                                return (
                                  <ExcelColumn
                                    key={i}
                                    label={item.Header}
                                    value={item.accessor}
                                  />
                                );
                              })}
                            </ExcelSheet>
                          </ExcelFile>
                        </h4>
                        <ReactTable
                          minRows={1}
                          pageSize={150}
                          minRow={1}
                          data={raw_data["transfers"]}
                          columns={transfers_columns}
                          pivotBy={[
                            "settlement_currency",
                            "payout_date",
                            "parent_mid",
                          ]}
                          showPagination={false}
                          sortable={false}
                          multiSort={false}
                          resizable={true}
                          filterable={false}
                        />
                      </Collapsible>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="sales"
              className="tab-pane fade"
              style={{ paddingTop: "30px" }}
            >
              <div
                style={{
                  color: "black",
                }}
              >
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "left",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                >
                  Daily Balances{" "}
                  <ExcelFile
                    element={
                      <button className="btn btn-link">
                        <i
                          style={{
                            color: "black",
                          }}
                          className="fa fa-download fa-1x"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={raw_data["daily_balances"]}
                      name="daily_balance"
                    >
                      {daily_balance_columns.map((item, i) => {
                        return (
                          <ExcelColumn
                            key={i}
                            label={item.Header}
                            value={item.accessor}
                          />
                        );
                      })}
                    </ExcelSheet>
                  </ExcelFile>
                </h4>
                <ReactTable
                  minRows={0}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={10}
                  data={raw_data["daily_balances"]}
                  columns={daily_balance_columns}
                  showPagination={true}
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  filterable={true}
                  pivotBy={["mid", "merge_date"]}
                  style={{ maxHeight: "550px", fontSize: "13px" }}
                />
              </div>
            </div>
            <div
              id="chargebacks"
              className="tab-pane fade"
              style={{ paddingTop: "30px" }}
            >
              <div
                style={{
                  color: "black",
                }}
              >
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "left",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                >
                  Chargebacks{" "}
                  <ExcelFile
                    element={
                      <button className="btn btn-link">
                        <i
                          style={{
                            color: "black",
                          }}
                          className="fa fa-download fa-1x"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={raw_data["chargebacks"]}
                      name="chargebacks"
                    >
                      {chargebacks_columns.map((item, i) => {
                        return (
                          <ExcelColumn
                            key={i}
                            label={item.Header}
                            value={item.accessor}
                          />
                        );
                      })}
                    </ExcelSheet>
                  </ExcelFile>
                </h4>
                <ReactTable
                  minRows={0}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={10}
                  data={raw_data["chargebacks"]}
                  columns={chargebacks_columns}
                  showPagination={
                    raw_data["chargebacks"] &&
                      raw_data["chargebacks"].length > 10
                      ? true
                      : false
                  }
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  filterable={true}
                  style={{ maxHeight: "550px", fontSize: "13px" }}
                />
              </div>
            </div>
            <div
              id="bankdetails"
              className="tab-pane fade"
              style={{ paddingTop: "30px" }}
            >
              <div
                style={{
                  color: "black",
                }}
              >
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "left",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                >
                  Bank Details{" "}
                  <ExcelFile
                    element={
                      <button className="btn btn-link">
                        <i
                          style={{
                            color: "black",
                          }}
                          className="fa fa-download fa-1x"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={raw_data["bankdetails"]}
                      name="bankdetails"
                    >
                      {bankdetails_columns.map((item, i) => {
                        return (
                          <ExcelColumn
                            key={i}
                            label={item.Header}
                            value={item.accessor}
                          />
                        );
                      })}
                    </ExcelSheet>
                  </ExcelFile>
                </h4>
                <ReactTable
                  minRows={0}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={10}
                  data={raw_data["bankdetails"]}
                  columns={bankdetails_columns}
                  showPagination={
                    raw_data["bankdetails"] &&
                      raw_data["bankdetails"].length > 10
                      ? true
                      : false
                  }
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  filterable={true}
                  style={{ maxHeight: "550px", fontSize: "13px" }}
                />
              </div>
            </div>
            <div
              id="balances"
              className="tab-pane fade"
              style={{ paddingTop: "30px" }}
            >
              <div
                style={{
                  color: "black",
                }}
              >
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "left",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                >
                  Balance{" "}
                  <ExcelFile
                    element={
                      <button className="btn btn-link">
                        <i
                          style={{
                            color: "black",
                          }}
                          className="fa fa-download fa-1x"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet data={raw_data["due_balances"]} name="balances">
                      {balances_columns.map((item, i) => {
                        return (
                          <ExcelColumn
                            key={i}
                            label={item.Header}
                            value={item.accessor}
                          />
                        );
                      })}
                    </ExcelSheet>
                  </ExcelFile>
                </h4>
                <ReactTable
                  minRows={0}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={10}
                  showPageJump={true}
                  data={raw_data["due_balances"]}
                  columns={balances_columns}
                  showPagination={
                    raw_data["due_balances"] &&
                      raw_data["due_balances"].length > 10
                      ? true
                      : false
                  }
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  filterable={true}
                  pivotBy={["settlement_currency"]}
                  style={{ maxHeight: "550px", fontSize: "13px" }}
                />
              </div>
            </div>
            <div
              id="reports"
              className="tab-pane fade"
              style={{
                paddingTop: "5px",
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              <h2>Downloads</h2>

              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <a data-toggle="collapse" href="#collapse2">
                        Category (Click to view)
                      </a>
                    </h4>
                  </div>
                  <div id="collapse2" className="panel-collapse collapse in">
                    <div className="panel-body">
                      <Collapsible
                        trigger="Statements"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Statements
                        </h4>
                        <ReactTable
                          minRows={2}
                          minRow={2}
                          data={raw_data["settlement_statements"]}
                          columns={settlement_files_column}
                          showPagination={true}
                          sortable={true}
                          multiSort={true}
                          resizable={false}
                          filterable={false}
                          pivotBy={["pc21_company", "mid"]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-footer">
                      <Collapsible
                        trigger="Monthly Balance"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Monthly Balance Confirmations
                        </h4>
                        <ReactTable
                          minRows={2}
                          minRow={2}
                          data={raw_data["mbc_statements"]}
                          columns={settlement_files_column}
                          showPagination={true}
                          sortable={true}
                          multiSort={true}
                          resizable={false}
                          filterable={false}
                          pivotBy={["pc21_company", "mid"]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-body">
                      <Collapsible
                        trigger="Payout Report"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Payout Reports
                        </h4>
                        <ReactTable
                          minRows={2}
                          minRow={2}
                          data={raw_data["conversion_reports"]}
                          columns={conversion_reports_column}
                          showPagination={true}
                          sortable={true}
                          multiSort={true}
                          resizable={false}
                          filterable={false}
                          pivotBy={["pc21_company", "mid"]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-footer">
                      <Collapsible
                        trigger="Invoices"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Invoices
                        </h4>
                        <ReactTable
                          minRows={2}
                          minRow={2}
                          data={raw_data["invoice_reports"]}
                          columns={invoice_reports_column}
                          showPagination={true}
                          sortable={true}
                          multiSort={true}
                          resizable={false}
                          filterable={false}
                          pivotBy={["pc21_company"]}
                        />
                      </Collapsible>
                    </div>
                    <div className="panel-body">
                      <Collapsible
                        trigger="Wireout Report"
                        triggerWhenOpen="x Close"
                        triggerStyle={{
                          color: "#5d98cf",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <h4
                          className="box-heading"
                          style={{
                            textAlign: "left",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Wireout Report
                        </h4>
                        <ReactTable
                          minRows={2}
                          minRow={2}
                          data={raw_data["wireout_reports"]}
                          columns={wireout_report_column}
                          showPagination={true}
                          sortable={true}
                          multiSort={true}
                          resizable={false}
                          filterable={false}
                          pivotBy={["pc21_company", "mid"]}
                        />
                      </Collapsible>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="livebalances"
              className="tab-pane fade"
              style={{ paddingTop: "30px" }}
            >
              <div
                style={{
                  color: "black",
                }}
              >
                <h4
                  className="box-heading"
                  style={{
                    textAlign: "left",
                    color: "white",
                    paddingLeft: "10px",
                  }}
                >
                  Live Balances{" "}
                  <ExcelFile
                    element={
                      <button className="btn btn-link">
                        <i
                          style={{
                            color: "black",
                          }}
                          className="fa fa-download fa-1x"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={raw_data["livebalances"]}
                      name="livebalances"
                    >
                      {livebalances_columns.map((item, i) => {
                        return (
                          <ExcelColumn
                            key={i}
                            label={item.Header}
                            value={item.accessor}
                          />
                        );
                      })}
                    </ExcelSheet>
                  </ExcelFile>
                </h4>
                <ReactTable
                  minRows={0}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={10}
                  data={raw_data["livebalances"]}
                  columns={livebalances_columns}
                  showPagination={
                    raw_data["livebalances"] &&
                      raw_data["livebalances"].length > 10
                      ? true
                      : false
                  }
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  filterable={true}
                  pivotBy={[
                    "pc21_company"
                  ]}
                  style={{ maxHeight: "550px", fontSize: "13px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  financeData: state.financeReducer,
  pdfData: state.downloadPDFReducer,

  // filterData: state.transLogReducer,
});

const mapDispatchToProps = {
  getFinanceData,
  downloadPDF,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Finance)
);
