import React, { PureComponent } from "react";
import { ResponsiveContainer, PieChart, Pie, Sector, Cell } from "recharts";
import { BarLoader } from "react-spinners";

const DataFormater = number => {
  if (number > 1000000000) {
    return (number / 1000000000).toFixed(2).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(2).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toFixed(2).toString() + "K";
  } else {
    return number.toFixed(2).toString();
  }
};
const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.ps_name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="orange" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${DataFormater(value)}€`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class PieChartActiveShape extends PureComponent {
  state = {
    activeIndex: 0
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    let meta_data =
      this.props.data && this.props.data.meta_data
        ? this.props.data.meta_data
        : [];
    let pieBarsData = [];
    const CHART_COLORS = [
      "#3D88B6",
      "#00C49F",
      "#f39b12",
      "#FF8042",
      "#08ab0f",
      "#e20c0c",
      "#ab089c",
      "#3f51b5"
    ];

    if (this.props && this.props.data && this.props.data.data) {
      this.props.data.data.map((item, i) => {
        delete item[`${meta_data.yVal2}`];
        pieBarsData.push(item);
        return null;
      });
    }

    let headers = [];
    // get axis keys
    var axisKeys = [];

    if (
      this.props.data &&
      Array.isArray(this.props.data) &&
      this.props.data.length !== 0
    ) {
      headers = this.props.data[0];
      Object.keys(headers).forEach(function(key) {
        axisKeys.push(key);
      });
      this.props.data.map((item, i) => {
        pieBarsData.push(item);
        return null;
      });
    }

    if (!this.props.data) {
      return (
        //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
        <BarLoader color={"#999"} speedMultiplier={1} />
      );
    }

    if (pieBarsData.length !== 0) {
      return (
        <div style={{ width: "99%", height: "441px" }}>
          <ResponsiveContainer width="99%" height="99%">
            <PieChart>
              <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                data={pieBarsData}
                innerRadius={100}
                outerRadius={140}
                fill="#2E80B2"
                dataKey={
                  meta_data && meta_data.yVal1
                    ? meta_data.yVal1
                    : axisKeys
                    ? axisKeys[1]
                    : ""
                }
                onMouseEnter={this.onPieEnter}
              >
                {pieBarsData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="noDataFound-style">
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            No data was found! 
          </h4>
        </div>
      );
    }
  }
}
