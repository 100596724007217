import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getTransData, filterBodyData } from "../../../actions/transLogAction";
import ReactTable from "react-table-6";
import NumberFormat from "react-number-format";
import { ScaleLoader } from "react-spinners";
import * as helper from "../../general/core/helper";

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
   	this.transactionType = "";
   	this.getChargebackTransList = this.getChargebackTransList.bind(this);
   	this.getFraudTransList = this.getFraudTransList.bind(this);
   	this.openTransDetailWindow = this.openTransDetailWindow.bind(this);
   	this.calcDate = this.calcDate.bind(this);
  }
 
 	async getChargebackTransList(company, dates, aqcuirer, cardBrand) {
  	let requestBody = {};
    let filter = {
    	orderId: "",
      dateFrom: dates[0],
      dateTo: dates[1],
      datetimeFrom: dates[0] + "T00:00:00",
      datetimeTo: dates[1] + "T23:59:59",
      transaction_id: 0,
      ps_name: aqcuirer,
      txLogParams: {
      	txcount: [1],
      	transaction_type: ["Chargeback-Report"],
      	merchant_company_id: [company],
      	//paymentsystem_id: ["25"],
      	card_brand: [cardBrand],
      },
    };
    this.props.getTransData(filter, requestBody);
  }

  async getFraudTransList(company, dates, aqcuirer, cardBrand) {
  	let requestBody = {};
    let filter = {
    	orderId: "",
      dateFrom: dates[0],
      dateTo: dates[1],
      datetimeFrom: dates[0] + "T00:00:00",
      datetimeTo: dates[1] + "T23:59:59",
      transaction_id: 0,
      ps_name: aqcuirer,
      txLogParams: {
      	txcount: [1],
      	transaction_type: ["Fraud-Reporting-Date"],
      	merchant_company_id: [company],
      	//paymentsystem_id: ["25"],
      	card_brand: [cardBrand],
      },
    };
    this.props.getTransData(filter, requestBody);
  }
 
 	isLeapYear(year) {
 		let cond1 = year % 4 === 0;
 		let cond2 = year % 100 === 0;
 		let cond3 = year % 400 === 0;
 		
 		if (cond1 && !cond2 && !cond3) return true;
 		else if (cond1 && cond2 && cond3) return true;
 		else return false;
 	}

	monthNumber(monthName) {
 		switch (monthName) {
 			case "Jan": return "1";
 			case "Feb": return "2";
 			case "Mar": return "3";
 			case "Apr": return "4";
 			case "May": return "5";
 			case "Jun": return "6";
 			case "Jul": return "7";
 			case "Aug": return "8";
 			case "Sep": return "9";
 			case "Oct": return "10";
 			case "Nov": return "11";
 			case "Dec": return "12";
 			default: return "00";
 		}
 	}
 
 	calcDate(monthYear, dateFrom, dateTo) {
 		let month = parseInt(this.monthNumber(monthYear.substring(0, 3)), 10);
 		let year = parseInt(monthYear.substring(4), 10);
 		//let dateFromYear = parseInt(dateFrom.substring(0, 4), 10);
 		let dateFromMonth = parseInt(dateFrom.substring(5, 7), 10);
 		let dateFromDay = parseInt(dateFrom.substring(8, 10), 10);
 		//let dateToYear = parseInt(dateTo.substring(0, 4), 10);
 		let dateToMonth = parseInt(dateTo.substring(5, 7), 10);
 		let dateToDay = parseInt(dateTo.substring(8, 10), 10);
 		
 		let res = [];
 	
 		if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) { // 31 days
 			if (dateFromMonth === month) {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-"+(dateFromDay < 10 ? "0"+dateFromDay : dateFromDay));
 			} else {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-01");
 			}
 			if (dateToMonth === month) {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-"+(dateToDay < 10 ? "0"+dateToDay : dateToDay));
 			} else {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-31");
 			}
 		} else if (month === 4 || month === 6 || month === 9 || month === 11) { // 30 days
 			if (dateFromMonth === month) {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-"+(dateFromDay < 10 ? "0"+dateFromDay : dateFromDay));
 			} else {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-01");
 			}
 			if (dateToMonth === month) {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-"+(dateToDay < 10 ? "0"+dateToDay : dateToDay));
 			} else {
 				res.push(year+"-"+(month < 10 ? "0"+month : month)+"-30");
 			}
 		} else { // 28 or 29 days
 			if (this.isLeapYear(year)) {
 				if (dateFromMonth === 2) {
	 				res.push(year+"-02-"+(dateFromDay < 10 ? "0"+dateFromDay : dateFromDay));
	 			} else {
	 				res.push(year+"-02-01");
	 			}
	 			if (dateToMonth === 2) {
	 				res.push(year+"-02-"+(dateToDay < 10 ? "0"+dateToDay : dateToDay));
	 			} else {
	 				res.push(year+"-02-29");
	 			}
 			} else {
 				if (dateFromMonth === 2) {
	 				res.push(year+"-02-"+(dateFromDay < 10 ? "0"+dateFromDay : dateFromDay));
	 			} else {
	 				res.push(year+"-02-01");
	 			}
	 			if (dateToMonth === 2) {
	 				res.push(year+"-02-"+(dateToDay < 10 ? "0"+dateToDay : dateToDay));
	 			} else {
	 				res.push(year+"-02-28");
	 			}
 			}
 		}
 		return res;
 	}

  componentDidMount() {
   	let company = window.location.search.split('&')[0];
   	company = company.substring(1);
   	let monthYear = window.location.search.split('&')[1];
    let dateFrom = window.location.search.split('&')[2];
    let dateTo = window.location.search.split('&')[3];
    let dates = this.calcDate(monthYear, dateFrom, dateTo);
    let aqcuirer = window.location.search.split('&')[4];
    let transactionType = window.location.search.split('&')[5];
    let cardBrand = window.location.search.split('&')[6];

		if (transactionType === "chargeback") {
			this.transactionType = "Chargeback Transactions" + (cardBrand === "V" ? " (VISA)" : " (Master)");
			this.getChargebackTransList(company, dates, aqcuirer, cardBrand);
		}
		if (transactionType === "fraud") {
			this.transactionType = "Fraud Transactions" + (cardBrand === "V" ? " (VISA)" : " (Master)");
			this.getFraudTransList(company, dates, aqcuirer, cardBrand);
		}
  }
 
 	openTransDetailWindow(transaction_id) {
		helper.renderTransDetails(transaction_id);
 	}
 
 	transactionLogColumn(label, key) {
    if (key === "amount" || key === "counter") {
      return {
        Header: label,
        accessor: key,
        headerStyle: {
          backgroundColor: "#5d98cf",
          color: "white",
          textAlign: "center",
          borderTop: "1px solid black",
          borderBottom: "1px solid black"
        },
        style: {
          whiteSpace: "unset",
          textAlign: "center",
          color: "#235669"
        },
        Cell: row => {
          return (
            <span>
              <NumberFormat
              value={row.value}
              thousandSeparator={true}
              decimalScale={2}
              displayType={"text"}
              fixedDecimalScale={true}/>
            </span>
          );
        }
      };
    } else {
      return {
        Header: label,
        accessor: key,
        label: label, // added for using CSVLink
        key: key, // added for using CSVLink
        headerStyle: {
          backgroundColor: "#5d98cf",
          color: "white",
          textAlign: "center",
          borderTop: "1px solid black",
          borderBottom: "1px solid black"
        },
        style: {
          whiteSpace: "unset",
          textAlign: "center",
          color: "#235669"
        }
      };
    }
  };

  render() {
		const { transLogData } =
      this.props.transLogData.transLogData !== null
        ? this.props.transLogData
        : [];
        
    const transaction_log_columns = [
      {
        Header: "Transaction ID",
        accessor: "transaction_id",
        label: "Transaction ID", // added for using CSVLink
        key: "transaction_id", // added for using CSVLink
        Cell: row => {
        	return (
          <span>
            <span
            style={{
              color:
                row.original.transaction_status === "Successful"
                  ? "#57d500"
                  : row.original.transaction_status === "Failed"
                  ? "#ff2e00"
                  : "#ffbf00",
              transition: "all .3s ease"
            }}>
              &#x25cf;
            </span>{" "}
            <span
            onClick={e => {
              e.preventDefault();
              this.openTransDetailWindow(row.original.transaction_id);
            }}
            style={{color: "#235669", textDecoration: "underline", cursor: "pointer"}}>
              <b>{row.value}</b>
            </span>{" "}
            {row.original.counter === "N/A"
              ? " - 00"
              : " - 0" + row.original.counter}
          </span>
        )},
        headerStyle: {
          backgroundColor: "#5d98cf",
          color: "white",
          textAlign: "center",
          borderTop: "1px solid black",
          borderBottom: "1px solid black"
        },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        }
      },

      this.transactionLogColumn("Merchant", "merchant_name"),
      //this.transactionLogColumn("Transaction Type", "transaction_type"),
      this.transactionLogColumn("Order Id", "order_id"),
      this.transactionLogColumn("PAN", "ccn_masked"),
      this.transactionLogColumn("Transaction Date", "date"),
      this.transactionLogColumn("Transaction Time", "time"),
      this.transactionLogColumn("Currency", "currency"),
      this.transactionLogColumn("Amount", "amount"),

      {
        Header: "Status",
        label: "Status", // added for using CSVLink
        accessor: "transaction_status",
        Cell: row => (
          <span>
            <span
            style={{
              color:
                row.value === "Successful"
                  ? "#57d500"
                  : row.value === "Failed"
                  ? "#ff2e00"
                  : "#ffbf00",
              transition: "all .3s ease"
            }}>
              &#x25cf;
            </span>{" "}
            {row.value === "Successful"
              ? "Successful "
              : row.value === "Failed"
              ? `Failed (` + row.original.error_code + ")"
              : row.value}
          </span>
        ),
        key: "transaction_status", // added for using CSVLink
        headerStyle: {
          backgroundColor: "#5d98cf",
          color: "white",
          textAlign: "center",
          borderTop: "1px solid black",
          borderBottom: "1px solid black"
        },
        style: {
          whiteSpace: "unset",
          textAlign: "center",
          color: "#235669"
        }
      }
    ];

    return (
    	<div className="box filter-chips" style={{ margin: "0px", borderTop:"none" }}>

       {/** ##################################################################
					* TABLE CONTENT
				####################################################################### */}
        {transLogData ? (
        	<>
        	<h4 className="tl-table-heading"
	        style={{paddingTop: "10px", paddingBottom: "10px", position:"fixed", zIndex:"100", width:"100%"}}>
	          {this.transactionType}
	        </h4>
        	
          <div className="table-responsive" style={{paddingTop:"38px", marginBottom:"0px"}}>
            <ReactTable
            minRows={12}
            className="-striped -highlight"
            pageSize={100}
            minRow={100}
            data={transLogData ? transLogData.page : []}
            columns={transaction_log_columns}
            showPagination={false}
            sortable={true}
            multiSort={true}
            resizable={true}/>
          </div>
          </>
        ) : (
       		<div className='centered-element'>
            <ScaleLoader color={"rgb(46, 128, 178)"} height={70} width={15} />
          </div>
      	)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transLogData: state.transLogReducer
});

const mapDispatchToProps = {
  getTransData,
  filterBodyData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionList)
);
