import {api} from "./api";
import * as apiLink from '../lib/constants/apiLink';

const baseUrl = apiLink.BASE_API_URL;

// POST /backoffice/add_subuser API
export const addSubuser = (token, body) => {
	return api(baseUrl, token).post(apiLink.BACKOFFICE_ADD_SUBUSER, body);
}

// POST /backoffice/get_subusers API
export const getSubusers = (token, body) => {
	return api(baseUrl, token).post(apiLink.BACKOFFICE_GET_SUBUSERS, body);
}

// POST /backoffice/update_subusers API
export const updateSubuser = (token, body) => {
	return api(baseUrl, token).post(apiLink.BACKOFFICE_UPDATE_SUBUSER, body);
}

// POST /backoffice/delete_subusers API
export const deleteSubuser = (token, body) => {
	return api(baseUrl, token).post(apiLink.BACKOFFICE_DELETE_SUBUSER, body);
}

export const boardPayPalMerchant = (token, body) => {
	return api(baseUrl, token).post(apiLink.BOARD_PAYPAL_MERCHANT, body);
}