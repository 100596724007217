import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  sideBarClass: null,
};

const sideBarToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIDE_BAR_MENU_TOGGLE:
      return {
        ...state,
        sideBarClass: action.payload.data,
      };
      
    default:
      return state;
  }
};

export default sideBarToggleReducer;