import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer
} from "recharts";
import {
  CHART_COLORS_2,
  CHART_COLORS
} from "../../../lib/constants/chartColors";
import * as helper from "../core/helper";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { BarLoader } from "react-spinners";

const ComposedCharts = ({ height, width, ...props }) => {
  const { data } = props.data ? props.data : [];
  let xLines = data && data.columns ? data.columns : [];

  let nestedRows = [];
  let total_rows = [];

  if (data && data.rows) {
    for (let k1 in data.rows) {
      if (k1 !== "total") {
        total_rows.push(k1);

        if (data.rows[k1].total) {
          data.rows[k1].total.name = k1;
          nestedRows.push(data.rows[k1].total);
        }
        for (let k2 in data.rows[k1]) {
          if (k2 !== "total") {
            data.rows[k1][k2].name = "• " + k2;
            nestedRows.push(data.rows[k1][k2]);
          }
        }
      }
    }
  }

  if (nestedRows) {
    sortByMonth(nestedRows);
  }

  if (nestedRows) {
    nestedRows.sort(function(a, b) {
      if (a.date) {
        let aComps = a.date.split(".");
        let bComps = b.date.split(".");

        var aDate = new Date(aComps[2], aComps[1], aComps[0]);
        var bDate = new Date(bComps[2], bComps[1], bComps[0]);
        return aDate - bDate;
      } else {
        return null;
      }
    });
  }

  function sortByMonth(arr) {
    var months = [
      "Jan-2018",
      "Feb-2018",
      "Mar-2018",
      "Apr-2018",
      "May-2018",
      "Jun-2018",
      "Jul-2018",
      "Aug-2018",
      "Sep-2018",
      "Oct-2018",
      "Nov-2018",
      "Dec-2018",
      "Jan-2019",
      "Feb-2019",
      "Mar-2019",
      "Apr-2019",
      "May-2019",
      "Jun-2019",
      "Jul-2019",
      "Aug-2019",
      "Sep-2019",
      "Oct-2019",
      "Nov-2019",
      "Dec-2019",
      "Jan-2020",
      "Feb-2020",
      "Mar-2020",
      "Apr-2020",
      "May-2020",
      "Jun-2020",
      "Jul-2020",
      "Aug-2020",
      "Sep-2020",
      "Oct-2020",
      "Nov-2020",
      "Dec-2020",
      "Jan-2021",
      "Feb-2021",
      "Mar-2021",
      "Apr-2021",
      "May-2021",
      "Jun-2021",
      "Jul-2021",
      "Aug-2021",
      "Sep-2021",
      "Oct-2021",
      "Nov-2021",
      "Dec-2021",
      "Jan-2022",
      "Feb-2022",
      "Mar-2022",
      "Apr-2022",
      "May-2022",
      "Jun-2022",
      "Jul-2022",
      "Aug-2022",
      "Sep-2022",
      "Oct-2022",
      "Nov-2022",
      "Dec-2022",
      "Jan-2023",
      "Feb-2023",
      "Mar-2023",
      "Apr-2023",
      "May-2023",
      "Jun-2023",
      "Jul-2023",
      "Aug-2023",
      "Sep-2023",
      "Oct-2023",
      "Nov-2023",
      "Dec-2023"
    ];
    arr.sort(function(a, b) {
      return months.indexOf(a.month_year) - months.indexOf(b.month_year);
    });
  }

  if (!props.data.data && !props.data) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  } else {
    if (total_rows.length !== 0) {
      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
    			<ResponsiveContainer width="99%" height="99%">
		        <ComposedChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={nestedRows}
		          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
		        >
		          <CartesianGrid stroke="#f5f5f5" />
		
		          <YAxis
		            yAxisId="right"
		            orientation="right"
		            tickFormatter={helper.DataFormater}
		          />
		
		          <YAxis tickFormatter={helper.DataFormater} unit="%" />
		
		          <XAxis dataKey="name" />
		
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		          />
		
		          <Bar
		            key={"bar_0"}
		            stackId="a"
		            dataKey={xLines.length ? xLines[1] : "not defined"}
		            fill={CHART_COLORS_2[1 - 1]}
		          />
		          <Bar
		            key={"bar_1"}
		            stackId="a"
		            dataKey={xLines.length ? xLines[2] : "not defined"}
		            fill={CHART_COLORS_2[2 - 1]}
		          />
		        </ComposedChart>
    			</ResponsiveContainer>
  			</div>
      );
    } else if (
      props.data &&
      Array.isArray(props.data) &&
      props.data.length !== 0
    ) {
      let received_data = props.data;
      let headers = received_data[0];
      // get axis keys
      var axisKeys = [];

      Object.keys(headers).forEach(function(key) {
        axisKeys.push(key);
      });

      return (
      	<div style={{ width: "99%", height: parseInt(height) }}>
    			<ResponsiveContainer width="99%" height="99%">
		        <ComposedChart
		          width={parseInt(width)}
		          height={parseInt(height)}
		          data={received_data}
		          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
		        >
		          <CartesianGrid stroke="#f5f5f5" />
		
		          <YAxis
		            yAxisId="right"
		            orientation="right"
		            tickFormatter={helper.DataFormater}
		            unit="%"
		            domain={[0, 100]}
		          />
		
		          <YAxis tickFormatter={helper.DataFormater} />
		
		          <XAxis
		            dataKey={axisKeys[0]}
		            interval={0}
		            scale="band"
		            tick={<CustomizedAxisTick />}
		          />
		
		          <Tooltip
		            cursor={{ stroke: "orange", strokeWidth: 3 }}
		            formatter={helper.renderToolTip}
		          />
		
		          <Bar key={"bar_0"} dataKey={axisKeys[1]} fill={CHART_COLORS[1 - 1]} />
		
		          <Line
		            key={"line_0"}
		            yAxisId="right"
		            type="monotone"
		            dataKey={axisKeys[2]}
		            stroke="red"
		          />
		        </ComposedChart>
    			</ResponsiveContainer>
  			</div>
      );
    } else {
      return (
        <div>
          <h4 className="noDataFound-style">
            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
            No data was found! 
          </h4>
        </div>
      );
    }
  }
};

export default ComposedCharts;
