import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as getEcommerceFiles from "../services/ecommercePlugin";



const _getPluginNames = data => ({
    type: actionTypes.ECOMMERCE_FILES,
    payload: data
});

const _downloadPlugin = data => ({
  type: actionTypes.ECOMMERCE_FILES,
  payload: data
});

export const getPluginNames = (body) => {
    return dispatch => {
      let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
      };
      return getEcommerceFiles
        .getPluginNames(token, body)
        .then(res => {
          return dispatch(_getPluginNames(res.data));
        })
        .catch(error => {
          if (error?.response !== undefined) {
            console.log(error.response);
          }
        });
    };
  };

  export const downloadPlugin = (body) => {
    let fileName = {'file_name': body};
    return dispatch => {
      let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
      };
      return getEcommerceFiles
        .downloadPlugin(token, fileName)
        .then(res => {
          return dispatch(_downloadPlugin(res));
        })
        .catch(error => {
          if (error?.response !== undefined) {
            console.log(error.response);
          }
        });
    };
  };