import React, { Component } from "react";
import { SEARCH_HISTORY_STATUS_DEFAULT, SEARCH_HISTORY_STATUS_3DSECURE } from "../../../lib/constants/filterData";
import { TX_ADVANCED_FILTER, TX_ADVANCED_FILTER2, SELECTFIELDS, CHECKBOXFIELDS, DATE15MIN } from "../../../lib/constants/filter";
import {TX_ADVANCED_FILTER_POS_Admin, TX_ADVANCED_FILTER_POS_Merchant} from "../../../lib/constants/posTransactions";
import SelectFilter from "../core/Select";
import Select from "react-select";
import InputFilter from "../core/Input";
import moment from "moment-timezone";
import DateFilter from "./DateFilter";
import Checkbox from "../core/Checkbox";
import _ from "lodash";

export class TransactionLogFilter extends Component {
  constructor(props) {
    super(props);

    const dateFilter = _.isEmpty(props.dateFilter) ? DATE15MIN : props.dateFilter;
    this.state = {
      ...dateFilter,
      filterData: {},
      isPosAdvancedFilter: this.props.isPosAdvancedFilter,
      isAdmin: this.props.isAdmin
    };

    this.HEADER = "Advanced Search";

    this.onSubmit = this.onSubmit.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onMultiChange = this.onMultiChange.bind(this);
    this.onSingleChange = this.onSingleChange.bind(this);

    this.inputRef = {
      order_id: React.createRef(),
      transaction_id: React.createRef(),
      error_code: React.createRef(),
      customer_id: React.createRef(),
      firstname: React.createRef(),
      lastname: React.createRef(),
      email: React.createRef(),
      custom_1: React.createRef(),
      custom_2: React.createRef(),
      custom_3: React.createRef(),
      rrn: React.createRef(),
      arn: React.createRef(),
      external_id: React.createRef(),
      refund_comment: React.createRef()
    }
  }

  handleDatePicker(event, picker) {
    event.preventDefault();

    this.setState(
      (state, props) => ({
        dateFrom: moment(picker.startDate).format("YYYY-MM-DD"),
        dateTo: moment(picker.endDate).format("YYYY-MM-DD"),
        datetimeFrom: moment(picker.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        datetimeTo: moment(picker.endDate).format("YYYY-MM-DDTHH:mm:ss")
      })
    );
  }

  onSubmit(event) {
    event.preventDefault();

    const dateObj = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      datetimeFrom: this.state.datetimeFrom,
      datetimeTo: this.state.datetimeTo
    };

    this.props.updateFilter(dateObj, this.state.filterData, true);
    this.props.transactionLogFilterIsOpen(false);
  }

  onClear(event) {
    event.preventDefault();

    // When setting state to clear the filters, also ensure to safely update input values if available
    this.setState({
      // Reset to initial date filter values and clear any existing filter data
      ...DATE15MIN,
      filterData: {}
    }, () => {
      // Iterate over all references in inputRef
      Object.entries(this.inputRef).forEach(([k, v]) => {
        // Check if the current reference is not null, indicating the element is mounted and accessible
        if (v.current) {
          // Safely call the updateValue method to reset any custom input components to their initial state
          v.current.updateValue();
        }
      });
      // After clearing the state, invoke the updateFilter method to apply the cleared state externally
      this.props.updateFilter(DATE15MIN, this.state.filterData, false);
    });
  }

  onClose(event) {
    //this.forceUpdate();
    this.props.transactionLogFilterIsOpen(false);
  }

  onChange(event) {
    const type = event.target.type;
    const prevState = this.state;
    const value = type === "checkbox" ? !prevState.filterData[event.target.name] : event.target.value;

    this.setState({
      filterData: {
        ...prevState.filterData,
        [event.target.name]: value
      }
    });
  }

  onMultiChange(value, event) {
    let values = [];

    if (value !== null) {
      value.map(obj => {
        if (obj.label === "Representment/Pre-Arb/Dispute Response") {
          values.push("Representment", "Pre-Arbitration", "Dispute Response");
        } else if (obj.label === "2nd Chargeback/Pre-Arb/Pre-Arb Response") {
          values.push("2nd Chargeback", "Pre-Arbitration", "Pre-Arbitration Response");
        } else {
          values.push(obj.value);
        }
        return null;
      });
    }

    let res = values.filter((item, index) => values.indexOf(item) === index);

    this.setState({
      filterData: {
        ...this.state.filterData,
        [event.name]: res === undefined || res.length === 0 ? "" : res
      }
    });
  }

 	onSingleChange(value, event) {
 		let values = [];
 		if (value) values.push(value.value);

    this.setState({
      filterData: {
        ...this.state.filterData,
        [event.name]: value ? values : ""
      }
    });
 	}

  componentDidMount() {
    // Delete all values setted earlier before leaving the Transaction Log Tab
    Object.keys(this.state.filterData).map(key => {
      if (!SELECTFIELDS.includes(key)) {
        this.setState({
          filterData: {
            ...this.state.filterData,
            [key]: CHECKBOXFIELDS.includes(key) ? false : ""
          }
        });
      }

      return null;
    });
  }

  componentWillUnmount() {
    if (this.props.transactionLogFilterOpened) {
      this.closeBtnElement.click();
      this.props.transactionLogFilterIsOpen(false);
    }
  }

  multiInput(name, placeholder, width = 100) {
    let values = [];

    if (this.state.filterData[name] !== "" && this.state.filterData[name] !== undefined) {
      this.state.filterData[name].map(v => values.push({ label: v, value: v }));
    }

    return (
      <div key={name} className="filter-field" style={{marginLeft:"0px",marginRight:"0px"}}>
        <InputFilter
          label={placeholder}
          valueArr={values}
          width={width + "%"}
          onValueChange={this.onMultiChange}
          name={name}
          ref={this.inputRef[name]}
        />
      </div>
    );
  }

 	singleSelect(name, options, placeholder) {
 		let values = [];

 		if (this.state.filterData[name] !== "" && this.state.filterData[name] !== undefined) {
      this.state.filterData[name].map(v =>
        values.push({ label: v, value: v })
      );
    }

    return (
      <div key={name} className="filter-field" style={{marginLeft:"0px",marginRight:"0px"}}>
        <Select
          placeholder={placeholder}
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name={name}
          value={values}
          options={options}
          onChange={this.onSingleChange}
          styles={{control: (base, state) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            border: "1px solid #FFFFFF",
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: "6px",
            "&:hover": {
              border: "1px solid #35A7E3"
              }
            }),
            dropdownIndicator: (base, state) => ({
              ...base,
              color: "#1E6AF6",
            }),
            }
          }
        />
      </div>
    );
  }

  multiSelect(name, placeholder, width = 100) {
    let values = [];

    if (this.state.filterData[name] !== "" && this.state.filterData[name] !== undefined) {
      this.state.filterData[name].map(v => {
        values.push({ label: v, value: v });
        return null;
      });
    }

    return (
      <div key={name} className="filter-field" style={{marginLeft:"0px",marginRight:"0px"}}>
        <SelectFilter
          label={placeholder}
          value={values}
          width={width + "%"}
          onChange={this.onMultiChange}
          options={this.props.serverFilterData}
          isMulti={true}
          name={name}
        />
      </div>
    );
  }

  renderFilterFields(fields) {
    const res = Object.keys(fields).map(key => {
      if (fields[key].type === 'input') {
        return this.multiInput(fields[key].id, fields[key].title)
      } else if (fields[key].type === 'checkbox') {
        return <Checkbox key={fields[key].id} name={fields[key].id} label={fields[key].title} checked={this.state.filterData[fields[key].id]} onChange={this.onChange} />
      } else if (fields[key].id === 'transaction_status') {
        let options = this.state.filterData.transaction_type === "3DSecure" ? SEARCH_HISTORY_STATUS_3DSECURE : SEARCH_HISTORY_STATUS_DEFAULT;
        return this.singleSelect("transaction_status", options, "All Status", 79)
      } else {
        return this.multiSelect(fields[key].id, fields[key].title)
      }
    })

    return (
      <>
        { res }
      </>
    );
  }

  updateFieldValues() {
    this.setState({
      filterData: this.props.filterData,
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo,
      datetimeFrom: this.props.datetimeFrom,
      datetimeTo: this.props.datetimeTo
    }, () => {
      Object.entries(this.inputRef).forEach(([k, v]) => v.current.updateValue());
    });
  }

  render() {
    const { isPosAdvancedFilter, isAdmin } = this.state; // Use isAdmin from state

    // Decide which filter set to use based on isAdmin and isPosAdvancedFilter
    const advancedFilterFields = isPosAdvancedFilter ? (isAdmin ? TX_ADVANCED_FILTER_POS_Admin : TX_ADVANCED_FILTER_POS_Merchant) : TX_ADVANCED_FILTER;
    return (
      <div className="container-fluid frosted-glass tl-filter">
        <h4 className="tl-filter-heading">
          { this.HEADER }
        </h4>

        <div className="form-group">
          <DateFilter datetimeFrom={this.state.datetimeFrom} datetimeTo={this.state.datetimeTo} onApply={this.handleDatePicker} />
        </div>

        <form onSubmit={this.onSubmit}>{" "}

        {/* Render advanced filters based on isAdmin */}
        {this.renderFilterFields(advancedFilterFields)}

          {this.state.filterData.pm_name !== undefined &&
            this.state.filterData.pm_name !== "" &&
            this.state.filterData.pm_name.includes("CreditCard") ? (
              <div style={{ textAlign: "right", paddingLeft: "20px" }} id="creditCardFieldsCollapse">
                {this.multiInput("ccn_masked", "424242******4242", 90)}
                {this.multiSelect("card_brand", "All Credit Card Type", 90)}
                {this.multiInput("cardholder_name", "Cardholder Name", 90)}
              </div>
            ) : null
          }

          <div className="row">
            <div className="col-md-12 col-xs-12" style={{paddingRight:"7px", paddingTop:"8px", paddingLeft:"18px"}}>
              <input
                //value={this.state.filterData.amount_from}
                className="custom-input-amount"
                type="text"
                name="amount_from"
                onChange={this.onChange}
                placeholder="Amount from"
                style={{ height: "38px", marginLeft:"0px" }}
              />

              <label style={{ paddingLeft: "0px" }}>
                {" "}
                &nbsp; &nbsp; &ndash; &nbsp; &nbsp;{" "}
              </label>

              <input
                value={this.state.filterData.amount_until}
                className="custom-input-amount"
                type="text"
                name="amount_until"
                onChange={this.onChange}
                placeholder="Amount until"
                style={{ height: "38px" }}
              />
            </div>
          </div>
          {!isPosAdvancedFilter && this.renderFilterFields(TX_ADVANCED_FILTER2)}

          <div className="row tl-filter-btns" style={{height:"80px", paddingBottom:"5px"}}>

            <div className="col-md-4 col-xs-4 group-left" style={{height:"100%"}}>
              <button
                type="submit"
                className="primaryBtn"
                style={{width:"100%"}}>
                  Search
              </button>
            </div>

            <div className="col-md-4 col-xs-4 group-right" style={{height:"100%"}}>
              <button
                type="button"
                className="secondaryBtn"
                onClick={this.onClear}
                style={{width:"100%"}}>
                  Clear
              </button>
            </div>

            <div className="col-md-4 col-xs-4 group-right" style={{height:"100%"}}>
              <button
                ref={button => this.closeBtnElement = button}
                className="secondaryBtn"
                type="button"
                onClick={this.onClose}
                style={{width:"100%"}}>
                  Close
              </button>
            </div>

          </div>

        </form>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    // This condition checks if the 'isPosAdvancedFilter' prop has changed.
    // 'prevProps' holds the props before the update,
    // while 'this.props' contains the current props after the update.
    if (prevProps.isPosAdvancedFilter !== this.props.isPosAdvancedFilter) {
      // If 'isPosAdvancedFilter' has changed, update the component's state
      // to reflect this new value. This state update triggers a re-render
      // of the component with the updated 'isPosAdvancedFilter' state.
      this.setState({ isPosAdvancedFilter: this.props.isPosAdvancedFilter });
    }
  } 
}