import { pdfApi } from "./api";

import * as apiLink from "../lib/constants/apiLink";
// Developers can override this with an env.local file
const baseUrl = apiLink.BASE_API_URL;

/**
 * Function for getting transaction Log data
 * @param {*} token
 * @param param // request params
 */
export const downloadPDF = (token, filterBody, params) => {
  return pdfApi(baseUrl, token).post(apiLink.DOWNLOAD_PDF_FILE, filterBody, {
    params: params
  });
};
