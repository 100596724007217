import * as style from "../style/style";
import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
export const transfers_columns = [
  {
    Header: "Payout Date",
    accessor: "payout_date",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 130,
  },
  {
    Header: "Settlement Date",
    accessor: "settl_date",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Mid",
    accessor: "mid",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 150,
  },
  {
    Header: "Parent Mid",
    accessor: "parent_mid",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    minWidth: 190,
  },
  {
    Header: "Company",
    accessor: "company",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Currency",
    accessor: "settlement_currency",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Type",
    accessor: "transaction_type",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
  {
    Header: "Settlement",
    accessor: "settlement_payout",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Reserve",
    accessor: "reserve_payout",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Security Deposit",
    accessor: "security_deposit",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Wire Fee",
    accessor: "wire_fee",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Total Amount",
    accessor: "total_amount",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    }
  },
  {
    Header: "Converted Amount",
    accessor: "converted_amount",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Converted Currency",
    accessor: "parent_mid_settlement_currency",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    }
  },
  {
    Header: "Exch. Rate",
    accessor: "rate",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    }
  },
  {
    Header: "Payment Details",
    accessor: "payment_details",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
    Cell: (row) => {
        return(
          <span>
            {row.value}
          </span>          
        );
    }
  },
  {
    Header: "Total Wire Amount",
    accessor: "total_wire_amount",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
      color: "black",
    },
    Cell: (row) => {
      return (
        <span>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
          />
        </span>
      );
    },
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
    aggregate: (vals) => _.sum(vals),
    Aggregated: (row) => {
      return (
        <div style={{ backgroundColor: "#e6f2ff" }}>
          <span>
            <i
              style={{ color: "black" }}
              className="fa fa-ellipsis-h"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      );
    },
    headerStyle: {
      backgroundColor: style.headerBackGroundColor,
      color: style.headertextColor,
      textAlign: style.headertextAlign,
      borderTop: style.headerborderTop,
      borderBottom: style.headerborderBottom,
    },
    style: {
      whiteSpace: "unset",
      textAlign: style.headertextAlign,
      backgroundColor: style.tableBackgroundColor,
    },
  },
];

export default {
  transfers_columns,
};
