import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Select from "react-select";
import styles from './CreateChargeback.module.css';
import TextAreaInput from "../../../general/core/TextAreaInput";
import { postChargeBack, updateChargeBack} from "../../../../actions/chargebackAlertsAction";

const CreateChargebackAlertRule = ({ open, onClose, companies, editingRule, onSave }) => {
  const [ruleDescription, setRuleDescription] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formErrors, setFormErrors] = useState({
    company: "",
    ruleDescription: ""
  });

  useEffect(() => {
    if (editingRule) {
      setRuleDescription(editingRule.rule);
      setSelectedCompany(companies.find(company => company.value === editingRule.company));
    } else {
      setRuleDescription("");
      setSelectedCompany(null);
    }
  }, [editingRule, companies]);

  const validateForm = () => {
    let errors = {};
    if (!selectedCompany) {
      errors.company = "Company is required";
    }
    if (!ruleDescription.trim()) {
      errors.ruleDescription = "Rule description is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onChangeSelectedMerchant = (e) => {
    setSelectedCompany(e);
    setFormErrors(prevState => ({ ...prevState, company: "" }));
  };

  const addOrUpdateChargebackPreventionRules = async () => {
    const payload = {
      company: editingRule ? selectedCompany.value : [selectedCompany.value],
      rule: ruleDescription
    };

    try {
      if (editingRule) {
        payload.id = editingRule.id;
        await updateChargeBack(payload);
      } else {
        await postChargeBack(payload);
      }
      onSave();
    } catch (error) {
      alert(`Error on add or update chargeback rule: ${error}`)
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      addOrUpdateChargebackPreventionRules();
      onClose();
    }
  };

  return (
    <Popup open={open} onClose={onClose} modal>
      <div className={styles.popup}>
        <div className={styles.content}>
          <h5 className={styles.popupContent}>{editingRule ? 'Edit chargeback alert rule' : 'Create chargeback alert rule'}</h5>
          <hr className={styles.separator} />
          <div className={styles.companySelectWrapper}>
            <label htmlFor="company">Company:</label>
            <div>
              <Select
                id="company"
                className={`${styles.companySelect} ${formErrors.company ? styles.errorBorder : ''}`}
                options={companies}
                components={{ IndicatorSeparator: null }}
                isSearchable={true}
                name={"merchants"}
                placeholder={"Select Company"}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                formatGroupLabel={true}
                onChange={onChangeSelectedMerchant}
                value={selectedCompany}
              />
              {formErrors.company && <div className={styles.error}>{formErrors.company}</div>}
            </div>
          </div>
          <div>
            <h3 className={styles.ruleDescription}>Rule Description:</h3>
            <TextAreaInput
              value={ruleDescription}
              onChange={(e) => {
                setRuleDescription(e.target.value);
                setFormErrors(prevState => ({ ...prevState, ruleDescription: "" }));
              }}
              className={`${styles.descriptionTextarea} ${formErrors.ruleDescription ? styles.errorBorder : ''}`}
              placeholder="Enter rule description"
              style={{ width: "100%" }}
            />
            {formErrors.ruleDescription && <div className={styles.error}>{formErrors.ruleDescription}</div>}
          </div>
          <div className={styles.actionButtons}>
            <button onClick={onClose} className={styles.cancelButton}>Cancel</button>
            <button onClick={handleSave} className={styles.saveButton}>{editingRule ? 'Update' : 'Save'}</button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CreateChargebackAlertRule;
