import React from "react";
import * as helper from "../core/helper";
import { BarLoader } from "react-spinners";

const GridTop = ({ height, width, ...props }) => {
  const meta_data =
    props.data && props.data.meta_data ? props.data.meta_data : [];
  if (props.data) {
    //console.log("BARCHARTS data", props.data.meta_data);
  }

  if (!props.data || !props.data.meta_data) {
    return (
      //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
      <BarLoader color={"#999"} speedMultiplier={1} />
    );
  }
  if (meta_data.length !== 0) {
    return (
      <div id="textDiv" className="container-fluid">
        <table id="textTable">
          <tbody>
            {meta_data &&
              Object.keys(meta_data).map(function(keyName, keyIndex) {
                if (meta_data[keyName]["amount_average"]) {
                  return (
                    <tr key={keyIndex} className="wide_row">
                      <td className="equal_col">
                        <b>{"Average"}</b> <br></br>{" "}
                      </td>
                      <td className="equal_col">
                        {" "}
                        {helper.nFormatter(
                          meta_data[keyName]["amount_average"],
                          2
                        ) + "€"}{" "}
                      </td>
                    </tr>
                  );
                } else if (meta_data[keyName]["date_max"]) {
                  return (
                    <tr key={keyIndex} className="wide_row">
                      <td className="equal_col">
                        <b>{`Max `}</b> <br></br>{" "}
                        <p className="date_value">{` (${meta_data[keyName]["date_max"]}) `}</p>{" "}
                      </td>
                      <td className="equal_col">
                        {" "}
                        {helper.nFormatter(
                          meta_data[keyName]["amount_eur"],
                          2
                        ) + "€"}{" "}
                      </td>
                    </tr>
                  );
                } else if (meta_data[keyName]["date_min"]) {
                  return (
                    <tr key={keyIndex} className="wide_row">
                      <td className="equal_col">
                        <b>{`Min `}</b>
                        <br></br>{" "}
                        <p className="date_value">{` (${meta_data[keyName]["date_min"]}) `}</p>{" "}
                      </td>
                      <td className="equal_col">
                        {helper.nFormatter(
                          meta_data[keyName]["amount_eur"],
                          2
                        ) + "€"}{" "}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            {/*
        <div className="row">
          <div className="col-md-7">
            <h3 style={{ textAlign: "center" }}>{`Average:`}</h3>
          </div>
          <div className="col-md-3">
            <h3 style={{ textAlign: "center" }}>{`2.01M`}</h3>
          </div>
          <div className="col-md-2">
            <h3 style={{ textAlign: "center" }}>
              <i style={{ color: "green" }} className="fa fa-circle-thin fa-1x" />
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <h3 style={{ textAlign: "center" }}>{`Max (20-08-2019):`}</h3>
          </div>
          <div className="col-md-3">
            <h3 style={{ textAlign: "center" }}>{`4.36M`}</h3>
          </div>
          <div className="col-md-2">
            <h3 style={{ textAlign: "center" }}>
              <i style={{ color: "#71eb34" }} className="fa fa-smile-o fa-1x" />
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <h3 style={{ textAlign: "center" }}>{`Min (03-08-2019):`}</h3>
          </div>
          <div className="col-md-3">
            <h3 style={{ textAlign: "center" }}>{`1.12M`}</h3>
          </div>
          <div className="col-md-2">
            <h3 style={{ textAlign: "center" }}>
              <i style={{ color: "red" }} className="fa fa-frown-o fa-1x" />
            </h3>
          </div>
        </div>
        <h4 style={{ textAlign: "left" }}>{`Chart Type: ${chart_type}`}</h4>
        <h3 style={{ textAlign: "left" }}>
          {lable}
          {` (size: ${chart_SizesLable})`}
        </h3>{" "}
        <hr />
        */}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div>
        <h4 className="noDataFound-style">
          <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
          No data was found! 
        </h4>
      </div>
    );
  }
};

export default GridTop;
