import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as paymentLinkService from "../services/paymentLink";

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __setPaymentLink = data => ({
  type: actionTypes.SET_PAYMENT_LINK,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getPaymentLink = data => ({
  type: actionTypes.GET_PAYMENT_LINK,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __updatePaymentLink = data => ({
  type: actionTypes.UPDATE_PAYMENT_LINK,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getPaymentLinkList = data => ({
  type: actionTypes.PAYMENT_LINK_LIST,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getPaymentMethods = data => ({
  type: actionTypes.PAYMENT_METHODS,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __sendPaymentLink = data => ({
  type: actionTypes.PAYMENT_LINK_EMAIL,
  payload: data
});



/**
 * Connects to payment method form backend api.
 * Fetches required data for payment with selected payment method.
 * @param {object} req Request object.
 * Mandatory properties: payment_method, currency, amount, merchantid.
 * @returns {object} Response object.
 */
export const setPaymentLink = (req) =>{
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .setPaymentLink(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__setPaymentLink(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
};

/**
 * Connects to payment method form backend api.
 * Fetches required data for payment with selected payment method.
 * @param {object} req Request object.
 * Mandatory properties: payment_method, currency, amount, merchantid.
 * @returns {object} Response object.
 */
export const getPaymentLink = (req) =>{
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .getPaymentLink(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getPaymentLink(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
};


/**
 * Connects to payment method form backend api.
 * Fetches required data for payment with selected payment method.
 * @param {object} req Request object.
 * Mandatory properties: payment_method, currency, amount, merchantid.
 * @returns {object} Response object.
 */
export const updatePaymentLink = (req) =>{
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .updatePaymentLink(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__updatePaymentLink(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
};


/**
 * Connects to payment method form backend api.
 * Fetches required data for payment with selected payment method.
 * @param {object} req Request object.
 * Mandatory properties: payment_method, currency, amount, merchantid.
 * @returns {object} Response object.
 */
export const getPaymentLinkList = (req) =>{
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .getPaymentLinkList(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getPaymentLinkList(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
};


/**
 * Fetches payment methods from backend api.
 * @param {object} req Request object with merchantid as property.
 * @returns {object} Response object.
 */
export const getPaymentMethods = (req) => {
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .getPaymentMethods(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getPaymentMethods(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
}


/**
 * Fetches payment methods from backend api.
 * @param {object} req Request object with merchantid as property.
 * @returns {object} Response object.
 */
export const sendPaymentLink = (req) => {
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return paymentLinkService
      .sendPaymentLink(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__sendPaymentLink(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
}
