export const BASE_API_URL = "https://portal.sandbox.payabl.com/api"; //base api url

/** End-Points of accounts service Url's */
export const USER_LOGIN_API_URL = BASE_API_URL + "/accounts/auth/login";
export const USER_LOGOUT_API_URL = "/accounts/auth/logout";
export const USER_SIGNUP_API_URL = "";
export const USER_PASS_RESET_API_URL = BASE_API_URL + "/accounts/auth/password/reset";
export const USER_PASS_RESET_CONF_URL = BASE_API_URL + "/accounts/auth/password/reset/confirm";
export const REFRESH_TOKEN_URL = BASE_API_URL + "/accounts/auth/refresh_token";
export const VERIFY_TOKEN_URL = "";

/** End-Points of backoffice service Url's */
export const BACKOFFICE_GET_PERMISSION = BASE_API_URL + "/backoffice/get_permissions"; //post
export const BACKOFFICE_DASHBOARD = BASE_API_URL + "/backoffice/dashboard"; //post
export const BACKOFFICE_GET_DATA = BASE_API_URL + "/backoffice/get_data"; //post

/** End-Points of transaction-Log service Url's */
export const TRANSACTION_GET_DATA = BASE_API_URL + "/backoffice/transactionLog"; //post
export const TRANSACTION_LOG_DOWNLOAD = BASE_API_URL + "/backoffice/transactionLog_download";
export const TRANSACTION_DETAILS_GET_DATA = BASE_API_URL + "/backoffice/get_transaction_details";
export const TRANSACTION_BREAK_DOWN_DOWNLOAD = BASE_API_URL + "/backoffice/transactionBreakDown_download";

/** End-Points of pos-transaction-Log service Url's */
export const POS_TRANSACTION_GET_DATA = BASE_API_URL + "/backoffice/posLog"; //post
export const POS_TRANSACTION_DOWNLOAD_DATA = BASE_API_URL + "/backoffice/posLog_download";

/** End-Points finance data service Url's */
export const FINANCE_GET_DATA = BASE_API_URL + "/backoffice/finance_data"; //post
export const DOWNLOAD_PDF_FILE = BASE_API_URL + "/backoffice/download_pdf"; //post

/** End-Points payment service Url's */
export const INTERFACE_PAYMENT_CALL = BASE_API_URL + "/backoffice/interface_payment";
export const VIRTUAL_TERMINAL_PAYMENT = BASE_API_URL + "/backoffice/virtual_terminal_payment";
export const GET_PAYMENT_METHODS = BASE_API_URL + "/backoffice/get_payment_methods";
export const GET_TX_DIAGNOSE = BASE_API_URL + "/backoffice/tx_diagnose";

/** End-Points user management service Url's */
export const BACKOFFICE_ADD_SUBUSER = BASE_API_URL + "/backoffice/add_subuser";
export const BACKOFFICE_GET_SUBUSERS = BASE_API_URL + "/backoffice/get_subusers";
export const BACKOFFICE_UPDATE_SUBUSER = BASE_API_URL + "/backoffice/update_subuser";
export const BACKOFFICE_DELETE_SUBUSER = BASE_API_URL + "/backoffice/delete_subuser";

/** End-Points payment link service Url's */
export const SET_PAYMENT_LINK = BASE_API_URL + "/backoffice/payment_link_set";
export const GET_PAYMENT_LINK = BASE_API_URL + "/backoffice/payment_link_get";
export const UPDATE_PAYMENT_LINK = BASE_API_URL + "/backoffice/payment_link_update";
export const GET_PAYMENT_LINK_LIST = BASE_API_URL + "/backoffice/payment_link_list";
export const SEND_PAYMENT_LINK = BASE_API_URL + "/backoffice/payment_link_email";

/** End-Points merchants service Url's */
export const BOARD_PAYPAL_MERCHANT = BASE_API_URL + "/backoffice/board_paypal_merchant";
export const BOARD_PAYPAL_MERCHANT_STATUS = BASE_API_URL + "/backoffice/board_paypal_merchant_status";
export const BOARD_PAYPAL_MERCHANT_DISABLE = BASE_API_URL + "/backoffice/board_paypal_merchant_disable";
export const GET_PARTNER_MERCHANTS_DATA = BASE_API_URL + "/backoffice/get_partner_merchants_data";
export const GET_ECOMMERCE_FILES = BASE_API_URL + "/backoffice/get_ecommerce_files_name";
export const GET_ALL_MERCHANTS = BASE_API_URL + "/backoffice/get_all_companies_and_merchants";

/** End-Points chargeback service Url's */
export const GET_CHARGEBACK_ALERTS = BASE_API_URL + "/backoffice/chargeback_alert";
export const GET_CHARGEBACK_ALERTS_FILTERS = BASE_API_URL + "/backoffice/chargeback_alert_filters";
export const CHARGEBACK_RULES = BASE_API_URL + "/backoffice/chargeback_rules_of_company";
export const CHARGEBACK_ALERT_DECISION = BASE_API_URL + "/backoffice/chargeback_alert_decision";
export const CHARGEBACK_RULES_EXPORT_DOWNLOAD = BASE_API_URL + "/backoffice/chargeback_alert_download";
