import React from "react";
import { Component } from "react";

class ExpandableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: [],
      hide: false,
      showIndex: null,
      data: [
        {
          id: 1,
          code: "Issuer"
        },
        {
          id: 2,
          code: "Gateway"
        }
      ]
    };
  }

  handleExpand = row => {
    let newExpandedRows = [...this.state.expandedRows];
    let idxFound = newExpandedRows.findIndex(id => {
      return id === row.id;
    });

    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(row.id);
    }

    this.setState({ expandedRows: [...newExpandedRows] });
  };

  isExpanded = row => {
    const idx = this.state.expandedRows.find(id => {
      return id === row.id;
    });

    return idx > -1;
  };

  expandAll = dataSet => {
    if (this.state.expandedRows.length === dataSet.length) {
      let newExpandedRows = [];
      this.setState({ expandedRows: [...newExpandedRows] });
    } else {
      let newExpandedRows = dataSet.map(row => row.id);
      this.setState({ expandedRows: [...newExpandedRows] });
    }
  };

  renderTableHeader(color) {
    if (this.props.data) {
      if (this.props.data.Issuer) {
        let header = this.props.data.Issuer;

        return Object.keys(header).map(function(key) {
          if (key !== "total") {
            return (
              <th
                key={key + "renderTableHeader"}
                style={{ backgroundColor: color, textAlign: "center" }}
              >
                {key}
              </th>
            );
          } else {
            return null;
          }
        });
      }
    }
  }

  renderFirstRow() {
    if (this.props.data) {
      if (this.props.data.Issuer) {
        let header = this.props.data.Issuer;
        let column_count = 0;
        Object.keys(header).map(function(key) {
          if (key !== "total") {
            column_count = column_count + 1;
          }
          return null;
        });

        return (
          <tr className="table-bordered_extended">
            <th
              style={{
                backgroundColor: "#2E80B2",
                color: "white",
                textAlign: "center"
              }}
            >
              {"-"}
            </th>
            <th
              style={{
                backgroundColor: "#2E80B2",
                color: "white",
                textAlign: "center"
              }}
              colSpan={column_count}
            >
              Tx Count
            </th>
            <th
              style={{
                backgroundColor: "#2E80B2",
                color: "white",
                textAlign: "center"
              }}
              colSpan={column_count}
            >
              Rate %
            </th>
          </tr>
        );
      }
    }
  }

  renNestedKey = (key, i) => {
    return (
      <>
        {/*<button
          onClick={() => {
            this.setState({ hide: !this.state.hide, showIndex: null });
          }}
        >
          {this.state.showIndex === i ? "-" : "+"}
        </button>*/}
        <span>{key}</span>
      </>
    );
  };

  renderErr = data => {
    return Object.keys(data).map(function(key, index) {
      if (key !== "total") {
        const key_split = key.split(":");
        let newKey = key_split[key_split.length - 1];
        return (
          <tr>
            <td key={key + index}>-</td>
            <td key={newKey + index}>{newKey}</td>
            <td key={key + index}>{data[key]["fail_count"]}</td>
          </tr>
        );
      } else {
        return <td></td>;
      }
    });

    //let err = [];
    //err = data.filter(x => x != "total");
    //return err.join(", ");
  };

  splitKey(key) {
    const key_split = key.split(":");

    return key_split[key_split.length() - 1];
  }

  renderErrPercentage = data => {
    return Object.keys(data).map(function(key) {
      if (key !== "total") {
        return (
          <tr>
            <td key={"-renderErrPercentage"}>-</td>
            <td key={key + "renderErrPercentage"}>{key}</td>
            <td key={key + "secondrenderErrPercentage"}>
              {data[key]["custom_percent"] + "%"}
            </td>
          </tr>
        );
      } else {
        return <td></td>;
      }
    });

    //let err = [];
    //err = data.filter(x => x != "total");
    //return err.join(", ");
  };

  renderTableData(code) {
    var that = this;
    if (this.props.data) {
      if (this.props.data[code]) {
        let header = this.props.data[code];
        var i = 0;
        let row_num = 0;
        return Object.keys(header).map(function(key) {
          if (key !== "total" && i === 0) {
            i = i + 1;
            return Object.keys(header[key]).map(function(nestedkey, index) {
              row_num = row_num + 1;
              if (nestedkey !== "total") {
                return (
                  <>
                    <tr
                      onClick={() => {
                        that.setState({
                          hide: !that.state.hide,
                          showIndex: that.state.showIndex == null ? index : null
                        });
                      }}
                    >
                      {/* <td>{nestedkey}</td>  */}
                      <td
                        key={index + "renderTableData" + code}
                        style={{ textAlign: "center" }}
                      >
                        {that.renNestedKey(nestedkey, index)}
                      </td>
                      {Object.keys(header).map(function(key, index) {
                        if (key !== "total") {
                          if (
                            header[key][nestedkey] &&
                            header[key][nestedkey]["total"]
                          ) {
                            return (
                              <td
                                key={"TxCount" + index + code + row_num}
                                style={{ textAlign: "center" }}
                              >
                                {header[key][nestedkey]["total"].fail_count}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={"TxCount" + index + "-" + code + row_num}
                                style={{ textAlign: "center" }}
                              >
                                {"-"}
                              </td>
                            );
                          }
                        } else {
                          return null;
                        }
                      })}

                      {Object.keys(header).map(function(key, index) {
                        if (key !== "total") {
                          if (
                            header[key][nestedkey] &&
                            header[key][nestedkey]["total"]
                          ) {
                            return (
                              <td
                                key={"Rate" + index + code + row_num}
                                style={{ textAlign: "center" }}
                              >
                                {header[key][nestedkey]["total"]
                                  .custom_percent + "%"}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={"Rate" + index + "-" + code + row_num}
                                style={{ textAlign: "center" }}
                              >
                                {"-"}
                              </td>
                            );
                          }
                        } else {
                          return null;
                        }
                      })}
                      {/*row_num = row_num + 1*/}
                    </tr>
                    {/* Expanding Error Messages 
                    {that.state.showIndex === index && (
                      <tr key={index}>
                        <td>-</td>
                        {Object.keys(header).map(function(key, i) {
                          if (key !== "total") {
                            if (
                              header[key][nestedkey] &&
                              header[key][nestedkey]["total"]
                            ) {
                              return (
                                <td>
                                  
                                  {that.renderErr(header[key][nestedkey])}
                                </td>
                              );
                            } else {
                              return <td>{"-"}</td>;
                            }
                          }
                        })}
                        {Object.keys(header).map(function(key, i) {
                          if (key !== "total") {
                            if (
                              header[key][nestedkey] &&
                              header[key][nestedkey]["total"]
                            ) {
                              return (
                                <td>
                                  
                                  {that.renderErrPercentage(
                                    header[key][nestedkey]
                                  )}
                                </td>
                              );
                            } else {
                              return <td>{"-"}</td>;
                            }
                          }
                        })}
                      </tr>
                    )}*/}
                  </>
                );
              } else {
                return null;
              }
            });
          } else {
            return null;
          }
        });
      }
    }
  }

  getRows = row => {
    let rows = [];
    const firstRow = (
      <tr onClick={() => this.handleExpand(row)}>
        <th>
          <button>{this.isExpanded(row) ? "-" : "+ Expand"}</button> {row.code}
        </th>
      </tr>
    );

    rows.push(firstRow);

    if (this.isExpanded(row)) {
      const detailRow = (
        <tr className="row-details">
          <td colSpan="4" className="row-details">
            <br />
            <table className="table table-bordered_ table-hover table-striped">
              <tbody>
                {this.renderFirstRow()}

                <tr>
                  <th
                    style={{ backgroundColor: "#a1b1b3", textAlign: "center" }}
                  >
                    Error
                  </th>
                  {this.renderTableHeader("#C8E4E6")}
                  {this.renderTableHeader("#78bfc4")}
                </tr>

                {this.renderTableData(row.code)}
              </tbody>
            </table>
            <br />
          </td>
        </tr>
      );

      rows.push(detailRow);
    }

    return rows;
  };

  getExpandableTable = data => {
    const dataRows = data.map(row => {
      return this.getRows(row);
    });

    var RandomNumber = Math.floor(Math.random() * 100) + 1;

    return (
      <table className="table table-striped">
        <tbody key={"table_1" + RandomNumber}>
          <tr>
            <th onClick={() => this.expandAll(data)}>
              <button>
                {data.length === this.state.expandedRows.length
                  ? "-"
                  : "+ Expand All"}
              </button>
            </th>
          </tr>
          {dataRows}
        </tbody>
      </table>
    );
  };

  render() {
    return <div>{this.getExpandableTable(this.state.data)}</div>;
  }
}

export default ExpandableTable;
