import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Switch, Route } from "react-router-dom";
import * as routes from "./lib/constants/routes";
import { withRouter, Redirect } from "react-router";
import { Login } from "./components/screen/auth";
import { ResetPwd } from "./components/screen/auth";
import { ConfPwd } from "./components/screen/auth";
import Generic from "./components/layouts/dashboard/Generic";
import NotFound from "./components/screen/auth/NotFound";
import TransactionLog from "./components/screen/transactionLog/TransactionLog";
import TransactionDetails from "./components/screen/transactionLog/TransactionDetails";
import VirtualTerminal from "./components/screen/transactionLog/VirtualTerminal";
import PaymentLink from "./components/screen/paymentLink/PaymentLink";
import PaymentLinkDetails from "./components/screen/paymentLink/PaymentLinkDetails";
import EcommercePlugin from "./components/screen/ecommerce/plugin";
import GeneratePaymentLink from "./components/screen/paymentLink/GeneratePaymentLink";
import BatchRefund from "./components/screen/transactionLog/BatchRefund";
import CreditFundTrans from "./components/screen/transactionLog/CreditFundTrans";
import DashBoard2 from "./components/screen/dashboard2/index";
import history from "./history";
import TransactionList from "./components/screen/transactionLog/TransactionList";
import Admin from "./components/screen/admin/Admin";
import ChargebackPreventionRules from "./components/screen/chargebackAlerts/ChargebackPreventionRules/ChargebackPreventionRules";
import {ACCESS_TOKEN_KEY} from "./lib/constants/utils/token";
import cookieUtils from "./lib/constants/utils/cookies";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        cookieUtils.get(ACCESS_TOKEN_KEY) ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.LOGIN} />
        )
      }
    />
  );
};

class App extends Component {

	state = {
      windowWidth: 0,
      isMobile: false,
    };
	mobileWidth = 600;

	//window.addEventListener("resize", this.handleWindowResize);

  webRender = () => {
//	if (this.state.isMobile) {
//		return (
//			"Mobile"
//		);
//	} else {


    return (
      <Switch>
        <Route history={history} exact path={routes.LOGIN} component={Login} />
        <Route
          history={history}
          exact
          path={routes.RESET_PASS}
          component={ResetPwd}
        />
        <Route
          history={history}
          exact
          path={routes.CONF_PASS}
          component={ConfPwd}
        />
        <Route
          history={history}
          exact
          path={"/generic/transaction-details"}
          component={TransactionDetails}
        />
        <Route
          history={history}
          exact
          path={"/generic/chargeback-prevention-rules"}
          component={ChargebackPreventionRules}
        />
        <Route
          history={history}
          exact
          path={"/generic/refund"}
          component={BatchRefund}
        />
        <Route
          history={history}
          exact
          path={"/generic/credit-fund-trans"}
          component={CreditFundTrans}
        />
        <Route
          history={history}
          exact
          path={"/generic/transaction-list"}
          component={TransactionList}
        />
        <Route
          history={history}
          exact
          path={"/generic/generate-payment-link"}
          component={GeneratePaymentLink}
        />
        <PrivateRoute
          history={history}
          exact
          path={routes.GENERIC_ID}
          component={Generic}
        />
        <Route
          history={history}
          exact
          path={routes.VIRTUAL_TERM}
          component={VirtualTerminal}
        />
        <Route
          history={history}
          exact
          path={routes.TRANS_LOG}
          component={TransactionLog}
        />
        <Route
          history={history}
          exact
          path={routes.PAYMENT_LI}
          component={PaymentLink}
        />
         <Route
          history={history}
          exact
          path={routes.ECOMMERCE_PLUGINS}
          component={EcommercePlugin}
        />
        <Route
          history={history}
          exact
          path={routes.PAYMENT_LI_DETAILS}
          component={PaymentLinkDetails}
        />
        <Route
          history={history}
          exact
          path={routes.DashBoard2}
          component={DashBoard2}
        />
        <Route
          history={history}
          exact
          path={routes.CHART_DETAILS}
          component={Generic}
        />
        <Route
          history={history}
          exact
          path={routes.ADMIN}
          component={Admin}
        />
        <Route history={history} exact path={"/"} component={Login} />
        <Route history={history} exact path="*" component={NotFound} />
      </Switch>
    );
  };

  render() {
    return (
      <div>
        <Route render={this.webRender} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sideBarData: state.dashboardReducer
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));