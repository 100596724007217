import React from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateFilterButton from "./DateFilterButton";
import { DATEPICKER_RANGES, DATEPICKER_LOCALE } from "../../../lib/constants/filter";

const DateFilter = ({datetimeFrom, datetimeTo, onApply, ...props}) => {

	return (
		<DateRangePicker
			onApply={onApply}
			startDate={moment(datetimeFrom)}
			endDate={moment(datetimeTo)}
			maxDate= {moment(moment().endOf("day").format('YYYY-MM-DDTHH:mm:ss'))}
			ranges={ DATEPICKER_RANGES }
			locale={ DATEPICKER_LOCALE }
			showDropdowns
			showCustomRangeLabel
			showWeekNumbers
			timePicker
			timePicker24Hour
			timePickerSeconds
		>
			<DateFilterButton datetimeFrom={datetimeFrom} datetimeTo={datetimeTo} disabled={props.disabled} />
		</DateRangePicker>
	);
}

export default DateFilter;