export const bin_region = [
  { label: "International", value: "INTERNATIONAL" },
  { label: "Intra Regional", value: "EUROPE" },
  { label: "Domestic", value: "DOMESTIC" }
];

export const BIN_REGION_BY_ID = {
  INTERNATIONAL: "International",
  EUROPE: "Intra Regional",
  DOMESTIC: "Domestic"
};

export const card_brand = [
  { label: "Visa", value: "V" },
  { label: "MasterCard", value: "E" },
  { label: "Maestro", value: "T" }
];

export const pm_name = [
  { label: "Card", value: "Kreditkartenzahlungen" },
  { label: "WalletSolutions", value: "PPro" }
];

export const ps_name = [
  { label: "Powercash21", value: "OmniPayCredit" },
  { label: "WalletSolutions", value: "PPro" }
];

export const CARD_CREDIT_TYPE = [
  { label: "Amex", value: "A" },
  { label: "Diners", value: "D" },
  { label: "JCB", value: "J" },
  { label: "VISA", value: "V" },
  { label: "Eurocard/Mastercard", value: "E" },
  { label: "Maestro", value: "T" }
];

export const CARD_BRAND_BY_ID = {
  A: "Amex",
  D: "Diners",
  J: "JCB",
  V: "VISA",
  E: "Eurocard/Mastercard",
  T: "Maestro"
};

export const SEARCH_HISTORY_STATUS_3DSECURE = [
  { label: "3D Not in Range", value: "Not in Range" },
  { label: "3D Not enrolled", value: "Not enrolled" },
  { label: "3D Incomplete", value: "Incomplete" },
  { label: "3D Verified (Y)", value: "Verified" },
  { label: "3D Attempted (A)", value: "Attempted" },
  { label: "3D Failed (N)", value: "Not Verified" },
  { label: "3D Unknown (U)", value: "Unknown" },
  { label: "3D Error", value: "Error" }
];

export const SEARCH_HISTORY_STATUS_DEFAULT = [
  { label: "Failed", value: "Failed" },
  { label: "Successful", value: "Successful" },
  { label: "Pending", value: "In progress" },
  { label: "Aborted", value: "Aborted" },
  { label: "Accepted", value: "Accepted" },
  { label: "Unknown", value: "Unknown" }
];

export const SELECT_FRAUD_REPORT_CURRENCY = [
  { label: "Fraud Report Currency : EUR", value: "EUR" },
  { label: "Fraud Report Currency : USD", value: "USD" },
];

export const SEARCH_HISTORY_TYPE = [
  { label: "Order", value: "Order" },
  { label: "Capture", value: "Capture" },
  { label: "Cancel", value: "Cancellation" },
  { label: "Refund", value: "Refund" },
  { label: "3DSecure", value: "3DSecure" },
  { label: "Shoppayment-Init", value: "Shoppayment-Init" },
  { label: "Shoppayment-Start", value: "Shoppayment-Start" },
  { label: "Shoppayment-Continue", value: "Shoppayment-Continue" },
  { label: "CFT", value: "CFT" },
  { label: "Wire-Transfer", value: "Wire-Transfer" },
  //{ label: "Chargeback/Dispute", value: "Chargeback-Report" },
  { label: "Chargeback", value: "Chargeback" },
  { label: "Dispute", value: "Dispute" },
  {
    label: "Representment/Pre-Arb/Dispute Response",
    nvalue: "Chargeback-Representment"
  },
  { label: "2nd Chargeback/Pre-Arb/Pre-Arb Response", value: "2nd Chargeback" },
  { label: "Reversal Charge Back", value: "Reversal Charge Back" },
  { label: "Reversal 2nd Charge Back", value: "Reversal 2nd Charge Back" },
  { label: "Arbitration", value: "Arbitration" },
  { label: "Retrieval Request", value: "Retrieval Request" },
  { label: "Returned CFT", value: "Returned CFT" },
  { label: "Returned Refund", value: "Returned Refund" },
  { label: "Returned CFT Representment", value: "Returned CFT Representment" },
  {
    label: "Returned Refund Representment",
    value: "Returned Refund Representment"
  }
];

export const PAYMENT_METHOD_ID = [
  { label: "Credit Card", value: "1" }
  // { label: "Wechat", value: "5" },
  // { label: "Amex", value: "11" },
  // { label: "SOFORT", value: "17" },
  // { label: "Bitcoin", value: "18" },
  // { label: "SkrillWallet", value: "19" },
  // { label: "Platincoin", value: "20" },
  // { label: "Neteller", value: "45" },
  // { label: "Trustly", value: "116" }
];

export const PAYMENT_METHOD_ID_TO_NAME = {
  1: "Credit Card",
  5: "Wechat",
  11: "Amex",
  17: "SOFORT",
  18: "Bitcoin",
  19: "SkrillWallet",
  20: "Platincoin",
  45: "Neteller",
  116: "Trustly"
};

export const country_codes = [
  { label: "AFG", value: "AFG" },
  { label: "ALA", value: "ALA" },
  { label: "ALB", value: "ALB" },
  { label: "DZA", value: "DZA" },
  { label: "ASM", value: "ASM" },
  { label: "AND", value: "AND" },
  { label: "AGO", value: "AGO" },
  { label: "AIA", value: "AIA" },
  { label: "ATA", value: "ATA" },
  { label: "ATG", value: "ATG" },
  { label: "ARG", value: "ARG" },
  { label: "ARM", value: "ARM" },
  { label: "ABW", value: "ABW" },
  { label: "AUS", value: "AUS" },
  { label: "AUT", value: "AUT" },
  { label: "AZE", value: "AZE" },
  { label: "BHS", value: "BHS" },
  { label: "BHR", value: "BHR" },
  { label: "BGD", value: "BGD" },
  { label: "BRB", value: "BRB" },
  { label: "BLR", value: "BLR" },
  { label: "BEL", value: "BEL" },
  { label: "BLZ", value: "BLZ" },
  { label: "BEN", value: "BEN" },
  { label: "BMU", value: "BMU" },
  { label: "BTN", value: "BTN" },
  { label: "BOL", value: "BOL" },
  { label: "BIH", value: "BIH" },
  { label: "BWA", value: "BWA" },
  { label: "BVT", value: "BVT" },
  { label: "BRA", value: "BRA" },
  { label: "VGB", value: "VGB" },
  { label: "IOT", value: "IOT" },
  { label: "BRN", value: "BRN" },
  { label: "BGR", value: "BGR" },
  { label: "BFA", value: "BFA" },
  { label: "BDI", value: "BDI" },
  { label: "KHM", value: "KHM" },
  { label: "CMR", value: "CMR" },
  { label: "CAN", value: "CAN" },
  { label: "CPV", value: "CPV" },
  { label: "CYM", value: "CYM" },
  { label: "CAF", value: "CAF" },
  { label: "TCD", value: "TCD" },
  { label: "CHL", value: "CHL" },
  { label: "CHN", value: "CHN" },
  { label: "HKG", value: "HKG" },
  { label: "MAC", value: "MAC" },
  { label: "CXR", value: "CXR" },
  { label: "CCK", value: "CCK" },
  { label: "COL", value: "COL" },
  { label: "COM", value: "COM" },
  { label: "COG", value: "COG" },
  { label: "COD", value: "COD" },
  { label: "COK", value: "COK" },
  { label: "CRI", value: "CRI" },
  { label: "CIV", value: "CIV" },
  { label: "HRV", value: "HRV" },
  { label: "CUB", value: "CUB" },
  { label: "CYP", value: "CYP" },
  { label: "CZE", value: "CZE" },
  { label: "DNK", value: "DNK" },
  { label: "DJI", value: "DJI" },
  { label: "DMA", value: "DMA" },
  { label: "DOM", value: "DOM" },
  { label: "ECU", value: "ECU" },
  { label: "EGY", value: "EGY" },
  { label: "SLV", value: "SLV" },
  { label: "GNQ", value: "GNQ" },
  { label: "ERI", value: "ERI" },
  { label: "EST", value: "EST" },
  { label: "ETH", value: "ETH" },
  { label: "FLK", value: "FLK" },
  { label: "FRO", value: "FRO" },
  { label: "FJI", value: "FJI" },
  { label: "FIN", value: "FIN" },
  { label: "FRA", value: "FRA" },
  { label: "GUF", value: "GUF" },
  { label: "PYF", value: "PYF" },
  { label: "ATF", value: "ATF" },
  { label: "GAB", value: "GAB" },
  { label: "GMB", value: "GMB" },
  { label: "GEO", value: "GEO" },
  { label: "DEU", value: "DEU" },
  { label: "GHA", value: "GHA" },
  { label: "GIB", value: "GIB" },
  { label: "GRC", value: "GRC" },
  { label: "GRL", value: "GRL" },
  { label: "GRD", value: "GRD" },
  { label: "GLP", value: "GLP" },
  { label: "GUM", value: "GUM" },
  { label: "GTM", value: "GTM" },
  { label: "GGY", value: "GGY" },
  { label: "GIN", value: "GIN" },
  { label: "GNB", value: "GNB" },
  { label: "GUY", value: "GUY" },
  { label: "HTI", value: "HTI" },
  { label: "HMD", value: "HMD" },
  { label: "VAT", value: "VAT" },
  { label: "HND", value: "HND" },
  { label: "HUN", value: "HUN" },
  { label: "ISL", value: "ISL" },
  { label: "IND", value: "IND" },
  { label: "IDN", value: "IDN" },
  { label: "IRN", value: "IRN" },
  { label: "IRQ", value: "IRQ" },
  { label: "IRL", value: "IRL" },
  { label: "IMN", value: "IMN" },
  { label: "ISR", value: "ISR" },
  { label: "ITA", value: "ITA" },
  { label: "JAM", value: "JAM" },
  { label: "JPN", value: "JPN" },
  { label: "JEY", value: "JEY" },
  { label: "JOR", value: "JOR" },
  { label: "KAZ", value: "KAZ" },
  { label: "KEN", value: "KEN" },
  { label: "KIR", value: "KIR" },
  { label: "PRK", value: "PRK" },
  { label: "KOR", value: "KOR" },
  { label: "KWT", value: "KWT" },
  { label: "KGZ", value: "KGZ" },
  { label: "LAO", value: "LAO" },
  { label: "LVA", value: "LVA" },
  { label: "LBN", value: "LBN" },
  { label: "LSO", value: "LSO" },
  { label: "LBR", value: "LBR" },
  { label: "LBY", value: "LBY" },
  { label: "LIE", value: "LIE" },
  { label: "LTU", value: "LTU" },
  { label: "LUX", value: "LUX" },
  { label: "MKD", value: "MKD" },
  { label: "MDG", value: "MDG" },
  { label: "MWI", value: "MWI" },
  { label: "MYS", value: "MYS" },
  { label: "MDV", value: "MDV" },
  { label: "MLI", value: "MLI" },
  { label: "MLT", value: "MLT" },
  { label: "MHL", value: "MHL" },
  { label: "MTQ", value: "MTQ" },
  { label: "MRT", value: "MRT" },
  { label: "MUS", value: "MUS" },
  { label: "MYT", value: "MYT" },
  { label: "MEX", value: "MEX" },
  { label: "FSM", value: "FSM" },
  { label: "MDA", value: "MDA" },
  { label: "MCO", value: "MCO" },
  { label: "MNG", value: "MNG" },
  { label: "MNE", value: "MNE" },
  { label: "MSR", value: "MSR" },
  { label: "MAR", value: "MAR" },
  { label: "MOZ", value: "MOZ" },
  { label: "MMR", value: "MMR" },
  { label: "NAM", value: "NAM" },
  { label: "NRU", value: "NRU" },
  { label: "NPL", value: "NPL" },
  { label: "NLD", value: "NLD" },
  { label: "ANT", value: "ANT" },
  { label: "NCL", value: "NCL" },
  { label: "NZL", value: "NZL" },
  { label: "NIC", value: "NIC" },
  { label: "NER", value: "NER" },
  { label: "NGA", value: "NGA" },
  { label: "NIU", value: "NIU" },
  { label: "NFK", value: "NFK" },
  { label: "MNP", value: "MNP" },
  { label: "NOR", value: "NOR" },
  { label: "OMN", value: "OMN" },
  { label: "PAK", value: "PAK" },
  { label: "PLW", value: "PLW" },
  { label: "PSE", value: "PSE" },
  { label: "PAN", value: "PAN" },
  { label: "PNG", value: "PNG" },
  { label: "PRY", value: "PRY" },
  { label: "PER", value: "PER" },
  { label: "PHL", value: "PHL" },
  { label: "PCN", value: "PCN" },
  { label: "POL", value: "POL" },
  { label: "PRT", value: "PRT" },
  { label: "PRI", value: "PRI" },
  { label: "QAT", value: "QAT" },
  { label: "REU", value: "REU" },
  { label: "ROU", value: "ROU" },
  { label: "RUS", value: "RUS" },
  { label: "RWA", value: "RWA" },
  { label: "BLM", value: "BLM" },
  { label: "SHN", value: "SHN" },
  { label: "KNA", value: "KNA" },
  { label: "LCA", value: "LCA" },
  { label: "MAF", value: "MAF" },
  { label: "SPM", value: "SPM" },
  { label: "VCT", value: "VCT" },
  { label: "WSM", value: "WSM" },
  { label: "SMR", value: "SMR" },
  { label: "STP", value: "STP" },
  { label: "SAU", value: "SAU" },
  { label: "SEN", value: "SEN" },
  { label: "SRB", value: "SRB" },
  { label: "SYC", value: "SYC" },
  { label: "SLE", value: "SLE" },
  { label: "SGP", value: "SGP" },
  { label: "SVK", value: "SVK" },
  { label: "SVN", value: "SVN" },
  { label: "SLB", value: "SLB" },
  { label: "SOM", value: "SOM" },
  { label: "ZAF", value: "ZAF" },
  { label: "SGS", value: "SGS" },
  { label: "SSD", value: "SSD" },
  { label: "ESP", value: "ESP" },
  { label: "LKA", value: "LKA" },
  { label: "SDN", value: "SDN" },
  { label: "SUR", value: "SUR" },
  { label: "SJM", value: "SJM" },
  { label: "SWZ", value: "SWZ" },
  { label: "SWE", value: "SWE" },
  { label: "CHE", value: "CHE" },
  { label: "SYR", value: "SYR" },
  { label: "TWN", value: "TWN" },
  { label: "TJK", value: "TJK" },
  { label: "TZA", value: "TZA" },
  { label: "THA", value: "THA" },
  { label: "TLS", value: "TLS" },
  { label: "TGO", value: "TGO" },
  { label: "TKL", value: "TKL" },
  { label: "TON", value: "TON" },
  { label: "TTO", value: "TTO" },
  { label: "TUN", value: "TUN" },
  { label: "TUR", value: "TUR" },
  { label: "TKM", value: "TKM" },
  { label: "TCA", value: "TCA" },
  { label: "TUV", value: "TUV" },
  { label: "UGA", value: "UGA" },
  { label: "UKR", value: "UKR" },
  { label: "ARE", value: "ARE" },
  { label: "GBR", value: "GBR" },
  { label: "USA", value: "USA" },
  { label: "UMI", value: "UMI" },
  { label: "URY", value: "URY" },
  { label: "UZB", value: "UZB" },
  { label: "VUT", value: "VUT" },
  { label: "VEN", value: "VEN" },
  { label: "VNM", value: "VNM" },
  { label: "VIR", value: "VIR" },
  { label: "WLF", value: "WLF" },
  { label: "ESH", value: "ESH" },
  { label: "YEM", value: "YEM" },
  { label: "ZMB", value: "ZMB" },
  { label: "ZWE", value: "ZWE" }
];

export const currency_codes = [
  { label: "AFN", value: "AFN" },
  { label: "ALL", value: "ALL" },
  { label: "DZD", value: "DZD" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "AOA", value: "AOA" },
  { label: "XCD", value: "XCD" },
  { label: "ARS", value: "ARS" },
  { label: "AMD", value: "AMD" },
  { label: "AWG", value: "AWG" },
  { label: "AUD", value: "AUD" },
  { label: "AZN", value: "AZN" },
  { label: "BSD", value: "BSD" },
  { label: "BHD", value: "BHD" },
  { label: "BDT", value: "BDT" },
  { label: "BBD", value: "BBD" },
  { label: "BYR", value: "BYR" },
  { label: "BEF", value: "BEF" },
  { label: "BZD", value: "BZD" },
  { label: "XOF", value: "XOF" },
  { label: "BMD", value: "BMD" },
  { label: "BTN", value: "BTN" },
  { label: "INR", value: "INR" },
  { label: "BOB", value: "BOB" },
  { label: "BAM", value: "BAM" },
  { label: "BWP", value: "BWP" },
  { label: "BRL", value: "BRL" },
  { label: "BND", value: "BND" },
  { label: "BGN", value: "BGN" },
  { label: "BIF", value: "BIF" },
  { label: "KHR", value: "KHR" },
  { label: "XAF", value: "XAF" },
  { label: "CAD", value: "CAD" },
  { label: "CVE", value: "CVE" },
  { label: "KYD", value: "KYD" },
  { label: "CLP", value: "CLP" },
  { label: "CNY", value: "CNY" },
  { label: "COP", value: "COP" },
  { label: "KMF", value: "KMF" },
  { label: "CDF", value: "CDF" },
  { label: "NZD", value: "NZD" },
  { label: "CRC", value: "CRC" },
  { label: "HRK", value: "HRK" },
  { label: "CUP", value: "CUP" },
  { label: "TRY", value: "TRY" },
  { label: "CZK", value: "CZK" },
  { label: "DKK", value: "DKK" },
  { label: "DJF", value: "DJF" },
  { label: "DOP", value: "DOP" },
  { label: "EGP", value: "EGP" },
  { label: "SVC", value: "SVC" },
  { label: "ERN", value: "ERN" },
  { label: "EEK", value: "EEK" },
  { label: "ETB", value: "ETB" },
  { label: "FKP", value: "FKP" },
  { label: "FOK", value: "FOK" },
  { label: "FJD", value: "FJD" },
  { label: "FIM", value: "FIM" },
  { label: "FRF", value: "FRF" },
  { label: "XPF", value: "XPF" },
  { label: "GMD", value: "GMD" },
  { label: "GEL", value: "GEL" },
  { label: "DEM", value: "DEM" },
  { label: "GHC", value: "GHC" },
  { label: "GIP", value: "GIP" },
  { label: "GRD", value: "GRD" },
  { label: "GTQ", value: "GTQ" },
  { label: "GNF", value: "GNF" },
  { label: "GWP", value: "GWP" },
  { label: "GYD", value: "GYD" },
  { label: "HTG", value: "HTG" },
  { label: "ITL", value: "ITL" },
  { label: "HNL", value: "HNL" },
  { label: "HKD", value: "HKD" },
  { label: "HUF", value: "HUF" },
  { label: "ISK", value: "ISK" },
  { label: "IDR", value: "IDR" },
  { label: "IRR", value: "IRR" },
  { label: "IQD", value: "IQD" },
  { label: "IEP", value: "IEP" },
  { label: "ILS", value: "ILS" },
  { label: "JMD", value: "JMD" },
  { label: "JPY", value: "JPY" },
  { label: "JOD", value: "JOD" },
  { label: "KZT", value: "KZT" },
  { label: "KES", value: "KES" },
  { label: "KPW", value: "KPW" },
  { label: "KRW", value: "KRW" },
  { label: "KWD", value: "KWD" },
  { label: "KGS", value: "KGS" },
  { label: "LAK", value: "LAK" },
  { label: "LBP", value: "LBP" },
  { label: "LSL", value: "LSL" },
  { label: "LRD", value: "LRD" },
  { label: "LYD", value: "LYD" },
  { label: "CHF", value: "CHF" },
  { label: "LTL", value: "LTL" },
  { label: "LUF", value: "LUF" },
  { label: "MOP", value: "MOP" },
  { label: "MKD", value: "MKD" },
  { label: "MGF", value: "MGF" },
  { label: "MWK", value: "MWK" },
  { label: "MYR", value: "MYR" },
  { label: "MVR", value: "MVR" },
  { label: "MTL", value: "MTL" },
  { label: "MRO", value: "MRO" },
  { label: "MUR", value: "MUR" },
  { label: "MXN", value: "MXN" },
  { label: "MDL", value: "MDL" },
  { label: "MNT", value: "MNT" },
  { label: "MAD", value: "MAD" },
  { label: "MZM", value: "MZM" },
  { label: "MMK", value: "MMK" },
  { label: "NAD", value: "NAD" },
  { label: "ZAR", value: "ZAR" },
  { label: "NPR", value: "NPR" },
  { label: "NLG", value: "NLG" },
  { label: "ANG", value: "ANG" },
  { label: "NIO", value: "NIO" },
  { label: "NGN", value: "NGN" },
  { label: "NOK", value: "NOK" },
  { label: "OMR", value: "OMR" },
  { label: "PKR", value: "PKR" },
  { label: "PAB", value: "PAB" },
  { label: "PGK", value: "PGK" },
  { label: "PYG", value: "PYG" },
  { label: "PEN", value: "PEN" },
  { label: "PHP", value: "PHP" },
  { label: "PLN", value: "PLN" },
  { label: "PTE", value: "PTE" },
  { label: "QAR", value: "QAR" },
  { label: "ROL", value: "ROL" },
  { label: "RUR", value: "RUR" },
  { label: "RUB", value: "RUB" },
  { label: "RWF", value: "RWF" },
  { label: "SHP", value: "SHP" },
  { label: "WST", value: "WST" },
  { label: "STD", value: "STD" },
  { label: "SAR", value: "SAR" },
  { label: "CSD", value: "CSD" },
  { label: "SCR", value: "SCR" },
  { label: "SLL", value: "SLL" },
  { label: "SGD", value: "SGD" },
  { label: "SKK", value: "SKK" },
  { label: "SIT", value: "SIT" },
  { label: "SBD", value: "SBD" },
  { label: "SOS", value: "SOS" },
  { label: "SSP", value: "SSP" },
  { label: "ESP", value: "ESP" },
  { label: "LKR", value: "LKR" },
  { label: "SDD", value: "SDD" },
  { label: "SDG", value: "SDG" },
  { label: "SRG", value: "SRG" },
  { label: "SZL", value: "SZL" },
  { label: "SEK", value: "SEK" },
  { label: "SYP", value: "SYP" },
  { label: "TWD", value: "TWD" },
  { label: "TJS", value: "TJS" },
  { label: "TZS", value: "TZS" },
  { label: "THB", value: "THB" },
  { label: "TOP", value: "TOP" },
  { label: "TTD", value: "TTD" },
  { label: "TND", value: "TND" },
  { label: "TMM", value: "TMM" },
  { label: "UGX", value: "UGX" },
  { label: "UAH", value: "UAH" },
  { label: "AED", value: "AED" },
  { label: "GBP", value: "GBP" },
  { label: "USS", value: "USS" },
  { label: "USN", value: "USN" },
  { label: "UYU", value: "UYU" },
  { label: "UZS", value: "UZS" },
  { label: "VUV", value: "VUV" },
  { label: "VEF", value: "VEF" },
  { label: "VND", value: "VND" },
  { label: "YER", value: "YER" },
  { label: "ZMW", value: "ZMW" },
  { label: "ZWD", value: "ZWD" }
];

export default {
  bin_region,
  card_brand,
  country_codes,
  currency_codes,
  pm_name,
  ps_name,
  SEARCH_HISTORY_STATUS_DEFAULT,
  SEARCH_HISTORY_STATUS_3DSECURE,
  SEARCH_HISTORY_TYPE,
  BIN_REGION_BY_ID
};
