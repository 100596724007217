import {api} from "./api";
import * as apiLink from '../lib/constants/apiLink';

const baseUrl = apiLink.BASE_API_URL;
 

// POST /backoffice/board_merchant API
export const boardPayPalMerchant = (token, body) => {
	return api(baseUrl, token).post(apiLink.BOARD_PAYPAL_MERCHANT, body);
}

// POST /backoffice/board_paypal_merchant_status API
export const boardPayPalMerchantStatus = (token, body) => {
	return api(baseUrl, token).post(apiLink.BOARD_PAYPAL_MERCHANT_STATUS, body);
}

// POST /backoffice/board_paypal_merchant_disable API
export const boardPayPalMerchantDisable = (token, body) => {
	return api(baseUrl, token).post(apiLink.BOARD_PAYPAL_MERCHANT_DISABLE, body);
}