import React, { Component } from "react";
import Toggle from 'react-toggle';

class SubUserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    
    this.sectionData = {}
    this.selectedSubuser = "";
   
   	this.handleToggleChange = this.handleToggleChange.bind(this);
   	this.updateSettings = this.updateSettings.bind(this);
  }
 
 	updateSettings() {
 		let data = this.props.data;
 		let settings = {};
 	
		Object.keys(data).forEach((key, index) => {
			settings[key] = data[key].isenabled ? true : false;
		});
 		
 		this.setState({
 			settings: settings	
 		});
 	}
 
 	handleToggleChange(event) {
 		this.setState({
      settings: {
        ...this.state.settings,
        [event.target.name]: event.target.checked,
      }
    });
 	}
 
 	renderToggleField(label, isEnabled, key) {
 		return (
 			<div key={key}>
	 			<div className="row" style={{ paddingTop:"10px" }}>
	 				<div className="col-md-8 col-sm-8 col-xs-6">
			 			<div style={{ fontWeight: this.state.settings[key] ? "bold" : "normal" }}>
			 				{label}
			 			</div>
		 			</div>
		 			<div className="col-md-4 col-sm-4 col-xs-6">
						<Toggle
						name={key}
						checked={isEnabled}
					  id='toggle'
					  onChange={this.handleToggleChange}
					  disabled={this.props.clickedSave} />
				  </div>
				  {/*<div className="col-md-3 col-sm-3"></div>*/}
			  </div>
		  </div>
 		);	
 	}
 
 	componentDidMount() {
 		this.selectedSubuser = this.props.selectedSubuser;
 		this.updateSettings();
 	}
 
 	componentDidUpdate() {
 		if (this.selectedSubuser !== this.props.selectedSubuser) {
 			this.selectedSubuser = this.props.selectedSubuser;
  		this.updateSettings();
  	}
  	
  	Object.keys(this.state.settings).forEach((key, index) => {
			this.sectionData[key] = this.state.settings[key] ? 1 : 0;
 		})
 		this.props.setEditBody(this.props.title, this.sectionData);
 	}
 
  render() {
  	
  	let data = this.props.data;
  	let toggleFields = [];
  	
  	if (this.state.settings) {
  		Object.keys(data).forEach((key, index) => {
				toggleFields.push(
	 				this.renderToggleField(data[key].name, this.state.settings[key], key)
	 			);
	 		})
  	}
  	
		return (
			<div style={{ padding:"10px", backdropFilter:"blur(5px)", border:"2px solid #64a6d0", backgroundColor:"white", height:"auto" }}>
				<h4 style={{ color:"#2e80b2", fontWeight:"bold" }}>{ this.props.title.toUpperCase() }</h4>
				<div>
					{ toggleFields }
				</div>
			</div>
			
		);
  }
}

export default SubUserSettings;
