import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as virtualTerminalService from "../services/virtualTerminal";


/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getVirtualTerminal = data => ({
  type: actionTypes.VIRTUAL_TERMINAL,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getPaymentMethods = data => ({
  type: actionTypes.PAYMENT_METHODS,
  payload: data
});

/**
 *
 * @param {object} data
 * @returns {object} Response with type and payload.
 */
const __getTXDiagnose = data => ({
  type: actionTypes.TX_DIAGNOSE,
  payload: data
});

/**
 * Connects to payment method form backend api.
 * Fetches required data for payment with selected payment method.
 * @param {object} req Request object.
 * Mandatory properties: payment_method, currency, amount, merchantid.
 * @returns {object} Response object.
 */
export const getVirtualTerminal = (req) =>{
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return virtualTerminalService
      .getVirtualTerminal(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getVirtualTerminal(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
};

/**
 * Fetches payment methods from backend api.
 * @param {object} req Request object with merchantid as property.
 * @returns {object} Response object.
 */
export const getPaymentMethods = (req) => {
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return virtualTerminalService
      .getPaymentMethods(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getPaymentMethods(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
}

/**
 * Fetches transaction data from backend api.
 * @param {object} req Request object with merchantid as property.
 * @returns {object} Response object.
 */
export const getTXDiagnose = (req) => {
  return dispatch =>{
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return virtualTerminalService
      .getTXDiagnose(token, req)
      .then(res =>{
         if(res.data){
           return dispatch(__getTXDiagnose(res.data));
         }
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
  };
}