import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProgressionBar from "./ProgressionBar";
import PaymentDetailsForm from "./PaymentDetailsForm";
import LinkDetailsForm from "./LinkDetailsForm";
import SendCopyForm from "./SendCopyForm";
import moment from "moment";
import * as helper from "../../general/core/helper";
import { setPaymentLink, sendPaymentLink, updatePaymentLink } from "../../../actions/paymentLinkAction";
import { ScaleLoader } from "react-spinners";

const FormBlock = {
  width:"100%",
  display:"block",
  minWidth: "250px",
  margin:"0",
  boxSizing: "border-box",
  fontSize: "14px",
  lineHeight: "1.4",
  opacity: "1",
  fontFamily:"SourceSansPro, Helvetica, Arial, sans-serif",
  color: "#555",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
  height: "120px"
};

const FORM_STATE = {
  PAYMENT_DETAILS: 0,
  LINK_DETAILS: 1,
  SEND_COPY: 2
};

const FORM_TITLES = [
  {
    title: "Payment details"
  },
  {
    title: "Link details"
  },
  {
    title: "Copy + share"
  },
];

const PaymentLinkForm = ({ setPaymentLink, sendPaymentLink, updatePaymentLink, serverFilterData, closePopup }) => {
  const [paymentLinkData, setPaymentLinkData] = useState({});
  const [date, setDate] = useState(new Date());
  const [formState, setFormState] = useState(FORM_STATE.PAYMENT_DETAILS);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [amountInputWrong, setAmountInputWrong] = useState(false);
  const [emailInputWrong, setEmailInputWrong] = useState(false);
  const [usePrevFormData, setUsePrevFormData] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    merchant: "",
    order: "",
    currency: "",
    amount: "0.00",
    lifespanHours: "24",
    email: "",
    paymentCurrencies: [],
  });

  const tick = () => {
    let lifespanDate = new Date();
    lifespanDate.setHours(date.getHours() + parseInt(formData.lifespanHours));
    let msec = Date.parse(lifespanDate);

    setDate(new Date());

    return msec;
  };

  const validateAmountInput = useCallback(() => {
    if (formData.amount) {
      if (/^\d*\.?\d{0,2}$/.test(formData.amount)) {
        setAmountInputWrong(false);
        return true;
      }

      else {
        setAmountInputWrong(true);
        return false;
      }
    }
    return true;
  }, [formData]);

  const validateEmailInput = useCallback(() => {
    if (/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i.test(formData.email) && formData.email !== "") {
      setEmailInputWrong(false);
      return true;
    } else {
      setEmailInputWrong(true);
      return false;
    }
  }, [formData]);

  const checkForm = useCallback(() => {
    if (formState === FORM_STATE.PAYMENT_DETAILS) {
      if (formData.merchant !== "" && formData.currency !== "" && formData.amount !== "" && validateAmountInput()) {
        setIsFormFilled(true);
      } else {
        setIsFormFilled(false);
      }
    }

    if (formState === FORM_STATE.LINK_DETAILS) {
      if (formData.lifespanHours !== "" && validateEmailInput()) {
        setIsFormFilled(true);
      } else {
        setIsFormFilled(false);
      }
    }

    if (formState === FORM_STATE.SEND_COPY) {
      setIsFormFilled(true);
    }
  }, [formState, formData, validateAmountInput, validateEmailInput]);

  const updateForm = (name = "", value = "") => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const nextForm = () => {
    if (formState === FORM_STATE.PAYMENT_DETAILS) {
      setFormState(FORM_STATE.LINK_DETAILS);
      setIsFormFilled(false);
    } else if (formState === FORM_STATE.LINK_DETAILS) {
      setFormState(FORM_STATE.SEND_COPY)
      setIsFormFilled(false);
    }
  };

  const prevForm = () => {
    if (formState === FORM_STATE.LINK_DETAILS) {
      setFormState(FORM_STATE.PAYMENT_DETAILS);
      setIsFormFilled(true);
      setUsePrevFormData(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let msec = tick();

    setLoading(true);
    setIsFormFilled(false);

    const requestBody = {
      merchant: formData["merchant"],
      order: formData["order"] !== "" ? formData["order"] : "N/A",
      currency: formData["currency"],
      amount: formData["amount"],
      email: formData["email"],
      lifespan: parseInt(msec)/1000,
      created: parseInt(Date.parse(new Date()))/1000,
    };

    const data = await setPaymentLink(requestBody);

    if (helper.isJsonString(data.payload)) {
      const dataParsed = JSON.parse(data.payload);
      const data_parsed = dataParsed['linkid'];
      if (!isNaN(data_parsed)) {
        setPaymentLinkData(dataParsed);
        nextForm();
      }
    }
  };

  const handleSubmitEmail = async (event) => {
    event.preventDefault();

    if (validateEmailInput()) {
      let requestBody = {
        paymentlink: paymentLinkData["paymentLink"],
        order: paymentLinkData["order"],
        amount: paymentLinkData["amount"],
        currency: paymentLinkData["currency"],
        date: moment(new Date(parseInt(paymentLinkData["lifespan"])*1000)).format("DD.MM.YYYY HH:mm:ss"),
        merchant_company_name: paymentLinkData["companyName"] ? paymentLinkData["companyName"] : "",
        email: formData["email"],
        transactionid: paymentLinkData["transactionid"],
        merchant_company_address1: paymentLinkData["companyAddress1"] ? paymentLinkData["companyAddress1"] : "",
        merchant_company_address2: paymentLinkData["companyAddress2"] ? paymentLinkData["companyAddress2"] : "",
      };

      let requestBodyEmailUpdate = {
        linkid: paymentLinkData["linkid"],
        merchant: paymentLinkData["merchant"],
        order: paymentLinkData["order"],
        lifespan: paymentLinkData["lifespan"],
        created: paymentLinkData["created"],
        currency: paymentLinkData["currency"],
        amount: paymentLinkData["amount"],
        email: formData["email"],
      };

      const response = await sendPaymentLink(requestBody);
      await updatePaymentLink(requestBodyEmailUpdate);

      if (response?.payload) {
        setEmailSent(true);
        return;
      }

      if (response && !hasOwnProperty(response.error)) {
        return;
      }
    } else {
      setEmailInputWrong(true);
    }
  };

  useEffect(() => {
    checkForm();
  }, [formData, checkForm]);

  return (
    <div style={{height:"619px"}}>
      <div className="container-fluid" style={{width:"100%", height:"100%", margin:"0", padding:"0", boxShadow: "0 0 15px 0 rgb(0 0 0 / 10%)", backgroundColor: "rgb(248, 250, 252)"}}>
        <form onSubmit={handleSubmit}
        style={{display:"flex", flexDirection:"column", justifyContent:"space-around", alignItems:"center", border:"0px", backgroundColor: "#F8FAFC"}}>

          <div style={FormBlock}>
            <div
            style = {{width: "100%", textAlign: "center", color: "white", padding: "20px 10px 20px 10px", background: "#283048", margin: "0",
            fontFamily: "Helvetica, Arial, sans-serif", fontSize: "16px", height: "100%"}}>
              <div>
                New Payment Link
              </div>

              <div style={{marginTop: "15px"}}>
                <ProgressionBar progressionValues={FORM_TITLES} progressionStates={FORM_STATE} state={formState}/>
              </div>
            </div>
          </div>

          <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", border: "0px none", padding:"32px", height:"499px"}}>

            { formState === FORM_STATE.PAYMENT_DETAILS ?
                <PaymentDetailsForm
                  updateForm = {updateForm}
                  serverFilterData = {serverFilterData}
                  amountInputWrong = {amountInputWrong}
                  prevFormData = {formData}
                  usePrevFormData = {usePrevFormData}/> :
              formState === FORM_STATE.LINK_DETAILS ?
                <LinkDetailsForm
                  updateForm = {updateForm}/> :
              formState === FORM_STATE.SEND_COPY ?
                <SendCopyForm
                  updateForm = {updateForm}
                  paymentLinkData = {paymentLinkData}
                  handleSubmitEmail = {handleSubmitEmail}
                  emailInputWrong = {emailInputWrong}
                  emailSent = {emailSent}
                  email = {formData["email"]}/> :
              null
            }

            <div style={{marginTop:"15px", width: "100%", textAlign:"center"}}>
            { formState === FORM_STATE.SEND_COPY ?
                <button
                  type="button"
                  className="primaryBtn"
                  onClick={() => closePopup()}
                  style={{display:"inline-block", width:"200px"}}
                  disabled={!isFormFilled}>
                    Back to payment page
                </button> :
                <>
                  <div style={{float:"left"}}>
                    { formState === FORM_STATE.PAYMENT_DETAILS ?
                      <button
                        type="button"
                        className="secondaryBtn"
                        onClick={() => closePopup()}>
                          Cancel
                      </button> :
                      <button
                        type="button"
                        value="Back"
                        className="secondaryBtn"
                        onClick={() => prevForm()}>
                          Back
                      </button>
                    }
                  </div>
                  <div style={{float:"right"}}>
                    { formState === FORM_STATE.PAYMENT_DETAILS ?
                      <button
                        type="button"
                        className="primaryBtn"
                        onClick={() => nextForm()}
                        disabled={!isFormFilled}>
                          Next
                      </button> :
                      <button
                        type="button"
                        className="primaryBtn"
                        onClick={e => handleSubmit(e)}
                        disabled={!isFormFilled}>
                          { loading ? <ScaleLoader color={"#a8aebb"} height={30} width={4}/> : "Generate link" }
                      </button>
                    }
                  </div>
                </>
            }
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  paymentLink: state.paymentLinkReducer,
  generatePaymentLink: state.paymentLinkReducer,
});

const mapDispatchToProps = {
  setPaymentLink,
  sendPaymentLink,
  updatePaymentLink,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentLinkForm)
);