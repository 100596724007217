import { api } from "./api";
import { api_for_file_download } from "./api";
import * as apiLink from "../lib/constants/apiLink";

const baseUrl = apiLink.BASE_API_URL;

export const getTransData = (token, filterBody, params, cancelToken) => {
	return api(baseUrl, token).post(apiLink.TRANSACTION_GET_DATA, filterBody, { params: params, cancelToken: cancelToken.token });
};

export const getTransDetails = (token, body) => {
  return api(baseUrl, token).post(apiLink.TRANSACTION_DETAILS_GET_DATA, body);
};

export const interfacePaymentCall = (token, body) => {
  return api(baseUrl, token).post(apiLink.INTERFACE_PAYMENT_CALL, body);
};

export const downloadTransData = (token, body) => {
  return api_for_file_download(baseUrl, token).post(apiLink.TRANSACTION_LOG_DOWNLOAD, body);
};

export const downloadTransBreakDownData = (token, body) => {
  return api_for_file_download(baseUrl, token).post(apiLink.TRANSACTION_BREAK_DOWN_DOWNLOAD, body);
};