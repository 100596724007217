import React from 'react';

const ErrorCodeCell = ({ original }) => {

  const renderErrorMessage = (error_code, error_message, sub_errors) => {
    return (
      <div className="container-fluid">
        <table className="table table-bordered stripped">
          <tbody id="errorTableBody">
            <tr className="errorTable-row">
              <td className="errorTable-col">{error_code}</td>

              {error_code !== "0" ? (
                <td className="errorTable-col">{error_message}</td>
              ) : null}
            </tr>

            {sub_errors
              ? Object.keys(sub_errors).map((item, index) => {
                if (sub_errors[item].error_code !== "0") {
                  return (
                    <tr key={index} className="errorTable-row">
                      <td className="errorTable-col">
                        {sub_errors[item].error_code}
                      </td>
                      <td className="errorTable-col">
                        {sub_errors[item].error_message}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
              : null}
          </tbody>
        </table>
      </div>
    );
  }

	return (
		<span>
      { original.error_code && original.error_code !== "0" ?
        <section>
          { original.error_code }
          <button className="error-message-btn">
            <span style={{ fontSize: "16px" }}>
              <i className="fa fa-info-circle" style={{ color: "red" }} />
            </span>
            <span className="error-message-btn-hover">
              { renderErrorMessage(original.error_code, original.error_message, original.sub_errors) }
            </span>
          </button>
        </section> : null
      }
    </span>
	)
};

export default ErrorCodeCell;