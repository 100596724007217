import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";

export default class NestedTableChart extends Component {
  stack = [];
  level = 0;

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  /**
   * Function for creating columns
   */

  createColumns = columns => {
    columns.unshift("name");
    columns = columns.filter(function(item, pos) {
      return columns.indexOf(item) === pos;
    });
    return columns.map(column => {
      return {
        dataField: column,
        // classes: 'color-custom-cell',
        // classes : this.customeStyle,
        classes:
          column === "chargeback_volume_usd"
            ? this.currencyStyle
            : column === "sales_count" || column === "sales_amount_usd"
            ? ""
            : this.customStyle,
        text: this.Capitalize(column),
        sort: true,
        formatter:
          column === "sales_amount_usd" ||
          column === "chargeback_volume_usd" ||
          column === "fraud_volume_usd"
            ? this.currencyFormatter
            : null
      };
    });
  };

  currencyStyle = (cell, row, rowIndex, colIndex) => {
    if (cell >= 500 && cell <= 1000) {
      return "bg-yellow";
    } else if (cell >= 1001 && cell <= 2000) {
      return "bg-orange";
    } else if (cell > 2000) {
      return "bg-red";
    }
  };

  customStyle = (cell, row, rowIndex, colIndex) => {
    if (cell >= 5 && cell <= 10) {
      return "bg-yellow";
    } else if (cell >= 11 && cell <= 20) {
      return "bg-orange";
    } else if (cell > 20) {
      return "bg-red";
    }
  };

  createRows = rows => {
    // let nestedRows = [];
    for (let k in rows) {
      if (k !== "total") {
        this.level = 0;
        this.addToStack(k, rows[k]);
      }
    }

    rows.total.name = "Total";
    this.stack.push(rows.total);

    this.stack.forEach((item, index) => {
      item["id"] = "" + index;
    });
    return this.stack;
  };

  addToStack = (k, rows) => {
    this.level++;

    if (rows.total) {
      // this.level++;
      rows.total.name = this.getListStyle(this.level) + k;
      this.stack.push(rows.total);
    }

    if (rows.sales_count) {
      // this.level++;
      rows.name = this.getListStyle(this.level) + k;
      this.stack.push(rows);
      this.level--;
      return;
    }

    if (!rows.total) {
      this.level--;
      return;
    }

    for (let k1 in rows) {
      if (k1 !== "total") {
        this.addToStack(k1, rows[k1]);
      }
    }
    this.level--;
  };

  getListStyle(level) {
    switch (level) {
      case 1:
        return "";
      case 2:
        return "• ";
      case 3:
        return "••";
      default:
        return "";
    }
  }

  /**
   * Function for formating the cell values in column
   */

  Capitalize(str) {
    var frags = str.split("_");
    var i = 0;
    for (i = 0; i < frags.length; i++) {
      if (frags[i].length === 2 || frags[i].length === 3) {
        frags[i] = frags[i].toUpperCase();
      } else {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
    }
    return frags.join(" ");
  }

  currencyFormatter = (cell, row) => {
    return (
      <span>
        <NumberFormat
          value={cell}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          displayType={"text"}
          fixedDecimalScale={true}
        />
      </span>
    );
  };

  render() {
    const { lable } = this.props;
    this.stack = [];
    this.level = 0;

    const columns = this.createColumns(this.props.data.columns);
    const rows = this.createRows(this.props.data.rows);
    // init of Search bar component
    const { SearchBar } = Search;
    // Custome options for pagination component
    const options = {
      custom: true,
      totalSize: rows ? rows.length : 0,
      sizePerPage: rows.length
      // sizePerPage: 5
    };

    return (
      <div
        className=" box filter-chips margin-auto"
        style={{ paddingTop: "6%" }}
      >
        {columns && (
          <div className="table-responsive tab-pagination" key={lable}>
            <ToolkitProvider
              keyField={"id"}
              data={rows ? rows : []}
              columns={
                columns !== null
                  ? columns
                  : [{ dataField: "id", text: "No Data Found" }]
              }
              search
              // rowClasses={ rowClasses }
            >
              {props => (
                <div style={{ paddingTop: "3%" }}>
                  <hr />
                  <SearchBar {...props.searchProps} key={lable} />
                  <hr />
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {({ baseProps, paginationProps, paginationTableProps }) => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          {...paginationTableProps}
                        />
                        {/* {options.totalSize > 5 && (
                          <PaginationListStandalone {...paginationProps} />
                        )} */}
                      </div>
                    )}
                  </PaginationProvider>
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </div>
    );
  }
}
