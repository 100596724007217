import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as boardMerchantService from "../services/boardMerchant";

const _boardPayPalMerchant = data => ({
    type: actionTypes.BOARD_PAYPAL_MERCHANT,
    payload: data
  });

const _boardPayPalMerchantStatus = data => ({
    type: actionTypes.BOARD_PAYPAL_MERCHANT_STATUS,
    payload: data
  });

const _boardPayPalMerchantDisable = data => ({
    type: actionTypes.BOARD_PAYPAL_MERCHANT_DISABLE,
    payload: data
  });


export const boardPayPalMerchantAction = (body) => {
    return dispatch => {
      let token = {
        Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
      };
      return boardMerchantService
      .boardPayPalMerchant(token, body)
      .then(res => {
        return dispatch(_boardPayPalMerchant(res.data));
      })
      .catch(error => {
        if(error.response !== undefined){
          console.log(error.response);
        }
      });
    };
};

export const boardPayPalMerchantStatusAction = (body) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return boardMerchantService
    .boardPayPalMerchantStatus(token, body)
    .then(res => {
      return dispatch(_boardPayPalMerchantStatus(res.data));
    })
    .catch(error => {
      if(error.response !== undefined){
        console.log(error.response);
      }
    });
  };
};

export const boardPayPalMerchantDisableAction = (body) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return boardMerchantService
    .boardPayPalMerchantDisable(token, body)
    .then(res => {
      return dispatch(_boardPayPalMerchantDisable(res.data));
    })
    .catch(error => {
      if (error.response !== undefined){
        console.log(error.response)
      }
    });
  };
};