import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  posTransactionsData: null,
  filterData: null,
  transDetailsData: null,
};

const posTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POS_TRANSACTION_LOG_DATA:
      return {
        ...state,
        posTransactionsData: action.payload
      };

    case actionTypes.TRANSACTION_DETAILS:
      return{
        ...state,
        transDetailsData: action.payload
      }

    case actionTypes.FILTER_DATA:
      return {
        ...state,
        filterData: action.payload
      };

    default:
      return state;
  }
};

export default posTransactionsReducer;