import * as actionTypes from "../../lib/constants/actionTypes";
import moment from "moment-timezone";

const initialFilterData = {
  dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
  dateTo: moment().endOf("month").format("YYYY-MM-DD"),
  datetimeFrom: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
  datetimeTo: moment().endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
  txLogParams: { txcount: [1] },
  records_per_page: 5,
};

const initialState = {
  chargebackAlertsData: null,
  chargebackAlertsFilters: null,
  filterData: initialFilterData,
};

const chargebackAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHARGEBACK_ALERTS:
      return {
        ...state,
        chargebackAlertsData: action.payload,
      };
    case actionTypes.CHARGEBACK_ALERTS_FILTERS:
      return {
        ...state,
        chargebackAlertsFilters: action.payload,
      };
    case actionTypes.ALL_MERCHANTS:
      return {
        ...state,
        merchantsData: action.payload,
      };
    case actionTypes.CHARGEBACK_PREVENTION_RULES:
      return {
        ...state,
        chargebackPreventionRules: action.payload,
      };
    case actionTypes.CHARGEBACK_RULES_OF_COMPANY:
      return {
        ...state,
        chargebackRulesOfCompany: action.payload,
      };
    default:
      return state;
  }
};

export default chargebackAlertsReducer;